import React, { useEffect, useState, useRef } from 'react';
import debounce from 'lodash/debounce';
import { connect } from 'react-redux';
import Select from 'react-select';
import FormGroup from 'react-bootstrap/FormGroup';
import InputGroup from 'react-bootstrap/InputGroup';
import Form from 'react-bootstrap/Form';
import './styles.css';

import { AsyncQueryWrapper } from '../../../../../common';
import {
  actions as PolicyActions,
  utils as PolicyUtils,
} from '../../../../../policies';

import AsyncPolicySummaryCard from '../AsyncPolicySummaryCard';

const styles = {
  select_container: {
    backgroundColor: '#f0f0f0',
    padding: '10px',
    width: '100%',
  },
  select_options: {
    display: 'inline-block',
    width: '50%',
    height: '100%',
    padding: '.5rem',
    fontSize: '1rem',
  },
};

const RenewalPolicySelect = props => {
  const { input: selected, meta } = props;
  const { error, touched } = meta;

  const [value, setValue] = useState('');
  const [inputVal, setInputVal] = useState('');
  const [optionSelected, setOptionSelected] = useState('');

  return (
    <AsyncQueryWrapper
      load={async ({ term }) => {
        if (!term) return Promise.resolve([]);
        const response = await props.searchPolicies(term);
        return response.policies;
      }}
      variables={{ term: value }}
      render={({ loading, data }) => {
        if (!data) data = [];
        return (
          <div>
            <FormGroup style={styles.select_container}>
              <Form.Label htmlFor="expiring-policy">Expiring Policy</Form.Label>
              <Select
                id="expiring-policy"
                isLoading={loading}
                options={data}
                filterOption={policies => {
                    return (
                      PolicyUtils.hasBeenIssued(policies.data) &&
                      !PolicyUtils.hasBeenRenewed(policies.data)
                    );
                }}
                getOptionLabel={option => {
                  return option.policy_number;
                }}
                value={data.filter(({ id }) => id === selected.value)}
                onChange={(policy, actionMeta) => {
                  selected.onChange(policy.id);
                  setValue(policy.policy_number);
                  setInputVal(' ');// Needs to be set for expected behavior
                  props.onSelectedPolicyChange(policy);
                  setOptionSelected(policy.policy_number);
                }}
                backspaceRemovesValue={false}
                isClearable={false}
                components={{
                  Option: renderOption,
                  Input: DebouncedInput,
                }}
                debounceWaitTime={250}
                optionSelected={optionSelected}
                updateParentOnChange={val => {
                  if (val !== value) {
                    setValue(val);
                    setInputVal(' ');// Needs to be set for expected behavior
                  }
                }}
                placeholder={false}
                controlShouldRenderValue={false}
                openMenuOnFocus={true}
                openMenuOnClick={true}
                closeMenuOnScroll={false}
                inputValue={inputVal}
                styles={{
                  option: (base, {isFocused, isSelected}) => ({ 
                    ...base, 
                    color: '#555',
                    backgroundColor: isFocused || isSelected ? '#eff7ff' : '#fff'
                  })
                }}
              />
              <Form.Text style={{ fontSize: '0.625rem' }} muted>
                Search by insured name or policy number
              </Form.Text>
              {touched &&
                !!error && (
                  <Form.Text style={{ fontSize: '0.625rem' }} muted>
                    {error}
                  </Form.Text>
                )}
            </FormGroup>
            {selected.value && (
              <AsyncPolicySummaryCard policy_id={selected.value} />
            )}
          </div>
        );
      }}
    />
  );
};

const DebouncedInput = props => {
  const { selectProps } = props;
  const { optionSelected } = selectProps;
  const [value, setValue] = useState('');
  const inputRef = useRef();
  const { debounceWaitTime, updateParentOnChange } = selectProps;

  useEffect(() => {
      setValue(optionSelected);
    },[optionSelected]
  );

  useEffect(() => {
    if(props.selectProps?.menuIsOpen){
      inputRef?.current?.focus();
    }
  },[props.selectProps.menuIsOpen]
);

  const handleChange = e => {
    const val = e.target.value;
    setValue(val);
    callOnChangeWithDebounce(val);
  };

  const callOnChangeWithDebounce = debounce(val => {
    return updateParentOnChange(val);
  }, debounceWaitTime);

  return (
    <div 
      ref={props.innerRef}
      onFocus={props.onFocus} 
      onBlur={props.onBlur}
    >
      <InputGroup>
        <InputGroup.Text style={{ border: '0' }}>
          <i className="bi bi-search" />
        </InputGroup.Text>
        <Form.Control
          value={value}
          style={{ border: '0', boxShadow: 'none' }}
          onChange={handleChange}
          ref={inputRef}
        />
      </InputGroup>
    </div>
  );
};

const renderOption = ({ data: policy, innerProps}) => {
  return (
    <span {...innerProps} className="renewal-select-option">
      <strong style={styles.select_options}>{policy.named_insured.name}</strong>
      <span style={styles.select_options}>{policy.policy_number}</span>
    </span>
  );
};

export default connect(
  null,
  dispatch => ({
    searchPolicies(term) {
      return dispatch(PolicyActions.searchPolicies({ term }));
    },
  })
)(RenewalPolicySelect);
