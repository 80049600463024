export const CREATE_SUBMISSION_REQUEST = 'CREATE_SUBMISSION_REQUEST';
export const CREATE_SUBMISSION_SUCCESS = 'CREATE_SUBMISSION_SUCCESS';
export const CREATE_RENEWAL_SUBMISSION_REQUEST =
  'CREATE_RENEWAL_SUBMISSION_REQUEST';
export const CREATE_RENEWAL_SUBMISSION_SUCCESS =
  'CREATE_RENEWAL_SUBMISSION_SUCCESS';
export const CREATE_SUBMISSION_FAILURE = 'CREATE_SUBMISSION_FAILURE';
export const GET_SUBMISSION_BY_ID_REQUEST = 'GET_SUBMISSION_BY_ID_REQUEST';
export const GET_SUBMISSION_BY_ID_SUCCESS = 'GET_SUBMISSION_BY_ID_SUCCESS';
export const GET_SUBMISSION_BY_ID_FAILURE = 'GET_SUBMISSION_BY_ID_FAILURE';
export const GET_SUBMISSION_BY_RATING_ID_REQUEST =
  'GET_SUBMISSION_BY_RATING_ID_REQUEST';
export const GET_SUBMISSION_BY_RATING_ID_SUCCESS =
  'GET_SUBMISSION_BY_RATING_ID_SUCCESS';
export const GET_SUBMISSION_BY_RATING_ID_FAILURE =
  'GET_SUBMISSION_BY_RATING_ID_FAILURE';
export const SEARCH_SUBMISSIONS_REQUEST = 'SEARCH_SUBMISSIONS_REQUEST';
export const SEARCH_SUBMISSIONS_SUCCESS = 'SEARCH_SUBMISSIONS_SUCCESS';
export const MANUALLY_CLEAR_SUBMISSION_REQUEST =
  'MANUALLY_CLEAR_SUBMISSION_REQUEST';
export const MANUALLY_CLEAR_SUBMISSION_SUCCESS =
  'MANUALLY_CLEAR_SUBMISSION_SUCCESS';
export const MANUALLY_CLEAR_SUBMISSION_FAILURE =
  'MANUALLY_CLEAR_SUBMISSION_FAILURE';
export const CONFIRM_SUBMISSION_CLEARANCE_REQUEST =
  'CONFIRM_SUBMISSION_CLEARANCE_REQUEST';
export const CONFIRM_SUBMISSION_CLEARANCE_SUCCESS =
  'CONFIRM_SUBMISSION_CLEARANCE_SUCCESS';
export const CONFIRM_SUBMISSION_CLEARANCE_FAILURE =
  'CONFIRM_SUBMISSION_CLEARANCE_FAILURE';
export const DELETE_DECLINED_SUBMISSION_REQUEST =
  'DELETE_DECLINED_SUBMISSION_REQUEST';
export const DELETE_DECLINED_SUBMISSION_SUCCESS =
  'DELETE_DECLINED_SUBMISSION_SUCCESS';
export const DELETE_DECLINED_SUBMISSION_FAILURE =
  'DELETE_DECLINED_SUBMISSION_FAILURE';
export const GET_SUBMISSION_BY_QUOTE_ID_REQUEST =
  'GET_SUBMISSION_BY_QUOTE_ID_REQUEST';
export const GET_SUBMISSION_BY_QUOTE_ID_SUCCESS =
  'GET_SUBMISSION_BY_QUOTE_ID_SUCCESS';
export const GET_SUBMISSION_BY_QUOTE_ID_FAILURE =
  'GET_SUBMISSION_BY_QUOTE_ID_FAILURE';
export const UPDATE_BROKER_REQUEST = 'UPDATE_BROKER_REQUEST';
export const UPDATE_BROKER_SUCCESS = 'UPDATE_BROKER_SUCCESS';
export const GET_SUBMISSION_BY_BINDER_ID_REQUEST =
  'GET_SUBMISSION_BY_BINDER_ID_REQUEST';
export const GET_SUBMISSION_BY_BINDER_ID_SUCCESS =
  'GET_SUBMISSION_BY_BINDER_ID_SUCCESS';
export const DELETE_BINDER_SUCCESS = 'DELETE_BINDER_SUCCESS';
export const GET_SUBMISSION_BY_POLICY_ID_REQUEST =
  'GET_SUBMISSION_BY_POLICY_ID_REQUEST';
export const GET_SUBMISSION_BY_POLICY_ID_SUCCESS =
  'GET_SUBMISSION_BY_POLICY_ID_SUCCESS';
export const POST_DECLINE_SUBMISSION_FAILURE =
  'POST_DECLINE_SUBMISSION_FAILURE';
export const POST_DECLINE_SUBMISSION_REQUEST =
  'POST_DECLINE_SUBMISSION_REQUEST';
export const POST_DECLINE_SUBMISSION_SUCCESS =
  'POST_DECLINE_SUBMISSION_SUCCESS';
