import { withRouter } from '../../../../common/components/WithRouter';
import { connect } from 'react-redux';
import QuoteWorkspace from './QuoteWorkspace';

import { actions as quotes } from '../../../../quote';

const mapDispatchToProps = (dispatch, props) => {
  const { navigate } = props;
  return {
    copyQuote: (params = {}) => {
      const { withRatings = false } = params;
      return dispatch(
        quotes.createQuoteCopy(props.quote.id, { withRatings })
      ).then(res => {
        navigate(`/quotes/${res.quote.id}/`, {state: {
          copy: true,
          copiedWithRatings: withRatings,
        }});
        return res;
      });
    },
    getQuoteLetter() {
      return dispatch(quotes.getQuoteLetter(props.quote.id)).then(({ url }) => {
        window.location.href = url;
      });
    },
  };
};

export default withRouter(
  connect(
    null,
    mapDispatchToProps
  )(QuoteWorkspace)
);
