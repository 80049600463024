import React from 'react';
import { connect } from 'react-redux';
import { getFormValues } from 'redux-form';

import LimitsSummaryView from '../../LimitsSummary';

const WithValues = connect((state, props) => state => {
  return {
    values: getFormValues(props.name)(state),
  };
})(props => props.render({ values: props.values }));

export default function LimitsSummaryContainer({ formName, ...props }) {
  return (
    <WithValues
      name={formName}
      render={({ values }) => {
        return (
          <LimitsSummaryView
            style={props.style}
            rating={props.rating}
            values={values}
            className={props.className}
          />
        );
      }}
    />
  );
}
