import React from 'react';

const styles = {
  label: {
    fontSize: '0.8125rem',
    fontWeight: 'bold',
    marginTop: '1rem',
    marginBottom: '0.375rem',
    textTransform: 'uppercase',
  },
  value: { fontSize: '0.875rem' },
};

// placeholder is an 'em-dash':
// http://www.chicagomanualofstyle.org/qanda/data/faq/topics/HyphensEnDashesEmDashes/faq0002.html
const placeholder = '\u2014';

const valueOrPlaceholder = value => {
  if (value == null) return placeholder;
  if (value === '') return placeholder;
  return value;
};

export default function SummaryItem({ label, value }) {
  return (
    <React.Fragment>
      <h5 style={styles.label}>{label}</h5>
      <div style={styles.value}>{valueOrPlaceholder(value)}</div>
    </React.Fragment>
  );
}
