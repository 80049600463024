import jmp from 'json-merge-patch';
import isEmpty from 'lodash/isEmpty';

import { selectors } from '../redux';

import {
  GET_RATING_BY_ID_REQUEST,
  GET_RATING_BY_ID_SUCCESS,
  GET_RATING_BY_BINDER_REQUEST,
  GET_RATING_BY_BINDER_SUCCESS,
  GET_RATING_BY_POLICY_REQUEST,
  GET_RATING_BY_POLICY_SUCCESS,
  UPDATE_RISK_ANALYSIS_REQUEST,
  UPDATE_RISK_ANALYSIS_SUCCESS,
  UPDATE_LIMITS_RETENTIONS_REQUEST,
  UPDATE_LIMITS_RETENTIONS_SUCCESS,
  GET_PREMIUM_REQUEST,
  GET_PREMIUM_SUCCESS,
  UPDATE_PREMIUM_ADJUSTMENTS_REQUEST,
  UPDATE_PREMIUM_ADJUSTMENTS_SUCCESS,
  CREATE_RATING_COPY_REQUEST,
  CREATE_RATING_COPY_SUCCESS,
  CREATE_RATING_FROM_QUOTE_REQUEST,
  CREATE_RATING_FROM_QUOTE_SUCCESS,
} from './constants';

export function get(rating_id) {
  return (dispatch, getState, { api }) => {
    const state = getState();
    const rating = selectors.ratingById(state, rating_id);
    if (rating) {
      return Promise.resolve({
        rating,
      });
    }
    dispatch({ type: GET_RATING_BY_ID_REQUEST });
    return api.ratings.get(rating_id).then(response => {
      dispatch({
        type: GET_RATING_BY_ID_SUCCESS,
        payload: {
          rating: response.rating,
        },
      });
      return response;
    });
  };
}

export function getByBinder(binder_id) {
  return (dispatch, getState, { api }) => {
    const state = getState();
    const rating = selectors.ratingByBinder(state, binder_id);
    if (rating) {
      return Promise.resolve({
        rating,
      });
    }
    dispatch({ type: GET_RATING_BY_BINDER_REQUEST });
    return api.ratings.getByBinder(binder_id).then(response => {
      dispatch({
        type: GET_RATING_BY_BINDER_SUCCESS,
        payload: {
          rating: response.rating,
        },
      });
      return response;
    });
  };
}

export function getByPolicy(binder_id) {
  return (dispatch, getState, { api }) => {
    const state = getState();
    const rating = selectors.ratingByPolicy(state, binder_id);
    if (rating) {
      return Promise.resolve({
        rating,
      });
    }
    dispatch({ type: GET_RATING_BY_POLICY_REQUEST });
    return api.ratings.getByPolicy(binder_id).then(response => {
      dispatch({
        type: GET_RATING_BY_POLICY_SUCCESS,
        payload: {
          rating: response.rating,
        },
      });
      return response;
    });
  };
}

export function updateRiskAnalysis(
  rating_id,
  new_risk_analysis,
  old_risk_analysis
) {
  return async (dispatch, getState, { api }) => {
    dispatch({ type: UPDATE_RISK_ANALYSIS_REQUEST });
    const diff = jmp.generate(old_risk_analysis, new_risk_analysis);
    if (isEmpty(diff)) return { success: true };
    const response = await api.ratings.updateRiskAnalysis(rating_id, diff);
    await dispatch({
      type: UPDATE_RISK_ANALYSIS_SUCCESS,
      payload: {
        rating: response.rating,
      },
    });
    return response;
  };
}

export function updateLimitsRetentions(
  rating_id,
  new_limits_retentions,
  old_limits_retentions
) {
  return async (dispatch, getState, { api }) => {
    dispatch({ type: UPDATE_LIMITS_RETENTIONS_REQUEST });
    const diff = jmp.generate(old_limits_retentions, new_limits_retentions);
    if (isEmpty(diff)) return { success: true };
    const response = await api.ratings.updateLimitsRetentions(rating_id, diff);
    await dispatch({
      type: UPDATE_LIMITS_RETENTIONS_SUCCESS,
      payload: {
        rating: response.rating,
      },
    });
    return response;
  };
}

export function updatePremiumAdjustments(rating_id, new_premium, old_premium) {
  return async (dispatch, getState, { api }) => {
    dispatch({ type: UPDATE_PREMIUM_ADJUSTMENTS_REQUEST });
    const diff = jmp.generate(old_premium, new_premium);
    if (isEmpty(diff)) return { success: true };
    const response = await api.ratings.updatePremiumAdjustments(
      rating_id,
      diff
    );
    await dispatch({
      type: UPDATE_PREMIUM_ADJUSTMENTS_SUCCESS,
      payload: {
        rating: response.rating,
      },
    });
    return response;
  };
}

export function getPremium(rating_id) {
  return async (dispatch, getState, { api }) => {
    dispatch({ type: GET_PREMIUM_REQUEST });
    const response = await api.ratings.getPremium(rating_id);
    await dispatch({
      type: GET_PREMIUM_SUCCESS,
      payload: {
        rating: response.rating,
      },
    });
    return response;
  };
}

export function createRatingCopy(rating_id) {
  return async (dispatch, getState, { api }) => {
    dispatch({ type: CREATE_RATING_COPY_REQUEST });
    const response = await api.ratings.createCopy(rating_id);
    await dispatch({
      type: CREATE_RATING_COPY_SUCCESS,
      payload: {
        rating: response.rating,
        quote: response.quote,
      },
    });
    return response;
  };
}

export function createFromQuote(quote_id) {
  return (dispatch, getState, { api }) => {
    dispatch({ type: CREATE_RATING_FROM_QUOTE_REQUEST });
    return api.ratings.createFromQuote(quote_id).then(async response => {
      await dispatch({
        type: CREATE_RATING_FROM_QUOTE_SUCCESS,
        payload: {
          rating: response.rating,
          submission: response.submission,
          quote: response.quote,
        },
      });
      return response;
    });
  };
}
