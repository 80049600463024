import get from 'lodash/get';

export const mapFormValuesToRiskAnalysis = form_values => {
  const selected_coverages = get(form_values, 'selected_coverages', []);
  const coverage_specific = {};
  for (let cov of selected_coverages) {
    coverage_specific[cov] = get(form_values, `coverage_specific.${cov}`);
  }
  const riskAnalysis = { ...form_values, coverage_specific };
  const expiration_date = getExpirationDate(form_values);
  if (expiration_date) riskAnalysis.expiration_date = expiration_date;
  return riskAnalysis;
};

const getExpirationDate = form_values => {
  const standard_policy_period = form_values.standard_policy_period;
  const expiration_date = form_values.expiration_date;
  if (!standard_policy_period && expiration_date) return expiration_date;
};
