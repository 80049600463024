import validator from 'validator';

const broker = {
  errors: {
    commission(value) {
      value = String(value || '');
      if (validator.isEmpty(value)) return 'Required';
      if (!validator.isDecimal(value)) return 'Please enter a valid number';
    },
    address: {
      street_line_1(value) {
        value = value || '';
        if (validator.isEmpty(value)) return 'Required';
      },
      city(value) {
        value = value || '';
        if (validator.isEmpty(value)) return 'Required';
      },
      state(value) {
        value = value || '';
        if (validator.isEmpty(value)) return 'Required';
      },
      zip(value) {
        value = value || '';
        if (validator.isEmpty(value)) return 'Required';
        if (!validator.isPostalCode(value, 'US'))
          return 'Please enter a valid Zip Code';
      },
    },
    phone(value) {
      value = value || '';
      if (validator.isEmpty(value)) return 'Required';
      const phoneRegex = /^(\+?1[ -]?)?(\([0-9]{3}\)|[0-9]{3})[ -]?[0-9]{3}[- ]?[0-9]{4}( ?(x|ext|,) ?[0-9]+)?$/;
      if (!value.match(phoneRegex)) return 'Phone number appears invalid';
    },
  },
  is_required: {
    address: {
      street_line_1: true,
      city: true,
      state: true,
      zip: true,
    },
    phone: true,
    commission: true,
  },
};

const details = {
  errors: {
    broker: {
      name(value) {
        value = value || '';
        if (validator.isEmpty(value)) return 'Required';
      },
      brokerage(value) {
        value = value || '';
        if (validator.isEmpty(value)) {
          return 'Required';
        }
      },
      email(value) {
        value = value || '';
        if (validator.isEmpty(value)) {
          return 'Required';
        }
        if (!validator.isEmail(value)) {
          return 'Please enter a valid e-mail address';
        }
      },
    },
    named_insured: {
      name(value) {
        value = value || '';
        if (validator.isEmpty(value)) return 'Required';
      },
      address: {
        street_line_1(value) {
          value = value || '';
          if (validator.isEmpty(value)) return 'Required';
        },
        city(value) {
          value = value || '';
          if (validator.isEmpty(value)) return 'Required';
        },
        state(value) {
          value = value || '';
          if (validator.isEmpty(value)) return 'Required';
        },
        zip(value) {
          value = value || '';
          if (validator.isEmpty(value)) return 'Required';
          if (!validator.isPostalCode(value, 'US'))
            return 'Please enter a valid Zip Code';
        },
      },
    },
  },
  is_required: {
    broker: {
      name: true,
      brokerage: true,
      email: true,
    },
    named_insured: {
      name: true,
      address: {
        street_line_1: true,
        city: true,
        state: true,
        zip: true,
      },
    },
  },
};

export { broker, details };
