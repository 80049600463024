import React from 'react';
import { Link } from 'react-router-dom';

import AlertBanner from './AlertBanner';
import { SubmissionSummaryCard } from '../../../../submissions';

const styles = {
  outer_container: {
    border: 'solid 1px #979797',
  },
  inner_container: {
    padding: '26px 80px',
  },
  header: {
    container: {
      marginBottom: '20px',
    },
    banner: {
      display: 'inline-block',
      margin: '0px',
    },
  },
  expiring_policy: {
    fontSize: '12px',
    marginTop: '-15px',
  },
  banner: {
    container: {
      backgroundColor: 'rgba(240, 240, 240, 0.6)',
      borderTopColor: '#31708f',
    },
    header: {
      color: '#31708f',
      marginTop: '0px',
    },
  },
};

function ReturnToClearanceBanner({ submission_id }) {
  return (
    <AlertBanner
      style={styles.banner}
      renderHeader={() => (
        <h3 style={styles.banner.header}>Renewal quote not started</h3>
      )}
      renderBody={() => 'Return to clearance results to start your quote'}
      renderButton={() => (
        <Link className="btn btn-primary" to={`/submissions/${submission_id}/`}>
          Return to Clearance
        </Link>
      )}
    />
  );
}

export default function UnstartedRenewalCard({ submission }) {
  return (
    <div style={styles.outer_container}>
      <ReturnToClearanceBanner submission_id={submission.id} />
      <div style={styles.inner_container}>
        <SubmissionSummaryCard submission={submission} />
      </div>
    </div>
  );
}
