import { template } from 'lodash';
export class Validation {
  constructor(config, conditions, message) {
    this.config = config;
    this.conditions = conditions;
    this.message = message;
  }
  isValid(value, values, context) {
    return this.conditions.every(condition =>
      condition.eval(value, values, context)
    );
  }
  getMessage(value, values, context) {
    if (this.isValid(value, values, context)) return null;
    const render = template(this.message);
    const message = render({ value, values, context });
    return message;
  }
}
