import React from 'react';

import { InlineButton } from '../../../../../common';

const styles = {
  item: { padding: '0', marginTop: '10px' },
};

export default function EndorsementInstructions({
  template_url,
  showEndorsementNumber,
}) {
  return (
    <div>
      <h4>Instructions to manually complete this endorsement</h4>
      <ol style={{ paddingLeft: '20px' }}>
        <li style={styles.item}>
          <a href={template_url} style={styles.link}>
            Download
          </a>{' '}
          and complete all terms in endorsement PDF.
        </li>
        <li style={styles.item}>
          Complete any approvals required by program guidelines.
        </li>
        <li style={styles.item}>
          Fill footer details and{' '}
          <InlineButton onClick={() => showEndorsementNumber()}>
            number endorsement
          </InlineButton>
          .
        </li>
        <li style={styles.item}>Upload PDF to book with Argo.</li>
      </ol>
    </div>
  );
}
