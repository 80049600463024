import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Sticky from 'react-stickynode';
import './StickySidebar.css';

export default function StickySidebarLayout(props) {
  const { renderMain, renderAside } = props;
  return (
      <Container style={{ paddingBottom: '25px'}} fluid="md">
        <Row>
          <Col 
            lg={{span: 6, offset: 1}} 
            md={{span: 7}}
            style={{ paddingTop: '25px' }}
          >
            {renderMain()}
          </Col>
          <Col md={5}>
            <Sticky>
              {props => (
                <div
                  style={{ ...props.style, zIndex: 1000, paddingTop: '25px' }}
                  className="sticky-sidebar-container"
                >
                  {renderAside()}
                </div>
              )}
            </Sticky>
          </Col>
        </Row>
      </Container>
  );
}
