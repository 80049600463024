import moment from 'moment';
import get from 'lodash/get';

const POLICY_PERIOD_IN_MONTHS = 12;

export const getEffectiveDate = rating => {
  return get(rating, 'risk_analysis.effective_date', null);
};

export const getExpirationDate = rating => {
  const standard_policy_period = get(
    rating,
    'risk_analysis.standard_policy_period',
    true
  );
  const expiration_date = get(rating, 'risk_analysis.expiration_date', null);
  if (!standard_policy_period && expiration_date) return expiration_date;
  const effective_date = getEffectiveDate(rating);
  if (standard_policy_period && effective_date) {
    return moment(effective_date)
      .add(POLICY_PERIOD_IN_MONTHS, 'months')
      .format('YYYY-MM-DD');
  }
  return null;
};
