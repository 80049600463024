import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Field, getFormValues } from 'redux-form';
import get from 'lodash/get';

import * as Components from './components';
import * as formatters from './formats';

const FieldWrapper = ({
  input,
  meta,
  field,
  form,
  required,
  disabled,
  hidden,
  error,
  warn,
  info,
  Component,
}) => {
  return (
    <Component
      input={input}
      required={required}
      disabled={disabled}
      hidden={hidden}
      error={error}
      warn={warn}
      info={info}
      active={meta.active}
      touched={meta.touched}
      field={field}
      form={form}
    />
  );
};

class ComponentWrapper extends React.Component {
  static contextTypes = {
    validation: PropTypes.object,
  };

  state = {
    error: null,
    warn: null,
    info: null,
  };

  validate = () => {
    const { validation } = this.context;
    const path = this.props.field.name;
    const values = this.props.values;
    const value = get(values, path);
    const result = validation.validator.validate(
      validation.schema,
      path,
      value,
      values,
      validation.context
    );
    return {
      error: result.error,
      warn: result.warn,
      info: result.info,
    };
  };

  required = () => {
    const { validation } = this.context;
    const result = validation.validator.required(
      validation.schema,
      this.props.field.name,
      get(this.props.value, this.props.field.name),
      this.props.values,
      validation.context
    );
    return result;
  };

  disabled = () => {
    if (!this.props.field.disabled) return false;
    const { validation } = this.context;
    const conditions = [].concat(this.props.field.disabled);
    const result = conditions.every(condition =>
      validation.validator.evaluateCondition(
        condition,
        this.props.value,
        this.props.values,
        validation.context
      )
    );
    return result;
  };

  hidden = () => {
    if (!this.props.field.hidden) return false;
    const { validation } = this.context;
    const conditions = [].concat(this.props.field.hidden);
    const result = conditions.every(condition =>
      validation.validator.evaluateCondition(
        condition,
        this.props.value,
        this.props.values,
        validation.context
      )
    );
    return result;
  };

  render() {
    const { form, field } = this.props;
    const Component = Components[field.component];
    if (!Component) {
      console.warn('No component for ' + field.component);
      console.warn(field);
      return null;
    }
    const hidden = this.hidden();
    if (field.name) {
      const required = this.required();
      const disabled = this.disabled();
      const { error, warn, info } = this.validate();
      const formatter = get(formatters, `${this.props.field.format}.input`, {});
      return (
        <Field
          name={field.name}
          component={FieldWrapper}
          format={formatter.format}
          parse={formatter.parse}
          props={{
            Component,
            form,
            field,
            required,
            disabled,
            hidden,
            error,
            warn,
            info,
          }}
        />
      );
    }
    return <Component form={form} field={field} hidden={hidden} />;
  }
}

export default connect((state, props) => {
  const values = getFormValues(props.form)(state);
  return {
    values,
  };
})(ComponentWrapper);
