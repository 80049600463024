import Pagination from 'react-bootstrap/Pagination';
import { v4 as uuidv4 } from 'uuid';

//Node use in Linked List
class Node {
  constructor(value, next = null) {
    this.value = value;
    this.next = next;
  }
}

const styles = {
  pagination: {
    float: 'right'
  }
};

const PaginationControl = ({
  prev,
  next,
  ellipsis,
  boundaryLinks,
  maxPageButttons,
  classNames,
  totalRecords,
  maxRecordsToDisplay,
  activePage = 1,
  onSelect,
}) => {
  const buildPaginationItems = (
    prev,
    next,
    ellipsis,
    boundaryLinks,
    maxPageButttons,
    totalRecords,
    maxRecordsToDisplay,
    activePage = 1,
    onSelect
  ) => {
    let itemsCounter = 0;
    let lastPageNum = Math.ceil(totalRecords / maxRecordsToDisplay);

    let prevPageNum = activePage - 1;
    let nextPageNum = activePage + 1;
    let startNode = null;
    let endNode = null;
    let newNode = null;
    newNode = new Node(
      (
        <Pagination.Item key={uuidv4()} active>
          {activePage}
        </Pagination.Item>
      )
    );
    startNode = newNode;
    endNode = startNode;
    itemsCounter++;

    //create numbered page item buttons
    while (itemsCounter < maxPageButttons) {
      let completed = true;
      if (prevPageNum > 1) {
        completed = false;
        newNode = new Node(
          (
            <Pagination.Item key={uuidv4()} onClick={onSelect(prevPageNum)}>
              {prevPageNum--}
            </Pagination.Item>
          )
        );
        itemsCounter++;
        newNode.next = startNode;
        startNode = newNode;
      }

      if (nextPageNum < lastPageNum && itemsCounter < maxPageButttons) {
        completed = false;
        newNode = new Node(
          (
            <Pagination.Item key={uuidv4()} onClick={onSelect(nextPageNum)}>
              {nextPageNum++}
            </Pagination.Item>
          )
        );
        itemsCounter++;
        endNode.next = newNode;
        endNode = newNode;
      }
      if(completed) break;
    }

    //add ellipse disabled button if applicable
    if (ellipsis === true) {
      if (prevPageNum >= 2) {
        newNode = new Node(
          <Pagination.Ellipsis key={uuidv4()} disabled={true} />
        );
        newNode.next = startNode;
        startNode = newNode;
      }

      if (nextPageNum <= lastPageNum - 1) {
        newNode = new Node(
          <Pagination.Ellipsis key={uuidv4()} disabled={true} />
        );
        endNode.next = newNode;
        endNode = newNode;
      }
    }

    //add buttons to 1st and last pages if not already in array
    if (prevPageNum >= 1) {
      newNode = new Node(
        (
          <Pagination.Item key={uuidv4()} onClick={onSelect(1)}>
            {1}
          </Pagination.Item>
        )
      );
      newNode.next = startNode;
      startNode = newNode;
    }

    if (nextPageNum <= lastPageNum) {
      newNode = new Node(
        (
          <Pagination.Item key={uuidv4()} onClick={onSelect(lastPageNum)}>
            {lastPageNum}
          </Pagination.Item>
        )
      );
      endNode.next = newNode;
      endNode = newNode;
    }

    //add previous and next buttons. Use prev and next if passed as parameters.
    //For example prev and next could be '< Back' or 'Next >' strings.
    if (activePage - 1 > 0) {
      if (prev != null) {
        newNode = new Node(
          (
            <Pagination.Item key={uuidv4()} onClick={onSelect(activePage - 1)}>
              {prev}
            </Pagination.Item>
          )
        );
      } else {
        newNode = new Node(
          <Pagination.Prev key={uuidv4()} onClick={onSelect(activePage - 1)} />
        );
      }
      newNode.next = startNode;
      startNode = newNode;
    } else {
      if (prev != null) {
        newNode = new Node(
          (
            <Pagination.Item key={uuidv4()} disabled={true}>
              {prev}
            </Pagination.Item>
          )
        );
      } else {
        newNode = new Node(<Pagination.Prev key={uuidv4()} disabled={true} />);
      }
      newNode.next = startNode;
      startNode = newNode;
    }

    if (activePage + 1 <= lastPageNum) {
      if (next != null) {
        newNode = new Node(
          (
            <Pagination.Item key={uuidv4()} onClick={onSelect(activePage + 1)}>
              {next}
            </Pagination.Item>
          )
        );
      } else {
        newNode = new Node(
          <Pagination.Next key={uuidv4()} onClick={onSelect(activePage + 1)} />
        );
      }
      endNode.next = newNode;
      endNode = newNode;
    } else {
      if (next != null) {
        newNode = new Node(
          (
            <Pagination.Item key={uuidv4()} disabled={true}>
              {next}
            </Pagination.Item>
          )
        );
      } else {
        newNode = new Node(<Pagination.Next key={uuidv4()} disabled={true} />);
      }
      endNode.next = newNode;
      endNode = newNode;
    }

    //add first and last buttons
    if (boundaryLinks === true) {
      newNode = new Node(
        <Pagination.First key={uuidv4()} onClick={onSelect(1)} />
      );
      newNode.next = startNode;
      startNode = newNode;

      newNode = new Node(
        <Pagination.Last key={uuidv4()} onClick={onSelect(lastPageNum)} />
      );
      endNode.next = newNode;
      endNode = newNode;
    }

    let pageItemArray = [];
    while (startNode != null) {
      pageItemArray.push(startNode.value);
      startNode = startNode.next;
    }
    return pageItemArray;
  };

  return (
    <div style={styles.pagination}>
      <Pagination>
        {buildPaginationItems(
          prev,
          next,
          ellipsis,
          boundaryLinks,
          maxPageButttons,
          totalRecords,
          maxRecordsToDisplay,
          activePage,
          onSelect
        )}
      </Pagination>
    </div>
  );
};

export default PaginationControl;
