export const getById = (state, id) => {
  return state.by_id[id];
};

export const getByRating = (state, rating_id) => {
  const quote_ids = Object.keys(state.by_id);
  let target_quotes = [];
  quote_ids.forEach(quote_id => {
    const quote = getById(state, quote_id);
    if (quote.ratings.some(qr => qr.rating_id === rating_id)) {
      target_quotes.push(quote);
    }
  });
  return target_quotes;
};

export const getBySubmission = (state, submission_id) => {
  return Object.keys(state.by_id)
    .map(id => state.by_id[id])
    .filter(quote => quote.submission_id === submission_id);
};
