import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Alert from 'react-bootstrap/Alert';

const styles = {
  alert: {
    marginBottom: 0,
    marginTop: '20px',
  },
  header: {
    marginTop: 0,
  },
  text: {
    color: 'black',
  },
};

export default class CopiedRatingBanner extends React.Component {
  state = {
    show: true,
  };

  render() {
    if (!this.state.show) return null;
    return (
      <Container>
        <Row>
          <Col sm={{span: 10, offset: 1}}>
            <Alert
              style={styles.alert}
              variant="success"
              onClose={() => this.setState({ show: false })}
            >
              <h3 style={styles.header}>This is a new Price Option</h3>
              <p style={styles.text}>
                Any changes won’t be reflected in your other Price Options
              </p>
            </Alert>
          </Col>
        </Row>
      </Container>
    );
  }
}
