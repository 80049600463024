import { connect } from 'react-redux';
import { withRouter } from '../../../common/components/WithRouter';

import * as policies from '../../actions';

import DownloadPolicyBanner from './DownloadPolicyBanner';

const mapDispatchToProps = (dispatch, props) => dispatch => ({
  downloadPolicyDocuments: () => {
    const { submission_id } = props;
    return dispatch(policies.getBySubmission(submission_id))
      .then(response => {
        return dispatch(policies.getPolicyDocuments(response.policy.id));
      })
      .then(({ url }) => {
        window.location.href = url;
      });
  },
});

export default withRouter(
  connect(null, mapDispatchToProps)(DownloadPolicyBanner)
);
