import * as validation from './validation';

/**
 * Note: The validation functions below return error message strings if
 * the input is invalid and falsy values if the input is valid. Thus,
 * using the not operator produces a boolean value where true = valid
 * and false = invalid
 */
export default function isValid({ quote, ratings }) {
  const { terms_conditions } = quote;
  const { exclusions, endorsements, erp } = terms_conditions;
  return (
    !validation.exclusions.do(exclusions.do, terms_conditions, {
      quote,
      ratings,
    }) &&
    !validation.exclusions.epl(exclusions.epl, terms_conditions, {
      quote,
      ratings,
    }) &&
    !validation.exclusions.fid(exclusions.fid, terms_conditions, {
      quote,
      ratings,
    }) &&
    !validation.endorsements(endorsements, terms_conditions, {
      quote,
      ratings,
    }) &&
    !validation.erp.years(erp.years) &&
    !validation.erp.percentage(erp.percentage)
  );
}
