import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import {
  ClearanceStatus,
  InsuredSummarySection,
  BrokerSummarySection,
} from '../../../../submissions';

import SearchQuotesBySubmission from './SearchQuotesBySubmissionApollo';

const styles = {
  sectionHeader: {
    fontSize: '20px',
  },
};

export default function BaseSubmissionInfo({ submission }) {
  return (
    <div>
      <h4 style={styles.sectionHeader}>Quote Letters</h4>
      <SearchQuotesBySubmission submission={submission} />
      <h4 style={styles.sectionHeader}>Clearance details</h4>
      <ClearanceStatus submission={submission} />
      <Row>
        <Col xs={12} sm={6}>
          <InsuredSummarySection named_insured={submission.named_insured} />
        </Col>
        <Col xs={12} sm={6}>
          <BrokerSummarySection broker={submission.broker} />
        </Col>
      </Row>
    </div>
  );
}
