import React, { Fragment } from 'react';

import ActionMenu from '../../../common/components/ActionMenu';
import Toggleable from '../../../common/components/Toggleable';
import DeclinationModal from '../DeclinationModal';

const styles = {
  list: {
    listStyleType: 'none',
    padding: '0',
    margin: '0',
  },
};

const ActionTypeToComponent = {
  link: ActionMenu.Link,
  button: ActionMenu.Button,
};

export default function ManageSubmission({
  actions,
  submission,
  style,
  title,
}) {
  return (
    <Toggleable
      render={({ visible, show, hide }) => (
        <Fragment>
          <ActionMenu style={style.menu}>
            <ActionMenu.Title style={style.title}>{title}</ActionMenu.Title>
            <ul style={{ ...styles.list }}>
              {actions.map(({ type, title, onClick, disabled, ...action }) => {
                const Component = ActionTypeToComponent[type];
                return (
                  <li key={title}>
                    <Component
                      key={title}
                      onClick={show}
                      disabled={disabled}
                      {...action}
                    >
                      {title}
                    </Component>
                  </li>
                );
              })}
            </ul>
          </ActionMenu>
          {!visible ? null : (
            <DeclinationModal
              hide={hide}
              show={show}
              submission={submission}
              visible={visible}
            />
          )}
        </Fragment>
      )}
    />
  );
}

ManageSubmission.defaultProps = {
  style: {},
  title: 'Manage Submission',
};
