import React from 'react';
import { Query } from '@apollo/client/react/components';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import moment from 'moment-timezone';
import { Document, Page, pdfjs } from 'react-pdf/dist/esm/entry';
import EndorsementBookedBanner from './EndorsementBookedBanner';
import { DownloadDocuments } from '../../../../common';

import { query } from '../query-midterm-endorsement';

pdfjs.GlobalWorkerOptions.workerSrc = '/pdf.worker.min.js';

const styles = {
  status: {
    fontWeight: 'bold',
    marginTop: '25px',
    textTransform: 'uppercase',
  },
  policy: {
    display: 'inline-block',
    marginTop: '1em',
  },
  policy_label: {
    display: 'inline-block',
    fontWeight: 'bold',
    marginRight: '0.25em',
  },
};

function MidTermEndorsementDocuments({ match, location }) {
  const tz_abbr = moment()
    .tz(moment.tz.guess())
    .format('z');
  return (
    <Query
      query={query}
      variables={{
        id: match.params.endorsement_id,
      }}
    >
      {({ data, loading, error }) => {
        if (loading) {
          return <div className="text-center">Loading...</div>;
        }
        const { endorsement } = data;
        const { policy } = endorsement;
        const endorsementUrl = endorsement.documents.location.url;
        const booked_at = moment(endorsement.booked_at);
        const created_by = `${endorsement.booked_by.first_name} ${
          endorsement.booked_by.last_name
        }`;
        const byline = `Added ${booked_at.format(
          `MMMM D, YYYY [at] h:mm a [${tz_abbr}]`
        )} by ${created_by}`;

        return (
          <Container fluid="md">
            <Row>
              <Col lg={{span: 10, offset: 1}}>
                {location.state &&
                  location.state.displayBanner && <EndorsementBookedBanner />}
              </Col>
            </Row>
            <Row>
              <Col style={{ marginBottom: '25px' }} lg={{span: 7, offset: 1}}>
                <h3 style={{marginTop: '20px'}}>{endorsement.title}</h3>
                <div className="text-success" style={styles.status}>
                  Complete
                </div>
              </Col>
            </Row>
            <Row>
              <Col lg={{span: 3, offset: 1}}>
                <div>{byline}</div>
                <div style={styles.policy}>
                  <span style={styles.policy_label}>Policy: </span>
                  {policy.policy_number}
                </div>
                <DownloadDocuments.Section
                  loading={loading}
                  renderBody={() => (
                    <DownloadDocuments.Button
                      style={{ marginTop: '10px' }}
                      className="btn btn-link"
                      url={endorsementUrl}
                      target="_blank"
                    >
                      Download Endorsement
                    </DownloadDocuments.Button>
                  )}
                />
              </Col>
              <Col style={{ marginBottom: '25px', textAlign: 'center' }} lg={7}>
                <div
                  style={{
                    border: '1px solid black',
                    display: 'inline-block',
                  }}
                >
                  <Document
                    file={{ url: endorsementUrl }}
                    loading={<div className="text-center">Loading...</div>}
                  >
                    <Page 
                      pageNumber={1} 
                      loading={null} 
                      renderTextLayer={false} 
                      renderAnnotationLayer={false}
                    />
                  </Document>
                </div>
              </Col>
            </Row>
          </Container>
        );
      }}
    </Query>
  );
}
export default MidTermEndorsementDocuments;
