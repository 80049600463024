import React from 'react';

const styles = {
  status_text: {
    fontSize: '1.25rem',
    fontWeight: '500',
    margin: '0 auto 0.25em',
  },
};

const clearance_status_to_class = {
  AUTOMATICALLY_CLEARED: 'text-success',
  MANUALLY_CLEARED: 'text-success',
  FAILED: 'text-danger',
};

const clearance_status_to_text = {
  AUTOMATICALLY_CLEARED: 'Submission automatically Cleared',
  MANUALLY_CLEARED: 'Submission has been Manually Cleared',
  FAILED:
    'Clearance has found similar Submissions. Please review the Matches below.',
};

export default function ClearanceResultsHeader({ style, submission }) {
  const clearance_status = submission.clearance.status;
  return (
    <h2
      className={clearance_status_to_class[clearance_status]}
      style={{ ...styles.status_text, ...style }}
    >
      {clearance_status_to_text[clearance_status]}
    </h2>
  );
}
