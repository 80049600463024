import { Condition } from './_base';
export class BooleanAndCondition extends Condition {
  eval(value, values, context) {
    for (let condition of this.config.conditions) {
      const conditionMet = this.condition(condition).eval(
        value,
        values,
        context
      );
      if (!conditionMet) return false;
    }
    return true;
  }
}
