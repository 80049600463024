import React from 'react';
import Alert from 'react-bootstrap/Alert';

export default function ErrorMessage({ style }) {
  return (
    <Alert variant="danger" style={{ ...style }}>
      <strong>Please review:</strong>
      <p style={{ color: 'black', display: 'inline' }}>
        {' '}
        Required selections are missing or do not meet underwriting guidelines.
      </p>
    </Alert>
  );
}
