import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import numeral from 'numeral';
import get from 'lodash/get';
import chunk from 'lodash/chunk';

import { SummaryItem } from '../.../../../../../common';

import DebitsCredits from '../../DebitsCredits';

const CoverageMap = {
  do: 'D&O',
  epl: 'EPL',
  fid: 'FID',
};

const getDCBlurb = premium => {
  if (!premium.adjustment) return '';
  const value = numeral(Math.abs(premium.adjustment)).format('$0,0[.]00');
  const type = premium.adjustment > 0 ? 'DR' : 'CR';
  return (
    <div>
      (includes {value} {type})
    </div>
  );
};

const getSummary = (coverages, rating) => {
  const summaries = coverages.map(coverage => {
    const premium = get(rating, `premium.coverage_specific.${coverage}`, null);
    return {
      label: CoverageMap[coverage],
      value: premium && (
        <div>
          {numeral(premium.base + (premium.adjustment || 0)).format('$0,0.00')}
          {getDCBlurb(premium)}
        </div>
      ),
    };
  });
  return chunk(summaries, 3);
};

export default function CoveragesSummary({ coverages, rating }) {
  const summary = getSummary(coverages, rating);
  return (
    <div>
      {summary.map((batch, i) => {
        return (
          <Row key={i} style={{ marginBottom: '10px' }}>
            {batch.map(item => (
              <Col key={item.label} md={4}>
                <SummaryItem {...item} />
              </Col>
            ))}
          </Row>
        );
      })}
      {!!rating.premium && (
        <DebitsCredits
          style={{ marginTop: '25px' }}
          coverages={coverages}
          rating={rating}
        />
      )}
    </div>
  );
}
