import React from 'react';
import Scrollspy from 'react-scrollspy';

import './BinderPreviewProgressBox.css';

const styles = {
  container: {
    border: '1px solid black',
    padding: '15px 20px',
  },
  header: {
    marginTop: '0',
  },
  list: {
    fontSize: '0.75rem',
    listStyle: 'none',
    marginTop: '15px',
    paddingLeft: '0',
    textTransform: 'uppercase',
  },
  list_item: {
    marginTop: '0.625em',
  },
};

export default function BinderPreviewProgressBox({ sections, style }) {
  const ids = sections.map(section => section.name);
  return (
    <section style={{ ...styles.container, ...style }}>
      <h4 style={styles.header}>Binder Preview</h4>
      <Scrollspy
        style={styles.list}
        items={ids}
        currentClassName="BinderPreviewProgressBox__section--selected"
      >
        {sections.map((section, i) => {
          return (
            <li key={section.name} style={styles.list_item}>
              <a href={`#${section.name}`}>{section.title}</a>
            </li>
          );
        })}
      </Scrollspy>
    </section>
  );
}
