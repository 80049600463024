import React from 'react';
import numeral from 'numeral';
import moment from 'moment';
import get from 'lodash/get';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import { SummaryItem } from '../../../common';

import { getEffectiveDate, getExpirationDate } from './utils';

const formatCurrency = value => {
  return value ? numeral(value).format('$0,0') : null;
};

const formatDate = value => {
  return value ? moment(value).format('MMMM D, YYYY') : null;
};

const formatPolicyPeriod = rating => {
  const effective_date = getEffectiveDate(rating);
  const expiration_date = getExpirationDate(rating);
  if (!effective_date) {
    return 'TBD (one year policy)';
  }
  if (!expiration_date) return null;
  return (
    <span>
      <DateNode date={effective_date} /> - <DateNode date={expiration_date} />
    </span>
  );
};

const DateNode = ({ date }) => (
  <span style={{ display: 'inline-block' }}>{formatDate(date)}</span>
);

export default function PriceOptionHighlights({
  style,
  rating,
  limit_summary,
}) {
  return (
    <Row style={style}>
      <Col md={4}>
        <SummaryItem
          label="Total Premium"
          value={formatCurrency(get(rating, 'premium.total'))}
        />
      </Col>
      <Col md={4}>
        <SummaryItem
          label="Aggregate Limit"
          value={formatCurrency(limit_summary.aggregate)}
        />
      </Col>
      <Col md={4}>
        <SummaryItem label="Policy Period" value={formatPolicyPeriod(rating)} />
      </Col>
    </Row>
  );
}
