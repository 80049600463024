import React from 'react';
import { Link } from 'react-router-dom';

import { PriceOptionSummary } from '../../../../rating';

const styles = {
  section_header: {
    fontSize: '1.25rem',
    marginBottom: '10px',
  },
  list: { listStyle: 'none', paddingLeft: '0' },
  list_item: { borderTop: '1px solid gray' },
};

export default function PremiumPreview({ style, ratings, ...props }) {
  return (
    <div style={style} {...props}>
      <h3 style={styles.section_header}>Premium</h3>
      <ul style={styles.list}>
        {ratings.map((rating, i) => (
          <li key={rating.id} style={i > 0 ? styles.list_item : null}>
            <PriceOptionSummary
              rating={rating}
              renderHeader={({ style }) => {
                return (
                  <h3 style={style}>
                    <Link to={`/ratings/${rating.id}`}>
                      {`Price Option ${i + 1}`}
                    </Link>
                  </h3>
                );
              }}
            />
          </li>
        ))}
      </ul>
    </div>
  );
}
