import jmp from 'json-merge-patch';
import isEmpty from 'lodash/isEmpty';

import { selectors } from '../redux';

import {
  GET_POLICY_BY_ID_REQUEST,
  GET_POLICY_BY_ID_SUCCESS,
  GET_POLICY_BY_SUBMISSION_ID_REQUEST,
  GET_POLICY_BY_SUBMISSION_ID_SUCCESS,
  UPDATE_POLICY_TERMS_CONDITIONS_REQUEST,
  UPDATE_POLICY_TERMS_CONDITIONS_SUCCESS,
  ISSUE_POLICY_REQUEST,
  ISSUE_POLICY_SUCCESS,
  SEARCH_POLICIES_REQUEST,
  SEARCH_POLICIES_SUCCESS,
  GET_POLICY_DOCUMENTS_SUCCESS,
  GET_POLICY_DOCUMENTS_REQUEST,
  UPDATE_POLICY_DETAILS_REQUEST,
  UPDATE_POLICY_DETAILS_SUCCESS,
} from './constants';

export function get(policy_id, options = {}) {
  const { cache = true } = options;
  return (dispatch, getState, { api }) => {
    const state = getState();
    const policy = selectors.policyById(state, policy_id);
    if (cache && policy) {
      return Promise.resolve({
        policy,
      });
    }
    dispatch({ type: GET_POLICY_BY_ID_REQUEST });
    return api.policies.get(policy_id).then(response => {
      dispatch({
        type: GET_POLICY_BY_ID_SUCCESS,
        payload: {
          policy: response.policy,
        },
      });
      return response;
    });
  };
}

export function getBySubmission(submission_id) {
  return (dispatch, getState, { api }) => {
    const state = getState();
    const policy = selectors.policyBySubmission(state, submission_id);
    if (policy) {
      return Promise.resolve({
        policy,
      });
    }
    dispatch({
      type: GET_POLICY_BY_SUBMISSION_ID_REQUEST,
    });
    return api.policies.getBySubmission(submission_id).then(response => {
      const { policy } = response;
      dispatch({
        type: GET_POLICY_BY_SUBMISSION_ID_SUCCESS,
        payload: {
          policy,
        },
      });
      return response;
    });
  };
}

export function updateTermsConditions(
  policy_id,
  updatedTermsConditions,
  oldTermsConditions
) {
  return (dispatch, getState, { api }) => {
    dispatch({ type: UPDATE_POLICY_TERMS_CONDITIONS_REQUEST });
    const diff = jmp.generate(oldTermsConditions, updatedTermsConditions);
    if (isEmpty(diff)) return Promise.resolve({ success: true });
    return api.policies
      .updateTermsConditions(policy_id, diff)
      .then(async response => {
        await dispatch({
          type: UPDATE_POLICY_TERMS_CONDITIONS_SUCCESS,
          payload: {
            policy_id,
            terms_conditions: updatedTermsConditions,
          },
        });
        return response;
      });
  };
}

export function issuePolicy(policy_id) {
  return (dispatch, getState, { api }) => {
    dispatch({
      type: ISSUE_POLICY_REQUEST,
    });
    return api.policies.issuePolicy(policy_id).then(async response => {
      await dispatch({
        type: ISSUE_POLICY_SUCCESS,
        payload: {
          policy: response.policy,
        },
      });
      return response;
    });
  };
}

export function getPolicyDocuments(policy_id) {
  return (dispatch, getState, { api }) => {
    dispatch({ type: GET_POLICY_DOCUMENTS_REQUEST });
    return api.policies.getPolicyDocuments(policy_id).then(response => {
      dispatch({ type: GET_POLICY_DOCUMENTS_SUCCESS });
      return response;
    });
  };
}

export function searchPolicies({ term }) {
  return async (dispatch, getState, { api }) => {
    dispatch({ type: SEARCH_POLICIES_REQUEST });
    const response = await api.policies.search({ term });
    dispatch({
      type: SEARCH_POLICIES_SUCCESS,
      payload: { policies: response.policies },
    });
    return response;
  };
}

export function updateDetails(policy_id, updatedDetails, oldDetails) {
  return (dispatch, getState, { api }) => {
    dispatch({ type: UPDATE_POLICY_DETAILS_REQUEST });
    const patch = jmp.generate(oldDetails, updatedDetails);
    return api.policies.updateDetails(policy_id, patch).then(async response => {
      if (!patch) return response;
      await dispatch({
        type: UPDATE_POLICY_DETAILS_SUCCESS,
        payload: {
          policy_id,
          ...updatedDetails,
        },
      });
      return response;
    });
  };
}
