import React from 'react';

const styles = {
  policy_number_container: {
    opacity: '0.87',
    marginTop: '20px',
  },
  label: {
    fontSize: '0.875rem',
  },
  value: {
    fontSize: '1.5rem',
  },
};

export default function PolicyDescription({ policy_number, children }) {
  return (
    <section>
      <h3 className="text-success">{children}</h3>
      <div style={styles.policy_number_container}>
        <h4 style={styles.label}>Policy number</h4>
        <p style={styles.value}>{policy_number}</p>
      </div>
    </section>
  );
}
