import { withRouter } from '../../../../../common/components/WithRouter';
import { connect } from 'react-redux';

import { actions as ratings } from '../../../../../rating';
import * as quotes from '../../../../actions';

import PriceOptionsSummarySection from './PriceOptionsSummarySection';

const mapDispatchToProps = (dispatch, props) => {
  const { quote, navigate } = props;
  return {
    copyRating: rating_id => dispatch(ratings.createRatingCopy(rating_id)),
    removeRating: rating_id => {
      return dispatch(
        quotes.removeRatingFromQuote({
          quote_id: quote.id,
          rating_id,
        })
      );
    },
    getRating: rating_id => {
      return dispatch(ratings.get(rating_id)).then(res => {
        navigate(`/ratings/${res.rating.id}/rating-worksheet`);
      });
    },
    createRating: () =>
      dispatch(ratings.createFromQuote(quote.id)).then(res => {
        navigate(`/ratings/${res.rating.id}/rating-worksheet`);
        return res;
      }),
    addRatingToQuote: ({ rating_id, before }) =>
      dispatch(
        quotes.addRatingToQuote({
          quote_id: props.quote.id,
          rating_id,
          before,
        })
      ),
  };
};

export default withRouter(
  connect(
    null,
    mapDispatchToProps
  )(PriceOptionsSummarySection)
);
