import React from 'react';
import { withRouter } from '../../../../common/components/WithRouter';

import * as permissions from '../../../permissions';
import { isRenewal } from '../../../utils';

import NewBusiness from './NewBusiness';
import Renewal from './Renewal';

function StartQuoteButton(props) {
  const disabled =
    !permissions.canCreateQuote({
      submission: props.submission,
    }) || props.submission.declined;
  if (!isRenewal(props.submission)) {
    return <NewBusiness disabled={disabled} {...props} />;
  }
  return <Renewal disabled={disabled} {...props} />;
}

export default withRouter(StartQuoteButton);
