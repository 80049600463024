import { connect } from 'react-redux';

import { selectors } from '../../../redux';
import { withRouter } from '../../../common/components/WithRouter';

import { actions as policies } from '../../../policies';
import { actions as submissions } from '../../../submissions';
import { actions as configuration } from '../../../common';

import MidTermEndorsementsContainer from './MidTermEndorsementsContainer';

const mapStateToProps = (state, props) => {
  const { policy_id } = props.match.params;
  return state => {
    const policy = selectors.policyById(state, policy_id);
    const submission = selectors.submissionByPolicy(state, policy_id);
    return {
      policy,
      submission,
    };
  };
};

const mapDispatchToProps = (dispatch, props) => {
  const { policy_id } = props.match.params;
  return dispatch => ({
    getPolicy: () => dispatch(policies.get(policy_id)),
    getSubmission: () =>
      dispatch(submissions.getByPolicy(policy_id, { cache: false })),
    getConfiguration: () => dispatch(configuration.getConfiguration()),
  });
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(MidTermEndorsementsContainer)
);
