import React from 'react';
import { Field, FormSection } from 'redux-form';
import Button from 'react-bootstrap/Button';
import get from 'lodash/get';

import InputControl from '../../../common/components/InputControl';
import ModalDialog from '../../../common/components/ModalDialog';

import { formatters, parsers } from '../../format';

export default function SupplementaryDataModal({ endorsement, index, hide }) {
  const name = `endorsements[${index}].data`;
  const supplementary_data = get(endorsement, 'supplementary_data');
  const fields = get(supplementary_data, 'fields', []);
  return (
    <FormSection name={name}>
      <ModalDialog.Standard
        show={!!supplementary_data}
        hide={hide}
        renderHeader={() => {
          return <h3>Fill Endorsement</h3>;
        }}
        renderBody={() =>
          supplementary_data && (
            <div>
              <h4>{supplementary_data.description}</h4>
              {fields &&
                fields.map(field => (
                  <Field
                    key={field.name}
                    name={field.name}
                    format={formatters[field.format]}
                    parse={parsers[field.format]}
                    component={InputControl}
                    label={field.label}
                  />
                ))}
              <Button variant="primary" onClick={hide}>
                Save Terms
              </Button>
            </div>
          )
        }
        renderFooter={() => {
          return (
            <p style={{ textAlign: 'left' }}>
              See endorsement specimen for full terms.
            </p>
          );
        }}
      />
    </FormSection>
  );
}
