import { Condition } from './_base';
export class ItemInArrayCondition extends Condition {
  eval(value, values, context) {
    const $ = { value, values, context };
    const item = this.value(this.config.item).eval($);
    const array = this.value(this.config.array).eval($);
    if (!Array.isArray(array)) return false;
    return array.includes(item);
  }
}
