import React from 'react';
import { Link } from 'react-router-dom';
import get from 'lodash/get';

import ExclusionsSummary from '../ExclusionsSummary';

import { utils as rating_utils } from '../../../rating';
import * as permissions from '../../permissions';

const renderHeader = quote => ({ styles }) => {
  return (
    <React.Fragment>
      <h3 style={styles.section_header}>Pending & Prior Exclusions</h3>
      <div className="pull-right">
        <Link
          style={styles.edit}
          className="btn btn-link"
          to={`/quotes/${quote.id}/terms-conditions`}
        >
          Edit
        </Link>
      </div>
    </React.Fragment>
  );
};

export default function Exclusions({ quote, ratings, ...props }) {
  const coverages = rating_utils.aggregateCoverages(ratings);
  const exclusions = get(quote, 'terms_conditions.exclusions', {});
  const can_update_terms_conditions = permissions.canUpdateTermsConditions({
    quote,
  });
  return (
    <ExclusionsSummary
      {...props}
      exclusions={exclusions}
      coverages={coverages}
      renderHeader={
        can_update_terms_conditions ? renderHeader(quote) : undefined
      }
    />
  );
}
