import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import { SummaryItem } from '../../../common';

import chunk from 'lodash/chunk';

const styles = {
  container: { borderTop: '1px solid #dddddd', marginBottom: '0.75rem' },
  header: { fontSize: '1.0625rem', fontWeight: 'normal', marginBottom: '2px' },
};

const transformFormValues = values =>
  values.map(section => ({
    ...section,
    fields: chunk(section.fields, 3),
  }));

export default function WorksheetSummarySection({ values }) {
  const form_values = transformFormValues(values);
  return (
    <div>
      {form_values.map((section, i) => (
        <div key={i} style={styles.container}>
          <h4 style={styles.header}>{section.title}</h4>
          {section.fields.map((row, i) => (
            <Row key={i}>
              {row.map((field, i) => (
                <Col md={4} key={i}>
                  <SummaryItem label={field.nickname} value={field.value} />
                </Col>
              ))}
            </Row>
          ))}
        </div>
      ))}
    </div>
  );
}
