import { ApiError } from '../../api/errors';

const GetQuoteDocuments = (fetch, auth) => quote_id => {
  return auth
    .getAccessToken()
    .then(token => {
      if (!token) {
        auth.signOut();
        return Promise.reject(new Error('User is not authenticated.'));
      }
      return token;
    })
    .then(token =>
      fetch(`/quotes/${quote_id}/documents`, {
        method: 'GET',
        headers: {
          Authorization: token,
          'Content-Type': 'application/json',
        },
      })
    )
    .then(response => {
      switch (response.status) {
        case 200:
          return response.json();
        case 404:
          return response.json().then(body => {
            throw new ApiError(body.message);
          });
        default:
          throw new ApiError();
      }
    });
};

export default GetQuoteDocuments;
