import { connect } from 'react-redux';

import { updatePremiumAdjustments } from '../../actions';

import DebitsCredits from './DebitsCredits';

const mapDispatchToProps = (dispatch, props) => ({
  updatePremiumAdjustment: (form_values, initialValues) => {
    const premium = props.rating.premium;
    const new_premium = {
      ...premium,
      coverage_specific: form_values,
    };
    const old_premium = {
      ...premium,
      coverage_specific: initialValues,
    };
    return dispatch(
      updatePremiumAdjustments(props.rating.id, new_premium, old_premium)
    );
  },
});

export default connect(
  null,
  mapDispatchToProps
)(DebitsCredits);
