import React from 'react';

export default class AsyncMutationWrapper extends React.Component {
  state = {
    loading: false,
    data: null,
    error: null,
  };

  mutate(...args) {
    this.setState({
      loading: true,
      error: null,
    });
    return this.props
      .mutate(...args)
      .then(data => {
        this.setState({
          loading: false,
          data,
        });
        return data;
      })
      .catch(error => {
        this.setState({
          loading: false,
          data: null,
          error,
        });
        return Promise.reject(error);
      });
  }

  render() {
    return this.props.render({
      mutate: this.mutate.bind(this),
      loading: this.state.loading,
      data: this.state.data,
      error: this.state.error,
    });
  }
}
