import React from 'react';
import Form from 'react-bootstrap/Form';

const RequiredTag = () => {
  return <span style={{ color: 'red', marginLeft: '0.25rem' }}>*</span>;
};

export default function Label({ required = false, children }) {
  return (
    <Form.Label className='mt-3'>
      <span>{children}</span>
      {required && <RequiredTag />}
    </Form.Label>
  );
}
