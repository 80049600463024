import React from 'react';
import moment from 'moment';

import { WithAuth } from '../../../auth';

const styles = {
  default: { fontSize: '0.75rem', marginBottom: '0' },
  danger: {
    fontSize: '0.75rem',
    fontWeight: 'bold',
    marginBottom: '0',
  },
};

const clearance_status_to_copy = {
  AUTOMATICALLY_CLEARED: 'Automatically cleared',
  MANUALLY_CLEARED: 'Manually cleared',
  FAILED: 'Clearance failed',
};

const creator_group_to_cross_group_copy = {
  sgs: 'Submission already received by Argo. Please review Broker details.',
  cdo: 'Submission already received by Corona.',
};

const CrossGroupClearanceStatus = ({ creator_group }) => {
  return (
    <p className="text-danger" style={styles.danger}>
      {creator_group_to_cross_group_copy[creator_group]}
    </p>
  );
};

const DefaultClearanceStatus = ({ submission }) => {
  return (
    <p style={styles.default}>
      {clearance_status_to_copy[submission.clearance.status]} on{' '}
      {moment(submission.clearance.timestamp).format('MMMM D, YYYY hh:mm a')}
    </p>
  );
};

const StatusComponent = (viewer, submission) => {
  return viewer.group === submission.created_by.group ? (
    <DefaultClearanceStatus submission={submission} />
  ) : (
    <CrossGroupClearanceStatus creator_group={submission.created_by.group} />
  );
};

export default function ClearanceStatus({ submission }) {
  return (
    <WithAuth
      render={({ user }) => {
        if(!user) return false;
        return StatusComponent(user, submission);
      }}
    />
  );
}
