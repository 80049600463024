import { gql } from '@apollo/client';

const ViewAccountContainer = gql`
  query ViewAccountContainer(
    $account_id: ID
    $quoteLimit: Int
    $quoteOffset: Int
  ) {
    accountById(id: $account_id) {
      submissions {
        id
        broker {
          brokerage
          email
          name
          commission
          phone
          address {
            street_line_1
            street_line_2
            city
            state
            zip
          }
        }
        named_insured {
          name
          address {
            street_line_1
            street_line_2
            city
            state
            zip
          }
        }
        declined
        declination {
          reasons
          comments
          declined_by {
            user_id
            group
            first_name
            last_name
          }
          declined_at
        }
        created_at
        updated_at
        created_by {
          user_id
          group
        }
        clearance {
          status
          timestamp
        }
        renewed_from_policy {
          id
          policy_number
        }
        submission_number
        status
        quotes(limit: $quoteLimit, offset: $quoteOffset) {
          results {
            id
            created_at
            status
            display_name
            updated_at
          }
          count
        }
        binder {
          id
          created_at
          updated_at
          status
          policy_number
          policy_effective_date
          policy_expiration_date
        }
        policy {
          created_at
          updated_at
          id
          issued_at
          policy_number
          status
          endorsements {
            results {
              id
              policy_id
              created_at
              updated_at
              title
            }
          }
          renewal_submission_id
          updated_at
        }
        endorsements {
          results {
            id
            policy_id
            created_at
            updated_at
            status
            title
          }
          count
        }
      }
    }
  }
`;

export { ViewAccountContainer };
