import { SubmissionError } from 'redux-form';

export default function submit(values, dispatch, props) {
  const parse = value => {
    const only_nums = String(value)
      .match(/-?\d*/g)
      .join('');
    return parseInt(only_nums, 10);
  };
  const parsed = {
    financial: {
      current: Object.keys(values.financial.current).reduce((result, key) => {
        result[key] = parse(values.financial.current[key]);
        return result;
      }, {}),
    },
  };
  return props.updateZScore(parsed.financial).catch(e => {
    throw new SubmissionError({
      _error:
        'A Z-Score could not be calculated. Please check the entries above and try again.',
    });
  });
}
