import React from 'react';
import Form from 'react-bootstrap/Form';

import Control from './Control';

const InlineCheckbox = props => {
  const { input, field } = props;
  return (
    <Control {...props}>
      <div>
        <Form.Check
          {...input}
          type='checkbox'
          style={{
            display: 'inline-block',
            marginRight: '0.5em',
            ...props.style,
          }}
          checked={input.value}
          label={field.label}
          className='mt-3 mb-3'
        />
      </div>
    </Control>
  );
};

export default InlineCheckbox;
