import GetQuote from './get';
import CreateQuote from './create';
import SearchQuotesBySubmission from './searchBySubmission';
import GetRatingsByQuote from './getRatingsByQuote';
import GenerateQuoteDocuments from './generateQuoteDocuments';
import GetQuoteLetter from './getQuoteLetter';
import GetQuoteDocuments from './getQuoteDocuments';
import GetByBinder from './getByBinder';
import CreateFromSubmission from './createFromSubmission';
import CreatePrepopulatedQuoteFromSubmission from './createPrepopulatedQuoteFromSubmission';
import GetByRating from './getByRating';
import CreateCopy from './createCopy';
import AddRating from './addRating';
import RemoveRating from './removeRating';
import UpdateQuote from './updateQuote';
import GetEligibleProducts from './getEligibleProducts';

export default function RatingsApi(fetch, auth) {
  return {
    get: GetQuote(fetch, auth),
    create: CreateQuote(fetch, auth),
    searchBySubmission: SearchQuotesBySubmission(fetch, auth),
    getRatings: GetRatingsByQuote(fetch, auth),
    generateQuoteDocuments: GenerateQuoteDocuments(fetch, auth),
    getQuoteLetter: GetQuoteLetter(fetch, auth),
    getQuoteDocuments: GetQuoteDocuments(fetch, auth),
    getByBinder: GetByBinder(fetch, auth),
    createFromSubmission: CreateFromSubmission(fetch, auth),
    createPrepopulatedQuoteFromSubmission: CreatePrepopulatedQuoteFromSubmission(
      fetch,
      auth
    ),
    getByRating: GetByRating(fetch, auth),
    createCopy: CreateCopy(fetch, auth),
    addRating: AddRating(fetch, auth),
    removeRating: RemoveRating(fetch, auth),
    updateQuote: UpdateQuote(fetch, auth),
    getEligibleProducts: GetEligibleProducts(fetch, auth),
  };
}
