import { selectors } from '../redux';
import {
  GET_ENDORSEMENT_OPTION_BY_ID_REQUEST,
  GET_ENDORSEMENT_OPTION_BY_ID_SUCCESS,
  GET_ENDORSEMENT_BY_ID_REQUEST,
  GET_ENDORSEMENT_BY_ID_SUCCESS,
  GET_ENDORSEMENTS_BY_SUBMISSION_REQUEST,
  GET_ENDORSEMENTS_BY_SUBMISSION_SUCCESS,
  GET_ENDORSEMENT_OPTION_URL_BY_ID_REQUEST,
  GET_ENDORSEMENT_OPTION_URL_BY_ID_SUCCESS,
  GET_ENDORSEMENT_URL_BY_ID_REQUEST,
  GET_ENDORSEMENT_URL_BY_ID_SUCCESS,
  ADD_ENDORSEMENT_TO_POLICY_REQUEST,
  ADD_ENDORSEMENT_TO_POLICY_SUCCESS,
  DELETE_ENDORSEMENT_REQUEST,
  DELETE_ENDORSEMENT_SUCCESS,
  BOOK_ENDORSEMENT_REQUEST,
  BOOK_ENDORSEMENT_SUCCESS,
} from './constants';

export function getOptionByIdVersion({ id, version }) {
  return (dispatch, getState, { api }) => {
    const state = getState();
    const option = selectors.endorsementOptionByIdVersion(state, {
      id,
      version,
    });
    if (option) {
      return Promise.resolve({
        option,
      });
    }
    dispatch({ type: GET_ENDORSEMENT_OPTION_BY_ID_REQUEST });
    return api.endorsements
      .getOptionByIdVersion({ id, version })
      .then(response => {
        dispatch({
          type: GET_ENDORSEMENT_OPTION_BY_ID_SUCCESS,
          payload: {
            option: response.option,
          },
        });
        return response;
      });
  };
}

export function getEndorsementsBySubmission(submission_id) {
  return (dispatch, getState, { api }) => {
    dispatch({
      type: GET_ENDORSEMENTS_BY_SUBMISSION_REQUEST,
    });
    return api.endorsements.getBySubmission(submission_id).then(response => {
      dispatch({
        type: GET_ENDORSEMENTS_BY_SUBMISSION_SUCCESS,
        payload: {
          endorsements: response.endorsements,
        },
      });
      return response;
    });
  };
}

export function generateOptionUrl(endorsement_option, options) {
  return (dispatch, getState, { api }) => {
    dispatch({ type: GET_ENDORSEMENT_OPTION_URL_BY_ID_REQUEST });
    return api.endorsements
      .getOptionUrlByIdVersion(endorsement_option, options)
      .then(response => {
        dispatch({
          type: GET_ENDORSEMENT_OPTION_URL_BY_ID_SUCCESS,
          payload: {
            template_url: response.template_url,
          },
        });
        return response;
      });
  };
}

export function addToPolicy({ policy_id, option_id, option_version }) {
  return (dispatch, getState, { api }) => {
    dispatch({ type: ADD_ENDORSEMENT_TO_POLICY_REQUEST });
    return api.endorsements
      .addToPolicy({ policy_id, option_id, option_version })
      .then(response => {
        dispatch({
          type: ADD_ENDORSEMENT_TO_POLICY_SUCCESS,
          payload: {
            endorsement: response.endorsement,
          },
        });
        return response;
      });
  };
}

export function deleteEndorsement({ policy_id, endorsement_id }) {
  return (dispatch, getState, { api }) => {
    dispatch({ type: DELETE_ENDORSEMENT_REQUEST });
    return api.endorsements
      .deleteEndorsement({ policy_id, endorsement_id })
      .then(response =>
        dispatch({
          type: DELETE_ENDORSEMENT_SUCCESS,
          payload: {
            endorsement_id,
          },
        })
      );
  };
}

export const bookEndorsement = (endorsement_id, file) => {
  return async (dispatch, getState, { api }) => {
    dispatch({ type: BOOK_ENDORSEMENT_REQUEST });
    const upload_data = await api.endorsements.getUploadUrl(endorsement_id);
    await api.endorsements.uploadEndorsement(upload_data, file);
    const body = await api.endorsements.bookEndorsement(endorsement_id, {
      documents: {
        location: {
          bucket: upload_data.data.bucket,
          key: upload_data.data.key,
        },
      },
    });
    await dispatch({
      type: BOOK_ENDORSEMENT_SUCCESS,
      payload: {
        endorsement: body.endorsement,
      },
    });
    return body;
  };
};

export function generateUrl(endorsement_id) {
  return (dispatch, getState, { api }) => {
    dispatch({ type: GET_ENDORSEMENT_URL_BY_ID_REQUEST });
    return api.endorsements.getUrlById(endorsement_id).then(response => {
      dispatch({
        type: GET_ENDORSEMENT_URL_BY_ID_SUCCESS,
        payload: {
          endorsement_url: response.endorsement_url,
        },
      });
      return response;
    });
  };
}

export function getById(endorsement_id, options = {}) {
  const { cache = true } = options;
  return (dispatch, getState, { api }) => {
    const state = getState();
    const endorsement = selectors.endorsementById(state, endorsement_id);
    if (cache && endorsement) {
      return Promise.resolve({
        endorsement,
      });
    }
    dispatch({ type: GET_ENDORSEMENT_BY_ID_REQUEST });
    return api.endorsements.getById(endorsement_id).then(response => {
      dispatch({
        type: GET_ENDORSEMENT_BY_ID_SUCCESS,
        payload: {
          endorsement: response.endorsement,
        },
      });
      return response;
    });
  };
}
