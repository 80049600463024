import React from 'react';

import { SubmissionSummaryCard } from '../../../';
import AccountSummaryCard from './AccountSummaryCard';

const styles = {
  header: { 
    fontSize: '1.25rem', 
    fontWeight: '500', 
    marginTop: '1rem'
  },
  list_item: { marginTop: '0.75em' },
};

const MatchTypeToComponent = {
  submission: data => <SubmissionSummaryCard submission={data} />,
  account: data => <AccountSummaryCard account={data} />,
};

export default function MatchesList({ style, matches }) {
  return (
    <section style={style}>
      <h2 style={styles.header}>Matching Submissions</h2>
      <ul className="list-unstyled">
        {matches.map(match => {
          const MatchComponent = MatchTypeToComponent[match.type];
          return (
            <li
              key={`${match.type}::${match.data.id}`}
              style={styles.list_item}
            >
              {MatchComponent(match.data)}
            </li>
          );
        })}
      </ul>
    </section>
  );
}
