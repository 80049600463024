import React from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';

import { AsyncMutationWrapper } from '../../../common';

import Coverage from './Coverage';

import * as format from './format';

const styles = {
  coverages_table: {
    display: 'table',
    listStyle: 'none',
    paddingLeft: '0',
  },
  coverages_header: {
    paddingBottom: '10px',
  },
  footer_container: {
    textAlign: 'left',
  },
  total_premium: {
    fontSize: '1.875rem',
  },
  buttons: {
    marginTop: '25px',
  },
  error: {
    color: 'red',
    marginTop: '10px',
  },
};

export class Body extends React.Component {
  coverage_components = {};

  clearPremiumAdjustments = () => {
    this.props.coverages.forEach(coverage => {
      this.coverage_components[coverage.code].clearInputs();
    });
  };

  render() {
    const { coverages, updatePremiumAdjustment } = this.props;
    return (
      <div>
        <table style={styles.coverages_table}>
          <thead>
            <tr>
              <th style={styles.coverages_header}>Base</th>
              <th style={styles.coverages_header}>Adjustment</th>
            </tr>
          </thead>
          <tbody>
            {coverages.map(coverage => {
              return (
                <Coverage
                  ref={input => {
                    this.coverage_components[coverage.code] = input;
                  }}
                  key={coverage.code}
                  coverage={coverage.code}
                  premium={coverage.premium}
                  updatePremiumAdjustment={adjustment => {
                    return updatePremiumAdjustment(coverage.code, adjustment);
                  }}
                />
              );
            })}
          </tbody>
        </table>
        <div style={{ textAlign: 'right' }}>
          <Button variant="primary" onClick={this.clearPremiumAdjustments}>
            <i className="bi bi-trash3" style={{marginRight:'5px'}}/>
            Clear Adjustments
          </Button>
        </div>
      </div>
    );
  }
}

export const Footer = ({ total_premium, hide, submit }) => {
  return (
    <section style={styles.footer_container}>
      <h4>Adjusted Premium</h4>
      <span style={styles.total_premium}>
        {format.wholeNumberCurrency(total_premium)}
      </span>
      <AsyncMutationWrapper
        mutate={submit}
        render={({ mutate, loading, error }) => {
          return (
            <div style={styles.buttons}>
              <div>
                <Button
                  variant="primary"
                  disabled={loading}
                  onClick={() => mutate().catch(() => null)}
                >
                  Save Premium
                </Button>
                <Button variant="link" onClick={hide}>
                  Cancel
                </Button>
              </div>
              {error && (
                <Form.Text style={styles.error}>{error.message}</Form.Text>
              )}
            </div>
          );
        }}
      />
    </section>
  );
};
