import React from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';

import { SUBMISSION_STATUSES, compareStatuses } from '../../statuses';

const SubmissionStatus = ({
  submission: { clearance: { status: clearanceStatus } = {}, declined, status },
} = {}) => {
  const is_declinable = [
    SUBMISSION_STATUSES.QUOTED,
    SUBMISSION_STATUSES.QUOTING_IN_PROGRESS,
  ].includes(status);

  const submission_is_declined =
    (clearanceStatus !== 'FAILED' || is_declinable) && declined === true;
  if (submission_is_declined) {
    return <span className="text-danger">Declined</span>;
  }
  switch (status) {
    case SUBMISSION_STATUSES.ISSUED:
      return <strong>Issued</strong>;
    case SUBMISSION_STATUSES.BOUND:
      return <strong className="text-success">Bound</strong>;
    case SUBMISSION_STATUSES.BINDING_IN_PROGRESS:
      return (
        <strong className="text-warning" style={{ color: '#f5a623' }}>
          Binding in Progress
        </strong>
      );
    case SUBMISSION_STATUSES.PRICING_IN_PROGRESS:
      return (
        <strong className="text-warning" style={{ color: '#f5a623' }}>
          Pricing in Progress
        </strong>
      );
    case SUBMISSION_STATUSES.QUOTING_IN_PROGRESS:
      return (
        <strong className="text-warning" style={{ color: '#f5a623' }}>
          Quoting in Progress
        </strong>
      );
    case SUBMISSION_STATUSES.QUOTED:
      return <strong className="text-success">Quoted</strong>;
    default:
      break;
  }
  switch (clearanceStatus) {
    case 'FAILED':
      return <span>Duplicate</span>;
    default:
      return <strong className="text-success">Cleared</strong>;
  }
};

const tableConfig = {
  columns: [
    {
      key: '1',
      title: 'Named Insured',
      content: submission => submission.named_insured.name,
    },
    {
      key: '2',
      title: 'Submission #',
      content: submission => `#${submission.submission_number}`,
    },
    {
      key: '3',
      title: 'Submitted by',
      content: submission =>
        `${submission.broker.name}, ${submission.broker.brokerage}`,
    },
    {
      key: '4',
      title: 'Status',
      content: submission => <SubmissionStatus submission={submission} />,
    },
    {
      key: '5',
      title: 'Date',
      content: submission => moment(submission.updated_at).format('M/D/YYYY'),
    },
    {
      key: '6',
      title: 'Action',
      content: submission => {
        const target =
          compareStatuses(
            submission.status,
            SUBMISSION_STATUSES.QUOTING_IN_PROGRESS
          ) >= 0
            ? `/accounts/${submission.account_id}/`
            : `/submissions/${submission.id}/`;
        return <Link to={target}>View</Link>;
      },
    },
  ],
};

export default tableConfig;
