import {
  GET_RATING_BY_ID_SUCCESS,
  GET_RATING_BY_BINDER_SUCCESS,
  GET_RATING_BY_POLICY_SUCCESS,
  UPDATE_RISK_ANALYSIS_SUCCESS,
  UPDATE_LIMITS_RETENTIONS_SUCCESS,
  UPDATE_PREMIUM_ADJUSTMENTS_SUCCESS,
  GET_PREMIUM_SUCCESS,
  GET_RATINGS_BY_QUOTE_SUCCESS,
  CREATE_RATING_COPY_SUCCESS,
  CREATE_RATING_FROM_QUOTE_SUCCESS,
} from './constants';

import { constants as quotes } from '../quote';

import { RATING_STATUSES } from './statuses';

const initial_state = {
  by_id: {},
};

export default function submissions(state = initial_state, action) {
  switch (action.type) {
    case GET_RATING_BY_ID_SUCCESS:
    case GET_RATING_BY_BINDER_SUCCESS:
    case GET_RATING_BY_POLICY_SUCCESS:
    case GET_PREMIUM_SUCCESS:
    case CREATE_RATING_COPY_SUCCESS:
    case CREATE_RATING_FROM_QUOTE_SUCCESS:
    case UPDATE_RISK_ANALYSIS_SUCCESS:
    case UPDATE_LIMITS_RETENTIONS_SUCCESS:
    case quotes.CREATE_PREPOPULATED_QUOTE_FROM_SUBMISSION_SUCCESS:
      return handlers.storeRating(state, action);
    case quotes.CREATE_QUOTE_COPY_SUCCESS:
      return handlers.storeRatings(state, action);
    case UPDATE_PREMIUM_ADJUSTMENTS_SUCCESS:
      return handlers.updatePremiumAdjustmentsSuccess(state, action);
    case GET_RATINGS_BY_QUOTE_SUCCESS:
      return handlers.getRatingsByQuoteIdSuccess(state, action);
    case quotes.GENERATE_QUOTE_DOCUMENTS_SUCCESS:
      return handlers.generateQuoteDocumentsSuccess(state, action);
    default:
      return state;
  }
}

const handlers = {
  storeRating(state, { payload }) {
    return {
      ...state,
      by_id: {
        ...state.by_id,
        [payload.rating.id]: payload.rating,
      },
    };
  },
  storeRatings(state, { payload }) {
    if (!payload.ratings) return state;
    return {
      ...state,
      by_id: payload.ratings.reduce(
        (by_id, rating) => {
          by_id[rating.id] = rating;
          return by_id;
        },
        { ...state.by_id }
      ),
    };
  },
  updatePremiumAdjustmentsSuccess(state, { payload }) {
    const { rating } = payload;
    return {
      ...state,
      by_id: {
        ...state.by_id,
        [rating.id]: {
          ...state.by_id[rating.id],
          premium: rating.premium,
        },
      },
    };
  },
  getRatingsByQuoteIdSuccess(state, { payload }) {
    const { ratings } = payload;
    return {
      ...state,
      by_id: ratings.reduce(
        (by_id, rating) => {
          by_id[rating.id] = rating;
          return by_id;
        },
        { ...state.by_id }
      ),
    };
  },
  generateQuoteDocumentsSuccess(state, { payload }) {
    const { ratings } = payload;
    return {
      ...state,
      by_id: ratings.reduce(
        (by_id, rating) => {
          return {
            ...by_id,
            [rating.id]: {
              ...by_id[rating.id],
              status: RATING_STATUSES.QUOTED,
            },
          };
        },
        { ...state.by_id }
      ),
    };
  },
};
