import { gql } from '@apollo/client';

const SearchQuotesBySubmission = gql`
  query QuotesBySubmission($submission_id: ID, $limit: Int, $offset: Int) {
    submissionById(id: $submission_id) {
      id
      quotes(limit: $limit, offset: $offset) {
        results {
          id
          created_at
          updated_at
          status
          display_name
        }
        count
      }
    }
  }
`;

export { SearchQuotesBySubmission };
