import { Condition } from './_base';
export class BooleanNotCondition extends Condition {
  eval(value, values, context) {
    const result = !this.condition(this.config.condition).eval(
      value,
      values,
      context
    );
    return result;
  }
}
