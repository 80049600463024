import React from 'react';
import Row from 'react-bootstrap/Row';
import { ExtendedReporting } from '../../../../quote';

import * as validation from './validation';

export default function ExtendedReportingSection({
  style,
  selected_coverages,
}) {
  return (
    <section style={style}>
      <Row style={{ margin: '0' }}>
        <h3>Extended Reporting Period</h3>
        <ExtendedReporting validation={validation.erp} />
      </Row>
    </section>
  );
}
