import React from 'react';
import { FormSection, Field } from 'redux-form';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import InputControl from '../InputControl';
import states from './states';

const Select = ({ options, ...props }) => {
  return (
    <select {...props}>
      {options.map(option => (
        <option value={option.value} key={option.value}>
          {option.value} - {option.label}
        </option>
      ))}
    </select>
  );
};

function AddressFields({ validate, warn, is_required }) {
  return (
    <FormSection name="address" component="fieldset">
      <Row>
        <Col md={12}>
          <Field
            name="street_line_1"
            type="text"
            component={InputControl}
            label="Address line 1"
            required={is_required.street_line_1}
            validate={validate.street_line_1}
            warn={warn.street_line_1}
          />
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <Field
            name="street_line_2"
            type="text"
            component={InputControl}
            label="Address line 2"
            validate={validate.street_line_2}
            warn={warn.street_line_2}
            placeholder="Optional"
          />
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <Field
            name="city"
            type="text"
            component={InputControl}
            label="City"
            required={is_required.city}
            validate={validate.city}
            warn={warn.city}
          />
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <Field
            name="state"
            as={Select}
            options={states.map(state => ({
              value: state.abbreviation,
              label: state.name,
            }))}
            component={InputControl}
            label="State"
            required={is_required.state}
            validate={validate.state}
            warn={warn.state}
          />
        </Col>
        <Col md={6}>
          <Field
            name="zip"
            type="text"
            component={InputControl}
            label="Zip"
            required={is_required.zip}
            validate={validate.zip}
            warn={warn.zip}
          />
        </Col>
      </Row>
    </FormSection>
  );
}

AddressFields.defaultProps = {
  validate: {},
  warn: {},
  is_required: {},
};

export default AddressFields;
