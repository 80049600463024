import React from 'react';

import { ExclusionsSummary } from '../../../quote';

export default function ExclusionsSection({ exclusions, rating, ...props }) {
  return (
    <ExclusionsSummary
      {...props}
      exclusions={exclusions}
      coverages={rating.risk_analysis.selected_coverages}
    />
  );
}
