import React from 'react';
import { Flag } from '../../../common/components/Flag';
import { Link } from 'react-router-dom';
import moment from 'moment';

import { StickySidebar } from '../../../layouts';

import { SubmissionSummaryCard } from '../../../submissions';
import { DecsPolFormsPreview } from '../../../quote';

import * as permissions from '../../permissions';

import BindPolicyButton from '../BindPolicyButton';
import CancelButton from '../BinderWorksheet/CancelButton';

import OverviewSection from './OverviewSection';
import InsuredBrokerDetails from './InsuredBrokerDetails';
import PriceOptionSection from './PriceOptionSection';
import SubjectivitiesSection from './SubjectivitiesSection';
import ExclusionsSection from './ExclusionsSection';
import EndorsementsSection from './EndorsementsSection';
import BinderPreviewProgressBox from './BinderPreviewProgressBox';
import BrokerSection from './BrokerSection';
import SLBrokerSection from './SLBrokerSection';
import ExtendedReporting from './ExtendedReporting';
import EffectiveDatePastWarning from './EffectiveDatePastWarning';
import { isSurplusLines } from '../../utils';

const styles = {
  sidebar: {
    margin: 'auto',
    maxWidth: '240px',
  },
  section_container: {
    borderTop: '1px solid grey',
    marginTop: '35px',
  },
  section_container2: {
    borderTop: '1px solid grey',
    marginTop: '35px',
    paddingTop: '20px'
  },
  submission_summary: {
    marginTop: '40px',
  },
  button: {
    marginTop: '25px',
  },
  cancelButton: { display: 'table', margin: 'auto' },
};

const sections = [
  {
    title: 'Overview',
    name: 'overview',
  },
  {
    title: 'Insured & Broker Details',
    name: 'insured_broker_details',
  },
  {
    title: 'Premium',
    name: 'premium',
  },
  {
    title: 'Broker details',
    name: 'broker_details',
  },
  {
    title: 'Surplus Lines Broker data',
    name: 'sl_broker',
  },
  {
    title: 'Subjectivities',
    name: 'subjectivities',
  },
  {
    title: 'Exclusions',
    name: 'exclusions',
  },
  {
    title: 'Declarations & Policy Forms',
    name: 'decs_policy',
  },
  {
    title: 'Endorsements',
    name: 'endorsements',
  },
];

const DateNode = ({ date }) => (
  <span style={{ display: 'inline-block' }}>
    {moment(date)
      .utc()
      .format('MMMM D, YYYY')}
  </span>
);

const isInThePast = value => {
  if (!value) return true;
  const now = new Date();
  const today = new Date(
    Date.UTC(now.getFullYear(), now.getMonth(), now.getDate())
  );
  const valueAsDate = new Date(value);
  return valueAsDate < today;
};

function BinderPreview({
  binder,
  submission,
  quote,
  rating,
  config,
  deleteBinder,
  ...props
}) {
  const overview_items = [
    {
      label: 'Binder Expiration Date',
      value: <DateNode date={binder.expiration_date} />,
    },
    { label: 'Policy #', value: binder.policy_number || 'Provided at Bind' },
    {
      label: 'Policy Period',
      value: (
        <span>
          <DateNode date={binder.policy_effective_date} /> -{' '}
          <DateNode date={binder.policy_expiration_date} />
        </span>
      ),
    },
    {
      label: 'Carrier',
      value: `${config.carrier.name} (${
        config.is_surplus_lines ? 'Non-Admitted' : 'Admitted'
      })`,
    },
  ];
  return (
    <StickySidebar
      renderMain={() => {
        return (
          <section>
            <h1>Binder preview</h1>
            <OverviewSection
              id="overview"
              style={styles.section_container2}
              items={overview_items}
            />
            <InsuredBrokerDetails
              id="insured_broker_details"
              style={styles.section_container2}
              named_insured={binder.named_insured}
              broker={binder.broker}
            />
            <PriceOptionSection
              id="premium"
              style={styles.section_container2}
              rating={rating}
            />
            <BrokerSection
              id="broker_details"
              style={styles.section_container2}
              broker={binder.broker}
              binder_id={binder.id}
              is_locked={!permissions.canUpdateBroker({ binder, submission })}
            />
            {isSurplusLines(binder) && (
              <SLBrokerSection
                id="sl_broker"
                style={styles.section_container2}
                sl_broker={binder.sl_broker}
                binder_id={binder.id}
                named_insured={binder.named_insured}
                is_locked={
                  !permissions.canUpdateSLBroker({ binder, submission })
                }
              />
            )}
            <SubjectivitiesSection
              id="subjectivities"
              style={styles.section_container}
              binder={binder}
            />
            <ExclusionsSection
              id="exclusions"
              style={styles.section_container}
              exclusions={binder.terms_conditions.exclusions}
              rating={rating}
            />
            <DecsPolFormsPreview
              id="decs_policy"
              style={styles.section_container2}
              policy_forms={binder.terms_conditions.policy_forms}
            />
            <EndorsementsSection
              id="endorsements"
              style={styles.section_container}
              terms_conditions={binder.terms_conditions}
            />
            <ExtendedReporting
              id="erp"
              style={styles.section_container}
              binder={binder}
            />
            {permissions.canBindPolicy({ binder, submission }) &&
              isInThePast(rating.risk_analysis.effective_date) && (
                <EffectiveDatePastWarning />
              )}
            {permissions.canBindPolicy({ binder, submission }) && (
              <BindPolicyButton
                style={styles.button}
                binder={binder}
                disabled={!!submission.declined}
              />
            )}
            {permissions.canDownloadDocuments({ binder }) && (
              <Link
                style={{ ...styles.button }}
                className="btn btn-primary"
                to={`/binders/${binder.id}/documents`}
              >
                Download Binder
              </Link>
            )}
          </section>
        );
      }}
      renderAside={() => {
        return (
          <section style={styles.sidebar}>
            {permissions.canCancelBinder({
              binder,
            }) && (
              <CancelButton
                deleteBinder={deleteBinder}
                style={styles.cancelButton}
              />
            )}
            <BinderPreviewProgressBox sections={sections} />
            <Flag
              name="binder.preview.submission_summary_card"
              render={() => {
                return (
                  <SubmissionSummaryCard
                    style={styles.submission_summary}
                    flex
                    submission={submission}
                  />
                );
              }}
            />
          </section>
        );
      }}
    />
  );
}

export default BinderPreview;
