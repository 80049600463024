import React from 'react';

import table_config from './table-config';

function PolicyRow({ policy }) {
  return table_config.columns.map(column => {
    return <td key={column.key}>{column.content(policy)}</td>;
  });
}

export default PolicyRow;
