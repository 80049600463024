import React from 'react';
import { Query } from '@apollo/client/react/components';
import jmp from 'json-merge-patch';

import { PageTitle } from '../../../common';

import { Routes, Route, Navigate } from 'react-router-dom';
import { Link } from 'react-router-dom';

import { QUOTE_STATUSES, compareStatuses } from '../../statuses';

import QuoteProgressNav from './QuoteProgressNav';
import QuoteWorksheet from '../QuoteWorksheet';
import QuotePreview from '../QuotePreview';
import QuoteWorkspace from '../QuoteWorkspace';
import QuoteDocuments from '../QuoteDocuments';
import QuoteDetails from '../QuoteDetails';

import SubmissionsSummaryBar from '../../../common/components/SubmissionsSummaryBar';
import { removeTypename } from '../../../graphql/utils';

import { query } from './query';
import { combinePathPatterns } from '../../../utils';

const stripNull = data => {
  return jmp.apply({}, data);
};

const decomposeGraphQLResponse = response => {
  const {
    submission: {
      quotes: { results: related_quotes },
      ...submission
    },
    config,
    ...quote
  } = stripNull(removeTypename(response.quote));
  const { ratings } = quote;
  return { submission, config, quote, ratings, related_quotes };
};

const renderQuoteDisplayName = ({ quote }) => ({ style, display_name }) => (
  <Link to={`/quotes/${quote.id}/`}>
    <strong style={style}>{display_name}</strong>
  </Link>
);

export default function ViewQuoteContainer(props) {
  const { match, location } = props;

  return (
    <Query
      query={query}
      variables={{
        quoteId: match.params.quote_id,
      }}
      fetchPolicy="cache-and-network"
    >
      {({ data, loading, error }) => {
        if (loading && !data?.quote) {
          return <div className="text-center-2">Loading...</div>;
        }
        if (error) {
          return (
            <div className="text-center">
              Sorry. Something went wrong. Please try refreshing.
            </div>
          );
        }
        const {
          config,
          ratings,
          submission,
          quote,
          related_quotes,
        } = decomposeGraphQLResponse(data);
        const isQuoted =
          quote && compareStatuses(quote.status, QUOTE_STATUSES.UNDEFINED) > 0;
        const activeRatings = ratings.filter(rating => !rating.deleted);
        const is_in_quote_workspace =
          quote && location.pathname === `/quotes/${quote.id}/`;
        const insured_name = submission && submission.named_insured.name;
        return (
          <div>
            <div>
              <SubmissionsSummaryBar
                submission={submission}
                display_name={quote.display_name}
                renderDisplayName={
                  is_in_quote_workspace
                    ? undefined
                    : renderQuoteDisplayName({ quote })
                }
              />
              <QuoteProgressNav
                ratings={activeRatings}
                quote={quote}
                submission={submission}
                pathPattern={match.pattern.path}
              />
            </div>
            <Routes>
              <Route
                path="terms-conditions"
                element={
                  isQuoted ? (
                    <React.Fragment>
                      <PageTitle title={`${insured_name} - Quote Preview`} />
                      <Navigate to="../preview" replace={true} />
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <PageTitle title={`${insured_name} - Quote Submission`} />
                      <QuoteWorksheet
                        config={config}
                        quote={quote}
                        submission={submission}
                        ratings={activeRatings}
                        pathPattern={combinePathPatterns([match.pattern.path, 'terms-conditions'])}
                      />
                    </React.Fragment>
                  )
                }
              />
              <Route
                path="preview"
                element={
                  <React.Fragment>
                    <PageTitle title={`${insured_name} - Quote Preview`} />
                    <QuotePreview
                      quote={quote}
                      submission={submission}
                      ratings={activeRatings}
                      config={config}
                      updateBroker={(...args) => props.updateBroker(...args)}
                      pathPattern={combinePathPatterns([match.pattern.path, 'preview'])}
                    />
                  </React.Fragment>
                }
              />
              <Route
                path="documents"
                element={
                  <React.Fragment>
                    <PageTitle title={`${insured_name} - Download Quote`} />
                    <QuoteDocuments quote={quote} submission={submission} />
                  </React.Fragment>
                }
              />
              <Route
                path="details"
                element={
                  isQuoted ? (
                    <React.Fragment>
                      <PageTitle title={`${insured_name} - Quote Preview`} />
                      <Navigate to="../preview" replace={true}/>
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <PageTitle title={`${insured_name} - Quote Details`} />
                      <QuoteDetails 
                        quote={quote} 
                        submission={submission} 
                        pathPattern={combinePathPatterns([match.pattern.path, 'details'])}  
                      />
                    </React.Fragment>
                  )
                }
              />
              <Route
                path="quote-letter"
                element={<Navigate to="../documents" replace={true} />}
              />
              <Route
                path="/"
                element={
                  <React.Fragment>
                    <PageTitle
                      title={`${insured_name} - ${quote.display_name}`}
                    />
                    <QuoteWorkspace
                      quote={quote}
                      ratings={activeRatings}
                      unfilteredRatings={ratings}
                      productConfig={config}
                      submission={submission}
                      related_quotes={related_quotes}
                      pathPattern={match.pattern.path}
                    />
                  </React.Fragment>
                }
              />
            </Routes>
          </div>
        );
      }}
    </Query>
  );
}
