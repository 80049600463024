const SUBMISSION_STATUSES = {
  UNDEFINED: undefined,
  NULL: null,
  PRICING_IN_PROGRESS: 'PRICING_IN_PROGRESS',
  QUOTING_IN_PROGRESS: 'QUOTING_IN_PROGRESS',
  QUOTED: 'QUOTED',
  BINDING_IN_PROGRESS: 'BINDING_IN_PROGRESS',
  BOUND: 'BOUND',
  ISSUED: 'ISSUED',
};

const status_to_number = new Map();
status_to_number.set(SUBMISSION_STATUSES.UNDEFINED, 0);
status_to_number.set(SUBMISSION_STATUSES.NULL, 0);
status_to_number.set(SUBMISSION_STATUSES.PRICING_IN_PROGRESS, 1);
status_to_number.set(SUBMISSION_STATUSES.QUOTING_IN_PROGRESS, 2);
status_to_number.set(SUBMISSION_STATUSES.QUOTED, 3);
status_to_number.set(SUBMISSION_STATUSES.BINDING_IN_PROGRESS, 4);
status_to_number.set(SUBMISSION_STATUSES.BOUND, 5);
status_to_number.set(SUBMISSION_STATUSES.ISSUED, 6);

const numericStatus = status => {
  if (status_to_number.has(status)) return status_to_number.get(status);
  throw new Error(`${status} is not a valid submission status.`);
};

const compareStatuses = (status1, status2) => {
  const num1 = numericStatus(status1);
  const num2 = numericStatus(status2);
  if (num1 > num2) return 1;
  if (num1 < num2) return -1;
  return 0;
};

export { SUBMISSION_STATUSES, compareStatuses };
