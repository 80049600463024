import { validator } from '../../../validation';

export default function isValid(productConfig, { rating, quote, submission }) {
  const { limits_retentions } = rating;
  const validations = validator.validateDeep(
    productConfig.limits_retentions_schema,
    limits_retentions,
    {
      rating,
      quote,
    }
  );
  return !validations.length;
}
