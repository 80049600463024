import { selectors as common } from '../common';
import { selectors as policies } from '../policies';
import { selectors as binders } from '../binder';
import { selectors as quotes } from '../quote';
import { selectors as ratings } from '../rating';
import { selectors as submissions } from '../submissions';
import { selectors as endorsements } from '../endorsements';

export const configuration = state => {
  return common.configuration(state);
};

export const submissionById = (state, submission_id) => {
  return submissions.getById(state.submissions, submission_id) || null;
};

export const submissionByRating = (state, rating_id) => {
  const rating = ratingById(state, rating_id);
  if (!rating) return null;
  return submissionById(state, rating.submission_id);
};

export const submissionByQuote = (state, quote_id) => {
  const quote = quoteById(state, quote_id);
  if (!quote) return null;
  return submissionById(state, quote.submission_id);
};

export const submissionByBinder = (state, binder_id) => {
  const binder = binderById(state, binder_id);
  if (!binder) return null;
  return submissionById(state, binder.submission_id);
};

export const submissionByPolicy = (state, policy_id) => {
  const policy = policyById(state, policy_id);
  if (!policy) return null;
  return submissionById(state, policy.submission_id);
};

export const ratingById = (state, rating_id) => {
  return ratings.getById(state.ratings, rating_id) || null;
};

export const ratingByBinder = (state, binder_id) => {
  const binder = binderById(state, binder_id);
  if (!binder) return null;
  return ratingById(state, binder.rating_id);
};

export const ratingByPolicy = (state, policy_id) => {
  const policy = policyById(state, policy_id);
  if (!policy) return null;
  return ratingById(state, policy.rating_id);
};

export const ratingsBySubmission = (state, submission_id) => {
  return ratings.getBySubmission(state.ratings, submission_id) || [];
};

export const ratingsByQuote = (state, quote_id) => {
  const quote = quoteById(state, quote_id);
  if (!quote) return [];
  return (quote.ratings || [])
    .map(quoteRatingAssoc => {
      const rating = ratingById(state, quoteRatingAssoc.rating_id);
      if (!rating) return null;
      return { ...rating, deleted: quoteRatingAssoc.deleted };
    })
    .filter(rating => rating);
};

export const quoteById = (state, quote_id) => {
  return quotes.getById(state.quotes, quote_id) || null;
};

export const quotesBySubmission = (state, submission_id) => {
  return quotes.getBySubmission(state.quotes, submission_id) || [];
};

export const quoteByBinder = (state, binder_id) => {
  const binder = binderById(state, binder_id);
  if (!binder) return null;
  return quoteById(state, binder.quote_id);
};

export const binderById = (state, binder_id) => {
  return binders.getById(state.binders, binder_id) || null;
};

export const binderBySubmission = (state, binder_id) => {
  return binders.getBySubmission(state.binders, binder_id) || null;
};

export const policyById = (state, policy_id) => {
  return policies.getById(state.policies, policy_id) || null;
};

export const policyBySubmission = (state, submission_id) => {
  return policies.getBySubmission(state.policies, submission_id) || null;
};

export const quotesByRating = (state, rating_id) => {
  return quotes.getByRating(state.quotes, rating_id) || [];
};

export const endorsementById = (state, endorsement_id) => {
  return endorsements.getById(state.endorsements, endorsement_id) || null;
};

export const endorsementsBySubmission = (state, endorsement_id) => {
  return endorsements.getBySubmission(state.endorsements, endorsement_id) || [];
};
export const endorsementOptionByIdVersion = (
  state,
  option
) => {
  return (
    endorsements.getOptionByIdVersion(
      state.endorsements,
      option) || null
  );
};
