import React from 'react';
import Form from 'react-bootstrap/Form';

import Control from './Control';

const Multicheck = props => {
  const { field, name, input, style } = props;
  return (
    <Control {...props}>
      <div>
        {field.options.map(option => {
          const id = `${name}-${option.value}`;
          const values = input.value || [];
          const checked = values.includes(option.value);
          return (
            <Form.Check
              key={option.value}
              type='checkbox'
              style={{
                display: 'inline-block',
                marginRight: '0.5em',
                ...style,
              }}
              {...input}
              id={id}
              value={option.value}
              checked={checked}
              onChange={() => {
                const new_values = checked
                  ? values.filter(selected => selected !== option.value)
                  : values.concat(option.value);
                return input.onChange(new_values);
              }}
              onBlur={() => input.onBlur(values)}
              aria-label={option.label}
              label={option.label}
              className='mt-2 mb-3'
            />
          );
        })}
      </div>
    </Control>
  );
};

export default Multicheck;
