import React from 'react';
import Container from 'react-bootstrap/Container';

import WithAuth from './WithAuth';

const styles = {
  container: {
    maxWidth: '480px',
  },
  header: {
    margin: '0.5em 0 0.5em',
  },
  text: {
    marginBottom: '1em',
  },
};

export default function Login() {
  return (
    <WithAuth
      render={({ auth, user, loading }) => {
        return (
          <Container style={styles.container} className="text-center">
            {loading ? (
              <p style={styles.text}>Loading...</p>
            ) : (
              <React.Fragment>
                <h1 style={styles.header}>Please log in</h1>
                <p style={styles.text}>
                  You may have been logged out for your security.
                </p>
              </React.Fragment>
            )}
            <button
              className="btn btn-primary"
              onClick={() => {
                return auth.signInWithRedirect('/');
              }}
            >
              {user ? 'Log in as Another User' : 'Log in to Protector'}
            </button>
          </Container>
        );
      }}
    />
  );
}
