import { connect } from 'react-redux';

import { updateZScore } from '../../../actions';

import FinancialsModal from './FinancialsModal';

const mapStateToProps = (state, props) => {
  const { selected_coverages, financial } = props.binder.rating.risk_analysis;
  const can_update_total_assets = !selected_coverages.includes('do');
  return {
    can_update_total_assets,
    initialValues: {
      financial: {
        current: {
          total_liabilities: financial.current.total_liabilities,
          retained_earnings: financial.current.retained_earnings,
          operating_income: financial.current.operating_income,
          ...(can_update_total_assets && {
            total_assets: financial.current.total_assets,
          }),
        },
      },
    },
  };
};

const mapDispatchToProps = (dispatch, props) => dispatch => ({
  updateZScore: financial => dispatch(updateZScore(props.binder.id, financial)),
});

export default connect(mapStateToProps, mapDispatchToProps)(FinancialsModal);
