import GetPolicy from './get';
import GetBySubmission from './getBySubmission';
import GetPolicyDocuments from './getPolicyDocuments';
import UpdateTermsConditions from './updateTermsConditions';
import IssuePolicy from './issuePolicy';
import SearchPolicies from './searchPolicies';
import UpdateDetails from './updateDetails';

export default function PoliciesApi(fetch, auth) {
  return {
    get: GetPolicy(fetch, auth),
    getBySubmission: GetBySubmission(fetch, auth),
    getPolicyDocuments: GetPolicyDocuments(fetch, auth),
    updateTermsConditions: UpdateTermsConditions(fetch, auth),
    issuePolicy: IssuePolicy(fetch, auth),
    search: SearchPolicies(fetch, auth),
    updateDetails: UpdateDetails(fetch, auth),
  };
}
