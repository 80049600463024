import React from 'react';
import { reduxForm, SubmissionError } from 'redux-form';
import pick from 'lodash/pick';

import { createAndClear } from '../../actions';

import { FormContainer, Insured, Broker, SubmitButton } from './common';

const NewBusiness = props => {
  return (
    <FormContainer handleSubmit={props.handleSubmit(submit)}>
      <Broker />
      <Insured />
      <SubmitButton {...props} />
    </FormContainer>
  );
};

async function submit(values, dispatch, props) {
  const fields = [
    'broker.brokerage',
    'broker.name',
    'broker.email',
    'named_insured.name',
    'named_insured.address.street_line_1',
    'named_insured.address.street_line_2',
    'named_insured.address.city',
    'named_insured.address.state',
    'named_insured.address.zip',
  ];
  try {
    const submission = pick(values, fields);
    const response = await dispatch(createAndClear(submission));
    props.navigate(`/submissions/${response.submission.id}`);
    return response;
  } catch (e) {
    throw new SubmissionError({ _error: e });
  }
}

export default reduxForm({
  form: 'create_submission',
  destroyOnUnmount: false,
})(NewBusiness);
