import React from 'react';
import { Flag } from '../../../common/components/Flag';
import { Link } from 'react-router-dom';
import moment from 'moment';
import Alert from 'react-bootstrap/Alert';

import { StickySidebar } from '../../../layouts';
import { SubmissionSummaryCard } from '../../../submissions';
import { BinderPreview } from '../../../binder';
import { DecsPolFormsPreview } from '../../../quote';
import { DynamicEndorsementAlert } from '../../../endorsements';

import * as permissions from '../../permissions';
import { isSurplusLines } from '../../utils';

import IssuePolicyButton from '../IssuePolicyButton';

import InsuredBrokerDetails from './InsuredBrokerDetails';
import BrokerSection from './BrokerSection';
import SLBrokerSection from './SLBrokerSection';
import SubjectivitiesSection from './SubjectivitiesSection';
import ExtendedReporting from './ExtendedReporting';

const {
  OverviewSection,
  PriceOptionSection,
  ExclusionsSection,
  EndorsementsSection,
} = BinderPreview;

const styles = {
  sidebar: {
    margin: 'auto',
    maxWidth: '240px',
  },
  section_container: {
    borderTop: '1px solid grey',
    marginTop: '35px',
  },
  section_container2: {
    borderTop: '1px solid grey',
    marginTop: '35px',
    paddingTop: '20px'
  },
  button: {
    marginTop: '25px',
  },
  alert: {
    fontSize: '0.8125rem',
    marginTop: '20px',
  },
  text: {
    color: 'black',
  },
};

const DateNode = ({ date }) => (
  <span style={{ display: 'inline-block' }}>
    {moment(date)
      .utc()
      .format('MMMM D, YYYY')}
  </span>
);

export default function PolicyPreview({ policy, submission, rating, config }) {
  const overview_items = [
    { label: 'Policy #', value: policy.policy_number },
    {
      label: 'Policy Period',
      value: (
        <span>
          <DateNode date={policy.effective_date} /> -{' '}
          <DateNode date={policy.expiration_date} />
        </span>
      ),
    },
    {
      label: 'Carrier',
      value: `${config.carrier.name} (${
        config.is_surplus_lines ? 'Non-Admitted' : 'Admitted'
      })`,
    },
  ];
  return (
    <StickySidebar
      renderMain={() => {
        return (
          <section>
            <h1>Policy Preview</h1>
            <OverviewSection
              id="overview"
              style={styles.section_container2}
              items={overview_items}
            />
            <InsuredBrokerDetails
              id="insured_broker_details"
              style={styles.section_container2}
              named_insured={policy.named_insured}
              broker={policy.broker}
            />
            <PriceOptionSection
              id="premium"
              style={styles.section_container2}
              rating={rating}
            />
            <BrokerSection
              id="broker_details"
              style={styles.section_container2}
              broker={policy.broker}
              policy_id={policy.id}
              is_locked={!permissions.canUpdateBroker({ policy })}
            />
            {isSurplusLines(policy) && (
              <SLBrokerSection
                id="sl_broker"
                style={styles.section_container2}
                sl_broker={policy.sl_broker}
                policy_id={policy.id}
                is_locked={!permissions.canUpdateSLBroker({ policy })}
              />
            )}
            <SubjectivitiesSection
              id="subjectivities"
              style={styles.section_container}
              policy={policy}
            />
            <ExclusionsSection
              id="exclusions"
              style={styles.section_container}
              exclusions={policy.terms_conditions.exclusions}
              rating={rating}
            />
            <DecsPolFormsPreview
              id="decs_policy"
              style={styles.section_container2}
              policy_forms={policy.terms_conditions.policy_forms}
            />
            <EndorsementsSection
              id="endorsements"
              style={styles.section_container}
              terms_conditions={policy.terms_conditions}
            />
            <ExtendedReporting
              id="erp"
              style={styles.section_container}
              policy={policy}
            />
            <DynamicEndorsementAlert
              endorsements={policy.terms_conditions.endorsements}
              render={endorsements => {
                return (
                  <Alert style={styles.alert} variant="success">
                    <strong>
                      Reminder: Fill out Endorsements after Policy download
                    </strong>
                    <div style={styles.text}>
                      <p style={{ marginTop: '10px' }}>
                        The following endorsement(s) have fields that you will
                        need to fill out{' '}
                        <strong>after downloading the policy documents:</strong>
                      </p>
                      <ul>
                        {endorsements.map((endorsement, i) => {
                          return <li key={i}>{endorsement.title}</li>;
                        })}
                      </ul>
                    </div>
                  </Alert>
                );
              }}
            />
            {permissions.canIssuePolicy({ policy, submission }) && (
              <IssuePolicyButton style={styles.button} policy={policy} />
            )}
            {permissions.canDownloadDocuments({ policy }) && (
              <Link
                style={{ ...styles.button }}
                className="btn btn-primary"
                to={`/policies/${policy.id}/documents`}
              >
                Download Policy
              </Link>
            )}
          </section>
        );
      }}
      renderAside={() => {
        return (
          <section style={styles.sidebar}>
            <Flag
              name="policy.preview.submission_summary_card"
              render={() => {
                return <SubmissionSummaryCard flex submission={submission} />;
              }}
            />
          </section>
        );
      }}
    />
  );
}
