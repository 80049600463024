import { SUBMISSION_STATUSES, compareStatuses } from './statuses';
import {
  QUOTE_STATUSES,
  compareStatuses as compareQuoteStatuses,
} from '../quote/statuses';

export const canSendClearanceConfirmation = ({ submission }) => {
  return !submission.declined;
};

export const canChangeDeclinationStatus = ({ submission }) => {
  return submission.clearance.status !== 'FAILED';
};

export const canAddRatingsToQuotes = ({ submission }) => {
  const submission_is_bound =
    compareStatuses(submission.status, SUBMISSION_STATUSES.BOUND) >= 0;
  return !submission_is_bound;
};

export const canRemoveRatingsFromQuotes = ({ submission }) => {
  const submission_is_bound =
    compareStatuses(submission.status, SUBMISSION_STATUSES.BOUND) >= 0;
  return !submission_is_bound;
};

export const canCreateRatings = ({ quote, submission }) => {
  const quote_is_quoted =
    compareQuoteStatuses(quote.status, QUOTE_STATUSES.QUOTED) >= 0;
  const submission_is_bound =
    compareStatuses(submission.status, SUBMISSION_STATUSES.BOUND) >= 0;
  return !submission_is_bound && !quote_is_quoted;
};

export const canCreateQuote = ({ submission }) => {
  const submission_is_cleared = submission.clearance.status !== 'FAILED';
  const submission_is_bound =
    compareStatuses(submission.status, SUBMISSION_STATUSES.BOUND) >= 0;
  return !!submission_is_cleared && !submission_is_bound;
};

export const canStartBind = ({ submission }) => {
  const submission_is_bound =
    compareStatuses(submission.status, SUBMISSION_STATUSES.BOUND) >= 0;
  const submisssion_is_quoted =
    compareStatuses(submission.status, SUBMISSION_STATUSES.QUOTED) >= 0;
  return !submission_is_bound && submisssion_is_quoted;
};
