import React from 'react';

import { connect } from 'react-redux';

import { actions as QuoteActions } from '../../../../quote';
import { AsyncMutationWrapper } from '../../../../common';

const CreateBlankQuote = ({ createBlankQuote, render }) => {
  return (
    <AsyncMutationWrapper mutate={() => createBlankQuote()} render={render} />
  );
};

const mapDispatchToProps = (dispatch, props) => {
  const { submission } = props;
  return {
    createBlankQuote: () =>
      dispatch(QuoteActions.createFromSubmission(submission.id)),
  };
};

export default connect(
  null,
  mapDispatchToProps
)(CreateBlankQuote);
