import {
  CREATE_SUBMISSION_SUCCESS,
  CREATE_RENEWAL_SUBMISSION_SUCCESS,
  GET_SUBMISSION_BY_ID_SUCCESS,
  GET_SUBMISSION_BY_RATING_ID_SUCCESS,
  SEARCH_SUBMISSIONS_SUCCESS,
  MANUALLY_CLEAR_SUBMISSION_SUCCESS,
  GET_SUBMISSION_BY_QUOTE_ID_SUCCESS,
  GET_SUBMISSION_BY_BINDER_ID_SUCCESS,
  DELETE_BINDER_SUCCESS,
  GET_SUBMISSION_BY_POLICY_ID_SUCCESS,
  POST_DECLINE_SUBMISSION_SUCCESS,
  DELETE_DECLINED_SUBMISSION_SUCCESS,
} from './constants';
import { SUBMISSION_STATUSES, compareStatuses } from './statuses';

import { constants as binders } from '../binder';
import { constants as policies } from '../policies';
import { constants as quotes } from '../quote';

const initial_state = {
  by_id: {},
};

export default function submissions(state = initial_state, action) {
  switch (action.type) {
    case CREATE_SUBMISSION_SUCCESS:
    case CREATE_RENEWAL_SUBMISSION_SUCCESS:
    case DELETE_DECLINED_SUBMISSION_SUCCESS:
    case GET_SUBMISSION_BY_ID_SUCCESS:
    case GET_SUBMISSION_BY_RATING_ID_SUCCESS:
    case MANUALLY_CLEAR_SUBMISSION_SUCCESS:
    case GET_SUBMISSION_BY_QUOTE_ID_SUCCESS:
    case GET_SUBMISSION_BY_BINDER_ID_SUCCESS:
    case GET_SUBMISSION_BY_POLICY_ID_SUCCESS:
    case quotes.CREATE_QUOTE_FROM_SUBMISSION_SUCCESS:
    case quotes.CREATE_PREPOPULATED_QUOTE_FROM_SUBMISSION_SUCCESS:
    case quotes.CREATE_QUOTE_COPY_SUCCESS:
    case POST_DECLINE_SUBMISSION_SUCCESS:
      return handlers.storeSubmission(state, action);
    case SEARCH_SUBMISSIONS_SUCCESS:
      return handlers.searchSubmissionSuccess(state, action);
    case quotes.CREATE_QUOTE_SUCCESS:
      return handlers.createQuoteSuccess(state, action);
    case binders.CREATE_BINDER_SUCCESS:
      return handlers.createBinderSuccess(state, action);
    case binders.BIND_POLICY_SUCCESS:
      return handlers.bindPolicySuccess(state, action);
    case DELETE_BINDER_SUCCESS:
      return handlers.deleteBinderSuccess(state, action);
    case policies.ISSUE_POLICY_SUCCESS:
      return handlers.issuePolicySuccess(state, action);
    default:
      return state;
  }
}

const handlers = {
  storeSubmission(state, { payload }) {
    return {
      ...state,
      by_id: {
        ...state.by_id,
        [payload.submission.id]: payload.submission,
      },
    };
  },
  searchSubmissionSuccess(state, { payload }) {
    return {
      ...state,
      by_id: {
        ...state.by_id,
        ...payload.submissions.reduce(
          (submissions, current) => {
            submissions[current.id] = current;
            return submissions;
          },
          { ...state.by_id }
        ),
      },
    };
  },
  createQuoteSuccess(
    state,
    {
      payload: { quote },
    }
  ) {
    const submission_id = quote.submission_id;
    const old_submission = state.by_id[submission_id];
    const is_past_quoting_in_progress =
      compareStatuses(
        old_submission.status,
        SUBMISSION_STATUSES.QUOTING_IN_PROGRESS
      ) >= 0;
    if (is_past_quoting_in_progress) return state;
    return {
      ...state,
      by_id: {
        ...state.by_id,
        [submission_id]: {
          ...old_submission,
          status: SUBMISSION_STATUSES.QUOTING_IN_PROGRESS,
        },
      },
    };
  },
  createBinderSuccess(
    state,
    {
      payload: { binder },
    }
  ) {
    const submission_id = binder.submission_id;
    return {
      ...state,
      by_id: {
        ...state.by_id,
        [submission_id]: {
          ...state.by_id[submission_id],
          status: SUBMISSION_STATUSES.BINDING_IN_PROGRESS,
        },
      },
    };
  },
  bindPolicySuccess(
    state,
    {
      payload: { binder },
    }
  ) {
    const submission_id = binder.submission_id;
    return {
      ...state,
      by_id: {
        ...state.by_id,
        [submission_id]: {
          ...state.by_id[submission_id],
          status: SUBMISSION_STATUSES.BOUND,
        },
      },
    };
  },
  deleteBinderSuccess(
    state,
    {
      payload: { submission_id },
    }
  ) {
    return {
      ...state,
      by_id: {
        ...state.by_id,
        [submission_id]: {
          ...state.by_id[submission_id],
          status: SUBMISSION_STATUSES.QUOTED,
        },
      },
    };
  },
  issuePolicySuccess(
    state,
    {
      payload: { policy },
    }
  ) {
    const submission_id = policy.submission_id;
    return {
      ...state,
      by_id: {
        ...state.by_id,
        [submission_id]: {
          ...state.by_id[submission_id],
          status: SUBMISSION_STATUSES.ISSUED,
        },
      },
    };
  },
};
