const yesNoToBoolean = {
  display: {
    format: value => {
      if (value === true) return 'yes';
      if (value === false) return 'no';
    },
  },
  input: {
    parse: value => {
      if (value === 'yes') return true;
      if (value === 'no') return false;
      return null;
    },
    format: value => {
      if (value === true) return 'yes';
      if (value === false) return 'no';
    },
  },
};

export default yesNoToBoolean;
