import React from 'react';
import numeral from 'numeral';
import get from 'lodash/get';

import CoveragesSummary from './CoveragesSummary';

import ViewQuoteButton from '../ViewQuoteButton';
import MinimumPremiumWarning from './MinimumPremiumWarning';

const styles = {
  section_header: {
    fontSize: '1.25rem',
    marginBottom: '27px',
    marginTop:'20px'
  },
  total_premium: {
    fontSize: '1.875rem',
  },
  total_premium_missing: {
    color: '#9b9b9b',
  },
  premium_alerts: {
    marginTop: '10px',
    color:'#8d6708',
  },
  worksheet_summary_container: {
    borderTop: '1px solid #979797',
    marginTop: '35px',
  },
  coverages_container: {
    borderTop: '1px solid #dddddd',
    marginTop: '25px',
  },
  coverages_header: {
    fontWeight: 'normal',
  },
};

const creditedBelowMinimumPremium = rating => {
  const total_premium = get(rating, 'premium.total', 0);
  const minimum_premium = get(rating, 'premium.minimum_premium', 0);
  return total_premium < minimum_premium;
};

const hasPremiumAdjustments = rating => {
  const coverages = get(rating, 'risk_analysis.selected_coverages', []);
  return coverages
    .map(coverage => get(rating.premium, `coverage_specific.${coverage}`, {}))
    .some(cov_premium => !!cov_premium.adjustment);
};

export default function PremiumSummary({ style, rating, submission }) {
  const has_premium = !!rating.premium;
  const coverages = get(rating, 'risk_analysis.selected_coverages', []);
  return (
    <section>
      <div style={styles.worksheet_summary_container}>
        <h3 style={styles.section_header}>Premium</h3>
        <div className="clearfix">
          <div className="pull-right">
            <ViewQuoteButton
              style={{ marginRight: '50px' }}
              quote_id={rating.quote_id}
            />
          </div>
          <span
            style={{
              ...styles.total_premium,
              ...(!has_premium && styles.total_premium_missing),
            }}
          >
            ${has_premium ? numeral(rating.premium.total).format('0,0') : '--'}
          </span>
          {hasPremiumAdjustments(rating) ? (
            <p style={styles.premium_alerts}>
              Includes Price Adjustment
            </p>
          ) : null}
          {creditedBelowMinimumPremium(rating) ? (
            <MinimumPremiumWarning
              style={styles.premium_alerts}
              minimum_premium={rating.premium.minimum_premium}
            />
          ) : null}
        </div>
        <div style={styles.coverages_container}>
          <h4 style={styles.coverages_header}>Section premiums</h4>
          <CoveragesSummary coverages={coverages} rating={rating} />
        </div>
      </div>
    </section>
  );
}
