import React from 'react';
import Form from 'react-bootstrap/Form';

import ComponentWrapper from '../ComponentWrapper';

const styles = {
  enabled: {
    borderTop: '3px solid black',
    color: 'black',
    paddingTop: '1rem',
    marginBottom: '2rem'
  },
  disabled: {
    borderTop: '3px solid grey',
    color: 'gray',
    paddingTop: '1rem',
    marginBottom: '2rem'
  },
};

const Helper = ({ children }) => {
  return <Form.Text style={{ fontSize: '0.875rem' }} muted>{children}</Form.Text>;
};

const DisableableSection = props => {
  const { form, hidden, field } = props;
  const { title, children } = field;
  return (
    <section style={hidden ? styles.disabled : styles.enabled} id={field.id}>
      {title && <h2>{title}</h2>}
      {(() => {
        if (hidden) {
          return <p>{field.disabledText}</p>;
        }
        return (
          <React.Fragment>
            {children &&
              children.map((child, i) => (
                <ComponentWrapper key={i} form={form} field={child} />
              ))}
            {field.helper && <Helper>{field.helper}</Helper>}
          </React.Fragment>
        );
      })()}
    </section>
  );
};

export default DisableableSection;
