import React from 'react';
import Container from 'react-bootstrap/Container';

const styles = {
  container: {
    maxWidth: '480px',
  },
  header: {
    margin: '0.5em 0 0.5em',
  },
};

export default function Logout() {
  return (
    <Container style={styles.container} className="text-center">
      <h1 style={styles.header}>Come back soon!</h1>
      <p>You have successfully logged out.</p>
    </Container>
  );
}
