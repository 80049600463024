import React from 'react';
import Button from 'react-bootstrap/Button';
import isEmpty from 'lodash/isEmpty';
import { SummaryItem } from '../../../common';

const styles = {
  editButton: {
    paddingLeft: 0,
    paddingRight: 0,
    marginTop: '1.25rem',
  },
};

// placeholder is an 'em-dash':
// http://www.chicagomanualofstyle.org/qanda/data/faq/topics/HyphensEnDashesEmDashes/faq0002.html
const placeholder = '\u2014';

const formatAddress = address => {
  if (!address || isEmpty(address)) return null;
  return (
    <address>
      {address.street_line_1 || placeholder}
      {!address.street_line_2 ? null : (
        <React.Fragment>
          <br />
          {address.street_line_2}
        </React.Fragment>
      )}
      <br />
      {address.city || placeholder}, {address.state || placeholder}{' '}
      {address.zip || placeholder}
    </address>
  );
};

function SLBrokerInactive({ sl_broker, is_locked, ...props }) {
  return (
    <div>
      <SummaryItem label="Sl Broker Name" value={sl_broker.name} />
      <SummaryItem label="Address" value={formatAddress(sl_broker.address)} />
      <SummaryItem
        label="Surplus Lines License Number"
        value={sl_broker.license_number || null}
      />
      {sl_broker.nj_transaction_number?<SummaryItem
        label="NJ Transaction Number"
        value={sl_broker.nj_transaction_number || null}
      />: null}
      {!is_locked && (
        <Button
          onClick={props.makeActive}
          style={{ ...styles.editButton }}
          variant="link"
        >
          Edit
        </Button>
      )}
    </div>
  );
}

export default SLBrokerInactive;
