import React from 'react';

import { SummaryItem } from '../../common';

const styles = {
  section_header: {
    fontSize: '1.25rem',
    margin: '20px 0 10px 0',
    display: 'inline-block',
  },
  list: {
    listStyle: 'none',
    marginTop: '20px',
    paddingLeft: '0',
  },
  list_item: {
    marginTop: '15px',
  },
  edit: {
    marginTop: '14px',
  },
};

const CoverageLabelMap = {
  do: 'D&O',
  epl: 'EPL',
  fid: 'FID',
};

const renderDefaultHeader = ({ styles }) => (
  <h3 style={styles.section_header}>Pending & Prior Exclusions</h3>
);

export default function ExclusionsSummary({
  style,
  exclusions,
  coverages,
  renderHeader = renderDefaultHeader,
  ...props
}) {
  return (
    <section style={style} {...props}>
      {renderHeader({ styles })}
      <ul style={styles.list}>
        {coverages.map(coverage => {
          return (
            <li key={coverage} style={styles.list_item}>
              <SummaryItem
                label={CoverageLabelMap[coverage]}
                value={exclusions[coverage]}
              />
            </li>
          );
        })}
      </ul>
    </section>
  );
}
