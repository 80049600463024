import React from 'react';
import Container from 'react-bootstrap/Container';
import { useNavigate } from 'react-router-dom';

import { WithAuth } from '../../auth';

import HomeAuthenticated from './Authenticated';
import HomeUnauthenticated from './Unauthenticated';

const styles = {
  container: {
    maxWidth: '480px',
  },
};

function Home() {
  const navigate = useNavigate();
  return (
    <WithAuth
      render={({ auth, user, loading }) => {
        if (loading) {
          return (
            <Container style={styles.container} className="text-center">
              {loading && <p className="text-center-2">Loading...</p>}
            </Container>
          );
        }
        if (!user) {
          return (
            <Container style={styles.container} className="text-center">
              <HomeUnauthenticated auth={auth} navigate={navigate}/>
            </Container>
          );
        }
        return <HomeAuthenticated />;
      }}
    />
  );
}

export default Home;
