import { connect } from 'react-redux';
import { actions as EndorsementsActions } from '../../../../../../endorsements';
import EndorsementRow from './EndorsementRow';

const mapDispatchToProps = (dispatch, props) => {
  const endorsement_id = props.endorsement.id;
  return {
    getEndorsementDocuments() {
      return dispatch(EndorsementsActions.generateUrl(endorsement_id)).then(
        ({ document_url }) => {
          window.location.href = document_url;
        }
      );
    },
  };
};

export default connect(
  null,
  mapDispatchToProps
)(EndorsementRow);
