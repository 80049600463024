import React from 'react';
import { Link } from 'react-router-dom';

const styles = {
  container: {
    margin: 'auto',
    maxWidth: '400px',
    paddingTop: '25px',
    textAlign: 'center',
  },
  header: {
    color: '#9b9b9b',
    fontSize: '3.75rem',
    fontWeight: 'normal',
  },
  subheader: {
    marginTop: '25px',
    fontSize: '1.25rem',
  },
  button: {
    marginTop: '25px',
  },
  text: {
    marginTop: '25px',
  },
};

export default function GenericError({ resetError }) {
  return (
    <section style={styles.container}>
      <h1 style={styles.header}>Sorry!</h1>
      <p style={styles.subheader}>
        We can’t find the page you’re looking for. Please return home and try
        again.
      </p>
      <div>
        <Link
          style={styles.button}
          onClick={resetError}
          className="btn btn-link"
          to="/"
        >
          Return to Homepage
        </Link>
      </div>
      <p style={styles.text}>
        If the problem persists,{' '}
        <a href="mailto:support@protector.argo-digital.com?subject=Protector%3A%20Tech%20support%20needed">
          get in touch
        </a>{' '}
        with team Protector.
      </p>
    </section>
  );
}
