import qs from 'querystring';

const SearchSubmissions = (fetch, auth) => ({ term, limit, offset }) => {
  const query_string = qs.stringify({
    ...(term && { q: term }),
    ...(limit && { limit }),
    ...(offset && { offset }),
    ...(!term && { sort: ['updated_at:desc'] }),
  });
  return auth
    .getAccessToken()
    .then(token => {
      if (!token) {
        auth.signOut();
        return Promise.reject(new Error('User is not authenticated.'));
      }
      return token;
    })
    .then(token =>
      fetch(`/submissions?${query_string}`, {
        headers: {
          Authorization: token,
          'Content-Type': 'application/json',
        },
      })
    )
    .then(response => response.json());
};

export default SearchSubmissions;
