import React from 'react';
import { Query } from '@apollo/client/react/components';

import { PageTitle } from '../../../common';

import { Routes, Route, Navigate } from 'react-router-dom';

import { BINDER_STATUSES, compareStatuses } from '../../statuses';

import BinderWorksheet from '../BinderWorksheet';
import BinderPreview from '../BinderPreview';
import BinderDocuments from '../BinderDocuments';
import BinderDetails from '../BinderDetails';

import SubmissionsSummaryBar from '../../../common/components/SubmissionsSummaryBar';

import { query } from './query';
import { removeTypename } from '../../../graphql/utils';
import { combinePathPatterns } from '../../../utils';

const decomposeGraphQLResponse = response => {
  if (!response.binder) return {};
  const { submission, config, quote, ...binder } = removeTypename(
    response.binder
  );
  const { rating } = binder;
  return { submission, config, quote, rating, binder };
};

export default function ViewBinderContainer(props) {
  const { match } = props;
  return (
    <Query
      query={query}
      variables={{
        binderId: match.params.binder_id,
      }}
      fetchPolicy="cache-and-network"
    >
      {({ data, loading, error }) => {
        if (loading && !data?.binder) {
          return <div className="text-center-2">Loading...</div>;
        }
        if (error) {
          return (
            <div className="text-center">
              Sorry. Something went wrong. Please try refreshing.
            </div>
          );
        }
        if (!data.binder) return null;
        const {
          quote,
          submission,
          binder,
          rating,
          config,
        } = decomposeGraphQLResponse(data);
        const isBound =
          binder && compareStatuses(binder.status, BINDER_STATUSES.BOUND) >= 0;
        const insured_name = submission.named_insured.name;
        return (
          <div>
            {submission &&
              quote && (
                <div>
                  <SubmissionsSummaryBar
                    submission={submission}
                    display_name={quote.display_name}
                  />
                </div>
              )}
            <Routes>
              <Route
                path="terms-conditions"
                element={
                  <React.Fragment>
                    <PageTitle title={`${insured_name} - Bind Submission`} />
                    <BinderWorksheet
                      {...props}
                      config={config}
                      binder={binder}
                      submission={submission}
                      quote={quote}
                      rating={rating}
                      pathPattern={combinePathPatterns([match.pattern.path, 'terms-conditions'])}
                    />
                  </React.Fragment>
                }
              />
              <Route
                path="details"
                element={
                  isBound ? (
                    <React.Fragment>
                      <PageTitle title={`${insured_name} - Binder Preview`} />
                      <Navigate to="preview" replace={true} />
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <PageTitle title={`${insured_name} - Bind Details`} />
                      <BinderDetails
                        {...props}
                        binder={binder}
                        submission={submission}
                      />
                    </React.Fragment>
                  )
                }
              />
              <Route
                path="preview"
                element={
                  <React.Fragment>
                    <PageTitle title={`${insured_name} - Binder Preview`} />
                    <BinderPreview
                      {...props}
                      binder={binder}
                      submission={submission}
                      quote={quote}
                      rating={rating}
                      config={config}
                    />
                  </React.Fragment>
                }
              />
              <Route
                path="documents"
                element={
                  <React.Fragment>
                    <PageTitle title={`${insured_name} - Download Binder`} />
                    <BinderDocuments
                      {...props}
                      binder={binder}
                      submission={submission}
                      quote={quote}
                      rating={rating}
                    />
                  </React.Fragment>
                }
              />
            </Routes>
          </div>
        );
      }}
    </Query>
  );
}
