const DeleteBinder = (fetch, auth) => binder_id => {
  return auth
    .getAccessToken()
    .then(token => {
      if (!token) {
        auth.signOut();
        return Promise.reject(new Error('User is not authenticated.'));
      }
      return token;
    })
    .then(token =>
      fetch(`/binders/${binder_id}`, {
        method: 'DELETE',
        headers: {
          Authorization: token,
          'Content-Type': 'application/json',
        },
      })
    )
    .then(response => response.json())
    .then(response => {
      if (!response.success) {
        return Promise.reject('Invalid response from server');
      }
      return { binder_id };
    });
};

export default DeleteBinder;
