import { connect } from 'react-redux';
import { withRouter } from '../../../common/components/WithRouter';

import { updateQuote } from '../../actions';
import { mapFormValuesToTermsConditions } from './mapFormValuesToTermsConditions';

import QuoteWorksheet from './QuoteWorksheet';

import isValid from './isValid';

const mapStateToProps = (state, props) => ({
  initialValues: {
    ...props.quote.terms_conditions,
  },
});

const mapDispatchToProps = (dispatch, props) => dispatch => ({
  updateTermsConditions: (form_values, initialValues) => {
    const { quote_id } = props.match.params;
    const updatedQuote = {
      terms_conditions: mapFormValuesToTermsConditions(form_values),
    };
    const oldQuote = {
      terms_conditions: mapFormValuesToTermsConditions(initialValues),
    };
    return dispatch(updateQuote(quote_id, updatedQuote, oldQuote));
  },
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(QuoteWorksheet)
);

export { isValid };
