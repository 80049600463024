import React from 'react';

const styles = {
  container: {
    borderTopColor: 'black',
    borderTopStyle: 'solid',
    borderTopWidth: '3px',
    display: 'flex',
    padding: '20px',
    verticalAlign: 'middle',
  },
  main: {
    display: 'inline-block',
    flexGrow: '1',
  },
  buttonContaner: {
    alignSelf: 'center',
    display: 'inline-block',
    float: 'right',
  },
};

function AlertBanner({ renderHeader, renderBody, renderButton, style }) {
  return (
    <div style={{ ...styles.container, ...style.container }}>
      <div style={{ ...styles.main, ...style.main }}>
        {renderHeader()}
        {renderBody()}
      </div>
      <div style={{ ...styles.buttonContaner, ...style.buttonContaner }}>
        {renderButton()}
      </div>
    </div>
  );
}

AlertBanner.defaultProps = {
  renderHeader: () => null,
  renderBody: () => null,
  renderButton: () => null,
  style: {},
};

export default AlertBanner;
