import { connect } from 'react-redux';
import * as quotes from '../../../../../actions';
import DeletedPriceOptions from './DeletedPriceOptions';

const mapDispatchToProps = (dispatch, props) => {
  return {
    addRatingToQuote: rating_id =>
      dispatch(
        quotes.addRatingToQuote({ quote_id: props.quote.id, rating_id })
      ).then(() => props.removeJustDeleted(rating_id)),
  };
};

export default connect(
  null,
  mapDispatchToProps
)(DeletedPriceOptions);
