import { connect } from 'react-redux';
import { withRouter } from '../../../../common/components/WithRouter';

import { actions } from '../../../../binder';
import IncompleteBinderBanner from './IncompleteBinderBanner';

const { getBinderBySubmission } = actions;

const mapDispatchToProps = (dispatch, props) => dispatch => ({
  resumeBinder: () => {
    const { submission_id } = props.match.params;
    return dispatch(getBinderBySubmission(submission_id)).then(({ binder }) => {
      return props.navigate(`/binders/${binder.id}/terms-conditions`);
    });
  },
});

export default withRouter(
  connect(null, mapDispatchToProps)(IncompleteBinderBanner)
);
