import jmp from 'json-merge-patch';
import isEmpty from 'lodash/isEmpty';

import { selectors } from '../redux';

import {
  GET_QUOTE_BY_ID_REQUEST,
  GET_QUOTE_BY_ID_SUCCESS,
  SEARCH_QUOTES_BY_SUBMISSION_REQUEST,
  SEARCH_QUOTES_BY_SUBMISSION_SUCCESS,
  CREATE_QUOTE_REQUEST,
  CREATE_QUOTE_SUCCESS,
  GET_RATINGS_BY_QUOTE_REQUEST,
  GET_RATINGS_BY_QUOTE_SUCCESS,
  GENERATE_QUOTE_DOCUMENTS_REQUEST,
  GENERATE_QUOTE_DOCUMENTS_SUCCESS,
  GET_QUOTE_BY_BINDER_ID_REQUEST,
  GET_QUOTE_BY_BINDER_ID_SUCCESS,
  CREATE_QUOTE_FROM_SUBMISSION_REQUEST,
  CREATE_QUOTE_FROM_SUBMISSION_SUCCESS,
  CREATE_PREPOPULATED_QUOTE_FROM_SUBMISSION_REQUEST,
  CREATE_PREPOPULATED_QUOTE_FROM_SUBMISSION_SUCCESS,
  GET_QUOTES_BY_RATING_REQUEST,
  GET_QUOTES_BY_RATING_SUCCESS,
  CREATE_QUOTE_COPY_REQUEST,
  CREATE_QUOTE_COPY_SUCCESS,
  ADD_RATING_TO_QUOTE_REQUEST,
  ADD_RATING_TO_QUOTE_SUCCESS,
  REMOVE_RATING_FROM_QUOTE_REQUEST,
  REMOVE_RATING_FROM_QUOTE_SUCCESS,
  UPDATE_QUOTE_REQUEST,
  UPDATE_QUOTE_SUCCESS,
} from './constants';

export function get(quote_id, options = {}) {
  const { cache = true } = options;
  return (dispatch, getState, { api }) => {
    const state = getState();
    const quote = selectors.quoteById(state, quote_id);
    if (quote && cache) {
      return Promise.resolve({
        quote,
      });
    }
    dispatch({ type: GET_QUOTE_BY_ID_REQUEST });
    return api.quotes.get(quote_id).then(response => {
      dispatch({
        type: GET_QUOTE_BY_ID_SUCCESS,
        payload: {
          quote: response.quote,
        },
      });
      return response;
    });
  };
}

export function searchBySubmission(submission_id, options = {}) {
  const { term, limit, offset } = options;
  return (dispatch, getState, { api }) => {
    dispatch({
      type: SEARCH_QUOTES_BY_SUBMISSION_REQUEST,
    });
    return api.quotes
      .searchBySubmission(submission_id, { term, limit, offset })
      .then(response => {
        const { quotes } = response;
        dispatch({
          type: SEARCH_QUOTES_BY_SUBMISSION_SUCCESS,
          payload: {
            quotes,
          },
        });
        return response;
      });
  };
}

export function createQuote(rating_id) {
  return (dispatch, getState, { api }) => {
    dispatch({ type: CREATE_QUOTE_REQUEST });
    return api.quotes.create(rating_id).then(response => {
      dispatch({
        type: CREATE_QUOTE_SUCCESS,
        payload: {
          quote: response.quote,
        },
      });
      return response;
    });
  };
}

export function getRatings(quote_id) {
  return (dispatch, getState, { api }) => {
    dispatch({ type: GET_RATINGS_BY_QUOTE_REQUEST });
    const state = getState();
    const ratings = selectors.ratingsByQuote(state, quote_id);
    const quote = selectors.quoteById(state, quote_id);
    if (quote && quote.ratings.length === ratings.length) {
      return Promise.resolve({
        ratings,
      });
    }
    return api.quotes.getRatings(quote_id).then(response => {
      dispatch({
        type: GET_RATINGS_BY_QUOTE_SUCCESS,
        payload: {
          ratings: response.ratings,
        },
      });
      return response;
    });
  };
}

export function generateQuoteDocuments(quote_id, { ratings }) {
  return async (dispatch, getState, { api }) => {
    dispatch({
      type: GENERATE_QUOTE_DOCUMENTS_REQUEST,
    });
    const res = await api.quotes.generateQuoteDocuments(quote_id, { ratings });
    await dispatch({
      type: GENERATE_QUOTE_DOCUMENTS_SUCCESS,
      payload: {
        quote: res.quote,
        ratings,
      },
    });
    return res;
  };
}

export function getQuoteLetter(quote_id) {
  return (dispatch, getState, { api }) => {
    return api.quotes.getQuoteLetter(quote_id);
  };
}

export function getQuoteDocuments(quote_id) {
  return (dispatch, getState, { api }) => {
    return api.quotes.getQuoteDocuments(quote_id);
  };
}

export function getByBinder(binder_id) {
  return (dispatch, getState, { api }) => {
    const state = getState();
    const quote = selectors.quoteByBinder(state, binder_id);
    if (quote) {
      return Promise.resolve({
        quote,
      });
    }
    dispatch({
      type: GET_QUOTE_BY_BINDER_ID_REQUEST,
    });
    return api.quotes.getByBinder(binder_id).then(response => {
      const { quote } = response;
      dispatch({
        type: GET_QUOTE_BY_BINDER_ID_SUCCESS,
        payload: {
          quote,
        },
      });
      return response;
    });
  };
}

export function createFromSubmission(submission_id, product_code) {
  return (dispatch, getState, { api }) => {
    dispatch({ type: CREATE_QUOTE_FROM_SUBMISSION_REQUEST });
    return api.quotes
      .createFromSubmission(submission_id, product_code)
      .then(response => {
        dispatch({
          type: CREATE_QUOTE_FROM_SUBMISSION_SUCCESS,
          payload: {
            quote: response.quote,
            submission: response.submission,
          },
        });
        return response;
      });
  };
}

export function createPrepopulatedQuoteFromSubmission(submission_id) {
  return (dispatch, getState, { api }) => {
    dispatch({ type: CREATE_PREPOPULATED_QUOTE_FROM_SUBMISSION_REQUEST });
    return api.quotes
      .createPrepopulatedQuoteFromSubmission(submission_id)
      .then(response => {
        dispatch({
          type: CREATE_PREPOPULATED_QUOTE_FROM_SUBMISSION_SUCCESS,
          payload: {
            quote: response.quote,
            rating: response.rating,
            submission: response.submission,
          },
        });
        return response;
      });
  };
}

export function getByRating(rating_id) {
  return (dispatch, getState, { api }) => {
    dispatch({
      type: GET_QUOTES_BY_RATING_REQUEST,
    });
    return api.quotes.getByRating(rating_id).then(response => {
      const { quotes } = response;
      dispatch({
        type: GET_QUOTES_BY_RATING_SUCCESS,
        payload: {
          quotes,
        },
      });
      return response;
    });
  };
}

export function createQuoteCopy(quote_id, opts = {}) {
  const { withRatings = false } = opts;
  return async (dispatch, getState, { api }) => {
    dispatch({ type: CREATE_QUOTE_COPY_REQUEST });
    const response = await api.quotes.createCopy(quote_id, { withRatings });
    await dispatch({
      type: CREATE_QUOTE_COPY_SUCCESS,
      payload: {
        quote: response.quote,
        submission: response.submission,
        ratings: response.ratings,
      },
    });
    return response;
  };
}

export function addRatingToQuote({ quote_id, rating_id, before }) {
  return async (dispatch, getState, { api }) => {
    dispatch({ type: ADD_RATING_TO_QUOTE_REQUEST });
    const response = await api.quotes.addRating({
      quote_id,
      rating_id,
      before,
    });
    await dispatch({
      type: ADD_RATING_TO_QUOTE_SUCCESS,
      payload: {
        quote: response.quote,
      },
    });
    return response;
  };
}

export function removeRatingFromQuote({ quote_id, rating_id }) {
  return async (dispatch, getState, { api }) => {
    dispatch({ type: REMOVE_RATING_FROM_QUOTE_REQUEST });
    const response = await api.quotes.removeRating({ quote_id, rating_id });
    await dispatch({
      type: REMOVE_RATING_FROM_QUOTE_SUCCESS,
      payload: {
        rating_id: rating_id,
        quote: response.quote,
      },
    });
    return response;
  };
}

export function updateQuote(quote_id, updatedQuote, oldQuote) {
  return async (dispatch, getState, { api }) => {
    dispatch({ type: UPDATE_QUOTE_REQUEST });
    const patch = jmp.generate(oldQuote, updatedQuote);
    if (isEmpty(patch)) return { quote: oldQuote };
    const response = await api.quotes.updateQuote(quote_id, patch);
    await dispatch({
      type: UPDATE_QUOTE_SUCCESS,
      payload: {
        quote: response.quote,
        ratings: response.ratings,
      },
    });
    return response;
  };
}

export function getEligibleProducts(quote_id) {
  return async (dispatch, getState, { api }) => {
    const response = await api.quotes.getEligibleProducts(quote_id);
    return response;
  };
}
