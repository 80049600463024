import React from 'react';
import { Flag } from '../../../common/components/Flag';
import Sticky from 'react-stickynode';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { SubmissionSummaryCard } from '../../../submissions';

import DetailsSummary from './DetailsSummary';
import Broker from './Broker';
import * as validation from './validation';

const styles = {
  container: {
    paddingTop: '25px',
    paddingBottom: '100px',
  },
  header: {
    marginBottom: '37px',
  },
  sidebar: {
    margin: '25px auto auto',
    maxWidth: '240px',
  },
  submissions_summary: {
    marginTop: '25px',
  },
  form_section: {
    borderTop: '1px solid grey',
    margin: '0px',
  },
};

function QuoteDetails({
  quote,
  submission,
  navigate,
  updateBroker,
  updateDetails,
}) {
  return (
      <Container fluid="md">
        <Row>
          <Col 
            md={{span: 6}} 
            lg={{span: 6, offset: 1}} 
            style={styles.container}
          >
            <DetailsSummary
              detail={quote}
              updateDetails={updateDetails}
              validation={validation.details}
            />
            <section style={styles.form_section}>
              <h3 style={{marginTop: '20px'}}>Additional Broker Details</h3>
              <Broker
                style={styles}
                updateDetails={updateDetails}
                navigate={navigate}
                quote_id={quote.id}
                initialValues={{
                  broker: quote.broker,
                }}
                validation={validation.broker}
                enableReinitialize
                prevPagePath={`/quotes/${quote.id}/terms-conditions`}
              />
            </section>
          </Col>
          <Col md={5}>
            <Sticky>
              {props => (
                <div style={props.style}>
                  <div style={styles.sidebar}>
                    <Flag
                      name="quote.details.submission_summary_card"
                      render={() => {
                        return (
                          <SubmissionSummaryCard
                            style={styles.submissions_summary}
                            flex
                            submission={submission}
                          />
                        );
                      }}
                    />
                  </div>
                </div>
              )}
            </Sticky>
          </Col>
        </Row>
      </Container>
  );
}

export default QuoteDetails;
