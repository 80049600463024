import React from 'react';
import Table from 'react-bootstrap/Table';
import orderBy from 'lodash/orderBy';

import table_config from './table-config';
import EndorsementRow from './EndorsementRow';
import PolicyRow from './PolicyRow';
import BinderRow from './BinderRow';

const styles = {
  table: {
    // container: {
    //   marginBottom: '0px',
    // },
    header: {
      fontSize: '0.75rem',
      fontWeight: 'bold',
      lineHeight: '1.2em',
      color: '#9B9B9B',
    },
    cell: {
      fontSize: '0.875rem',
    },
  },
};

function PolicyDocuments({ binder, policy, endorsements }) {
  return (
    <Table responsive>
      <thead style={styles.table.header}>
        <tr>
          {table_config.columns.map(({ key, title }) => {
            return (
              <th key={key} style={styles.table.header}>
                {title}
              </th>
            );
          })}
        </tr>
      </thead>
      <tbody>
        {endorsements.length === 0
          ? null
          : orderBy([...endorsements], ['created_at'], ['desc']).map(
              (endorsement, id) => {
                return (
                  <tr key={id} style={styles.table.cell}>
                    <EndorsementRow endorsement={endorsement} />
                  </tr>
                );
              }
            )}
        {policy && (
          <tr style={styles.table.cell}>
            <PolicyRow policy={policy} />
          </tr>
        )}
        {binder && (
          <tr style={styles.table.cell}>
            <BinderRow binder={binder} />
          </tr>
        )}
      </tbody>
    </Table>
  );
}
export default PolicyDocuments;
