import { ApiError } from '../../api/errors';

const GetEndorsementOptionByIdVersion = (fetch, auth) => ({ id, version }) => {
  return auth
    .getAccessToken()
    .then(token => {
      if (!token) {
        auth.signOut();
        return Promise.reject(new Error('User is not authenticated.'));
      }
      return token;
    })
    .then(token =>
      fetch(`/endorsements/options/${id}/${version}`, {
        method: 'GET',
        headers: {
          Authorization: token,
          'Content-Type': 'application/json',
        },
      })
    )
    .then(response => {
      switch (response.status) {
        case 200:
          return response.json();
        default:
          throw new ApiError();
      }
    });
};

export default GetEndorsementOptionByIdVersion;
