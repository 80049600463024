import { Condition } from './_base';
export class NumberGteCondition extends Condition {
  eval(value, values, context) {
    const $ = { value, values, context };
    const left = this.value(this.config.left).eval($);
    const right = this.value(this.config.right).eval($);
    return (
      typeof left !== 'number' || typeof right !== 'number' || left >= right
    );
  }
}
