const POLICY_STATUSES = {
  ISSUE_IN_PROGRESS: 'ISSUE_IN_PROGRESS',
  ISSUED: 'ISSUED',
};

const status_to_number = new Map();
status_to_number.set(POLICY_STATUSES.ISSUE_IN_PROGRESS, 0);
status_to_number.set(POLICY_STATUSES.ISSUED, 1);

const numericStatus = status => {
  if (status_to_number.has(status)) return status_to_number.get(status);
  throw new Error(`${status} is not a valid status in POLICY_STATUSES`);
};

const compareStatuses = (status1, status2) => {
  const num1 = numericStatus(status1);
  const num2 = numericStatus(status2);
  if (num1 > num2) return 1;
  if (num1 < num2) return -1;
  return 0;
};

module.exports = {
  POLICY_STATUSES,
  compareStatuses,
};
