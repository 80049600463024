import { constants } from '../auth';

const AnalyticsMiddleware = store => next => action => {
  if (action.type === constants.INITIATE_AUTH_SUCCESS) {
    const { user } = action.payload;
    if (user) {
      window.FS.identify(user.id, {
        email: user.email,
        displayName: user.full_name,
      });
    }
  }
  return next(action);
};

export default AnalyticsMiddleware;
