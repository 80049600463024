import React from 'react';

const SubjectivityWrapper = ({ children, style }) => {
  return (
    <li
      style={{
        display: 'table-row',
        fontSize: '0.875rem',
        borderTop: '1px solid rgb(179, 179, 179)',
        ...style,
      }}
    >
      {children}
    </li>
  );
};

export default SubjectivityWrapper;
