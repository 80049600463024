import React from 'react';
import { SLBroker } from '../../../../binder';
import { errors as validation, is_required } from './validation';

const styles = {
  section_header: {
    fontSize: '1.25rem',
    marginBottom: '20px',
  },
};

export default function SLBrokerSection({
  sl_broker,
  style,
  updateSLBroker,
  is_locked,
  ...props
}) {
  return (
    <section style={style} {...props}>
      <h3 style={styles.section_header}>Surplus Lines Broker data</h3>
      <SLBroker
        sl_broker={sl_broker}
        is_locked={is_locked}
        validation={validation}
        is_required={is_required}
        updateSLBroker={updateSLBroker}
      />
    </section>
  );
}
