import jmp from 'json-merge-patch';
import isEmpty from 'lodash/isEmpty';

import { selectors } from '../redux';

import {
  GET_BINDER_BY_ID_REQUEST,
  GET_BINDER_BY_ID_SUCCESS,
  UPDATE_BINDER_TERMS_CONDITIONS_REQUEST,
  UPDATE_BINDER_TERMS_CONDITIONS_SUCCESS,
  CREATE_BINDER_REQUEST,
  CREATE_BINDER_SUCCESS,
  GET_BINDER_BY_SUBMISSION_REQUEST,
  GET_BINDER_BY_SUBMISSION_SUCCESS,
  BIND_POLICY_REQUEST,
  BIND_POLICY_SUCCESS,
  DELETE_BINDER_REQUEST,
  DELETE_BINDER_SUCCESS,
  UPDATE_BINDER_Z_SCORE_REQUEST,
  UPDATE_BINDER_Z_SCORE_SUCCESS,
  UPDATE_BINDER_DETAILS_REQUEST,
  UPDATE_BINDER_DETAILS_SUCCESS,
} from './constants';

export function get(binder_id, options = {}) {
  const { cache = true } = options;
  return (dispatch, getState, { api }) => {
    const state = getState();
    const binder = selectors.binderById(state, binder_id);
    if (cache && binder) {
      return Promise.resolve({
        binder,
      });
    }
    dispatch({ type: GET_BINDER_BY_ID_REQUEST });
    return api.binders.get(binder_id).then(response => {
      dispatch({
        type: GET_BINDER_BY_ID_SUCCESS,
        payload: {
          binder: response.binder,
        },
      });
      return response;
    });
  };
}

export function updateTermsConditions(
  binder_id,
  new_terms_conditions,
  old_terms_conditions
) {
  return async (dispatch, getState, { api }) => {
    dispatch({ type: UPDATE_BINDER_TERMS_CONDITIONS_REQUEST });
    const diff = jmp.generate(old_terms_conditions, new_terms_conditions);
    if (isEmpty(diff)) return { success: true };
    const response = await api.binders.updateTermsConditions(binder_id, diff);
    await dispatch({
      type: UPDATE_BINDER_TERMS_CONDITIONS_SUCCESS,
      payload: {
        binder_id,
        terms_conditions: new_terms_conditions,
      },
    });
    return response;
  };
}

export function createBinder({ quote_id, rating_id }) {
  return async (dispatch, getState, { api }) => {
    dispatch({ type: CREATE_BINDER_REQUEST });
    const response = await api.binders.create({ quote_id, rating_id });
    await dispatch({
      type: CREATE_BINDER_SUCCESS,
      payload: {
        binder: response.binder,
      },
    });
    return response;
  };
}

export function getBinderBySubmission(submission_id) {
  return (dispatch, getState, { api }) => {
    const state = getState();
    const binder = selectors.binderBySubmission(state, submission_id);
    if (binder) {
      return Promise.resolve({
        binder,
      });
    }
    dispatch({
      type: GET_BINDER_BY_SUBMISSION_REQUEST,
    });
    return api.binders.getBySubmission(submission_id).then(response => {
      dispatch({
        type: GET_BINDER_BY_SUBMISSION_SUCCESS,
        payload: {
          binder: response.binder,
        },
      });
      return response;
    });
  };
}

export function updateZScore(binder_id, financial) {
  return async (dispatch, getState, { api }) => {
    dispatch({ type: UPDATE_BINDER_Z_SCORE_REQUEST });
    const response = await api.binders.updateZScore(binder_id, financial);
    await dispatch({
      type: UPDATE_BINDER_Z_SCORE_SUCCESS,
      payload: {
        binder: response.binder,
      },
    });
    return response;
  };
}

export function bindPolicy(binder_id) {
  return async (dispatch, getState, { api }) => {
    dispatch({
      type: BIND_POLICY_REQUEST,
    });
    const response = await api.binders.bindPolicy(binder_id);
    await dispatch({
      type: BIND_POLICY_SUCCESS,
      payload: {
        binder: response.binder,
      },
    });
    return response;
  };
}

export function deleteBinder(binder, navigate) {
  return (dispatch, getState, { api }) => {
    const { id: binder_id, submission_id } = binder;
    dispatch({ type: DELETE_BINDER_REQUEST });
    return api.binders.deleteById(binder_id).then(response => {
      // navigate away before deleting binder in store
      // to prevent error
      navigate(`/quotes/${binder.quote_id}`);
      dispatch({
        type: DELETE_BINDER_SUCCESS,
        payload: {
          binder_id,
          submission_id,
        },
      });
      return response;
    });
  };
}

export function getBinderDocuments(binder_id) {
  return (dispatch, getState, { api }) => {
    return api.binders.getBinderDocuments(binder_id);
  };
}

export function updateDetails(binder_id, updatedBinder, oldBinder) {
  return async (dispatch, getState, { api }) => {
    dispatch({ type: UPDATE_BINDER_DETAILS_REQUEST });
    const patch = jmp.generate(oldBinder, updatedBinder);
    const response = await api.binders.updateDetails(binder_id, patch);
    if (!patch) return response;
    await dispatch({
      type: UPDATE_BINDER_DETAILS_SUCCESS,
      payload: {
        binder_id,
        ...updatedBinder,
      },
    });
    return response;
  };
}
