import React from 'react';
import { isRenewal } from '../../utils';

const styles = {
  newBusiness: {
    backgroundColor: 'rgba(155, 155, 155, 0.17',
    color: '#9b9b9b',
  },
  renewal: {
    backgroundColor: '#4a90e2',
    color: 'white',
  },
  status: {
    alignSelf: 'center',
    fontSize: '10px',
    fontWeight: 'bold',
    lineHeight: 1,
    marginTop: '5px',
    marginBottom: '5px',
    padding: '4px',
    textTransform: 'uppercase',
  },
};
export default function SubmissionTypeBadge({ className, data, style }) {
  const renewalType = isRenewal(data) ? 'renewal' : 'newBusiness';
  const labelText = isRenewal(data) ? 'Renewal' : 'New Business';
  const mergedStyles = {
    ...styles.status,
    ...styles[renewalType],
    ...style,
  };
  return (
    <span className={className} style={mergedStyles}>
      {labelText}
    </span>
  );
}
