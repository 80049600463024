import React from 'react';

import SectionContainer from './SectionContainer';
import FieldContainer from '../../components/FieldContainer';

const styles = {
  fields_container: {
    display: 'table',
    tableLayout: 'fixed',
    marginTop: '20px',
  },
};

const TableSection = props => {
  const { fields, description, additional_description } = props;
  return (
    <SectionContainer {...props}>
      {description && <p>{description}</p>}
      {additional_description && <strong>{additional_description}</strong>}
      <div style={styles.fields_container}>
        {fields.map(field => <FieldContainer key={field.name} field={field} />)}
      </div>
    </SectionContainer>
  );
};

export default TableSection;
