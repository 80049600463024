import get from 'lodash/get';

import uiSchema from './ui-schema.json';

import { validator } from '../../../validation';

import { formats, utils } from '../../../common/components/FormBuilderV2';

const IsField = characteristic => (field, value, values, context) => {
  const property = field[characteristic];
  if (!property) return false;
  const conditions = [].concat(property);
  const result = conditions.every(condition => {
    return validator.evaluateCondition(condition, value, values, context);
  });
  return result;
};

const isFieldHidden = IsField('hidden');

export default function getVisibleValuesBySection({ rating }) {
  const context = { today: new Date().toISOString() };
  const values = rating.risk_analysis;
  const sections = uiSchema.children
    .filter(section => !isFieldHidden(section, values, values, context))
    .map(section => {
      const fields = utils.getLeafFields(section);
      return {
        title: section.title,
        fields: fields
          .filter(field => {
            const args = [field, values, values, context];
            return !isFieldHidden(...args);
          })
          .map(field => {
            return {
              nickname: getFieldTitle(field),
              value: formatFieldValue(field, get(values, field.name)),
            };
          }),
      };
    });
  return sections;
}

const getFieldTitle = field => {
  if (field.name.split('.')[0] === 'financial') {
    return field.title;
  }
  return field.nickname;
};

/**
 * TODO
 * Figure out a better solution for populating copy for
 * TBD effective date and standard policy period
 */
const SpecialFormatters = {
  effective_date: value => {
    if (!value) return 'TBD';
  },
  standard_policy_period: value => {
    if (value === false) return 'Custom';
    return 'One Year';
  },
};

const formatFieldValue = (field, value) => {
  const specialFormat = SpecialFormatters[field.name];
  if (specialFormat && specialFormat(value)) return specialFormat(value);
  const format = get(formats, `${field.format}.display.format`, v => v);
  value = format(value);
  if (field.options) {
    if (Array.isArray(value)) {
      return value.map(v => getOptionLabel(v, field)).join(', ');
    } else {
      return getOptionLabel(value, field);
    }
  }
  return value;
};

const getOptionLabel = (value, field) => {
  if (typeof value === 'undefined') return;
  const selectedOption = field.options.find(option => option.value === value);
  if (selectedOption) return selectedOption.label;
};
