import React from 'react';
import Alert from 'react-bootstrap/Alert';

const styles = {
  alert: {
    fontSize: '0.8125rem',
    marginTop: '1rem',
  },
  header: {
    fontSize: '0.8125rem',
    marginTop: 0,
  },
  body: {
    color: '#000000',
  },
};

function ApiError({ style, error }) {
  return (
    <Alert style={{ ...styles.alert, ...style }} variant="danger">
      <h5 style={styles.header}>Error</h5>
      <section style={styles.body}>
        <p>Sorry. Something went wrong. Please try refreshing.</p>
        {error && (
          <ul>
            <li>{error.message}</li>
          </ul>
        )}
      </section>
    </Alert>
  );
}

export default ApiError;
