import React from 'react';

import {
  AsyncQueryWrapper,
  SearchContainer,
  PaginatedTable,
} from '../../../common';
import SearchError from './SearchError';

import table_config from './table-config';

export default function SearchSubmissions(props) {
  return (
    <SearchContainer
      render={({ term, page, limit }) => {
        return (
          <AsyncQueryWrapper
            load={variables => props.searchSubmissions(variables)}
            variables={{
              term: term.value,
              limit: limit.value,
              offset: limit.value * (page.value - 1),
            }}
            render={({ data, loading, error, hasSucceeded }) => {
              return (
                <div>
                  <PaginatedTable
                    row_id="id"
                    columns={table_config.columns}
                    placeholder="Search submissions"
                    list={data && data.submissions}
                    total={data && data.count}
                    term={term}
                    limit={limit}
                    page={page}
                    loading={loading}
                    is_searchable={true}
                    renderLoading={() => {
                      return <p>Loading submissions history...</p>;
                    }}
                    error={error}
                    renderError={() => {
                      return (
                        <SearchError
                          hasSucceeded={hasSucceeded}
                          error={error}
                        />
                      );
                    }}
                  />
                </div>
              );
            }}
          />
        );
      }}
    />
  );
}
