import React from 'react';
import moment from 'moment';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import { ProgressBar } from '../../../common';
import { utils as SubmissionUtils } from '../../../submissions';
import { isValid as isValidTermsConditions } from '../QuoteWorksheet';
import { isValid as isValidDetails } from '../QuoteDetails';
import { compareStatuses, QUOTE_STATUSES } from '../../statuses';

const styles = {
  header_container: {
    margin: '20px auto 10px',
  },
  header: {
    display: 'inline-block',
    margin: 0,
    verticalAlign: 'middle',
  },
  header_tag: {
    textSize: '0.5rem',
    fontWeight: 'bold',
    textTransform: 'uppercase',
    fontSize: '1em',
    marginLeft: '20px',
    verticalAlign: 'middle',
  },
  progress_indicator: {
    listStyle: 'none',
    margin: '20px auto 10px',
    paddingLeft: '0',
    verticalAlign: 'top',
  },
};

function QuoteProgressNav({ submission, ratings, quote, pathPattern }) {
  const quote_is_quoted =
    compareStatuses(quote.status, QUOTE_STATUSES.QUOTED) === 0;
  const is_renewal = SubmissionUtils.isRenewal(submission);
  const status_label = `Quote Letter Generated (${moment(
    quote.quoted_at
  ).format('M/D/YYYY')})`;

  const pages = [
    {
      title: 'Premium',
      path: '',
    },
    {
      title: 'Terms and Conditions',
      path: 'terms-conditions',
      valid: isValidTermsConditions({ ratings, quote }),
      disabled: compareStatuses(quote.status, QUOTE_STATUSES.QUOTED) === 0,
    },
    {
      title: 'Broker and Insured Details',
      path: 'details',
      valid: isValidDetails({ ratings, quote }),
      disabled: compareStatuses(quote.status, QUOTE_STATUSES.QUOTED) === 0,
    },
    {
      title: 'Confirm and Generate',
      path: 'preview',
    },
  ];

  return (
    <Container fluid="md">
      <Row>
        <Col 
          md={{span: 10}}
          lg={{span: 10, offset: 1}}
        >
          <div style={styles.header_container} className="clearfix">
            <h1 style={styles.header}>Complete Quote Letter</h1>
            {is_renewal && <span style={styles.header_tag}>Renewal</span>}
            {quote_is_quoted && (
              <span className="text-success" style={styles.header_tag}>
                {status_label}
              </span>
            )}
            <ul style={styles.progress_indicator}>
              <ProgressBar pages={pages} pathPattern={pathPattern}/>
            </ul>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default QuoteProgressNav;
