import validator from 'validator';

const errors = {
  name(value = '') {
    if (validator.isEmpty(value)) return 'Required';
  },
  address: {
    street_line_1(value = '') {
      if (validator.isEmpty(value)) return 'Required';
    },
    city(value = '') {
      if (validator.isEmpty(value)) return 'Required';
    },
    state(value = '') {
      if (validator.isEmpty(value)) return 'Required';
    },
    zip(value = '') {
      if (validator.isEmpty(value)) return 'Required';
      if (!validator.isPostalCode(value, 'US'))
        return 'Please enter a valid Zip Code';
    },
  },
  license_number: null,
};

const is_required = {
  name: true,
  address: {
    street_line_1: true,
    city: true,
    state: true,
    zip: true,
  },
  license_number: false,
};

export { errors, is_required };
