import { connect } from 'react-redux';

import { getQuoteDocuments } from '../../actions';

import QuoteDocuments from './QuoteDocuments';

const mapDispatchToProps = (dispatch, props) => dispatch => ({
  getQuoteDocuments() {
    return dispatch(getQuoteDocuments(props.quote.id));
  },
});

export default connect(null, mapDispatchToProps)(QuoteDocuments);
