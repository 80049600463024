import React from 'react';

const styles = {
  section_header: {
    fontSize: '1.25rem',
    margin: '20px 0 10px 0',
    display: 'inline-block',
  },
  list: {
    fontSize: '0.875rem',
    lineHeight: '1.5',
    listStyle: 'none',
    paddingLeft: '0',
  },
  list_item: {
    marginTop: '10px',
  },
  item: {
    color: 'rgba(155, 155, 155, 0.87)',
  },
  edit: {
    marginTop: '14px',
  },
};

const renderDefaultHeader = ({ styles }) => (
  <h3 style={styles.section_header}>Subjectivities</h3>
);

export default function SubjectivitiesSummary({
  style,
  subjectivities,
  renderHeader = renderDefaultHeader,
  ...props
}) {
  const hasSubjectivities = subjectivities.length > 0;
  return (
    <section style={style} {...props}>
      {renderHeader({ styles })}
      <ul style={styles.list}>
        {hasSubjectivities ? (
          subjectivities.map((subj, i) => {
            return (
              <li key={i} style={styles.list_item}>
                {subj.label}
              </li>
            );
          })
        ) : (
          <p style={styles.item}>No Subjectivities selected</p>
        )}
      </ul>
    </section>
  );
}
