import { connect } from 'react-redux';
import { withRouter } from '../../../common/components/WithRouter';
import * as policies from '../../actions';

import PolicyDetails from './PolicyDetails';

const mapDispatchToProps = (dispatch, props) => {
  const { policy_id } = props.match.params;
  return dispatch => ({
    updateDetails: (values, initialValues) =>
      dispatch(policies.updateDetails(policy_id, values, initialValues)),
  });
};

export default withRouter(
  connect(
    null,
    mapDispatchToProps
  )(PolicyDetails)
);
