import React from 'react';
import FormControl from 'react-bootstrap/FormControl';

import LabeledControl from './LabeledControl';

const InputControl = props => {
  const { input, meta, onValueChange, ...otherProps } = props;

  return (
    <LabeledControl {...props}>
      <FormControl
        id={otherProps.id || input.name}
        {...input}
        {...otherProps}
        onChange={
          onValueChange
            ? event => onValueChange(event, input.onChange)
            : input.onChange
        }
      />
    </LabeledControl>
  );
};

export default InputControl;
