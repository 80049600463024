import { connect } from 'react-redux';
import { getFormValues, hasSubmitSucceeded } from 'redux-form';

import SaveOnLeave from './SaveOnLeave';

const mapStateToProps = (state, props) => {
  const values = getFormValues(props.form)(state);
  const submitSucceeded = hasSubmitSucceeded(props.form)(state);
  return { values, submitSucceeded };
};

export default connect(mapStateToProps)(SaveOnLeave);
