import { connect } from 'react-redux';
import { withRouter } from '../../../common/components/WithRouter';

import * as policies from '../../actions';

import PolicyWorksheet from './PolicyWorksheet';

const mapStateToProps = (state, props) => ({
  initialValues: {
    ...props.policy.terms_conditions,
    endorsements: props.policy.terms_conditions.endorsements.map(
      endorsement => ({
        ...endorsement,
        value: { id: endorsement.value, version: endorsement.version },
      })
    ),
  },
});

const mapValuesToTermsAndConditions = values => {
  const endorsements = (values.endorsements || []).map(({ value, data }) => ({
    value: value.id,
    version: value.version,
    ...(data && { data }),
  }));
  return { ...values, endorsements };
};

const mapDispatchToProps = (dispatch, props) => {
  const policy_id = props.policy.id;
  return dispatch => ({
    updateTermsConditions: async (values, initialValues) => {
      return dispatch(
        policies.updateTermsConditions(
          policy_id,
          mapValuesToTermsAndConditions(values),
          mapValuesToTermsAndConditions(initialValues)
        )
      );
    },
  });
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(PolicyWorksheet)
);
