import React from 'react';
import { connect } from 'react-redux';
import Form from 'react-bootstrap/Form';

import { AsyncQueryWrapper } from '../../../../common';
import {
  actions as PolicyActions,
  PolicySummaryCard,
} from '../../../../policies';

const styles = {
  loading: {
    marginBottom: '10px',
  },
};

const AsyncPolicySummaryCard = ({ policy_id, getPolicyById }) => (
  <AsyncQueryWrapper
    load={({ policy_id }) => getPolicyById(policy_id)}
    render={({ loading, data }) => {
      if (loading)
        return (
          <div style={styles.loading}>Loading selected expiring policy...</div>
        );
      return (
        <PolicySummaryCard
          policy={data.policy}
          renderFooter={() => {
            return (
              <Form.Text style={{ margin: '0' }} muted>
                <span className="text-danger">*</span> The above details are
                from the <strong>policy at inception</strong>
              </Form.Text>
            );
          }}
        />
      );
    }}
    variables={{ policy_id }}
  />
);

export default connect(
  null,
  dispatch => ({
    getPolicyById(id) {
      return dispatch(PolicyActions.get(id));
    },
  })
)(AsyncPolicySummaryCard);
