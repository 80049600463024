import React from 'react';
import { path } from 'path';
import { Link } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { constants } from '../../..';
import { PaginatedTable } from '../../../../common';

const getPreviewLink = (policy_id, option_id, option_version) => {
  return path.join(
    '/policies',
    policy_id,
    'endorsements/mid-term/options',
    option_id,
    option_version,
    'preview'
  );
};

const columns = ({ match }) => [
  {
    key: '1',
    title: 'Endorsement',
    content: item => (
      <Link to={getPreviewLink(match.params.policy_id, item.id, item.version)}>
        {item.title}
      </Link>
    ),
  },
  {
    key: '2',
    title: 'Action',
    content: item => (
      <Link to={getPreviewLink(match.params.policy_id, item.id, item.version)}>
        View
      </Link>
    ),
  },
];

const FilterEndorsements = term => endt => {
  const normalizeText = text => {
    const pattern = /[A-z0-9\s]*/gi;
    const matches = text.match(pattern);
    return (matches ? matches.join('') : '').toLowerCase();
  };
  const matches = normalizeText(endt.title).includes(normalizeText(term));
  return matches;
};

export default class MidTermEndorsementsList extends React.Component {
  state = {
    term: '',
  };
  render() {
    const { configuration } = this.props;
    const endorsements = configuration.endorsements
      .filter(endorsement => {
        if(endorsement.valid_for == null) return false;
        return endorsement.valid_for.includes(constants.ENDORSEMENT_CONTEXT_MIDTERM);
      })
      .map(endorsement => ({
        id: endorsement.value,
        version: endorsement.version,
        title: endorsement.title,
      }));
    const filtered_list = endorsements.filter(
      FilterEndorsements(this.state.term)
    );
    return (
      <Container fluid="md">
        <Row>
          <Col lg={{span: 10, offset: 1}}>
            <PaginatedTable
              row_id="id"
              columns={columns(this.props)}
              list={filtered_list}
              limit={{ value: filtered_list.length }}
              is_searchable
              placeholder="Search endorsements"
              term={{
                value: this.state.term,
                onChange: term => this.setState({ term }),
              }}
            />
          </Col>
        </Row>
      </Container>
    );
  }
}
