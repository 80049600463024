import validator from 'validator';

const required = (value = '') => {
  if (validator.isEmpty(value)) return 'Required';
};

const decimal = (value = '') => {
  if (validator.isEmpty(value)) return 'Required';
  if (!validator.isDecimal(value)) return 'Please enter a valid number';
};

const zipCode = (value = '') => {
  if (validator.isEmpty(value)) return 'Required';
  if (!validator.isPostalCode(value, 'US')) {
    return 'Please enter a valid Zip Code';
  }
};

const phoneRegex = /^(\+?1[ -]?)?(\([0-9]{3}\)|[0-9]{3})[ -]?[0-9]{3}[- ]?[0-9]{4}( ?(x|ext|,) ?[0-9]+)?$/;
const phoneNumber = (value = '') => {
  if (validator.isEmpty(value)) return 'Required';
  if (!value.match(phoneRegex)) return 'Phone number appears invalid';
};

const email = (value = '') => {
  if (!validator.isEmail(value)) {
    if (validator.isEmpty(value)) return 'Required';
    return 'Please enter a valid e-mail address';
  }
};

const broker = {
  errors: {
    commission: decimal,
    address: {
      street_line_1: required,
      city: required,
      state: required,
      zip: zipCode,
    },
    phone: phoneNumber,
  },
  is_required: {
    address: {
      street_line_1: true,
      city: true,
      state: true,
      zip: true,
    },
    phone: true,
    commission: true,
  },
};

const sl_broker = {
  errors: {
    address: {
      street_line_1: required,
      city: required,
      state: required,
      zip: zipCode,
    },
    license_number: null,
    nj_transaction_number(value, allValues, props) {
      if (props.insured_state === 'NJ') {
        return required(value);
      }
    },
    name: required,
  },
  is_required: {
    address: {
      street_line_1: true,
      city: true,
      state: true,
      zip: true,
    },
    license_number: false,
    nj_transaction_number: insured_state => insured_state === 'NJ',
    name: true,
  },
};

const details = {
  errors: {
    broker: {
      name: required,
      brokerage: required,
      email: email,
    },
    named_insured: {
      name: required,
      address: {
        street_line_1: required,
        city: required,
        state: required,
        zip: zipCode,
      },
    },
  },
  is_required: {
    broker: {
      name: true,
      brokerage: true,
      email: true,
    },
    named_insured: {
      name: true,
      address: {
        street_line_1: true,
        city: true,
        state: true,
        zip: true,
      },
    },
  },
};

export { broker, details, sl_broker };
