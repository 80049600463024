import React from 'react';
import { Link } from 'react-router-dom';
import { reduxForm, Field, FormSection } from 'redux-form';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';
import InputGroup from 'react-bootstrap/InputGroup';
import FormControl from 'react-bootstrap/FormControl';
import { SaveOnLeave } from '../../../common';
import { AddressFields, LabeledControl, InputControl } from '../../../common';
import { submitPage } from './submit';

const Commission = props => {
  const { input, meta, ...otherProps } = props;
  return (
    <LabeledControl {...props}>
      <InputGroup>
        <FormControl
          id={otherProps.id || input.name}
          {...input}
          {...otherProps}
        />
        <InputGroup.Text>%</InputGroup.Text>
      </InputGroup>
    </LabeledControl>
  );
};

function Broker(props) {
  const {
    handleSubmit,
    validation: { errors, is_required },
    submitting,
    form,
    updateDetails,
    prevPagePath,
  } = props;
  return (
    <form
      noValidate
      onSubmit={event => {
        event.preventDefault();
        handleSubmit();
      }}
    >
      <SaveOnLeave
        form={form}
        save={values => updateDetails(values, props.initialValues)}
      />
      <FormSection name="broker">
        <Row>
          <Col md={4}>
            <Field
              name="commission"
              type="number"
              component={Commission}
              label="Broker commission"
              parse={value => {
                const number = parseFloat(value, 10);
                if (!isNaN(number)) return number;
                return null;
              }}
              required={is_required.commission}
              validate={errors.commission}
            />
          </Col>
        </Row>
        <h4>Broker contact details</h4>
        <Row>
          <Col md={8}>
            <Field
              name="phone"
              type="tel"
              component={InputControl}
              label="Phone"
              required={is_required.phone}
              validate={errors.phone}
              text="Example: 555-555-1234 x123"
            />
          </Col>
        </Row>
        <Row>
          <Col md={8}>
            <AddressFields
              validate={errors.address}
              is_required={is_required.address}
            />
          </Col>
        </Row>
        <Row>
          <Col md={8} className="mt-3">
            <Button variant="primary" type="submit" disabled={submitting}>
              {submitting ? 'Saving...' : 'Preview Quote'}
            </Button>
            {prevPagePath && (
              <Link to={prevPagePath} className="btn btn-link">
                Go Back
              </Link>
            )}
          </Col>
        </Row>
      </FormSection>
    </form>
  );
}

export default reduxForm({
  form: 'broker',
  onSubmit: submitPage,
  onSubmitSuccess: (_result, _dispatch, props) =>
    props.navigate(`/quotes/${props.quote_id}/preview`),
})(Broker);
