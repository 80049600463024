import React from 'react';
import PropTypes from 'prop-types';
import { reduxForm } from 'redux-form';

import { validator } from '../../../validation';

import ComponentWrapper from './ComponentWrapper';

class Form extends React.Component {
  static childContextTypes = {
    validation: PropTypes.object,
  };

  static defaultProps = {
    validator,
  };

  getChildContext() {
    return {
      validation: {
        validator: this.props.validator,
        schema: this.props.validationSchema,
        context: this.props.context,
      },
    };
  }

  render() {
    const { name, uiSchema } = this.props;
    return (
      <form name={name}>
        {uiSchema.children.map((field, i) => {
          return <ComponentWrapper key={i} form={name} field={field} />;
        })}
      </form>
    );
  }
}

const ReduxForm = reduxForm()(Form);

const ReduxFormWrapper = ({
  name,
  uiSchema,
  validationSchema,
  validator,
  context,
  ...props
}) => {
  return (
    <ReduxForm
      form={name}
      name={name}
      uiSchema={uiSchema}
      validationSchema={validationSchema}
      validator={validator}
      context={context}
      {...props}
    />
  );
};

export default ReduxFormWrapper;
