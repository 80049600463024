import { ApiError } from '../../api/errors';

const CreateQuoteFromSubmission = (fetch, auth) => async (
  submission_id,
  product_code
) => {
  const token = await auth.getAccessToken();
  if (!token) {
    auth.signOut();
    throw new Error('User is not authenticated.');
  }
  const response = await fetch(`/submissions/${submission_id}/quotes`, {
    method: 'POST',
    headers: {
      Authorization: token,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      product_code,
    }),
  });
  switch (response.status) {
    case 201:
      return response.json();
    case 400:
    case 403:
    case 404:
      return response.json().then(body => {
        throw new ApiError(body.message);
      });
    default:
      throw new ApiError();
  }
};

export default CreateQuoteFromSubmission;
