import React from 'react';
import Form from 'react-bootstrap/Form';

import ComponentWrapper from '../ComponentWrapper';

import { getValidationClassName } from './utils';

const FORM_GROUP = 'form-group';

const Helper = ({ children }) => {
  return <Form.Text style={{ fontSize: '0.875rem' }} muted>{children}</Form.Text>;
};

const styles = {
  header: {
    marginTop: '1.5rem'
  }
}

const FieldGroup = props => {
  const { form, field, error, warn, info } = props;
  const { title, children } = field;

 const validationClassName = `${FORM_GROUP} ${getValidationClassName(error, warn, true)}`;

  return (
    <section className={validationClassName}>
      {title && <h3 style={styles.header}>{title}</h3>}
      {children &&
        children.map((child, i) => (
          <ComponentWrapper key={i} form={form} field={child} />
        ))}
      {field.helper && <Helper>{field.helper}</Helper>}
      {error && <Helper>{error}</Helper>}
      {warn && <Helper>{warn}</Helper>}
      {info && <Helper>{info}</Helper>}
    </section>
  );
};

export default FieldGroup;
