import React from 'react';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import get from 'lodash/get';
import minBy from 'lodash/minBy';

import { ZScoreBase } from '../../../common';

const styles = {
  alert: {
    fontSize: '0.8125rem',
  },
  info: {
    marginTop: '1em',
  },
  text: {
    color: 'black',
  },
  button: {
    fontSize: '0.75rem',
    paddingLeft: '0',
  },
  icon: {
    marginRight: '0.5em',
  },
};

class AdditionalInfo extends React.Component {
  state = { open: false };
  open = () => this.setState({ open: true });
  close = () => this.setState({ open: false });
  render() {
    if (this.state.open) {
      return (
        <div style={styles.info}>
          <p style={styles.text}>
            Z-Score calculation requires the Current Fiscal Year’s total assets,
            total liabilities, retained earnings, operating income as well as
            total revenue, current assets, and current liabilities.
          </p>
          <p style={styles.text}>
            You can check for a passing or failing Z-Score during the bind
            process.
          </p>
          <Button style={styles.button} variant="link" onClick={this.close}>
            <i className="bi bi-dash-circle-fill"/>Show Less
          </Button>
        </div>
      );
    }
    return (
      <div style={styles.info}>
        <Button style={styles.button} variant="link" onClick={this.open}>
          <i className="bi bi-plus-circle-fill"/>Learn More
        </Button>
      </div>
    );
  }
}

export default function QuoteZScoreAlert({ ratings }) {
  // This selects the rating with the lowest z-score
  const rating = minBy(ratings, rating => get(rating, 'premium.z_score', 0));
  const NotValid = ({ z_score }) => {
    return (
      <Alert style={styles.alert} variant="warning">
        <p>
          <strong>Reminder:</strong>{' '}
          <span style={styles.text}>
            A passing Z-Score could not be calculated. You can check the Z-score
            at bind, if possible.
          </span>
        </p>
        <AdditionalInfo />
      </Alert>
    );
  };
  return (
    <ZScoreBase rating={rating} invalid={NotValid} indeterminate={NotValid} />
  );
}
