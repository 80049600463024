import React from 'react';
import { Query } from '@apollo/client/react/components';
import { Link } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import EndorsementUpload from './EndorsementUpload';
import EndorsementInstructions from './EndorsementInstructions';
import BookEndorsementButton from './BookEndorsementButton';
import EndorsementActionMenu from './EndorsementActionMenu';
import EndorsementNumberModal from './EndorsementNumberModal';

import { query } from '../query-midterm-endorsement';

export default class MidTermEndorsementsConfirmation extends React.Component {
  state = {
    file: null,
    show_endorsement_number: false,
  };

  selectFile = file => this.setState({ file });
  unselectFile = () => this.setState({ file: null });
  showEndorsementNumber = () => {
    this.setState({ show_endorsement_number: true });
  };
  hideEndorsementNumber = () => {
    this.setState({ show_endorsement_number: false });
  };

  render() {
    const { match, deleteEndorsement, bookEndorsement } = this.props;
    return (
      <Query
        query={query}
        variables={{
          id: match.params.endorsement_id,
        }}
      >
        {({ data, loading, error }) => {
          if (loading) return <div className="text-center">Loading...</div>;
          const { endorsement } = data;
          if (!endorsement) {
            return (
              <div>
                Invalid Endorsement.{' '}
                <Link to={'../preview'}>Return to endorsement preview.</Link>
              </div>
            );
          }
          return (
            <Container fluid="md">
              <Row>
                <Col style={{ marginBottom: '25px' }} md={{span: 10}} lg={{offset: 1}}>
                  <h3>{endorsement.title}</h3>
                  <strong className="text-danger">Incomplete</strong>
                </Col>
              </Row>
              <Row>
                <Col md={{span: 3}} lg={{offset: 1}}>
                  <EndorsementInstructions
                    template_url={endorsement.option.template_location.url}
                    showEndorsementNumber={this.showEndorsementNumber}
                  />
                  <BookEndorsementButton
                    bookEndorsement={bookEndorsement}
                    file={this.state.file}
                  />
                  <EndorsementActionMenu
                    style={{ marginTop: '30px', marginBottom: '30px' }}
                    template_url={endorsement.option.template_location.url}
                    deleteEndorsement={deleteEndorsement}
                    submission_id={endorsement.submission_id}
                    showEndorsementNumber={this.showEndorsementNumber}
                  />
                  <EndorsementNumberModal
                    show={this.state.show_endorsement_number}
                    hide={this.hideEndorsementNumber}
                    policy={endorsement.policy}
                    endorsements={endorsement.policy.endorsements.results}
                  />
                </Col>
                <Col md={7}>
                  <EndorsementUpload
                    file={this.state.file}
                    selectFile={this.selectFile}
                    unselectFile={this.unselectFile}
                  />
                </Col>
              </Row>
            </Container>
          );
        }}
      </Query>
    );
  }
}
