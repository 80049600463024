const flags = {
  rqbi: process.env.REACT_APP_WITH_RQBI === 'true',
  submission_summary_card: false,
  rating: {
    preview: {
      copy: false,
    },
  },
  quote: {
    worksheet: {
      submission_summary_card: flags => flags.submission_summary_card,
    },
    details: {
      submission_summary_card: flags => flags.submission_summary_card,
    },
    preview: {
      submission_summary_card: flags => flags.submission_summary_card,
    },
  },
  binder: {
    worksheet: {
      submission_summary_card: flags => flags.submission_summary_card,
    },
    details: {
      submission_summary_card: flags => flags.submission_summary_card,
    },
    preview: {
      submission_summary_card: flags => flags.submission_summary_card,
    },
  },
  policy: {
    worksheet: {
      submission_summary_card: flags => flags.submission_summary_card,
    },
    details: {
      submission_summary_card: flags => flags.submission_summary_card,
    },
    preview: {
      submission_summary_card: flags => flags.submission_summary_card,
    },
  },
};

export default flags;