import { withRouter } from '../../../common/components/WithRouter';
import { connect } from 'react-redux';

import * as quotes from '../../actions';

import CopyQuoteButton from './CopyQuoteButton';

const mapDispatchToProps = (dispatch, props) => {
  const quote = props.quote;
  return dispatch => ({
    copyQuote: () =>
      dispatch(quotes.createQuoteCopy(quote.id)).then(response => {
        props.navigate(`/quotes/${response.quote.id}/`, {
          state: {copy: true }
        });
        return response;
      }),
  });
};

export default withRouter(connect(null, mapDispatchToProps)(CopyQuoteButton));
