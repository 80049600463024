import { connect } from 'react-redux';
import { withRouter } from '../../../common/components/WithRouter';
import update from 'immutability-helper';

import { selectors } from '../../../redux';

import * as policies from '../../actions';
import { actions as common } from '../../../common';
import { utils as e_utils } from '../../../endorsements';
import { actions as submissions } from '../../../submissions';
import { actions as ratings } from '../../../rating';

import ViewPolicyContainer from './ViewPolicyContainer';

const mapStateToProps = (state, props) => {
  const { policy_id } = props.match.params;
  return state => {
    const policy = selectors.policyById(state, policy_id);
    const submission = selectors.submissionByPolicy(state, policy_id);
    const rating = selectors.ratingByPolicy(state, policy_id);
    const config = selectors.configuration(state);
    return {
      policy:
        policy &&
        config &&
        update(policy, {
          terms_conditions: {
            endorsements: {
              $set: e_utils.appendEndorsementLabels(
                policy.terms_conditions.endorsements,
                config.endorsements
              ),
            },
          },
        }),
      submission,
      rating,
      config,
    };
  };
};

const mapDispatchToProps = (dispatch, props) => {
  const { policy_id } = props.match.params;
  return dispatch => ({
    getPolicy: () => dispatch(policies.get(policy_id)),
    getSubmission: () => dispatch(submissions.getByPolicy(policy_id)),
    getRating: () => dispatch(ratings.getByPolicy(policy_id)),
    getConfig: () => dispatch(common.getConfiguration()),
  });
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(ViewPolicyContainer)
);
