import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from '../../../common/components/WithRouter';
import NavigationBar from './NavigationBar';
import { actions, WithAuth } from '../../../auth';

const mapDispatchToProps = dispatch => ({
  logout: path => dispatch(actions.logout(path)),
});

function NavigationBarWithAuth(props) {
  return (
    <WithAuth render={({ user, auth }) => <NavigationBar auth={auth} user={user} {...props} />} />
  );
}

export default compose(
  withRouter,
  connect(
    null,
    mapDispatchToProps
  )
)(NavigationBarWithAuth);
