import { connect } from 'react-redux';
import { updateDetails } from '../../../actions';
import SLBrokerSection from './SLBrokerSection';

const mapDispatchToProps = (dispatch, props) => {
  const { policy_id } = props;
  return dispatch => ({
    updateSLBroker: async (values, initialValues) =>
      dispatch(
        updateDetails(
          policy_id,
          { sl_broker: values },
          { sl_broker: initialValues }
        )
      ),
  });
};

export default connect(
  null,
  mapDispatchToProps
)(SLBrokerSection);
