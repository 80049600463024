import React from 'react';
import { AsyncMutationWrapper, TooltipButton } from '../../../../../common';

export default function BookEndorsementButton({ bookEndorsement, file }) {
  return (
    <AsyncMutationWrapper
      mutate={() => bookEndorsement(file)}
      render={({ mutate, loading, error }) => {
        return (
          <div>
            <TooltipButton
              style={{ marginTop: '20px' }}
              variant="primary"
              onClick={mutate}
              disabled={!file || loading}
              tooltiptext="Review your PDF before booking. This cannot be undone."
            >
              Book Endorsement
            </TooltipButton>
            {!!error && <p className="text-danger">{error.message}</p>}
          </div>
        );
      }}
    />
  );
}
