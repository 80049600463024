import React from 'react';
import { connect } from 'react-redux';
import { reduxForm, Field, getFormValues } from 'redux-form';
import get from 'lodash/get';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';
import submit from './submit';

import { AddressFields, InputControl } from '../../../common';

function SLBrokerActive(props) {
  const { makeInactive, handleSubmit, validation, is_required, named_insured } = props;
  const insuredState = get(named_insured, 'address.state');

  return (
    <form
      onSubmit={event => {
        event.preventDefault();
        handleSubmit();
      }}
      noValidate
    >
      <Row>
        <Col sm={8}>
          <Field
            name="name"
            type="text"
            component={InputControl}
            label="SL Broker Name"
            required={is_required.name}
            validate={validation.name}
          />
        </Col>
      </Row>
      <Row>
        <Col sm={8}>
          <AddressFields
            validate={validation.address}
            is_required={is_required.address}
          />
        </Col>
      </Row>

      <Row>
        <Col sm={8}>
          <Field
            name="license_number"
            type="text"
            component={InputControl}
            label="Surplus Lines License Number"
            required={is_required.license_number}
            validate={validation.license_number}
          />
        </Col>
      </Row>
      {validation.nj_transaction_number?<Row>
        <Col sm={8}>
          <Field
            name="nj_transaction_number"
            type="text"
            component={InputControl}
            label="NJ Transaction Number"
            required={is_required.nj_transaction_number(insuredState)}
            validate={validation.nj_transaction_number}
          />
        </Col>
      </Row>: null}
      <Row>
        <Row />
        <Col sm={8}>
          <Button variant="primary" type="submit" disabled={props.submitting}>
            {props.submitting ? 'Submitting...' : 'Submit'}
          </Button>
          <Button onClick={makeInactive} variant="link">
            Cancel
          </Button>
        </Col>
      </Row>
    </form>
  );
}

SLBrokerActive.defaultProps = {
  validation: {},
  is_required: {},
};

export default connect(
  state => ({ formValues: getFormValues('sl_broker')(state) }),
)(
  reduxForm({
    form: 'sl_broker',
    onSubmit: submit,
  })(SLBrokerActive)
);
