import React from 'react';
import { Link } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const formatSubmissionNumber = submission_number =>
  submission_number.match(/\d{4}/g).join('-');

const styles = {
  summary_bar: {
    padding: '15px 0',
    borderBottom: '1px solid black',
    backgroundColor: 'white',
  },
  summary_content: {
    display: 'inline-block',
    marginLeft: '1em',
  },
};

const renderDefaultDisplayName = ({ style, display_name }) => {
  return <strong style={style}>{display_name}</strong>;
};

const SummaryBar = ({
  display_name,
  submission,
  renderDisplayName = renderDefaultDisplayName,
}) => (
  <div style={styles.summary_bar}>
    <Container fluid="md">
      <Row>
        <Col 
          md={{span: 10}}
          lg={{span: 10, offset: 1}}
        >
          <Link to={`/accounts/${submission.account_id}/`}>
            Submission {formatSubmissionNumber(submission.submission_number)}
          </Link>
          <strong style={styles.summary_content}>
            {submission.named_insured.name}
          </strong>
          {renderDisplayName({ display_name, style: styles.summary_content })}
        </Col>
      </Row>
    </Container>
  </div>
);

export default SummaryBar;
