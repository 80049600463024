import React from 'react';
import { BrokerDetails } from '../../../../quote';

export default function BrokerSection({
  broker,
  style,
  updateBroker,
  is_locked,
  ...props
}) {
  return (
    <section style={style} {...props}>
      <BrokerDetails
        broker={broker}
        is_locked={is_locked}
        updateBroker={updateBroker}
      />
    </section>
  );
}
