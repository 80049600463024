import get from 'lodash/get';
import flatten from 'lodash/flatten';
import uniq from 'lodash/uniq';

export const aggregateCoverages = ratings => {
  const mapped = ratings.map(rating => {
    return get(rating, 'risk_analysis.selected_coverages', []);
  });
  const flattened = flatten(mapped);
  return uniq(flattened);
};

export const isEffectiveDateTBD = rating => {
  return !rating.risk_analysis.effective_date;
};
