import { isEmpty } from 'lodash';

const UpdatePolicyDetails = (fetch, auth) => (policy_id, patch) => {
  if (!patch || isEmpty(patch)) {
    return Promise.resolve({ success: true });
  }
  return auth
    .getAccessToken()
    .then(token => {
      if (!token) {
        auth.signOut();
        return Promise.reject(new Error('User is not authenticated.'));
      }
      return token;
    })
    .then(token =>
      fetch(`/policies/${policy_id}/details`, {
        method: 'PATCH',
        headers: {
          Authorization: token,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(patch),
      })
    )
    .then(response => response.json());
};

export default UpdatePolicyDetails;
