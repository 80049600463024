import React from 'react';
import { Link } from 'react-router-dom';

import { StickySidebar } from '../../../layouts';
import { SubmissionSummaryCard } from '../../../submissions';

import * as permissions from '../../permissions';

import DownloadBinderDocuments from './DownloadBinderDocuments';

import { AsyncQueryWrapper } from '../../../common';

const styles = {
  documents: {
    marginTop: '40px',
  },
  sidebar: {
    margin: 'auto',
    maxWidth: '240px',
  },
};

export default function BinderDocuments({
  submission,
  binder,
  getBinderDocuments,
  ...props
}) {
  return (
    <StickySidebar
      renderMain={() => {
        return (
          <AsyncQueryWrapper
            load={() => getBinderDocuments().catch(() => null)}
            render={({ loading, data, reload }) => {
              return permissions.canDownloadDocuments({ binder }) ? (
                <section>
                  <DownloadBinderDocuments
                    style={styles.documents}
                    binder={(data && data.binder) || binder}
                    getBinderDocuments={reload}
                  />
                </section>
              ) : (
                <section>
                  <p className="text-danger">
                    Whoops. We sent you here, but we shouldn't have. This binder
                    hasn't been completed.
                  </p>
                  <Link to={`/binders/${binder.id}/preview`}>
                    Binder Preview
                  </Link>
                </section>
              );
            }}
          />
        );
      }}
      renderAside={() => {
        return (
          <section style={styles.sidebar}>
            <SubmissionSummaryCard flex submission={submission} />
          </section>
        );
      }}
    />
  );
}
