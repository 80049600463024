import GetBinder from './get';
import GetBinderDocuments from './getBinderDocuments';
import BindPolicy from './bindPolicy';
import UpdateTermsConditions from './updateTermsConditions';
import CreateBinder from './create';
import GetBySubmission from './getBySubmission';
import DeleteById from './delete';
import UpdateZScore from './updateZScore';
import UpdateDetails from './updateDetails';

export default function BindersApi(fetch, auth) {
  return {
    get: GetBinder(fetch, auth),
    getBinderDocuments: GetBinderDocuments(fetch, auth),
    bindPolicy: BindPolicy(fetch, auth),
    updateTermsConditions: UpdateTermsConditions(fetch, auth),
    create: CreateBinder(fetch, auth),
    getBySubmission: GetBySubmission(fetch, auth),
    deleteById: DeleteById(fetch, auth),
    updateZScore: UpdateZScore(fetch, auth),
    updateDetails: UpdateDetails(fetch, auth),
  };
}
