import React from 'react';
import PropTypes from 'prop-types';
import { path } from 'path';
import { matchPath } from 'react-router-dom';
import { withRouter } from '../../../common/components/WithRouter';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import { combinePathPatterns } from '../../../utils'

import './ProgressBar.css';

function ProgressBar({ pages, location, match, renderItem }) {
  return pages.map(page => {
    const pathPattern = combinePathPatterns([match.pattern.path, page.path]);
    const to = path.join(match.pathnameBase, page.path);
    const matches = !!matchPath({
      caseSensitive: true,
      path: pathPattern,
      },
      location.pathname
    );
    return renderItem({ matches, page, to });
  });
}

ProgressBar.propTypes = {
  pages: PropTypes.array,
  renderItem: PropTypes.func,
};

ProgressBar.defaultProps = {
  renderItem: ({ matches, page, to }) => {
    const disabled = matches || page.disabled;
    const className = classnames('ProgressBar__item', {
      'ProgressBar__item--active': matches,
      'ProgressBar__item--disabled': page.disabled,
    });
    const Element = disabled ? 'span' : Link;
    return (
      <div className="ProgressBar__defaultcontainer" key={page.title}>
        <Element className={className} to={!disabled ? to : null}>
          {page.title}
        </Element>
        {page.valid === false && (
          <div className="ProgressBar__statuslabel">Incomplete</div>
        )}
      </div>
    );
  },
};

export default withRouter(ProgressBar);
