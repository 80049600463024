import React from 'react';

const styles = {
  subheader: {
    fontSize: '0.875rem',
    fontWeight: 'bold',
    textTransform: 'uppercase',
    marginTop: '1rem'
  },
  detail_text: {
    fontSize: '0.875rem',
    margin: '0.25em 0',
    wordWrap: 'break-word',
  },
};

export default function InsuredSummarySection({ named_insured }) {
  return (
    <React.Fragment>
      <h3 style={styles.subheader}>Named Insured</h3>
      <address style={styles.detail_text}>
        {named_insured.name}
        <br />
        {named_insured.address.street_line_1}
        <br />
        {named_insured.address.street_line_2 && (
          <React.Fragment>
            {named_insured.address.street_line_2}
            <br />
          </React.Fragment>
        )}
        {named_insured.address.city}, {named_insured.address.state}{' '}
        {named_insured.address.zip}
      </address>
    </React.Fragment>
  );
}
