const ENDORSEMENT_STATUSES = {
  UNDEFINED: undefined,
  BOOKING_IN_PROGRESS: 'BOOKING_IN_PROGRESS',
  BOOKED: 'BOOKED',
};

const status_to_number = new Map();
status_to_number.set(ENDORSEMENT_STATUSES.UNDEFINED, 0);
status_to_number.set(ENDORSEMENT_STATUSES.BOOKING_IN_PROGRESS, 1);
status_to_number.set(ENDORSEMENT_STATUSES.BOOKED, 2);

const numericStatus = status => {
  if (status_to_number.has(status)) return status_to_number.get(status);
  throw new Error(`${status} is not a valid status in ENDORSEMENT_STATUSES`);
};

const compareStatuses = (status1, status2) => {
  const num1 = numericStatus(status1);
  const num2 = numericStatus(status2);
  if (num1 > num2) return 1;
  if (num1 < num2) return -1;
  return 0;
};

module.exports = {
  ENDORSEMENT_STATUSES,
  compareStatuses,
};
