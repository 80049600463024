import { utils } from '../../../../rating';

const toInt = val => parseInt(val, 10);

const isPositive = value => toInt(value) > 0;

export const isPositiveInt = value =>
  isPositive(value) && Number.isInteger(Number(value));

const Exclusions = coverage => (exclusion, values, { rating }) => {
  const coverages = utils.aggregateCoverages([rating]);
  if (!coverages.includes(coverage)) return null;
  const coverage_has_content = !!exclusion;
  if (!coverage_has_content) {
    return 'Please provide Exclusions for each selected coverage.';
  }
};

export const exclusions = {
  do: Exclusions('do'),
  epl: Exclusions('epl'),
  fid: Exclusions('fid'),
};

export const endorsements = endorsements => {
  const at_least_one_endorsement = endorsements && !!endorsements.length;
  if (!at_least_one_endorsement) {
    return 'Please select at least one Endorsement.';
  }
};

const is100orMore = value => value >= 100;

export const erp = {
  years: years => {
    if (!isPositiveInt(years)) return 'Must be a positive integer';
  },
  percentage: percentage => {
    if (!isPositiveInt(percentage)) return 'Must be a positive integer';
    if (!is100orMore(percentage)) return 'Must be at least 100';
  },
};
