import React from 'react';
import { reduxForm, Field, SubmissionError } from 'redux-form';
import pick from 'lodash/pick';
import { connect } from 'react-redux';
import { change } from 'redux-form';

import { createAndClearRenewal } from '../../../actions';

import { Insured, Broker, SubmitButton, FormContainer } from '../common';

import RenewalPolicySelect from './RenewalPolicySelect';
import HydrateForm from './HydrateForm';
import { actions as PolicyActions } from '../../../../policies';

const Renewal = props => (
  <FormContainer handleSubmit={props.handleSubmit(submit)}>
    <div style={{ borderLeft: '3px solid #d8d8d8', paddingLeft: '5px' }}>
      <Field
        name="renewed_from_policy_id"
        component={RenewalPolicySelect}
        validate={value => {
          if (!value) return 'You must select a policy to renew';
          return null;
        }}
        props={props}
      />
      <HydrateForm
        getPolicyById={props.getPolicyById}
        onSelectedPolicyChange={props.onSelectedPolicyChange}
      />
    </div>
    <Broker />
    <Insured />
    <SubmitButton {...props} />
  </FormContainer>
);

async function submit(values, dispatch, props) {
  const fields = [
    'renewed_from_policy_id',
    'broker.brokerage',
    'broker.name',
    'broker.email',
    'named_insured.name',
    'named_insured.address.street_line_1',
    'named_insured.address.street_line_2',
    'named_insured.address.city',
    'named_insured.address.state',
    'named_insured.address.zip',
  ];
  try {
    const submission = pick(values, fields);
    const response = await dispatch(createAndClearRenewal(submission));
    props.navigate(`/submissions/${response.submission.id}`);
    return response;
  } catch (e) {
    throw new SubmissionError({ _error: e });
  }
}

export default reduxForm({
  form: 'create_submission',
  destroyOnUnmount: false,
})(
  connect(
    null,
    (dispatch, props) => ({
      getPolicyById() {
        return dispatch(
          PolicyActions.get(props.initialValues.renewed_from_policy_id)
        );
      },
      onSelectedPolicyChange(policy) {
        const broker = pick(policy.broker, 'brokerage', 'name', 'email');
        const named_insured = policy.named_insured;
        dispatch(change(props.form, 'broker', broker));
        dispatch(change(props.form, 'named_insured', named_insured));
      },
    })
  )(Renewal)
);
