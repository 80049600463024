import React, { Component } from 'react';

class DocumentsFetcher extends Component {
  componentDidMount() {
    this.interval = window.setInterval(() => {
      this.props.fetch();
    }, 10000);
  }

  componentWillUnmount() {
    window.clearInterval(this.interval);
  }

  render() {
    return null;
  }
}

export default function DownloadDocsMain({ fetch, done, children }) {
  return (
    <React.Fragment>
      {!done && <DocumentsFetcher fetch={fetch} />}
      {children}
    </React.Fragment>
  );
}
