import React from 'react';
import { Link } from 'react-router-dom';
import Button from 'react-bootstrap/Button';

import IssuePolicyModal from './IssuePolicyModal';

const styles = {
  button: {
    marginRight: '20px',
  },
};

export default class IssuePolicyButton extends React.Component {
  state = { show: false };

  show = () => this.setState({ show: true });
  hide = () => this.setState({ show: false });

  render() {
    const { style, policy, issuePolicy } = this.props;
    return (
      <div style={style}>
        <Button style={styles.button} variant="primary" onClick={this.show}>
          Issue Policy
        </Button>
        <Link
          to={`/policies/${policy.id}/terms-conditions`}
          style={styles.button}
          className="btn btn-link"
        >
          Go Back
        </Link>
        <IssuePolicyModal
          show={this.state.show}
          hide={this.hide}
          issuePolicy={issuePolicy}
        />
      </div>
    );
  }
}
