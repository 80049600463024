import React from 'react';
import './App.css';

import { PageTitle } from '../../../common';
import MainRoutes from './MainRoutes';
import { ScrollToTop } from '../../../common';

import GenericError from './GenericError';

export default class App extends React.Component {
  state = {
    error: null,
  };

  componentDidCatch(error, info) {
    this.setState({ error });
  }

  render() {
    const { error } = this.state;
    return (
      <ScrollToTop>
        <div>
          <PageTitle title="Protector" />
          {error ? (
            <GenericError
              resetError={() => {
                this.setState({ error: null });
              }}
            />
          ) : (
            <MainRoutes auth={this.props.auth} />
          )}
        </div>
      </ScrollToTop>
    );
  }
}
