import React from 'react';
import { Query } from '@apollo/client/react/components';
import { Routes, Route, Navigate } from 'react-router-dom';

import { PageTitle } from '../../../common';
import { POLICY_STATUSES, compareStatuses } from '../../statuses';
import SubmissionsSummaryBar from '../../../common/components/SubmissionsSummaryBar';
import { removeTypename } from '../../../graphql/utils';

import PolicyWorksheet from '../PolicyWorksheet';
import PolicyPreview from '../PolicyPreview';
import PolicyDocuments from '../PolicyDocuments';
import PolicyDetails from '../PolicyDetails';

import { query } from './query';
import { combinePathPatterns } from '../../../utils';

const decomposeGraphQLResponse = response => {
  if (!response.policy) return {};
  const { submission, config, quote, ...policy } = removeTypename(
    response.policy
  );
  const { rating } = policy;
  return { submission, config, quote, rating, policy };
};

export default function ViewPolicyContainer(props) {
  const { match } = props;
  return (
    <Query query={query} variables={{ policyId: match.params.policy_id }}>
      {({ loading, error, data, refetch }) => {
        if (loading) return <div className="text-center-2">Loading...</div>;
        if (error) {
          return (
            <div className="text-center">
              Sorry. Something went wrong. Please try refreshing.
            </div>
          );
        }
        const {
          policy,
          submission,
          quote,
          rating,
          config,
        } = decomposeGraphQLResponse(data);
        const isIssued =
          policy && compareStatuses(policy.status, POLICY_STATUSES.ISSUED) >= 0;
        const insured_name = submission.named_insured.name;
        return (
          <div>
            {submission &&
              policy && (
                <div>
                  <SubmissionsSummaryBar
                    submission={submission}
                    display_name={`Policy #${policy.policy_number}`}
                  />
                </div>
              )}
            <Routes>
              <Route
                path="preview"
                element={
                  <React.Fragment>
                    <PageTitle title={`${insured_name} - Policy Preview`} />
                    <PolicyPreview
                      {...props}
                      policy={policy}
                      submission={submission}
                      rating={rating}
                      config={config}
                    />
                  </React.Fragment>
                }
              />
              <Route
                path="terms-conditions"
                element={
                  <React.Fragment>
                    <PageTitle title={`${insured_name} - Issue Policy`} />
                    <PolicyWorksheet
                      {...props}
                      config={config}
                      policy={policy}
                      quote={quote}
                      submission={submission}
                      rating={rating}
                      pathPattern={combinePathPatterns([match.pattern.path, 'terms-conditions'])}
                    />
                  </React.Fragment>
                }
              />
              <Route
                path="details"
                element={
                  isIssued ? (
                    <React.Fragment>
                      <PageTitle title={`${insured_name} - Policy Preview`} />
                      <Navigate to="../preview" replace={true} />
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <PageTitle title={`${insured_name} - Policy Details`} />
                      <PolicyDetails
                        {...props}
                        policy={policy}
                        submission={submission}
                        pathPattern={combinePathPatterns([match.pattern.path, 'details'])}
                      />
                    </React.Fragment>
                  )
                }
              />
              <Route
                path="documents"
                element={
                  <React.Fragment>
                    <PageTitle title={`${insured_name} - Download Policy`} />
                    <PolicyDocuments
                      {...props}
                      policy={policy}
                      submission={submission}
                      rating={rating}
                      config={config}
                      refetch={refetch}
                    />
                  </React.Fragment>
                }
              />
            </Routes>
          </div>
        );
      }}
    </Query>
  );
}
