import React from 'react';
import Col from 'react-bootstrap/Col';
import { Field } from 'redux-form';

import {
  NewSubjectivitiesField,
} from '../../../subjectivities';

export default function Subjectivities({ styles, options }) {
  return (
    <Col sm={12} style={styles.wide_nested_column}>
      <h3 style={styles.form_section_header}>Subjectivities</h3>
      <Field
        name="subjectivities"
        component={NewSubjectivitiesField}
        options={options}
        label="Add Subjectivity"
        placeholder="Create or Select an option."
        emptystate="No Subjectivities. Create one or select them from the list below."
        creatable
      />
    </Col>
  );
}
