import React from 'react';
import { Link } from 'react-router-dom';
import Table from 'react-bootstrap/Table';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import orderBy from 'lodash/orderBy';

import { AsyncQueryWrapper } from '../../../common';

import EndorsementRow from './EndorsementRow';
import PolicyRow from './PolicyRow';
import BinderRow from './BinderRow';

import { permissions as permissions_p } from '../../../policies';

import table_config from './table-config';

const styles = {
  search_container: {
    marginBottom: '20px',
  },
  loading_indicator: {
    marginLeft: '10px',
    verticalAlign: 'middle',
  },
  table: {
    header: {
      fontSize: '0.75rem',
      fontWeight: 'bold',
      lineHeight: '1.2em',
      color: '#9B9B9B',
    },
    cell: {
      fontSize: '0.875rem',
    },
  },
};

export default function SearchBinder(props) {
  return (
    <AsyncQueryWrapper
      load={() =>
        Promise.all([
          props.getBinder(),
          props.getPolicy(),
          props.getEndorsements(),
        ])
      }
      render={({ data, loading, error }) => {
        if (loading) return null;
        const [{ binder }, { policy }, { endorsements }] = data;
        return (
          <div>
            {data &&
              binder && (
                <React.Fragment>
                  <Row style={styles.search_container}>
                    <Col xs={12}>
                      {loading && (
                        <span style={styles.loading_indicator}>Loading...</span>
                      )}
                    </Col>
                  </Row>
                  <Table responsive>
                    <thead style={styles.table.header}>
                      <tr>
                        {table_config.columns.map(({ key, title }) => {
                          return (
                            <th key={key} style={styles.table.header}>
                              {title}
                            </th>
                          );
                        })}
                      </tr>
                    </thead>
                    <tbody>
                      {endorsements.length === 0
                        ? null
                        : orderBy(
                            [...endorsements],
                            ['created_at'],
                            ['desc']
                          ).map((endorsement, id) => {
                            return (
                              <tr key={id} style={styles.table.cell}>
                                <EndorsementRow endorsement={endorsement} />
                              </tr>
                            );
                          })}
                      {policy && (
                        <tr style={styles.table.cell}>
                          <PolicyRow policy={policy} />
                        </tr>
                      )}
                      {binder && (
                        <tr style={styles.table.cell}>
                          <BinderRow binder={binder} />
                        </tr>
                      )}
                    </tbody>
                  </Table>
                  {policy &&
                    permissions_p.canAddEndorsement({ policy }) && (
                      <Link
                        className="btn btn-link"
                        to={`/policies/${policy.id}/endorsements/mid-term`}
                      >
                        Add Endorsement
                      </Link>
                    )}
                </React.Fragment>
              )}
          </div>
        );
      }}
    />
  );
}
