import React from 'react';
import { Link } from 'react-router-dom';

export default function AddTermsButton({ style, quote }) {
  return (
    <Link
      className="btn btn-primary"
      to={`/quotes/${quote.id}/terms-conditions`}
      style={{ ...style }}
    >
      Next: Add Terms
    </Link>
  );
}
