import { get } from 'lodash';
const concatPath = (parentPath, childPath) => {
  if (!parentPath) return childPath;
  return `${parentPath}.${childPath}`;
};
export class BaseSchema {
  constructor(config, required, errors, warn, info) {
    this.config = config;
    this._required = required || [];
    this.error = errors || [];
    this.warn = warn || [];
    this.info = info || [];
  }
  getSubSchema(path) {
    const parts = path.split('.');
    return parts.reduce((schema, part) => {
      return schema._getSubSchema(part);
    }, this);
  }
  required(value, values, context) {
    if (this._required.length === 0) return false;
    for (let condition of this._required) {
      if (!condition.eval(value, values, context)) return false;
    }
    return true;
  }
  validate(value, values, context) {
    const args = [value, values, context];
    const required = this.required(...args);
    if (!required && value == null) return {};
    if (required && value == null) {
      return {
        error: 'Required.',
      };
    }
    return {
      error: this._getValidationMessages(this.error, ...args),
      warn: this._getValidationMessages(this.warn, ...args),
      info: this._getValidationMessages(this.info, ...args),
    };
  }
  _getValidationMessages(validations, value, values, context) {
    return validations.reduce((message, error) => {
      return message || error.getMessage(value, values, context);
    }, null);
  }
}
export class ObjectSchema extends BaseSchema {
  constructor(properties, ...args) {
    super(...args);
    this.properties = properties;
  }
  _getSubSchema(property) {
    return this.properties[property];
  }
  validateDeep(parentPath, value, values, context) {
    let result = [];
    const shallowResult = this.validate(value, values, context);
    const types = ['error', 'warn', 'info'];
    types.forEach(type => {
      const message = shallowResult[type];
      if (message) {
        result.push({ type, path: parentPath, message });
      }
    });
    for (let property in this.properties) {
      const subSchema = this._getSubSchema(property);
      const newPath = concatPath(parentPath, property);
      const results = subSchema.validateDeep(
        newPath,
        get(value, property),
        values,
        context
      );
      result.push(...results);
    }
    return result;
  }
}
export class ArraySchema extends BaseSchema {
  constructor(items, ...args) {
    super(...args);
    this.items = items;
  }
  _getSubSchema() {
    return this.items;
  }
  validateDeep(parentPath, value, values, context) {
    let result = [];
    const shallowResult = this.validate(value, values, context);
    const types = ['error', 'warn', 'info'];
    types.forEach(type => {
      const message = shallowResult[type];
      if (message) {
        result.push({ type, path: parentPath, message });
      }
    });
    for (let property in value) {
      const subSchema = this._getSubSchema();
      const newPath = concatPath(parentPath, property);
      const results = subSchema.validateDeep(
        newPath,
        get(value, property),
        values,
        context
      );
      result.push(...results);
    }
    return result;
  }
}
class PrimitiveSchema extends BaseSchema {
  _getSubSchema(path) {
    return null;
  }
  validateDeep(parentPath, value, values, context) {
    let result = [];
    const shallowResult = this.validate(value, values, context);
    const types = ['error', 'warn', 'info'];
    types.forEach(type => {
      const message = shallowResult[type];
      if (message) {
        result.push({ type, path: parentPath, message });
      }
    });
    return result;
  }
}
export class NumberSchema extends PrimitiveSchema {
  validate(value, values, context) {
    if (value == null || typeof value === 'number') {
      return super.validate(value, values, context);
    }
    return {
      error: 'Must be of type `number`',
    };
  }
}
export class StringSchema extends PrimitiveSchema {
  validate(value, values, context) {
    if (value == null || typeof value === 'string') {
      return super.validate(value, values, context);
    }
    return {
      error: 'Must be of type `string`',
    };
  }
}
export class BooleanSchema extends PrimitiveSchema {
  validate(value, values, context) {
    if (value == null || typeof value === 'boolean') {
      return super.validate(value, values, context);
    }
    return {
      error: 'Must be of type `boolean`',
    };
  }
}
