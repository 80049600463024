import './styles/stylesheets/bootstrap-custom.css';

import './index.css';

import 'babel-polyfill';

import url from 'url';
import { path } from 'path';
import React, { createContext } from 'react';
import { createRoot } from 'react-dom/client';
import { Provider as ReduxProvider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  ApolloLink,
  HttpLink,
  from,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';

import Config from './config';
import flags from './flags';
import Api from './api';
import { ApiError } from './api/errors';
import { AuthService } from './auth';
import { App } from './main';
import configureStore from './redux/store';
import { inMemoryConfig } from './configurations/apollo/configurations';

import { actions as auth_actions } from './auth';

const config = Config();

const fetch = {
  v1: (target_path, options) => {
    const base_url = url.parse(config.api_url);
    const target_url = url.parse(target_path);
    const full_url = url.format({
      protocol: base_url.protocol,
      host: base_url.host,
      pathname: path.join(base_url.pathname, target_url.pathname),
      search: target_url.search,
    });
    return window.fetch(full_url, options).then(response => {
      if (response.status >= 500) {
        throw new ApiError();
      }
      if (response.status >= 400) {
        return Promise.reject(response);
      }
      return response;
    });
  },
  v2: (target_path, options) => {
    const base_url = url.parse(config.api_url);
    const target_url = url.parse(target_path);
    const full_url = url.format({
      protocol: base_url.protocol,
      host: base_url.host,
      pathname: path.join(base_url.pathname, target_url.pathname),
      search: target_url.search,
    });
    return window.fetch(full_url, options).catch(e => {
      throw new ApiError(e);
    });
  },
};

const auth = new AuthService({
  issuer: config.okta.issuer_url,
  client_id: config.okta.client_id,
  location: window.location,
  pkce: true,
});

const cache = new InMemoryCache(inMemoryConfig);

const authLink = setContext(async (request, previousContext) => {
  try {
    const token = await auth.getAccessToken();
    if (!token) {
      throw new Error('Access Denied');
    }
    return { token };
  } catch (e) {
    auth.signOut();
    throw new Error('User is not authenticated.');
  }
});

const setHeaderLink = new ApolloLink((operation, forward) => {
  operation.setContext({
    headers: { Authorization: operation.getContext().token },
  });
  return forward(operation);
});

const graphQlEndPointLink = new HttpLink({
  uri: config.graphql.uri,
});

const additiveLink = from([authLink, setHeaderLink, graphQlEndPointLink]);

const apolloClient = new ApolloClient({
  cache: cache,
  link: additiveLink,
});

const api = Api(fetch, auth);

const store = configureStore(
  {},
  {
    api,
    auth,
    apolloClient,
  }
);

store.dispatch(auth_actions.init());

auth.authStateManager.subscribe(authState => {
  if (authState == null) return;
  if (!authState.isAuthenticated) {
    store.dispatch(auth_actions.clearUser());
  }
});

export const FlagsProviderContext = createContext();

const container = document.getElementById('root');
const root = createRoot(container);

const render = App =>
  root.render(
    <FlagsProviderContext.Provider value={flags}>
      <ReduxProvider store={store}>
        <ApolloProvider client={apolloClient}>
          <Router>
            <App auth={auth} />
          </Router>
        </ApolloProvider>
      </ReduxProvider>
    </FlagsProviderContext.Provider>
  );

render(App);

if (module.hot) {
  module.hot.accept(
    [
      './api',
      './api/errors',
      './auth',
      './common',
      './config',
      './flags',
      './main',
      './redux/store',
    ],
    () => {
      const App = require('./main').App;
      return render(App);
    }
  );
}
