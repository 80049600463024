import React from 'react';
import Form from 'react-bootstrap/Form';

const MulticheckControl = ({ options, meta, ...props }) => {
  return (
    <div>
      {options.map(({ value, label }) => {
        const id = `${props.id}-${value}`;
        const values = props.value || [];
        const checked = values.includes(value);
        return (
          <Form.Check
            type='checkbox'
            key={value}
            {...props}
            style={{
              display: 'inline-block',
              marginRight: '0.5em',
              ...props.style,
            }}
            id={id}
            value={value}
            checked={checked}
            onChange={() => {
              const new_values = checked
                ? values.filter(selected => selected !== value)
                : values.concat(value);
              return props.onChange(new_values);
            }}
            onBlur={() => props.onBlur(values)}
            aria-label={label}
            label={label}
          />
        );
      })}
    </div>
  );
};

export default MulticheckControl;
