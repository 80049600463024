import React from 'react';
import Button from 'react-bootstrap/Button';
import SubmissionSummarySection from '../SubmissionSummarySection';
import DetailsModal from './DetailsModal';

const styles = {
  edit: {
    marginTop: '14px',
  },
  form_section: {
    borderTop: '1px solid grey',
    margin: '0 0 20px',
  },
};

export default class DetailSummary extends React.Component {
  state = {
    show: false,
  };
  show = () => this.setState({ show: true });
  hide = () => this.setState({ show: false });
  render() {
    const { detail, validation, updateDetails } = this.props;
    return (
      <section style={styles.form_section}>
        <h3 style={{marginTop: '20px'}}>Submission Details</h3>
        <div className="pull-right">
          <Button variant="link" style={styles.edit} onClick={this.show}>
            Edit
          </Button>
        </div>
        <DetailsModal
          noValidate
          show={this.state.show}
          hide={this.hide}
          initialValues={detail}
          updateDetails={updateDetails}
          validation={validation}
        />
        <SubmissionSummarySection
          named_insured={detail.named_insured}
          broker={detail.broker}
          style={{ marginBottom: '30px' }}
        />
      </section>
    );
  }
}
