import React from 'react';
import { Field as ReduxFormField } from 'redux-form';

import WithForm from './WithForm';
import ConditionalField from './ConditionalField';

const FieldContainer = ({ field }) => {
  const { container, ...props } = field;
  return (
    <WithForm
      render={({ containers }) => {
        const FieldComponent = containers[container];
        let element = null;
        if (field.name && !field.control) {
          element = (
            <ReduxFormField
              name={field.name}
              component={FieldComponent}
              props={props}
            />
          );
        } else {
          element = <FieldComponent {...props} />;
        }
        if (field.conditions) {
          return <ConditionalField field={field} render={() => element} />;
        }
        return element;
      }}
    />
  );
};

export default FieldContainer;
