import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Alert from 'react-bootstrap/Alert';

import { Toggleable } from '../../../common';

const styles = {
  alert: {
    marginBottom: 0,
    marginTop: '20px',
  },
  header: {
    marginTop: 0,
  },
  text: {
    color: 'black',
  },
};

export default function FromExpiringPolicyBanner() {
  return (
    <Toggleable
      initiallyVisible
      render={({ visible, hide }) =>
        visible && (
          <Container fluid="md">
            <Row>
              <Col md={{ span: 10 }} lg={{ offset: 1 }}>
                <div style={{ background: '#ffffff' }}>
                  <Alert
                    style={styles.alert}
                    variant="success"
                    onClose={() => hide()}
                    dismissible
                  >
                    <h3 style={styles.header}>Expiring Policy data copied</h3>
                    <p style={styles.text}>
                      Confirm that all answers are accurate. Complete effective
                      date, financials, and loss history questions.
                    </p>
                  </Alert>
                </div>
              </Col>
            </Row>
          </Container>
        )
      }
    />
  );
}
