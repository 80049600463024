import React from 'react';
import { Link } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import moment from 'moment';

import { AsyncMutationWrapper } from '../../../../common';

import {
  ENDORSEMENT_STATUSES,
  compareStatuses as compareEndorsementStatuses,
} from '../../../../endorsements';

const styles = {
  title: {
    display: 'inline-block',
    width: '15em',
    textOverflow: 'ellipsis',
    overflowX: 'hidden',
    whiteSpace: 'nowrap',
  },
  download: {
    border: 'none',
    padding: 0,
    textAlign: 'top',
  },
};

function isBooked(endorsement) {
  return compareEndorsementStatuses(
    endorsement.status,
    ENDORSEMENT_STATUSES.BOOKED
  ) < 0
    ? false
    : true;
}

function getEndorsementLink(endorsement) {
  const page = isBooked(endorsement) ? 'documents' : 'confirmation';
  return `/policies/${endorsement.policy_id}/endorsements/mid-term/${
    endorsement.id
  }/${page}`;
}

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  columns: [
    {
      key: '0',
      content: ({ endorsement }) => {
        const destination_url = getEndorsementLink(endorsement);
        return (
          <Link
            to={destination_url}
            style={styles.title}
            title={`Endorsement - ${endorsement.title}`}
          >
            Endorsement - {endorsement.title}
          </Link>
        );
      },
    },
    {
      key: '1',
      content: ({ endorsement }) => {
        const is_completed =
          compareEndorsementStatuses(
            endorsement.status,
            ENDORSEMENT_STATUSES.BOOKED
          ) >= 0;
        if (!is_completed) {
          return <strong style={{ color: '#f5a623' }}>In Progress</strong>;
        }
        const label = `Booked (${moment(endorsement.updated_at).format(
          'M/D'
        )})`;
        return <strong className="text-success">{label}</strong>;
      },
    },
    {
      key: '2',
      content: ({ endorsement }) => {
        return <span>{moment(endorsement.created_at).format('M/D/YYYY')}</span>;
      },
    },
    {
      key: '3',
      content: ({ endorsement }) => {
        const destination_url = getEndorsementLink(endorsement);
        return <Link to={destination_url}>View</Link>;
      },
    },
    {
      key: '4',
      content: ({ endorsement, getEndorsementDocuments }) => {
        if (!isBooked(endorsement)) return null;
        return (
          <AsyncMutationWrapper
            mutate={getEndorsementDocuments}
            render={({ loading, mutate }) => (
              <Button
                variant="link"
                style={styles.download}
                onClick={mutate}
                disabled={loading}
              >
                Download
              </Button>
            )}
          />
        );
      },
    },
  ],
};
