import React from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';

import {
  POLICY_STATUSES,
  compareStatuses as comparePolicyStatuses,
} from '../../../../policies';

const tableConfig = {
  columns: [
    {
      key: '0',
      content: policy => {
        return (
          <Link to={`/policies/${policy.id}/preview`}>
            Policy {policy.policy_number}
          </Link>
        );
      },
    },
    {
      key: '1',
      content: policy => {
        const is_issued =
          comparePolicyStatuses(policy.status, POLICY_STATUSES.ISSUED) === 0;

        if (!is_issued) {
          return <strong style={{ color: '#f5a623' }}>In Progress</strong>;
        }
        const label = `Issued (${moment(policy.issued_at).format('M/D')})`;
        return <strong className="text-success">{label}</strong>;
      },
    },
    {
      key: '2',
      content: policy => {
        return <span>{moment(policy.created_at).format('M/D/YYYY')}</span>;
      },
    },
    {
      key: '3',
      content: policy => {
        return <Link to={`/policies/${policy.id}/preview`}>View</Link>;
      },
    },
    {
      key: '4',
      content: policy => {
        return <Link to={`/policies/${policy.id}/documents`}>Download</Link>;
      },
    },
  ],
};

export default tableConfig;
