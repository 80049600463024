import React, {useState, useEffect } from 'react';

import LimitsSummaryView from '../LimitsSummary';

import { mapPropsToFormValues } from '../PricingWorksheet/mapPropsToFormValues';

const LimitsSummary = (props) => {
  const [values, setValues] = useState(null);

  useEffect(() => {
    const { rating } = props;
    setValues(mapPropsToFormValues({ rating }));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

    return (
      <LimitsSummaryView
        style={props.style}
        rating={props.rating}
        values={values}
      />
    );
  
}

export default LimitsSummary;
