import React from 'react';
import Form from 'react-bootstrap/Form';

import ComponentWrapper from '../ComponentWrapper';

const Helper = ({ children }) => {
  return <Form.Text style={{ fontSize: '0.875rem' }} muted>{children}</Form.Text>;
};

const TableSection = props => {
  const { form, field } = props;
  const { title, children } = field;
  return (
    <section style={
        {
          borderTop: '3px solid black',
          marginTop: '1.5rem',
          marginBottom: '1rem',
          paddingTop: '1rem'
        }
      } id={field.id}>
      {title && <h2>{title}</h2>}
      {field.helper && <Helper>{field.helper}</Helper>}
      <div style={{ display: 'table' }}>
        {children &&
          children.map((child, i) => (
            <ComponentWrapper key={i} form={form} field={child} />
          ))}
      </div>
    </section>
  );
};

export default TableSection;
