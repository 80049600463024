import React from 'react';

import * as PricingWorksheet from '../PricingWorksheet';
import CoverageDescription from '../CoverageDescription';
import PriceOptionHighlights from '../PriceOptionHighlights';

const defaultFooter = () => null;

const styles = {
  label: {
    fontSize: '0.875rem',
    textTransform: 'uppercase',
  },
  coverage_text: {
    fontSize: '0.75rem',
    paddingLeft: '0',
  },
  footer: { marginTop: '15px' },
};

export default function BasePriceOptionsSummaryCard({
  rating,
  renderHeader,
  renderFooter = defaultFooter,
}) {
  const limit_summary = PricingWorksheet.getLimitAggregations({ rating });
  return (
    <div>
      {renderHeader(rating)}
      <PriceOptionHighlights rating={rating} limit_summary={limit_summary} />
      <CoverageDescription
        limit_summary={limit_summary}
        style={styles.coverage_text}
      />
      {renderFooter({ style: { ...styles.footer } })}
    </div>
  );
}
