import {
  GET_POLICY_BY_ID_SUCCESS,
  GET_POLICY_BY_SUBMISSION_ID_SUCCESS,
  UPDATE_POLICY_TERMS_CONDITIONS_SUCCESS,
  ISSUE_POLICY_SUCCESS,
  SEARCH_POLICIES_SUCCESS,
  UPDATE_POLICY_DETAILS_SUCCESS,
} from './constants';

import { constants as submissions } from '../submissions';

const initial_state = {
  by_id: {},
};

export default function policies(state = initial_state, action) {
  switch (action.type) {
    case GET_POLICY_BY_ID_SUCCESS:
    case GET_POLICY_BY_SUBMISSION_ID_SUCCESS:
    case ISSUE_POLICY_SUCCESS:
    case submissions.CREATE_RENEWAL_SUBMISSION_SUCCESS:
      return handlers.storePolicy(state, action);
    case SEARCH_POLICIES_SUCCESS:
      return handlers.storePolicies(state, action);
    case UPDATE_POLICY_TERMS_CONDITIONS_SUCCESS:
      return handlers.updateTermsConditionsSuccess(state, action);
    case UPDATE_POLICY_DETAILS_SUCCESS:
      return handlers.updateDetailsSuccess(state, action);
    default:
      return state;
  }
}

const handlers = {
  storePolicy(state, { payload }) {
    return {
      ...state,
      by_id: {
        ...state.by_id,
        [payload.policy.id]: payload.policy,
      },
    };
  },
  storePolicies(state, { payload }) {
    return {
      ...state,
      by_id: {
        ...state.by_id,
        ...payload.policies.reduce((by_id, policy) => {
          by_id[policy.id] = policy;
          return by_id;
        }, {}),
      },
    };
  },
  updateTermsConditionsSuccess(state, { payload }) {
    const { policy_id, terms_conditions } = payload;
    return {
      ...state,
      by_id: {
        ...state.by_id,
        [policy_id]: {
          ...state.by_id[policy_id],
          terms_conditions,
        },
      },
    };
  },
  updateDetailsSuccess(
    state,
    {
      payload: { policy_id, broker, named_insured, sl_broker },
    }
  ) {
    const existingPolicy = state.by_id[policy_id] || {};
    return {
      ...state,
      by_id: {
        ...state.by_id,
        [policy_id]: {
          ...existingPolicy,
          broker: broker || existingPolicy.broker,
          sl_broker: sl_broker || existingPolicy.sl_broker,
          named_insured: named_insured || existingPolicy.named_insured,
        },
      },
    };
  },
};
