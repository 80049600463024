import React from 'react';
import { FormSection as ReduxFormSection } from 'redux-form';

import FieldContainer from '../../components/FieldContainer';

const FieldGroup = ({ name, title, description, fields }) => {
  return (
    <ReduxFormSection name={name} component="fieldset">
      <h3>{title}</h3>
      <p>{description}</p>
      {fields.map(field => <FieldContainer key={field.name} field={field} />)}
    </ReduxFormSection>
  );
};

export default FieldGroup;
