import React from 'react';
import { Query } from '@apollo/client/react/components';
import { Navigate, Route, Routes, matchPath } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import { SubmissionsSummaryBar } from '../../../common';
import { removeTypename } from '../../../graphql/utils';
import { combinePathPatterns } from '../../../utils';

import MidTermEndorsementsList from './MidTermEndorsementsList';
import MidTermEndorsementPreview from './MidTermEndorsementPreview';
import MidTermEndorsementConfirmation from './MidTermEndorsementConfirmation';
import MidTermEndorsementDocuments from './MidTermEndorsementDocuments';

import { query } from './query';

const pages = [
  {
    title: 'Choose Endorsement',
    path: 'list',
    component: MidTermEndorsementsList,
  },
  {
    title: 'Preview',
    path: 'options/:option_id/:option_version/preview',
    component: MidTermEndorsementPreview,
  },
  {
    title: 'Download and Fill',
    path: ':endorsement_id/confirmation',
    component: MidTermEndorsementConfirmation,
  },
  {
    title: 'Documents',
    path: ':endorsement_id/documents',
    component: MidTermEndorsementDocuments,
    hideHeader: true,
  },
];

const styles = {
  progress_indicator: {
    listStyle: 'none',
    paddingLeft: '0',
  },
  progress_indicator_item: {
    color: 'gray',
    display: 'inline-block',
    marginRight: '2em',
  },
  progress_indicator_item__active: {
    color: 'black',
    borderBottom: '3px solid #0d3e67',
  },
};

const ProgressIndicator = ({ style, match, title, location }) => {
  return (
    <ul style={{ ...styles.progress_indicator, ...style }}>
      {pages.filter(page => !page.hideHeader).map(page => {
        const pathPattern = combinePathPatterns([match.pattern.path, page.path]);
        const matches = !!matchPath({
          path: pathPattern,
        },
        location.pathname 
        );
        const style = { ...styles.progress_indicator_item };
        if (matches)
          Object.assign(style, styles.progress_indicator_item__active);
        return (
          <li key={page.title} style={style}>
            {page.title}
          </li>
        );
      })}
    </ul>
  );
};

function InProgressHeader({ policy_number, match, location }) {
  return (
    <Container fluid="md">
      <Row>
        <Col md={{span: 8}} lg={{offset: 1}} className="mt-4">
          <h2>Endorse Policy {policy_number}</h2>
          <ProgressIndicator
            style={{ margin: '20px 0 50px' }}
            match={match}
            location={location}
          />
        </Col>
      </Row>
    </Container>
  );
}

const decomposeGraphQLResponse = response => {
  if (!response.policy) return {};
  const { submission, config, ...policy } = removeTypename(response.policy);
  return { submission, config, policy };
};

const pageMatchPathData = (pathPattern, currentPath) => {
  let matchData = null;
  const pathPatternArr = pages.filter(page => !page.hideHeader).map(page => combinePathPatterns([pathPattern, page.path]));
  
  for(let i = 0; i < pathPatternArr.length; i++){
    matchData = matchPath(pathPatternArr[i], currentPath);
    if( matchData ) break;
  }
  return matchData;
}

export default function MidTermEndorsementsContainer(props) {
  const showInProgressHeader = !!pageMatchPathData(props.match.pattern.path, props.location.pathname);
  return (
    <Query query={query} variables={{ policyId: props.match.params.policy_id }}>
      {({ loading, error, data }) => {
        if (loading) return <div className="text-center-2">Loading...</div>;
        const { policy, submission, config } = decomposeGraphQLResponse(data);
        return (
          <div>
            <div>
              <SubmissionsSummaryBar
                submission={submission}
                display_name="Endorse Policy"
              />
            </div>
            {/* The following is used for conditional rendering of
              * InProgressHeader
              */}
            {showInProgressHeader && (
              <InProgressHeader
                policy_number={policy.policy_number}
                match={props.match}
                location={props.location}
              />
            )}
            <Routes>
              <Route
                path="/"
                element={<Navigate to="list" replace={true} />}
              />
              {pages.map(page => (
                <Route
                  key={page.title}
                  path={page.path}
                  element={
                    <page.component
                      {...props}
                      submission={submission}
                      configuration={config}
                      policy={policy}
                      pathPattern={combinePathPatterns([props.match.pattern.path, page.path])}
                    />
                  }
                />
              ))}
            </Routes>
          </div>
        );
      }}
    </Query>
  );
}
