import React from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import keyBy from 'lodash/keyBy';

import { AsyncMutationWrapper } from '../../../../common';

import ValidationModal from '../ValidationModal';

import { utils } from '../../../../common/components/FormBuilderV2';

const styles = {
  container: {
    display: 'inline-block',
  },
};

export default class SubmitButtons extends React.Component {
  state = {
    messages: [],
    show: false,
  };

  handleSubmit = () => {
    return this.props.onSubmit(this.props.values);
  };

  submit = () => {
    const fieldsByName = keyBy(utils.getAllFields(this.props.uiSchema), 'name');
    const validations = this.props.validator.validateDeep(
      this.props.validationSchema,
      this.props.values,
      this.props.context
    );
    const messages = validations.map(validation => {
      const field = fieldsByName[validation.path];
      return {
        label: field.nickname || field.title,
        text: validation.message,
      };
    });
    if (!messages.length) return this.handleSubmit();
    this.setState({
      show: true,
      messages,
    });
    return Promise.resolve();
  };

  hide() {
    this.setState({
      show: false,
    });
  }

  render() {
    const { style, children } = this.props;
    return (
      <div style={{ ...styles.container, ...style }}>
        <AsyncMutationWrapper
          mutate={this.submit}
          render={({ loading, mutate, error }) => (
            <React.Fragment>
              <Button
                variant="primary"
                type="button"
                disabled={loading}
                onClick={mutate}
              >
                {children}
              </Button>
              {error && (
                <Form.Text className="text-danger" muted>
                  Sorry, a problem occurred. Please try refreshing the page.
                </Form.Text>
              )}
            </React.Fragment>
          )}
        />
        <ValidationModal
          messages={this.state.messages}
          onSubmit={this.handleSubmit}
          show={this.state.show}
          onHide={() => {
            this.hide();
          }}
        />
      </div>
    );
  }
}
