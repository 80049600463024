import React from 'react';

import WithForm from './WithForm';

const Control = ({ control, ...props }) => {
  return (
    <WithForm
      render={({ controls }) => {
        const ControlComponent = controls[control] || (() => null);
        return <ControlComponent {...props} />;
      }}
    />
  );
};

export default Control;
