import React from 'react';
import { reduxForm, Field, FormSection } from 'redux-form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

import { AddressFields, InputControl } from '../../../common';
import { submitModal } from './submit';

const styles = {
  container: {
    padding: '20px',
  },
  header: {
    border: '0',
    padding: '0 0 20px 0',
  },
  body: {
    backgroundColor: '#f7f7f7',
    overflowY: 'scroll',
    padding: '20px 25px',
  },
  footer: {
    border: '0',
    padding: '20px 0 0 0',
    textAlign: 'left',
  },
  button: {
    marginTop: '25px',
  },
};

function ModalBody({ validation: { errors, warnings, is_required } }) {
  return (
    <React.Fragment>
      <FormSection name="named_insured">
        <h3>Insured Details</h3>
        <Row>
          <Col sm={10}>
            <Field
              name="name"
              type="text"
              component={InputControl}
              label="Insured Name"
              validate={errors.named_insured.name}
              required={is_required.named_insured.name}
            />
          </Col>
        </Row>
        <Row>
          <Col sm={10}>
            <AddressFields
              validate={errors.named_insured.address}
              is_required={is_required.named_insured.address}
            />
          </Col>
        </Row>
      </FormSection>
      <FormSection name="broker">
        <h3 style={{marginTop: '20px'}}>Broker Details</h3>
        <Row>
          <Col sm={10}>
            <Field
              name="brokerage"
              type="text"
              component={InputControl}
              label="Submitting brokerage"
              validate={errors.broker.brokerage}
              required={is_required.broker.brokerage}
            />
          </Col>
        </Row>
        <Row>
          <Col sm={10}>
            <Field
              name="name"
              type="text"
              component={InputControl}
              label="Broker name"
              validate={errors.broker.name}
              required={is_required.broker.name}
            />
          </Col>
        </Row>
        <Row>
          <Col sm={10}>
            <Field
              name="email"
              type="text"
              component={InputControl}
              label="Broker email"
              validate={errors.broker.email}
              required={is_required.broker.email}
            />
          </Col>
        </Row>
      </FormSection>
    </React.Fragment>
  );
}

function QuoteDetailsModal({
  style,
  show,
  hide,
  validation,
  handleSubmit,
  submitting,
}) {
  return (
    <Modal show={show} onHide={hide}>
      <div style={styles.container}>
        <form
          onSubmit={event => {
            event.preventDefault();
            handleSubmit();
          }}
        >
          <Modal.Header style={styles.header} closeButton />
          <Modal.Body style={styles.body}>
            <ModalBody validation={validation} />
          </Modal.Body>
          <Modal.Footer style={styles.footer}>
            <Button
              style={styles.button}
              variant="primary"
              type="submit"
              disabled={submitting}
            >
              {submitting ? 'Updating...' : 'Update Details'}
            </Button>
            <Button
              style={styles.button}
              variant="link"
              onClick={hide}
              disabled={submitting}
            >
              Cancel
            </Button>
          </Modal.Footer>
        </form>
      </div>
    </Modal>
  );
}

export default reduxForm({
  form: 'quote_details_modal',
  onSubmit: submitModal,
})(QuoteDetailsModal);
