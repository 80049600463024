import { Condition } from './_base';
export class DateExistsCondition extends Condition {
  eval(value, values, context) {
    const date = this.value(this.config.date).eval({
      value,
      values,
      context,
    });
    return !!date;
  }
}
