import React from 'react';
import isEmpty from 'lodash/isEmpty';

import { PersistErrors } from '../../../common';
import { StickySidebar } from '../../../layouts';

import { SubmissionSummaryCard } from '../../../submissions';

import ViewPriceButton from '../ViewPriceButton';
import LimitsSummary from './LimitsSummary';

import { Form } from '../../../common/components/FormBuilderV2';
import uiSchema from './ui-schema.json';

import './styles.css';

import { mapPropsToFormValues } from './mapPropsToFormValues';

const styles = {
  main_container: {
    backgroundColor: '#fff',
    padding: '25px 40px',
  },
  sidebar_container: {
    maxWidth: '240px',
    margin: '25px auto auto',
  },
  sidebar_summary: {
    backgroundColor: '#ffffff',
    borderColor: '#979797',
  },
};

const FORM_NAME = 'pricing_worksheet';

export default class PricingWorksheet extends React.Component {
  render() {
    const { rating, submission, quote, productConfig, pathPattern } = this.props;
    return (
      <PersistErrors
        formName={FORM_NAME}
        persistErrors={!isEmpty(rating.limits_retentions)}
      >
        <StickySidebar
          renderMain={() => {
            return (
              <section style={styles.main_container}>
                <Form
                  name={FORM_NAME}
                  uiSchema={uiSchema}
                  validationSchema={productConfig.limits_retentions_schema}
                  context={{
                    today: new Date().toISOString(),
                    rating,
                    quote,
                  }}
                  initialValues={mapPropsToFormValues({ rating })}
                />
                <LimitsSummary
                  formName={FORM_NAME}
                  className="limits-summary-container" // Appears only in smaller screen sizes
                  style={{ margin: '25px 0px', ...styles.sidebar_summary }}
                  rating={rating}
                />
                <ViewPriceButton
                  rating={rating}
                  formName={FORM_NAME}
                  style={{ marginTop: '15px' }}
                  pathPattern={pathPattern}
                />
              </section>
            );
          }}
          renderAside={() => {
            return (
              <div>
                <section style={styles.sidebar_container}>
                  <div
                    style={{
                      display: 'table',
                      margin: 'auto auto 20px auto',
                    }}
                  >
                    <ViewPriceButton rating={rating} formName={FORM_NAME} pathPattern={pathPattern}/>
                  </div>
                  <LimitsSummary
                    formName={FORM_NAME}
                    style={{ marginBottom: '25px', ...styles.sidebar_summary }}
                    rating={rating}
                  />
                  <SubmissionSummaryCard
                    flex
                    submission={submission}
                    style={styles.sidebar_summary}
                  />
                </section>
              </div>
            );
          }}
        />
      </PersistErrors>
    );
  }
}
