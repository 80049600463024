import get from 'lodash/get';
import moment from 'moment';

const DAYS_QUOTE_IS_VALID = 30;
const MS_IN_DAY = 24 * 60 * 60 * 1000;

function formatDate(date) {
  return moment(date)
    .utc()
    .format('MMMM D, YYYY');
}

function getQuoteExpirationDateForOneRating(rating) {
  const default_expiration_date = new Date();
  default_expiration_date.setTime(
    default_expiration_date.getTime() + DAYS_QUOTE_IS_VALID * MS_IN_DAY
  );
  const calculated_expiration_date = new Date(
    get(rating, 'risk_analysis.effective_date') || default_expiration_date
  ).getTime();

  return new Date(
    Math.min(calculated_expiration_date, default_expiration_date.getTime())
  );
}

function getQuoteExpirationDate(ratings) {
  const expiration_dates = ratings.map(getQuoteExpirationDateForOneRating);
  const expiration_date = formatDate(new Date(Math.min(...expiration_dates)));

  return expiration_date;
}

export { getQuoteExpirationDate };
