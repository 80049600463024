import React from 'react';
import Button from 'react-bootstrap/Button';
import moment from 'moment';
import sortBy from 'lodash/sortBy';
import last from 'lodash/last';

import { ModalDialog } from '../../../../common';

export default function EndorsementNumberModal({
  policy,
  endorsements,
  show,
  hide,
}) {
  return (
    <ModalDialog.Standard
      show={show}
      hide={hide}
      renderHeader={() => (
        <h2 style={{ fontSize: '0.875rem' }}>Filling the endorsement footer</h2>
      )}
      renderBody={() => (
        <div style={{ fontSize: '0.875rem' }}>
          <h3 style={{ fontSize: '1.125rem' }}>Endorsement No</h3>
          <p style={{ fontSize: '2.25rem' }}>
            {(policy.footer_endorsements_count || 0) + 1}
          </p>
          <p>
            When adding endorsements, make sure they are completed and numbered
            in the desired sequence.
          </p>
          <h4 style={{ fontSize: '0.875rem', marginTop: '1.125em' }}>
            Last Booked Endorsement
          </h4>
          <LastBookedEndorsement policy={policy} endorsements={endorsements} />
        </div>
      )}
      renderFooter={() => (
        <div style={{ textAlign: 'left' }}>
          <Button variant="link" onClick={() => hide()}>
            Okay
          </Button>
        </div>
      )}
    />
  );
}

const LastBookedEndorsement = ({ policy, endorsements }) => {
  const booked_endorsements = endorsements.filter(
    endt => endt.status === 'BOOKED'
  );
  if (booked_endorsements.length) {
    const last_endorsement = last(sortBy(booked_endorsements, 'booked_at'));
    return (
      <p>
        <strong>No. {policy.footer_endorsements_count}:</strong>{' '}
        <span>
          {last_endorsement.title} on{' '}
          {moment(last_endorsement.booked_at).format('MMMM D, YYYY')}.
        </span>
      </p>
    );
  }
  if (policy.footer_endorsements_count > 0) {
    return (
      <p>
        <strong>No. {policy.footer_endorsements_count}:</strong>{' '}
        <span>On Policy at Inception.</span>
      </p>
    );
  }
  return <p>No endorsements have been booked on this policy.</p>;
};
