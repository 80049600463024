import React from 'react';

import WithAuth from '../../../../auth/components/WithAuth';

import { StackedRadioControl } from '../../../../common';

const reasons = [
  'DIFFERENT_INSURED',
  'BOR_CHANGE',
  'ARGO_RENEWAL',
  'ARGO_APPROVED',
  'OTHER',
];

const generateOptions = ({ reasonsMap, user }) => {
  return reasons
    .map(reason => ({
      value: reason,
      label: reasonsMap[reason].text,
      groups: reasonsMap[reason].groups,
    }))
    .filter(shouldShowToUser(user))
    .map(({ value, label }) => ({ value, label }));
};

const shouldShowToUser = user => option => {
  const group = user.group;
  return option.groups.has(group);
};

export default function ClearanceOverrideReasons({
  onChange,
  value,
  reasonsMap,
}) {
  return (
    <WithAuth
      render={({ user }) => {
        if(!user) return false;
        const options = generateOptions({ reasonsMap, user });
        return (
          <StackedRadioControl
            options={options}
            value={value}
            onChange={onChange}
          />
        );
      }}
    />
  );
}
