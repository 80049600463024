import React from 'react';

import Alert from 'react-bootstrap/Alert';

const styles = {
  alert: {
    fontSize: '0.8125rem',
    marginTop: '20px',
  },
  header: {
    fontSize: '0.8125rem',
    marginTop: 0,
  },
  body: {
    color: '#000000',
  },
};

export default function EffectiveDatePastWarning({ style }) {
  return (
    <Alert style={{ ...styles.alert, ...style }} variant="warning">
      <h5 style={styles.header}>Effective Date in Past</h5>
      <section style={styles.body}>
        <p>
          The effective date for this binder is in the past, see program
          guidelines to confirm you can proceed.
        </p>
      </section>
    </Alert>
  );
}
