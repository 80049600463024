import React from 'react';

import { SubjectivitiesSummary } from '../../../subjectivities';

const combineSubjectivities = subjectivities => {
  return subjectivities.binder
    .filter(subjectivity => !subjectivity.resolved)
    .concat(subjectivities.policy);
};

export default function SubjectivitiesSection({ policy, ...props }) {
  const subjectivities = combineSubjectivities(
    policy.terms_conditions.subjectivities
  );
  return <SubjectivitiesSummary {...props} subjectivities={subjectivities} />;
}
