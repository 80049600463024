const CreateAndClearRenewalSubmission = (fetch, auth) => async submission => {
  const token = await auth.getAccessToken();
  if (!token) {
    auth.signOut();
    return Promise.reject(new Error('User is not authenticated.'));
  }
  const response = await fetch('/submissions/renewal', {
    method: 'POST',
    headers: {
      Authorization: token,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      submission,
    }),
  });
  return response.json();
};

export default CreateAndClearRenewalSubmission;
