import React from 'react';
import Button from 'react-bootstrap/Button';
import { Document, Page, pdfjs } from 'react-pdf/dist/esm/entry';
import { FileUpload, InlineButton } from '../../../../common';

pdfjs.GlobalWorkerOptions.workerSrc = '/pdf.worker.min.js';

const SelectedFile = ({ file, unselectFile }) => {
  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <Button className="btn btn-link" onClick={() => unselectFile()}>
        <i className="bi bi-trash3"/>
      </Button>
      <span
        style={{
          maxWidth: '25em',
          whiteSpace: 'initial',
          textAlign: 'left',
        }}
      >
        {file.name}
      </span>
    </div>
  );
};

export default function EndorsementUpload({
  name,
  file,
  selectFile,
  unselectFile,
}) {
  return (
    <div>
      {!file && (
        <FileUpload
          accept={{'application/pdf': ['.pdf']}}
          onSelect={files => selectFile(files[0])}
          maxFiles={1}
        >
          Drag and drop a PDF here or <InlineButton>Select a file</InlineButton>
        </FileUpload>
      )}
      {!!file && (
        <div style={{ textAlign: 'center' }}>
          <SelectedFile file={file} unselectFile={unselectFile} />
          <div
            style={{
              border: '1px solid black',
              display: 'inline-block',
            }}
          >
            <Document file={URL.createObjectURL(file)}>
              <Page pageNumber={1} renderTextLayer={false} renderAnnotationLayer={false}/>
            </Document>
          </div>
        </div>
      )}
    </div>
  );
}
