import { ApiError } from '../../api/errors';

const UpdateLimitsRetentions = (fetch, auth) => (rating_id, diff) => {
  if (!diff) return Promise.resolve({ success: true });
  return auth
    .getAccessToken()
    .then(token => {
      if (!token) {
        auth.signOut();
        return Promise.reject(new Error('User is not authenticated.'));
      }
      return token;
    })
    .then(token =>
      fetch(`/ratings/${rating_id}/limits-retentions`, {
        method: 'PATCH',
        headers: {
          Authorization: token,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          limits_retentions: diff,
        }),
      })
    )
    .then(response => {
      switch (response.status) {
        case 200:
          return response.json();
        case 400:
          return response.json().then(body => {
            throw new ApiError(body.message);
          });
        case 404:
          return response.json().then(body => {
            throw new ApiError(body.message);
          });
        default:
          throw new ApiError();
      }
    });
};

export default UpdateLimitsRetentions;
