import { connect } from 'react-redux';
import { withRouter } from '../../../common/components/WithRouter';
import * as binders from '../../actions';

import BinderDetails from './BinderDetails';

const mapDispatchToProps = (dispatch, props) => {
  const binder = props.binder;
  const binder_id = binder.id;
  return dispatch => ({
    updateDetails: (values, initialValues) =>
      dispatch(binders.updateDetails(binder_id, values, initialValues)),
    deleteBinder: () => {
      return dispatch(binders.deleteBinder(binder, props.navigate));
    },
  });
};

export default withRouter(
  connect(
    null,
    mapDispatchToProps
  )(BinderDetails)
);
export { default as Brokers } from './Brokers';
