import React from 'react';

import table_config from './table-config';

function BinderRow({ binder }) {
  return table_config.columns.map(column => {
    return <td key={column.key}>{column.content(binder)}</td>;
  });
}

export default BinderRow;
