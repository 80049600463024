import get from 'lodash/get';
import groupBy from 'lodash/groupBy';
import numeral from 'numeral';

const getAggregateLimit = ({ separate, shared }) => {
  return aggregateGroupLimits(separate) + aggregateGroupLimits(shared);
};

const aggregateGroupLimits = groups => {
  return groups.reduce((limit, group) => {
    return limit + group.limit;
  }, 0);
};

export default function mapValuesToLimitSummary(rating) {
  const global_limit_type = get(rating, 'limits_retentions.type');
  const coverages = get(rating, 'limits_retentions.coverages') || {};
  const selected_coverages = get(
    rating,
    'risk_analysis.selected_coverages',
    []
  );

  const only_one_coverage = selected_coverages.length === 1;

  const list = selected_coverages.filter(code => coverages[code]).map(code => {
    const coverage = coverages[code];
    let group = 'separate';
    if (global_limit_type === 'shared') {
      group = 'shared';
    } else if (coverage.limit_type === 'shared') {
      group = coverage.limit_group;
    }
    return {
      coverage: code,
      group,
      limit: numeral(get(coverage, `total_limit`)).value(),
    };
  });

  const aggregateLimitWithinGroup = members => {
    return Math.max.apply(null, members.map(member => member.limit));
  };

  const separate = only_one_coverage
    ? list.map(coverage => ({ ...coverage, group: 'separate' }))
    : list.filter(coverage => coverage.group === 'separate');
  const shared = only_one_coverage
    ? []
    : list.filter(coverage => coverage.group !== 'separate');
  const groups = groupBy(shared, 'group');
  const shared_list = Object.keys(groups)
    .sort()
    .map(group => ({
      group,
      members: groups[group],
      limit: aggregateLimitWithinGroup(groups[group]),
    }));
  return {
    aggregate: getAggregateLimit({ separate, shared: shared_list }),
    separate,
    shared: shared_list,
  };
}
