import { gql } from '@apollo/client';

export const fragments = {
  binder: gql`
    fragment ViewBinderContainer on Binder {
      id
      status
      expiration_date
      policy_effective_date
      policy_expiration_date
      product_code
      quote_id
      submission_id
      broker {
        name
        brokerage
        email
        commission
        phone
        address {
          street_line_1
          street_line_2
          city
          state
          zip
        }
      }
      sl_broker {
        license_number
        nj_transaction_number
        name
        address {
          street_line_1
          street_line_2
          city
          state
          zip
        }
      }
      named_insured {
        name
        address {
          street_line_1
          street_line_2
          city
          state
          zip
        }
      }
      terms_conditions {
        subjectivities {
          quote {
            value
            label
            resolved
          }
          binder {
            value
            label
          }
        }
        endorsements {
          value
          version
          data
          supplementary_data
          title
          dynamic
        }
        policy_forms {
          id
          version
          title
        }
        exclusions {
          do
          epl
          fid
        }
        erp {
          years
          percentage
        }
      }
      rating {
        id
        status
        deleted
        risk_analysis {
          selected_coverages
          loss_history
          sic_code
          effective_date
          standard_policy_period
          expiration_date
          employee_count {
            full_time
            other
          }
          financial {
            current {
              cash_flow
              current_assets
              current_liabilities
              net_income
              operating_income
              retained_earnings
              total_assets
              total_liabilities
              total_revenue
            }
            previous {
              cash_flow
              net_income
              total_revenue
            }
          }
          company_history {
            mergers_acquisitions
            years_in_business
          }
          coverage_specific {
            do {
              bank_credit_compliance
              ownership_structure
              securities_offerings
            }
            epl {
              employee_handbook
              employee_turnover
              hr_professional
            }
            fid {
              db_plan_funding_level
              esop
              plan_assets
              plan_modification
              plan_type
            }
          }
        }
        limits_retentions {
          type
          coverages {
            do {
              custom_retentions
              limit_type
              limit_group
              sublimit_employed_lawyers_claims
              sublimit_inquiry_costs
              sublimit_non_indemnified_loss
              sublimit_shareholder_derivative_demands
              subretention_company_liability
              subretention_company_reimbursement
              total_limit
              total_retention
            }
            epl {
              custom_retentions
              limit_type
              limit_group
              sublimit_sensitivity_training_costs
              sublimit_third_party
              subretention_first_party
              subretention_third_party
              total_limit
              total_retention
            }
            fid {
              limit_type
              limit_group
              sublimit_hipaa
              sublimit_voluntary_compliance
              subretention_hipaa
              subretention_voluntary_compliance
              total_limit
            }
          }
        }
        premium {
          coverage_specific {
            do {
              base
              adjustment
            }
            epl {
              base
              adjustment
            }
            fid {
              base
              adjustment
            }
          }
          minimum_premium
          total
          z_score
        }
      }
    }
  `,
};

export const query = gql`
  ${fragments.binder}
  query ViewBinderContainer($binderId: ID) {
    binder: binderById(id: $binderId) {
      ...ViewBinderContainer
      config {
        endorsements {
          id
          value: id
          version
          title
          label: title
          valid_for
          supplementary_data
          dynamic
        }
        subjectivities {
          value: id
          label: text
        }
        carrier {
          name
        }
        is_surplus_lines
      }
      quote {
        id
        display_name
        named_insured {
          name
          address {
            street_line_1
            street_line_2
            city
            state
            zip
          }
        }
        ratings {
          id
          rating_id: id
          deleted
        }
      }
      submission {
        id
        account_id
        submission_number
        status
        broker {
          name
          brokerage
          email
        }
        named_insured {
          name
          address {
            street_line_1
            street_line_2
            city
            state
            zip
          }
        }
        created_by {
          user_id
          group
        }
        clearance {
          status
          timestamp
        }
        created_at
        updated_at
      }
    }
  }
`;
