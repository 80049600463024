import React from 'react';
import { Link } from 'react-router-dom';

const styles = {
  linkDisabled: {
    opacity: '.5'
  }
}

function DisableableLink({ disabled, children, ...props }) {
  return (
    <div style={disabled ? styles.linkDisabled : {}}>
      <Link
        disabled={disabled}
        onClick={(e) => {
          if (disabled) {
            e.preventDefault();
          }
        }}
        {...props}
      >
        {children}
      </Link>
    </div>
  );
}
export default DisableableLink;
