import qs from 'querystring';

import { ApiError } from '../../api/errors';

const SearchQuotesBySubmission = (fetch, auth) => (
  submission_id,
  { term, limit, offset }
) => {
  const query_string = qs.stringify({
    ...(term && { q: term }),
    ...(limit && { limit }),
    ...(offset && { offset }),
    ...(!term && { sort: ['created_at:desc'] }),
  });
  return auth
    .getAccessToken()
    .then(token => {
      if (!token) {
        auth.signOut();
        return Promise.reject(new Error('User is not authenticated.'));
      }
      return token;
    })
    .then(token =>
      fetch(`/submissions/${submission_id}/quotes?${query_string}`, {
        headers: {
          Authorization: token,
          'Content-Type': 'application/json',
        },
      })
    )
    .then(response => {
      switch (response.status) {
        case 200:
          return response.json();
        default:
          throw new ApiError();
      }
    });
};

export default SearchQuotesBySubmission;
