import React from 'react';
import Alert from 'react-bootstrap/Alert';

const styles = {
  alert: {
    fontSize: '0.8125rem',
  },
};

export default function InvalidRatingAlert() {
  return (
    <Alert variant="danger" style={styles.alert}>
      <strong>Price cannot be generated</strong>
      <p style={{ color: 'black', marginTop: '10px' }}>
        Limits or Rating information is either missing or does not meet
        underwriting guidelines. Please review below.
      </p>
    </Alert>
  );
}
