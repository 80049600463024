import React from 'react';
import InputGroup from 'react-bootstrap/InputGroup';
import Form from 'react-bootstrap/Form';
import numeral from 'numeral';

import * as format from './format';

const styles = {
  container: {
    fontSize: '0.875rem',
  },
  coverage_title: {
    width: '30%',
    paddingBottom: '15px',
  },
  input_group: {
    display: 'inline-table',
  },
  absolute_input: {
    marginRight: '2.5%',
    width: '52.5%',
  },
  percentage_input: {
    width: '45%',
  },
};

const CoverageMap = {
  do: 'D&O',
  epl: 'EPL',
  fid: 'FID',
};

export default class Coverage extends React.Component {
  state = {
    percentage: format.decimal(
      this.calculatePercentage(this.props.premium.adjustment) || 0
    ),
    absolute: format.decimal(String(this.props.premium.adjustment) || 0),
  };

  clearInputs = () => {
    this.onChange('absolute')({ target: { value: '0' } });
  };

  onChange = field => e => {
    const value = format.inputDecimal(e.target.value);
    let absolute, percentage;
    if (field === 'percentage') {
      absolute = this.calculateAbsolute(value);
      percentage = value;
    } else {
      absolute = value;
      percentage = this.calculatePercentage(value);
    }
    this.props.updatePremiumAdjustment(
      absolute === '-' ? 0 : numeral(absolute).value()
    );
    this.setState({ absolute, percentage });
  };

  getBasePremium() {
    return format.decimalCurrency(this.props.premium.base);
  }

  calculatePercentage(absolute) {
    if (!absolute) return '';
    if (absolute === '-') return '-';
    const percentage =
      (numeral(absolute).value() / this.props.premium.base) * 100;
    return percentage.toFixed(2);
  }

  calculateAbsolute(percentage) {
    if (!percentage) return '';
    if (percentage === '-') return '-';
    const absolute =
      Math.trunc(numeral(percentage).value() * this.props.premium.base) / 100;
    return absolute.toFixed(2);
  }

  render() {
    return (
      <tr style={styles.container}>
        <th style={styles.coverage_title}>
          <div>{CoverageMap[this.props.coverage]}</div>
          <div style={{ fontWeight: 'normal' }}>{this.getBasePremium()}</div>
        </th>
        <td style={{ paddingBottom: '15px' }}>
          <InputGroup>
            <InputGroup.Text>$</InputGroup.Text>
            <Form.Control
              type="text"
              value={this.state.absolute}
              onChange={this.onChange('absolute')}
              aria-label="Adjustment in Dollars"
              as="input"
            />
          </InputGroup>
        </td>
        <td style={{ paddingBottom: '15px' }}>
          <InputGroup>
            <Form.Control
              type="text"
              value={this.state.percentage}
              onChange={this.onChange('percentage')}
              aria-label="Adjustment Percentage"
            />
            <InputGroup.Text>%</InputGroup.Text>
          </InputGroup>
        </td>
      </tr>
    );
  }
}
