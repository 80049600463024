import React from 'react';
import PropTypes from 'prop-types';

export default class Toggleable extends React.Component {
  static propTypes = {
    initiallyVisible: PropTypes.bool,
    render: PropTypes.func.isRequired,
  };

  static defaultProps = { initiallyVisible: false };

  state = { visible: this.props.initiallyVisible };

  toggle = () => this.setState({ visible: !this.state.visible });
  show = () => this.setState({ visible: true });
  hide = () => this.setState({ visible: false });

  render() {
    return this.props.render({
      visible: this.state.visible,
      show: this.show,
      hide: this.hide,
      toggle: this.toggle,
    });
  }
}
