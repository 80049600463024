import { build as buildValue } from '../builder';
export class SumOperationValue {
  constructor(config) {
    this.config = config;
  }
  eval($) {
    const values = this.config.operands.map(
      valueConfig => buildValue(valueConfig).eval($) || 0
    );
    let sum = 0;
    values.forEach(value => {
      sum += value;
    });
    return sum;
  }
}
