import { ApiError } from '../../api/errors';

const AddRatingToQuote = (fetch, auth) => ({ quote_id, rating_id, before }) => {
  return auth
    .getAccessToken()
    .then(token => {
      if (!token) {
        auth.signOut();
        return Promise.reject(new Error('User is not authenticated.'));
      }
      return token;
    })
    .then(token =>
      fetch(
        `/quotes/${quote_id}/ratings/${rating_id}${
          before ? `?before=${before}` : ''
        }`,
        {
          method: 'PUT',
          headers: {
            Authorization: token,
            'Content-Type': 'application/json',
          },
        }
      )
    )
    .then(response => {
      switch (response.status) {
        case 200:
          return response.json();
        case 400:
        case 404:
          return response.json().then(body => {
            throw new ApiError(body.message);
          });
        default:
          throw new ApiError();
      }
    });
};

export default AddRatingToQuote;
