import React from 'react';
import { Flag } from '../../../common/components/Flag';
import { Routes, Route } from 'react-router-dom';

import { PageTitle } from '../../../common';
import { AsyncQueryWrapper } from '../../../common';

import ClearanceResults from '../ClearanceResults';
import SubmissionWorkspace from '../SubmissionWorkspace';
import ClearanceError from './ClearanceError';

export default function ViewSubmission(props) {
  const { submission, match } = props;

  return (
    <AsyncQueryWrapper
      load={props.getSubmission}
      render={({ loading, error, data}) => {
        if (loading) {
          return <p className="text-center-2">Loading...</p>;
        }
        if (error) {
          return <ClearanceError error={error} />;
        }
        const insured_name = submission.named_insured.name;
        return (
          <Flag
            name="rqbi"
            render={() => {
              if (!submission.status) {
                return (
                  <React.Fragment>
                    <PageTitle title={`${insured_name} - Clearance Results`} />
                    <ClearanceResults submission={submission} />
                  </React.Fragment>
                );
              }
              return (
                <Routes>
                  <Route
                    path="clearance"
                    element={
                      <React.Fragment>
                        <PageTitle
                          title={`${insured_name} - Clearance Results`}
                        />
                        <ClearanceResults submission={submission} />
                      </React.Fragment>
                    }
                  />
                  <Route
                    path="/"
                    element={
                      <React.Fragment>
                        <PageTitle
                          title={`${insured_name} - Submission Workspace`}
                        />
                        <SubmissionWorkspace 
                          submission={submission} 
                          pathPattern={match.pattern.path}  
                        />
                      </React.Fragment>
                    }
                  />
                </Routes>
              );
            }}
            fallbackRender={() => {
              return (
                <React.Fragment>
                  <PageTitle title={`${insured_name} - Clearance Results`} />
                  <ClearanceResults submission={submission} />
                </React.Fragment>
              );
            }}
          />
        );
      }}
    />
  );
}
