import React from 'react';
import InputGroup from 'react-bootstrap/InputGroup';
import FormControl from 'react-bootstrap/FormControl';

const styles = {
  input: {
    textAlign: 'right',
  },
};

const CurrencyControl = props => {
  return (
    <InputGroup>
      <InputGroup.Text>$</InputGroup.Text>
      <FormControl
        type="text"
        {...props}
        style={{ ...props.style, ...styles.input }}
      />
    </InputGroup>
  );
};

export default CurrencyControl;
