import { connect } from 'react-redux';
import { withRouter } from '../../../common/components/WithRouter';

import IssuePolicyBanner from './IssuePolicyBanner';

import { getBySubmission } from '../../actions';

const mapDispatchToProps = (dispatch, props) => dispatch => ({
  issuePolicy: () => {
    const { submission_id } = props;
    return dispatch(getBySubmission(submission_id)).then(({ policy }) => {
      return props.navigate(`/policies/${policy.id}/terms-conditions`);
    });
  },
});

export default withRouter(connect(null, mapDispatchToProps)(IssuePolicyBanner));
