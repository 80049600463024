import React from 'react';
import Button from 'react-bootstrap/Button';

import { AsyncMutationWrapper } from '../../../common';

export default function StartQuoteButton({
  style,
  className,
  createQuote,
  children,
}) {
  return (
    <AsyncMutationWrapper
      mutate={createQuote}
      render={({ loading, mutate }) => {
        return (
          <Button
            style={{ ...style }}
            className={className}
            variant="link"
            disabled={loading}
            onClick={() => mutate()}
          >
            {children}
          </Button>
        );
      }}
    />
  );
}
