import React from 'react';

const styles = {
  download_icon: {
    fontSize: '1.25rem',
    marginRight: '10px',
    verticalAlign: 'middle',
  },
};

export default function Button({ url, children, ...props }) {
  return (
    <a href={url} className="btn btn-primary" {...props}>
      <i style={styles.download_icon} className="bi bi-download"/>
      <span style={{ verticalAlign: 'middle' }}>{children}</span>
    </a>
  );
}
