import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import { SUBMISSION_STATUSES, compareStatuses } from '../../../../submissions';
import { utils as utils_p } from '../../../../policies';

import BoundSubmissionCard from './BoundSubmissionCard';
import InProgressSubmissionCard from './InProgressSubmissionCard';
import UnstartedRenewalCard from './UnstartedRenewalCard';
import DeclinedSubmissionCard from './DeclinedSubmissionCard';
import ManagePolicy from './ManagePolicy';
import { ManageSubmission } from '../../../../submissions';

function getCardType({ submission, policy }) {
  const isStarted =
    compareStatuses(
      submission.status,
      SUBMISSION_STATUSES.QUOTING_IN_PROGRESS
    ) >= 0;
  const isBound =
    compareStatuses(submission.status, SUBMISSION_STATUSES.BOUND) >= 0;
  const isRenewal = !!submission.renewed_from_policy;
  const isDeclined = !!submission.declined;

  if (isBound && policy) {
    return BoundSubmissionCard;
  } else if (isDeclined) {
    return DeclinedSubmissionCard;
  } else if (isStarted) {
    return InProgressSubmissionCard;
  } else if (isRenewal) {
    return UnstartedRenewalCard;
  }
  return null;
}

function getManagePolicySidebarActions({ policy, submission }) {
  const actions = [];
  const isIssued =
    compareStatuses(submission.status, SUBMISSION_STATUSES.ISSUED) >= 0;
  if (isIssued) {
    actions.push(
      {
        type: 'link',
        title: 'Add Endorsement',
        to: `/policies/${policy.id}/endorsements/mid-term`,
      },
      {
        disabled: utils_p.hasBeenRenewed(policy),
        type: utils_p.hasBeenRenewed(policy) ? 'button' : 'link',
        title: 'Clear and Start Renewal Quote',
        to: `/submissions/new?renewed_from_policy_id=${policy.id}`,
        tooltiptext:
          'A renewal submission has been started for this account. Please look above for the current status.',
      }
    );
  }
  return actions;
}
function getManageSubmissionSidebarActions({ submission }) {
  const is_quoted =
    compareStatuses(submission.status, SUBMISSION_STATUSES.QUOTED) === 0;
  const is_quoting =
    compareStatuses(
      submission.status,
      SUBMISSION_STATUSES.QUOTING_IN_PROGRESS
    ) === 0;

  return is_quoted || is_quoting
    ? [
        {
          type: 'button',
          title: 'Record Declination Status',
        },
      ]
    : [];
}

export default function SubmissionCard({
  submission,
  quotes,
  binder,
  policy,
  endorsements,
}) {
  const SubmissionCardComponent = getCardType({ submission, policy });
  const managePolicySidebarActions = getManagePolicySidebarActions({
    policy,
    submission,
  });
  const manageSubmissionSidebarActions = getManageSubmissionSidebarActions({
    submission,
  });

  return (
    <Row>
      <Col md={{span: 7, offset:1}}>
        <SubmissionCardComponent
          submission={submission}
          quotes={quotes}
          binder={binder}
          policy={policy}
          endorsements={endorsements}
        />
      </Col>
      <Col md={3}>
        {managePolicySidebarActions.length > 0 && (
          <ManagePolicy actions={managePolicySidebarActions} />
        )}
        {manageSubmissionSidebarActions.length > 0 && (
          <ManageSubmission
            actions={manageSubmissionSidebarActions}
            submission={submission}
          />
        )}
      </Col>
    </Row>
  );
}
