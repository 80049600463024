import { useContext } from 'react';
import { FlagsProviderContext } from "../..";

/*
* This Flag component is a custom replacement for flag npm library which isn't react 18 compatible. 
*/
const noop = () => null;

export const Flag = ({ name, render, fallbackRender }) => {
    fallbackRender ??= noop;
    const flags = useContext(FlagsProviderContext);
    const flag = getFlag(name, flags);

    return flag === false ? fallbackRender() : render(flag);

}

const getFlag = (keyPath, flags) => {
    if(keyPath == null || typeof keyPath !== 'string') return false;
    const keyArr = keyPath.split('.');
    if (keyArr.length === 0) return false;
    let result = flags;
    for(const key of keyArr){
        result = result[key];
        if(result === undefined) return false;
    }
    try{
        if(typeof result === 'function') result = result(flags);
    }catch(e){
        return false;
    }
    if(typeof result === 'boolean') return result;
    return false;
}
