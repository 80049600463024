import React, { Component } from 'react';
import { withRouter } from '../../../../../common/components/WithRouter';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import {
  ActionMenu,
  AsyncMutationWrapper,
  ModalDialog,
} from '../../../../../common';

const styles = {
  footer: {
    textAlign: 'left',
  },
};

class DeleteEndorsement extends Component {
  state = { visible: false };
  show = () => this.setState({ visible: true });
  hide = () => this.setState({ visible: false });
  render() {
    return (
      <div>
        <ActionMenu.Button style={this.props.style} onClick={this.show}>
          Cancel Endorsement Request
        </ActionMenu.Button>
        <ModalDialog.Standard
          show={this.state.visible}
          hide={this.hide}
          renderHeader={() => <h3>Are you sure?</h3>}
          renderBody={() =>
            'This endorsement request will be cancelled and removed from your Policy.'
          }
          renderFooter={() => (
            <AsyncMutationWrapper
              mutate={() =>
                this.props
                  .deleteEndorsement()
                  .then(() =>
                    this.props.navigate(
                      `/submissions/${this.props.submission_id}/`
                    )
                  )
              }
              render={({ mutate, loading, error }) => (
                <div style={styles.footer}>
                  <Button
                    style={{ ...styles.button }}
                    type="button"
                    variant="danger"
                    onClick={mutate}
                    disabled={loading}
                  >
                    Yes, Delete Endorsement
                  </Button>
                  <Button
                    style={{ ...styles.button }}
                    variant="link"
                    onClick={this.hide}
                  >
                    No, Keep It
                  </Button>
                  {error && (
                    <Form.Text className="text-danger" muted>
                      Sorry, a problem occurred. Please try refreshing the page.
                    </Form.Text>
                  )}
                </div>
              )}
            />
          )}
        />
      </div>
    );
  }
}
export default withRouter(DeleteEndorsement);
