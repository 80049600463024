import React from 'react';

import Form from 'react-bootstrap/Form';

import ComponentWrapper from '../ComponentWrapper';

const styles = {
  container: {
    borderLeft: '3px solid #b3b3b3',
    paddingLeft: '10px',
  },
};

const Helper = ({ children }) => {
  return <Form.Text style={{ fontSize: '0.875rem' }} muted>{children}</Form.Text>;
};

const SupplementalSection = props => {
  const { form, field, hidden } = props;
  const { title, description, children } = field;
  if (hidden) return null;
  return (
    <section style={styles.container} id={field.id}>
      {title && <h3>{title}</h3>}
      {description && <p>{description}</p>}
      {children &&
        children.map((child, i) => (
          <ComponentWrapper key={i} form={form} field={child} />
        ))}
      {field.helper && <Helper>{field.helper}</Helper>}
    </section>
  );
};

export default SupplementalSection;
