import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {
  InsuredSummarySection,
  BrokerSummarySection,
} from '../../../submissions';

const DetailSection = ({ children, ...props }) => (
  <Col {...props} sm={12} md={4}>
    {children}
  </Col>
);

export default function SubmissionSummarySection({
  named_insured,
  broker,
  style,
}) {
  return (
    <Row style={style}>
      <DetailSection>
        <InsuredSummarySection named_insured={named_insured} />
      </DetailSection>
      <DetailSection>
        <BrokerSummarySection broker={broker} />
      </DetailSection>
    </Row>
  );
}
