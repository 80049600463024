import { defaultDataIdFromObject } from '@apollo/client';

// This custom cache implementation allows for the cache to identify that the
// Submission object returned by the Account object is also the same object as
// the one returned from the root submissionById query.
// See the following documentation for more details:
// https://www.apollographql.com/docs/react/caching/overview/

export const inMemoryConfig = {
  dataIdFromObject: (obj) => {
    switch (obj.__typename) {
      case 'DocumentTemplate':
        return `${obj.__typename}::${obj.id}::${obj.version}`;
      default:
        return defaultDataIdFromObject(obj);
    }
  },
  typePolicies: {
    Query: {
      fields: {
        submissionById: {
          read(_, { args, toReference }) {
            return toReference({ __typename: 'Submission', id: args.id });
          },
        },
        documentByIdAndVersion: {
          read(_, { args, toReference }) {
            return toReference({
              __typename: 'DocumentTemplate',
              id: args.id,
              version: args.version,
            });
          },
        },
      },
    },
    Submission: {
      fields: {
        broker: {
          merge: true,
        },
      },
    },
    Quote: {
      fields: {
        broker: {
          merge: true,
        },
        config: {
          merge: true,
        },
      },
    },
    Policy: {
      fields: {
        config: {
          merge: true,
        },
      },
    },
  },
};
