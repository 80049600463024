import { connect } from 'react-redux';

import { manuallyClear as manuallyClearSubmission } from '../../../actions';

import ClearanceOverridePrompt from './ClearanceOverridePrompt';

const mapDispatchToProps = (dispatch, props) => {
  const { id: submission_id } = props.submission;
  return dispatch => ({
    manuallyClearSubmission: reason => {
      return dispatch(manuallyClearSubmission({ submission_id, reason }));
    },
  });
};

export default connect(null, mapDispatchToProps)(ClearanceOverridePrompt);
