import { validator } from '../../../validation';

export default function isValid(productConfig, { rating }) {
  const { risk_analysis } = rating;
  const validations = validator.validateDeep(
    productConfig.risk_analysis_schema,
    risk_analysis,
    {
      today: new Date().toISOString(),
    }
  );
  return !validations.length;
}
