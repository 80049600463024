import isPlainObject from 'lodash/isPlainObject';
import isArray from 'lodash/isArray';

function removeTypename(item) {
  if (isArray(item)) {
    return item.map(removeTypename);
  }
  if (isPlainObject(item))
    return Object.keys(item).reduce((cleanedObj, key) => {
      if (key === '__typename') return cleanedObj;
      cleanedObj[key] = removeTypename(item[key]);
      return cleanedObj;
    }, {});
  return item;
}

export { removeTypename };
