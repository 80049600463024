import React from 'react';
import { Link } from 'react-router-dom';
import Button from 'react-bootstrap/Button';

import BindPolicyModal from './BindPolicyModal';

const styles = {
  button: {
    marginRight: '20px',
  },
};

export default class BindPolicyButton extends React.Component {
  state = { show: false };

  show = () => this.setState({ show: true });
  hide = () => this.setState({ show: false });

  render() {
    const { style, binder, bindPolicy, disabled } = this.props;
    return (
      <div style={style}>
        <Button
          style={styles.button}
          variant="primary"
          onClick={this.show}
          disabled={disabled}
        >
          Bind Policy
        </Button>
        <Link
          to={`/binders/${binder.id}/terms-conditions`}
          style={styles.button}
          className="btn btn-link"
        >
          Go Back
        </Link>
        <BindPolicyModal
          show={this.state.show}
          hide={this.hide}
          bindPolicy={bindPolicy}
        />
      </div>
    );
  }
}
