import get from 'lodash/get';

import { QUOTE_STATUSES, compareStatuses } from './statuses';
import {
  SUBMISSION_STATUSES,
  compareStatuses as compareSubmissionStatuses,
} from '../submissions';

export const canAddRating = ({ quote, rating }) => {
  const rating_already_associated = quote.ratings.some(
    qr => qr.rating_id === rating.id
  );
  const is_quote_quoted =
    compareStatuses(quote.status, QUOTE_STATUSES.QUOTED) >= 0;
  return !rating_already_associated && !is_quote_quoted;
};

export const canUpdateTermsConditions = ({ quote }) => {
  const is_quote_quoted = quote.status === QUOTE_STATUSES.QUOTED;
  return !is_quote_quoted;
};

export const canUpdateQuoteDetails = ({ quote }) => {
  const is_quote_quoted = quote.status === QUOTE_STATUSES.QUOTED;
  return !is_quote_quoted;
};

export const canStartBind = ({ quote, rating, submission }) => {
  const is_submission_binding_in_progress =
    compareSubmissionStatuses(
      submission.status,
      SUBMISSION_STATUSES.BINDING_IN_PROGRESS
    ) >= 0;
  const is_quote_quoted = quote.status === QUOTE_STATUSES.QUOTED;
  const has_policy_effctive_date = !!get(
    rating,
    'risk_analysis.effective_date'
  );
  return (
    is_quote_quoted &&
    has_policy_effctive_date &&
    !is_submission_binding_in_progress
  );
};
