import { ApiError } from '../../api/errors';

const GetUploadUrl = (fetch, auth) => async endorsement_id => {
  const token = await auth.getAccessToken();
  const res = await fetch(`/endorsements/${endorsement_id}/upload-url`, {
    headers: {
      Authorization: token,
    },
  });
  switch (res.status) {
    case 200:
      return res.json();
    case 400:
    case 404:
      return res.json().then(body => {
        throw new ApiError(body.message);
      });
    default:
      throw new ApiError();
  }
};

export default GetUploadUrl;
