import React from 'react';
import { Query } from '@apollo/client/react/components';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';

import { AsyncMutationWrapper } from '../../../../common';
import { Document, Page, pdfjs } from 'react-pdf/dist/esm/entry';
import { query } from '../query-endorsement-option';

pdfjs.GlobalWorkerOptions.workerSrc = '/pdf.worker.min.js';

export default function MidTermEndorsementsPreview({ addToPolicy, match }) {
  const { option_id, option_version } = match.params;
  return (
    <Query
      query={query}
      variables={{
        id: option_id,
        version: option_version,
      }}
    >
      {({ data, loading, error }) => {
        if (loading) return null;
        const { option } = data;
        return (
          <Container fluid="md">
            <Row>
              <Col style={{ marginBottom: '25px' }} md={{span: 3}} lg={{offset: 1}}>
                <h3>{option.title}</h3>
                <AsyncMutationWrapper
                  mutate={addToPolicy}
                  render={({ mutate, loading, error }) => (
                    <React.Fragment>
                      <Button
                        className="btn btn-primary"
                        onClick={mutate}
                        disabled={loading}
                      >
                        Add to Policy
                      </Button>
                      {error && (
                        <Form.Text className="text-danger" muted> 
                          Sorry, a problem occurred. Please try refreshing the
                          page.
                        </Form.Text>
                      )}
                    </React.Fragment>
                  )}
                />
              </Col>
              <Col style={{ marginBottom: '25px', textAlign: 'center' }} md={7}>
                <div
                  style={{ border: '1px solid black', display: 'inline-block' }}
                >
                  <Document
                    file={{ url: option.template_location.url }}
                    loading={<div className="text-center">Loading...</div>}
                  >
                    <Page 
                      pageNumber={1} 
                      loading={null} 
                      renderTextLayer={false} 
                      renderAnnotationLayer={false}
                    />
                  </Document>
                </div>
              </Col>
            </Row>
          </Container>
        );
      }}
    </Query>
  );
}
