import React from 'react';

import SectionContainer from './SectionContainer';
import FieldContainer from '../../components/FieldContainer';

const FormSection = ({ fields, ...props }) => {
  return (
    <SectionContainer {...props}>
      {fields.map(field => (
        <FieldContainer key={field.key || field.name} field={field} />
      ))}
    </SectionContainer>
  );
};

export default FormSection;
