const AddEndorsementToPolicy = (fetch, auth) => ({
  policy_id,
  option_id,
  option_version,
}) => {
  return auth
    .getAccessToken()
    .then(token => {
      if (!token) {
        auth.signOut();
        return Promise.reject(new Error('User is not authenticated.'));
      }
      return token;
    })
    .then(token =>
      fetch(`/policies/${policy_id}/endorsements`, {
        method: 'POST',
        headers: {
          Authorization: token,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          option_id,
          option_version,
        }),
      })
    )
    .then(response => response.json());
};

export default AddEndorsementToPolicy;
