import { connect } from 'react-redux';
import { withRouter } from '../../../common/components/WithRouter';
import * as quotes from '../../actions';

import QuoteDetails from './QuoteDetails';

const mapDispatchToProps = (dispatch, props) => {
  const { quote_id } = props.match.params;
  return dispatch => ({
    updateDetails: (values, initialValues) => {
      return dispatch(quotes.updateQuote(quote_id, values, initialValues));
    },
  });
};

export { default as DetailsSummary } from './DetailsSummary';
export { default as isValid } from './isValid';
export default withRouter(
  connect(
    null,
    mapDispatchToProps
  )(QuoteDetails)
);
