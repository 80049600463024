import React from 'react';
import { useLocation } from 'react-router-dom';

import { compareStatuses, QUOTE_STATUSES } from '../../statuses';
import QuotedQuoteWorkspace from './QuotedQuoteWorkspace';
import UnquotedQuoteWorkspace from './UnquotedQuoteWorkspace';

export default function QuoteWorkspace({
  quote,
  submission,
  unfilteredRatings,
  productConfig,
  ratings,
  related_quotes
}) {
  const location = useLocation();
  const copy = (!!location.state && !!location.state.copy);
  const copiedWithRatings = (location.state && !!location.state.copiedWithRatings);

  const quote_is_quoted =
    compareStatuses(quote.status, QUOTE_STATUSES.QUOTED) === 0;
  if (quote_is_quoted) {
    return (
      <QuotedQuoteWorkspace
        quote={quote}
        submission={submission}
        ratings={ratings}
        related_quotes={related_quotes}
        productConfig={productConfig}
        copy={copy}
      />
    );
  }
  return (
    <UnquotedQuoteWorkspace
      quote={quote}
      submission={submission}
      unfilteredRatings={unfilteredRatings}
      productConfig={productConfig}
      ratings={ratings}
      related_quotes={related_quotes}
      copy={copy}
      copiedWithRatings={copiedWithRatings}
    />
  );
}
