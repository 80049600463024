import React from 'react';

import Button from 'react-bootstrap/Button';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

const styles = {
  button__disabled: {
    pointerEvents: 'none',
  },
};

export default function TooltipButton({
  children,
  bsStyle,
  tooltiptext,
  style,
  disabled,
  onClick,
}) {
  const tooltip = <Tooltip id="tooltip">{tooltiptext}</Tooltip>;
  const button_style = {
    ...(disabled && styles.button__disabled),
  };
  return (
    <div style={style}>
      <OverlayTrigger placement="right" overlay={tooltip}>
        <div style={{ display: 'inline-block' }}>
          <Button
            variant={bsStyle}
            style={button_style}
            onClick={onClick}
            disabled={disabled}
          >
            {children}
          </Button>
        </div>
      </OverlayTrigger>
    </div>
  );
}
