import React from 'react';

import { StickySidebar } from '../../../layouts';
import { AsyncQueryWrapper } from '../../../common';
import { SubmissionSummaryCard } from '../../../submissions';

import DownloadQuoteDocuments from './DownloadQuoteDocuments';

const styles = {
  sidebar: {
    margin: 'auto',
    maxWidth: '240px',
  },
};

export default function QuoteDocuments({
  submission,
  quote,
  getQuoteDocuments,
  ...props
}) {
  return (
    <StickySidebar
      renderMain={() => {
        return (
          <AsyncQueryWrapper
            load={() => getQuoteDocuments().catch(() => null)}
            render={({ reload, data }) => {
              return (
                <DownloadQuoteDocuments
                  quote={(data && data.quote) || quote}
                  getQuoteDocuments={reload}
                  {...props}
                />
              );
            }}
          />
        );
      }}
      renderAside={() => {
        return (
          <section style={styles.sidebar}>
            <SubmissionSummaryCard flex submission={submission} />
          </section>
        );
      }}
    />
  );
}
