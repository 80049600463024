import React from 'react';
import Button from 'react-bootstrap/Button';

import { AsyncMutationWrapper } from '../../../common';

export default function CopyRatingButton({ style, copyRating, children }) {
  return (
    <AsyncMutationWrapper
      mutate={copyRating}
      render={({ loading, mutate }) => {
        return (
          <Button
            style={{ ...style }}
            variant="link"
            disabled={loading}
            onClick={() => mutate()}
          >
            {children}
          </Button>
        );
      }}
    />
  );
}
