import React from 'react';

class HydrateForm extends React.Component {
  componentDidMount() {
    const { getPolicyById, onSelectedPolicyChange } = this.props;
    getPolicyById().then(res => onSelectedPolicyChange(res.policy));
  }
  render() {
    return null;
  }
}

export default HydrateForm;
