import {
  INITIATE_AUTH_REQUEST,
  INITIATE_AUTH_SUCCESS,
  INITIATE_AUTH_FAILURE,
  CLEAR_USER_INFO
} from './constants';

const initial_state = {
  loading: false,
  user: null,
};

export default function auth(state = initial_state, action) {
  switch (action.type) {
    case INITIATE_AUTH_REQUEST:
      return handlers.initiateAuthRequest(state, action);
    case INITIATE_AUTH_SUCCESS:
      return handlers.initiateAuthSuccess(state, action);
    case INITIATE_AUTH_FAILURE:
      return handlers.initiateAuthFailure(state, action);
    case CLEAR_USER_INFO:
        return handlers.clearUserInfo(state, action);
    default:
      return state;
  }
}

const handlers = {
  initiateAuthRequest(state, { payload }) {
    return {
      ...state,
      loading: true,
      user: null,
    };
  },

  initiateAuthSuccess(state, { payload }) {
    return {
      ...state,
      loading: false,
      user: payload.user,
    };
  },
  initiateAuthFailure(state) {
    return {
      ...state,
      loading: false,
      user: null,
    };
  },
  clearUserInfo(state) {
    return {
      ...state,
      user: null,
    };
  }
};
