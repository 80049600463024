import React from 'react';
import { Link } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';

import {
  isValid as isRatingValid,
  RATING_STATUSES,
  compareStatuses,
} from '../../';

import BasePriceOptionsSummaryCard from './Base';
import AsyncMutationWrapper from '../../../common/components/AsyncMutationWrapper';

const styles = {
  container: {
    border: '1px solid #979797',
    padding: '20px 20px 20px',
  },
  status: {
    fontSize: '0.75rem',
    marginLeft: '10px',
    textTransform: 'uppercase',
  },
  status_success: {
    color:'#417505'
  },
  status_warning: {
    color:'#8d6708'
  },
  row: {
    marginLeft: '0',
    marginRight: '0',
  },
  inlineButton: {
    border: 'none',
    paddingTop: 0,
    paddingBottom: 0,
    textTransform: 'initial',
  },
  trash: { float: 'right' },
};

const isComplete = (productConfig, { rating, quote }) => {
  const is_quoted =
    compareStatuses(rating.status, RATING_STATUSES.QUOTED) === 0;
  if (is_quoted) return true;
  const is_valid = isRatingValid(productConfig, { rating, quote });
  return is_valid;
};

const getRatingNumber = ({ quote, rating }) => {
  return (
    quote.ratings
      .filter(quoteRatingAssoc => !quoteRatingAssoc.deleted)
      .findIndex(quoteRatingAssoc => quoteRatingAssoc.rating_id === rating.id) +
    1
  );
};

export default function StandardPriceOptionsSummaryCard({
  style,
  quote,
  rating,
  productConfig,
  displayName,
  actions,
  handleDelete,
}) {
  const defaultDisplayName =
    quote && rating ? `Option ${getRatingNumber({ quote, rating })}` : 'Option';
  return (
    <div style={{ ...styles.container, ...style }}>
      <Row style={styles.row}>
        <Col>
          <BasePriceOptionsSummaryCard
            rating={rating}
            renderHeader={rating => {
              const is_complete = isComplete(productConfig, {
                rating,
                quote,
              });
              const status_label = is_complete ? 'Complete' : 'In progress';
              const status_styles = is_complete
                ? {...styles.status, ...styles.status_success}
                : {...styles.status, ...styles.status_warning};
              return (
                <div className="clearfix">
                  <Link to={`/ratings/${rating.id}`}>
                    {rating.deleted
                      ? 'Deleted Option'
                      : displayName || defaultDisplayName}
                  </Link>
                  <strong style={status_styles}>
                    {status_label}
                  </strong>
                  {!!actions.length &&
                    actions.map(action => (
                      <AsyncMutationWrapper
                        key={rating.id + action.title}
                        mutate={action.onSelect}
                        render={({ mutate, loading }) => (
                          <Button
                            variant="link"
                            style={styles.inlineButton}
                            disabled={action.disabled || loading}
                            onClick={mutate}
                          >
                            {action.title}
                          </Button>
                        )}
                      />
                    ))}
                  {handleDelete && (
                    <AsyncMutationWrapper
                      mutate={handleDelete}
                      render={({ mutate, loading }) => (
                        <Button
                          variant="link"
                          style={{ ...styles.inlineButton, ...styles.trash }}
                          onClick={mutate}
                          disabled={loading}
                        >
                          <i className="bi bi-trash3"/>
                        </Button>
                      )}
                    />
                  )}
                </div>
              );
            }}
          />
        </Col>
      </Row>
    </div>
  );
}

StandardPriceOptionsSummaryCard.defaultProps = {
  actions: [],
};
