import { connect } from 'react-redux';
import { withRouter } from '../../../common/components/WithRouter';

import * as quotes from '../../actions';

import StartQuoteFromSubmissionButton from './StartQuoteFromSubmissionButton';

const mapDispatchToProps = (dispatch, props) => {
  const { submission, navigate } = props;
  return dispatch => ({
    createQuote: () =>
      dispatch(quotes.createFromSubmission(submission.id)).then(res => {
        navigate(`/quotes/${res.quote.id}`);
        return res;
      }),
  });
};

export default withRouter(
  connect(null, mapDispatchToProps)(StartQuoteFromSubmissionButton)
);
