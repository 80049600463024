import { connect } from 'react-redux';

import { withRouter } from '../../../../common/components/WithRouter'
import MidTermEndorsementConfirmation from './MidTermEndorsementConfirmation';
import {
  deleteEndorsement,
  getEndorsementsBySubmission,
  bookEndorsement,
  generateOptionUrl,
} from '../../../actions';

import { getOptionFilename } from '../../../utils';

const mapDispatchToProps = (dispatch, props) => {
  const { policy_id, endorsement_id } = props.match.params;
  return {
    getEndorsementsBySubmission() {
      return dispatch(getEndorsementsBySubmission(props.policy.submission_id));
    },
    deleteEndorsement() {
      return dispatch(
        deleteEndorsement({ policy_id: props.policy.id, endorsement_id })
      );
    },
    bookEndorsement(file) {
      return dispatch(bookEndorsement(endorsement_id, file)).then(res => {
        props.navigate(
          `/policies/${policy_id}/endorsements/mid-term/${endorsement_id}/documents`,
          {state: { displayBanner: true }}
        );
        return res;
      });
    },
    getTemplateUrl(endorsement_option, policy) {
      const filename = getOptionFilename(endorsement_option, policy);
      return dispatch(
        generateOptionUrl(endorsement_option, {
          filename,
        })
      );
    },
  };
};

export default withRouter(
  connect(
    null,
    mapDispatchToProps
  )(MidTermEndorsementConfirmation)
);
