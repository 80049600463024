import numeral from 'numeral';

const countDecimals = value => {
  const decimals = value.split('.')[1];
  return !decimals ? 0 : decimals.length;
};

const padZero = (value, places) => {
  let result = '';
  const count = countDecimals(value);
  if (!count && !!places) result += '.';
  for (let i = countDecimals(value); i < places; i++) {
    result += '0';
  }
  return result;
};

const FormatNumber = format => value => {
  value = String(value);
  if (value == null) return '';
  if (value === '') return '';
  if (value === '-') return '-';
  const first = value.slice(0, 1);
  const last = value.slice(-1);
  if (countDecimals(value) > 2) return value.slice(0, -1);
  const parsed = numeral(value).value();
  const base = numeral(Math.abs(parsed)).format(format);
  const negative = first === '-' ? '-' : '';
  const zeroes = padZero(base, countDecimals(value));
  const trailing = last === '.' ? '.' : '';
  return negative + base + zeroes + trailing;
};

export const inputDecimal = FormatNumber('0,0.[00]');

export const decimal = value => numeral(value).format('0,00.00');
export const decimalCurrency = value => numeral(value).format('$0,0.00');
export const wholeNumberCurrency = value => numeral(value).format('$0,0');
