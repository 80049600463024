const integer = {
  display: {
    format: value => {
      if (typeof value === 'number') return value.toString();
    },
  },
  input: {
    parse: value => {
      if (value === '-') return '-';
      const result = parseInt(value, 10);
      if (!isNaN(result) && typeof result === 'number') return result;
      return null;
    },
    format: value => {
      if (value === '-') return '-';
      if (typeof value === 'number') return value.toString();
    },
  },
};

export default integer;
