import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { reduxForm, Field, FormSection, getFormValues } from 'redux-form';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';
import InputGroup from 'react-bootstrap/InputGroup';
import FormControl from 'react-bootstrap/FormControl';
import { AddressFields, LabeledControl, InputControl } from '../../../common';
import SaveOnLeave from '../../../common/components/SaveOnLeave';

const styles = {
  form_section: {
    borderTop: '1px solid grey',
    margin: '0 0 20px',
    paddingTop: '20px'
  },
};

const Commission = props => {
  const { input, meta, ...otherProps } = props;
  return (
    <LabeledControl {...props}>
      <InputGroup>
        <FormControl
          id={otherProps.id || input.name}
          {...input}
          {...otherProps}
        />
        <InputGroup.Text>%</InputGroup.Text>
      </InputGroup>
    </LabeledControl>
  );
};

function Brokers(props) {
  const {
    isSurplusLines = true,
    handleSubmit,
    validation: { broker, sl_broker },
    submitButtonLabel,
    form,
    initialValues,
    updateDetails,
    prevPagePath,
    namedInsured: {
      address: {
        state
      }
    }
  } = props;
  const isNJInsured = String(state).toLowerCase() === 'nj'
  return (
    <form noValidate onSubmit={handleSubmit}>
      <SaveOnLeave
        form={form}
        save={values => updateDetails(values, initialValues)}
      />
      {isSurplusLines && (
        <section style={styles.form_section}>
          <FormSection name="sl_broker">
            <h3>Surplus Lines Broker Data</h3>
            <Row>
              <Col md={8}>
                <Field
                  name="license_number"
                  type="text"
                  component={InputControl}
                  label="Surplus Lines License Number"
                  required={sl_broker.is_required.license_number}
                  validate={sl_broker.errors.license_number}
                />
              </Col>
            </Row>
            {isNJInsured ? <Row>
              <Col md={8}>
                <Field
                  name="nj_transaction_number"
                  type="text"
                  component={InputControl}
                  label="NJ Transaction Number"
                  required={sl_broker.is_required.nj_transaction_number(
                    state
                  )}
                  validate={sl_broker.errors.nj_transaction_number}
                  text="For New Jersey transactions only"
                />
              </Col>
            </Row> : null}
            <h4>Surplus Lines Broker contact details</h4>
            <Row>
              <Col md={8}>
                <Field
                  name="name"
                  type="text"
                  component={InputControl}
                  label="Name"
                  required={sl_broker.is_required.name}
                  validate={sl_broker.errors.name}
                />
              </Col>
            </Row>
            <Row>
              <Col md={8}>
                <AddressFields
                  is_required={sl_broker.is_required.address}
                  validate={sl_broker.errors.address}
                />
              </Col>
            </Row>
          </FormSection>
        </section>
      )}
      <section style={styles.form_section}>
        <FormSection name="broker">
          <h3>Additional Broker Details</h3>
          <Row>
            <Col md={4}>
              <Field
                name="commission"
                type="number"
                component={Commission}
                label="Broker commission"
                required={broker.is_required.commission}
                validate={broker.errors.commission}
              />
            </Col>
          </Row>
          <h4>Broker contact details</h4>
          <Row>
            <Col md={8}>
              <Field
                name="phone"
                type="tel"
                component={InputControl}
                label="Phone"
                required={broker.is_required.phone}
                validate={broker.errors.phone}
                text="Example: 555-555-1234 x123"
              />
            </Col>
          </Row>
          <Row>
            <Col md={8}>
              <AddressFields
                validate={broker.errors.address}
                is_required={broker.is_required.address}
              />
            </Col>
          </Row>
        </FormSection>
      </section>
      <Row>
        <Col md={8}>
          <Button variant="primary" type="submit" disabled={props.submitting}>
            {props.submitting ? 'Saving...' : submitButtonLabel}
          </Button>
          <Link to={prevPagePath} className="btn btn-link">
            Go Back
          </Link>
        </Col>
      </Row>
    </form>
  );
}

export default connect(
  state => ({ formValues: getFormValues('broker')(state) }),
)(
  reduxForm({
    form: 'broker',
    enableReinitialize: true,
  })(Brokers)
);
