import React from 'react';
import { useDropzone } from 'react-dropzone';
import styled from 'styled-components';
import upload from './assets/upload.svg';

const palette = {
  GRAY: '#bdbdbd',
  DARK_GRAY: '#888888',
  PRIMARY: '#428bca',
  PRIMARY_ACTIVE: '#0f40ab',
  GREEN_500: '#a5d6a7',
  RED_500: '#ef9a9a',
};

const getColor = props => {
  if (props.isDragAccept) {
    return palette.GRAY;
  }
  if (props.isDragReject) {
    return palette.GRAY;
  }
  if (props.isFocused) {
    return palette.DARK_GRAY;
  }
  return palette.GRAY;
};

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 20px 10px 20px;
  border-color: ${props => getColor(props)};
  border-style: dashed;
  background-color: none;
  background: url(${upload}) center 35px no-repeat;
  background-size: 75px 75px;
  text-align: center;
  color: #000000;
  outline: none;
  transition: border 0.24s ease-in-out;
  border-radius: 2px;
  width: 472px;
  height: 160px;
  font-size: 14px;
  justify-content: flex-end;

  button {
    color: ${palette.PRIMARY};

    &:hover,
    &:active {
      color: ${palette.PRIMARY_ACTIVE};
    }
  }
`;

function StyledDropzone({ children, onSelect, maxFiles, accept }) {
  const {
    getRootProps,
    getInputProps,
    isFocused,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    accept: accept,
    onDrop: files => {
      onSelect(files);
    },
    maxFiles: maxFiles,
  });

  return (
    <div className="container">
      <Container {...getRootProps({ isFocused, isDragAccept, isDragReject })}>
        <input {...getInputProps()} />
        <p>{children}</p>
      </Container>
    </div>
  );
}

export default StyledDropzone;
