import React from 'react';
import Alert from 'react-bootstrap/Alert';

const styles = {
  alert: {
    marginBottom: 0,
    marginTop: '20px',
  },
  header: {
    marginTop: 0,
  },
  text: {
    color: 'black',
  },
};

class EndorsementBookedBanner extends React.Component {
  state = {
    show: true,
  };

  render() {
    if (!this.state.show) return null;
    return (
      <Alert
        style={styles.alert}
        variant="success"
        onClose={() => this.setState({ show: false })}
      >
        <h3 style={styles.header}>Endorsement booked</h3>
        <p style={styles.text}>
          This endorsement has been received by the carrier.
        </p>
      </Alert>
    );
  }
}

export default EndorsementBookedBanner;
