import { gql } from '@apollo/client';

export const query = gql`
  query EndorsementOption($id: ID, $version: String) {
    option: documentByIdAndVersion(id: $id, version: $version) {
      id
      dynamic
      title
      valid_for
      version
      template_location {
        url
      }
    }
  }
`;
