import React from 'react';

import EditableRowActive from './EditableRowActive';
import EditableRowInactive from './EditableRowInactive';

class EditableRow extends React.Component {
  state = { editing: false };

  startEditing = () => {
    this.setState({ editing: true });
  };
  stopEditing = () => {
    this.setState({ editing: false });
  };

  render() {
    return this.state.editing ? (
      <EditableRowActive
        label={this.props.label}
        value={this.props.value}
        input={this.props.input}
        updateOptions={this.props.updateOptions}
        stopEditing={this.stopEditing}
      />
    ) : (
      <EditableRowInactive
        label={this.props.label}
        handleRemoveClick={this.props.unselect}
        startEditing={this.startEditing}
      />
    );
  }
}
export default EditableRow;
