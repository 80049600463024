import React from 'react';
import FormGroup from 'react-bootstrap/FormGroup';
import Form from 'react-bootstrap/Form';

import ControlContainer from '../../components/ControlContainer';
import Control from '../../components/Control';

const styles = {
  cell: {
    display: 'table-cell',
    minWidth: '175px',
    verticalAlign: 'top',
    padding: '10px 0',
  },
  helper: {
    fontSize: '0.875rem',
  },
  asterisk: { color: 'red', marginLeft: '0.125rem' },
};

const TableControl = ({ label, input, meta, required, ...props }) => {
  const validationClassName = !meta.touched
    ? null
    : meta.error ? 'has-error' : meta.warning ? 'has-warning' : null;
  return (
    <FormGroup style={{ display: 'table-row' }} className={validationClassName}>
      <Form.Label style={styles.cell} htmlFor={input.name}>
        {label}
        {required && <span style={styles.asterisk}>*</span>}
      </Form.Label>
      <div style={styles.cell}>
        <Control id={input.name} {...input} {...props} />
        {meta.touched &&
          meta.error && (
            <Form.Text style={styles.helper} muted>{meta.error}</Form.Text>
          )}
        {meta.touched &&
          meta.warning && (
            <Form.Text style={styles.helper} muted>{meta.warning}</Form.Text>
          )}
      </div>
    </FormGroup>
  );
};

export default ControlContainer(TableControl);
