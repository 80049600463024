class IsCondition {
  constructor(value) {
    this.value = value;
  }
  eval() {
    return this.value;
  }
}
export const build = (config, conditionsMap) => {
  if (typeof config === 'boolean') return new IsCondition(config);
  const Constructor = conditionsMap.get(config.operator);
  if (Constructor) {
    return new Constructor(config, conditionsMap);
  }
  throw new Error(`Condition not found: ${config.operator}`);
};
