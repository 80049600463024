import React from 'react';
import Form from 'react-bootstrap/Form';
import debounce from 'lodash/debounce';

export default class DebouncedInput extends React.Component {
  state = { value: '' };

  componentDidUpdate(prevProps) {
    if (this.props.value !== prevProps.value) {
      this.setState({ value: this.props.value });
    }
  }

  handleChange = e => {
    const value = e.target.value;
    this.setState({ value });
    this.onChange(value);
  };

  onChange = debounce(e => {
    return this.props.onChange(this.state.value);
  }, this.props.wait);

  render() {
    const { wait, ...props } = this.props;
    return (
      <Form.Control
        {...props}
        value={this.state.value}
        onChange={this.handleChange}
      />
    );
  }
}
