import React from 'react';
import Button from 'react-bootstrap/Button';

import { AsyncMutationWrapper } from '../../../common';

const styles = {
  error: {
    display: 'block',
  },
};

function ViewPriceButton({
  updateLimitsRetentions,
  values,
  getPremium,
  style,
}) {
  return (
    <AsyncMutationWrapper
      mutate={() => updateLimitsRetentions(values).then(getPremium)}
      render={({ error, loading, mutate }) => {
        return (
          <React.Fragment>
            <Button
              style={{ ...style }}
              variant="primary"
              disabled={loading}
              onClick={mutate}
            >
              View Price
            </Button>
            {error && (
              <span style={styles.error} className="text-danger">
                Error Viewing Price: {error.message}
              </span>
            )}
          </React.Fragment>
        );
      }}
    />
  );
}

export default ViewPriceButton;
