import { PrimitiveValue } from './implementations/primitive';
import { ReferenceValue } from './implementations/reference';
import { SumOperationValue } from './implementations/sum';
export const build = valueConfig => {
  if (valueConfig && typeof valueConfig === 'object') {
    if (valueConfig.hasOwnProperty('$ref')) {
      return new ReferenceValue(valueConfig.$ref);
    }
    if (valueConfig.hasOwnProperty('operator')) {
      if (valueConfig.operator === 'number.sum') {
        return new SumOperationValue(valueConfig);
      }
    }
  }
  return new PrimitiveValue(valueConfig);
};
