import React from 'react';
import Form from 'react-bootstrap/Form';

import Control from './Control';

const StackedRadioControl = props => {
  const { field, input } = props;
  return (
    <Control {...props}>
      {field.options.map(option => {
        const id = `${field.name}-${option.value}`;
        return (
          <Form.Check
            key={option.value}
            type='radio'
            {...input}
            id={id}
            value={option.value}
            checked={input.value === option.value}
            aria-label={option.label}
            label={option.label}
            className='mt-1 mb-1'
            isInvalid={((props.touched && props.warn )|| (props.touched && props.error)) ? true : false}
          />
        );
      })}
    </Control>
  );
};

export default StackedRadioControl;
