import React from 'react';
import FormControl from 'react-bootstrap/FormControl';

import Control from './Control';

const styles = {
  input: {
    textAlign: 'right',
    width: 'auto',
  },
};

const NumberInput = props => {
  const { input } = props;
  const value = input.value === '' ? NaN : Number(input.value);
  return (
    <Control {...props}>
      <div>
        <FormControl
          type="number"
          {...input}
          style={{ ...styles.input }}
          value={isNaN(value) ? '' : String(value)}
        />
      </div>
    </Control>
  );
};

export default NumberInput;
