import React from 'react';
import { reduxForm, Field } from 'redux-form';
import {
  FormGroup,
  InputGroup,
  FormControl,
  OverlayTrigger,
  Tooltip,
  Button,
  Form
} from 'react-bootstrap';
import numeral from 'numeral';

import { ModalDialog } from '../../../../common';

import ZScoreAlert from './ZScoreAlert';

import submit from './submit';

const styles = {
  cell: {
    display: 'table-cell',
    width: '150px',
    verticalAlign: 'middle',
    paddingTop: '5px',
    paddingBottom: '5px',
  },
  button: {
    marginTop: '20px',
  },
  error: {
    marginTop: '10px',
  },
};

const format = value => {
  if (value === '-') return value;
  if (!value) return value;
  return numeral(value).format('0,0');
};

const DisplayOnlyField = ({ value, ...props }) => {
  return <FieldRow disabled input={{ value }} {...props} tooltip="This value affects premium. Create a new quote and price option to update." />;
};

const FieldRow = ({ name, label, disabled, tooltip, input }) => {
  const input_el = (
    <InputGroup>
      <InputGroup.Text>$</InputGroup.Text>
      <FormControl
        style={{ textAlign: 'right' }}
        id={name}
        disabled={disabled}
        {...input}
      />
    </InputGroup>
  );
  const tooltip_el = (
    <Tooltip placement="right" id={`${name}-tooltip`}>
      {tooltip}
    </Tooltip>
  );
  return (
    <FormGroup style={{ display: 'table-row', fontSize: '0.8125rem' }}>
      <Form.Label htmlFor={name} style={styles.cell}>
        {label}
      </Form.Label>
      <div style={styles.cell}>
        {!tooltip ? (
          input_el
        ) : (
          <OverlayTrigger placement="right" overlay={tooltip_el}>
            {input_el}
          </OverlayTrigger>
        )}
      </div>
    </FormGroup>
  );
};

function BinderFinanciaslModal({
  binder,
  visible,
  hide,
  can_update_total_assets,
  handleSubmit,
  submitting,
  error,
}) {
  return (
    <ModalDialog.Standard
      show={visible}
      hide={hide}
      renderHeader={() => {
        return <h3>Check Z-Score</h3>;
      }}
      renderBody={() => {
        const current_financials =
          binder.rating.risk_analysis.financial.current;
        return (
          <div>
            <h4>Financials: Current Fiscal Year</h4>
            <form>
              <div style={{ display: 'table', tableLayout: 'fixed' }}>
                <DisplayOnlyField
                  name="financial.current.total_revenue"
                  label="Total revenue"
                  value={format(current_financials.total_revenue)}
                />
                <DisplayOnlyField
                  name="financial.current.current_assets"
                  label="Current assets"
                  value={format(current_financials.current_assets)}
                />
                <DisplayOnlyField
                  name="financial.current.current_liabilities"
                  label="Current liabilities"
                  value={format(current_financials.current_liabilities)}
                />
                {can_update_total_assets ? (
                  <Field
                    name="financial.current.total_assets"
                    label="Total assets"
                    component={FieldRow}
                    format={format}
                  />
                ) : (
                  <DisplayOnlyField
                    name="financial.current.total_assets"
                    label="Total assets"
                    value={format(current_financials.total_assets)}
                  />
                )}
                <Field
                  name="financial.current.total_liabilities"
                  label="Total liabilities"
                  component={FieldRow}
                  format={format}
                />
                <Field
                  name="financial.current.retained_earnings"
                  label="Retained earnings"
                  component={FieldRow}
                  format={format}
                />
                <Field
                  name="financial.current.operating_income"
                  label="Operating income"
                  component={FieldRow}
                  format={format}
                />
              </div>
              <Button
                style={styles.button}
                type="button"
                variant="primary"
                disabled={submitting}
                onClick={handleSubmit}
              >
                {submitting ? 'Calculating...' : 'Calculate Score'}
              </Button>
              {error && (
                <p style={styles.error} className="text-danger">
                  {error}
                </p>
              )}
            </form>
          </div>
        );
      }}
      renderFooter={() => {
        return <ZScoreAlert binder={binder} hideModal={hide} />;
      }}
    />
  );
}

export default reduxForm({
  form: 'binder_financials',
  onSubmit: submit,
})(BinderFinanciaslModal);
