import React from 'react';
import styled from 'styled-components';
import BaseSummaryCard from '../../BaseSummaryCard';

// All four borders are manually specified to work around a bug where
// Removing one border and then re-adding it won't register
const StyledBaseSummaryCard = styled(BaseSummaryCard)`
  border-top: 1px solid #979797;
  border-bottom: 1px solid #979797;
  border-left: 1px solid #979797;
  border-right: 1px solid #979797;
  display: flex;
  flex-wrap: wrap;
  padding: 20px;
  width: 100%;
`;

const StyledClearanceStatus = styled.p`
  font-size: 0.75rem;
  font-weight: bold;
`;

export default function AccountSummaryCard({ flex, style, account }) {
  return (
    <StyledBaseSummaryCard
      displayBadge={false}
      flex={flex}
      style={style}
      data={{
        ...account,
        submission_number: account.policy_number,
      }}
      renderClearanceStatus={() => (
        <StyledClearanceStatus className="text-danger">
          Existing Policy in-force
        </StyledClearanceStatus>
      )}
    />
  );
}
