import React from 'react';
import { Field, FormSection } from 'redux-form';

import {
  OldSubjectivitiesField,
  NewSubjectivitiesField,
} from '../../../../subjectivities';

export default function Subjectivities(props) {
  const {
    style,
    subjectivities,
    options,
    loading,
    error,
    updateTermsConditions,
    config: { oldType, newType },
    message,
  } = props;
  return (
    <section style={style}>
      <h3>Subjectivities</h3>
      <FormSection name="subjectivities">
        {message && <p>{message}</p>}
        <Field
          name={oldType}
          component={OldSubjectivitiesField}
          loading={loading}
          error={error}
          subjectivities={subjectivities[oldType]}
          afterChange={updateTermsConditions}
        />
        <Field
          name={newType}
          component={NewSubjectivitiesField}
          options={options}
          label="Add Subjectivity"
          placeholder="Create or Select an option."
          creatable
        />
      </FormSection>
    </section>
  );
}
