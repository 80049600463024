import { Condition } from './_base';
export class NumberMultipleOfCondition extends Condition {
  eval(value, values, context) {
    const $ = { value, values, context };
    const numerator = this.value(this.config.numerator).eval($);
    const divisor = this.value(this.config.divisor).eval($);
    return (
      typeof numerator !== 'number' ||
      typeof divisor !== 'number' ||
      numerator % divisor === 0
    );
  }
}
