import React from 'react';
import { Flag } from '../../../common/components/Flag';
import { Link } from 'react-router-dom';
import Button from 'react-bootstrap/Button';

import { StickySidebar } from '../../../layouts';
import { AsyncMutationWrapper } from '../../../common';
import { SubmissionSummaryCard } from '../../../submissions';
import {
  QUOTE_STATUSES,
  compareStatuses as compareQuoteStatuses,
} from '../../statuses';
import Overview from './Overview';
import QuotePreviewProgressBox from './QuotePreviewProgressBox';
import PremiumPreview from './PremiumPreview';
import InsuredBrokerDetails from './InsuredBrokerDetails';
import Subjectivities from './Subjectivities';
import Exclusions from './Exclusions';
import DecsPolFormsPreview from './DecsPolFormsPreview';
import Endorsements from './Endorsements';
import ExtendedReporting from './ExtendedReporting';
import ErrorAlert from './ErrorAlert';
import BrokerDetails from '../BrokerDetails';

const styles = {
  container: {
    paddingTop: '25px',
    paddingBottom: '100px',
  },
  header: {
    marginBottom: '37px',
  },
  summary_section: {
    borderTop: '1px solid #979797',
    marginTop: '35px',
    paddingTop: '20px'
  },
  sidebar: {
    margin: '25px auto auto',
    maxWidth: '240px',
  },
  submissions_summary: {
    marginTop: '25px',
  },
  button: {
    marginTop: '25px',
  },
  error: {
    marginTop: '25px',
  },
};

const sections = [
  {
    title: 'Overview',
    name: 'overview',
  },
  {
    title: 'Insured & Broker Details',
    name: 'insured_broker_details',
  },
  {
    title: 'Premium',
    name: 'premium',
  },
  {
    title: 'Broker Details',
    name: 'broker_details',
  },
  {
    title: 'Subjectivities',
    name: 'subjectivities',
  },
  {
    title: 'Exclusions',
    name: 'exclusions',
  },
  {
    title: 'Declarations & Policy Forms',
    name: 'decs_policy',
  },
  {
    title: 'Endorsements',
    name: 'endorsements',
  },
];

export default function QuotePreview({
  quote,
  ratings,
  submission,
  config,
  generateQuoteDocuments,
  updateBroker,
}) {
  const is_quoted =
    compareQuoteStatuses(quote.status, QUOTE_STATUSES.QUOTED) >= 0;
  return (
    <StickySidebar
      renderMain={() => {
        return (
          <div style={styles.container}>
            <Overview
              id="overview"
              quote={quote}
              ratings={ratings}
              config={config}
              style={styles.summary_section}
            />
            <InsuredBrokerDetails
              id="insured_broker_details"
              style={styles.summary_section}
              named_insured={quote.named_insured}
              broker={quote.broker}
            />
            <PremiumPreview
              id="premium"
              style={styles.summary_section}
              quote={quote}
              ratings={ratings}
              submission={submission}
            />
            <BrokerDetails
              id="broker_details"
              style={styles.summary_section}
              broker={quote.broker}
              updateBroker={updateBroker}
              is_locked={is_quoted}
            />
            <Subjectivities
              id="subjectivities"
              style={styles.summary_section}
              quote={quote}
            />
            <Exclusions
              id="exclusions"
              style={styles.summary_section}
              ratings={ratings}
              quote={quote}
            />
            <DecsPolFormsPreview
              id="decs_policy"
              style={styles.summary_section}
              policy_forms={quote.terms_conditions.policy_forms}
            />
            <Endorsements
              id="endorsements"
              style={styles.summary_section}
              quote={quote}
            />
            <ExtendedReporting
              id="erp"
              style={styles.summary_section}
              quote={quote}
            />
            <AsyncMutationWrapper
              mutate={generateQuoteDocuments}
              render={({ mutate, loading, error }) => {
                return (
                  <div>
                    {is_quoted ? (
                      <React.Fragment>
                        <Link
                          className="btn btn-primary"
                          style={styles.button}
                          to={`/quotes/${quote.id}/documents`}
                        >
                          Download Quote Letter
                        </Link>
                      </React.Fragment>
                    ) : (
                      <React.Fragment>
                        <Button
                          style={styles.button}
                          variant="primary"
                          disabled={loading || submission.declined}
                          onClick={() => mutate().catch(() => null)}
                        >
                          {loading ? 'Submitting...' : 'Generate Quote Letter'}
                        </Button>
                        <Link
                          className="btn btn-link"
                          style={styles.button}
                          to={`/quotes/${quote.id}/terms-conditions`}
                        >
                          Go Back
                        </Link>
                      </React.Fragment>
                    )}
                    {!!error &&
                      !loading && (
                        <ErrorAlert
                          style={styles.error}
                          quote={quote}
                          error={error}
                        />
                      )}
                  </div>
                );
              }}
            />
          </div>
        );
      }}
      renderAside={() => {
        return (
          <div style={styles.sidebar}>
            <QuotePreviewProgressBox
              sections={sections}
              style={{ marginBottom: '40px' }}
            />
            <Flag
              name="quote.preview.submission_summary_card"
              render={() => {
                return (
                  <SubmissionSummaryCard
                    style={styles.submissions_summary}
                    flex
                    submission={submission}
                  />
                );
              }}
            />
          </div>
        );
      }}
    />
  );
}
