import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import { ApiError, AsyncMutationWrapper } from '../../../../common';

const styles = {
  container: { border: '1px solid grey', padding: '20px 30px 20px 30px' },
  header: { marginTop: '10px', color:'#8d6708' },
  button: { marginTop: '25px' },
};

export default function IncompleteBinderBanner({ resumeBinder, style }) {
  return (
    <section style={{ ...styles.container, ...style }}>
      <Row>
        <Col sm={9}>
          <h3 style={styles.header}>
            Incomplete bind
          </h3>
          <p>
            A Binder has been started but has not been created for this policy.
          </p>
        </Col>
        <Col sm={3}>
          <AsyncMutationWrapper
            mutate={resumeBinder}
            render={({ error, loading, mutate }) => {
              return (
                <React.Fragment>
                  <Button
                    variant="primary"
                    style={styles.button}
                    onClick={mutate}
                    disabled={loading}
                  >
                    {loading ? 'Loading...' : 'Resume Bind'}
                  </Button>
                  {error && <ApiError error={error} />}
                </React.Fragment>
              );
            }}
          />
        </Col>
      </Row>
    </section>
  );
}
