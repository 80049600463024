import { SubmissionError } from 'redux-form';

export function submitPage(values, dispatch, props) {
  return props
    .updateDetails(values, props.initialValues)
    .then(res => {
      props.navigate(`/policies/${props.policy_id}/preview`);
      return res;
    })
    .catch(() => {
      throw new SubmissionError({
        _error: 'Something went wrong when updating terms and conditions.',
      });
    });
}
