import numeral from 'numeral';

const currency = {
  display: {
    format: value => {
      if (typeof value === 'number') {
        return numeral(value).format('$#,#');
      }
      return '';
    },
  },
  input: {
    parse: value => {
      if (value === '-') return '-';
      const result = numeral(value).value();
      if (!isNaN(result) && typeof result === 'number') return result;
      return null;
    },
    format: value => {
      if (value === '-') return '-';
      if (typeof value === 'number') {
        return numeral(value).format('#,#');
      }
      return '';
    },
  },
};

export default currency;
