import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const formatSubmissionNumber = submission_number =>
  submission_number.match(/\d{4}/g).join('-');

const styles = {
  summary_bar: {
    padding: '15px 0',
    backgroundColor: '#0d3e67',
  },
  summary_content: {
    display: 'inline-block',
    marginLeft: '1em',
    textTransform: 'uppercase',
    color: 'white',
    fontWeight: '500',
    letterSpacing: '0.0625rem'
  },
};

const SubmissionWorkspaceSummaryBar = ({ submission }) => (
  <div style={styles.summary_bar}>
    <Container fluid="lg">
      <Row>
        <Col md={{span: 10, offset: 1}}>
          <span style={{ color: 'white' }}>
            Submission {formatSubmissionNumber(submission.submission_number)}
          </span>
          <span style={styles.summary_content}>Submission Workspace</span>
        </Col>
      </Row>
    </Container>
  </div>
);

export default SubmissionWorkspaceSummaryBar;
