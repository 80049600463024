import { ItemInArrayCondition } from './array.in';
import { ArrayMinLengthCondition } from './array.minlength';
import { BooleanEqualsCondition } from './boolean.equals';
import { BooleanAndCondition } from './boolean.and';
import { BooleanNotCondition } from './boolean.not';
import { BooleanOrCondition } from './boolean.or';
import { DateExistsCondition } from './date.exists';
import { DateGtCondition } from './date.gt';
import { DateGteCondition } from './date.gte';
import { NumberEqualsCondition } from './number.equals';
import { NumberGteCondition } from './number.gte';
import { NumberLtCondition } from './number.lt';
import { NumberLteCondition } from './number.lte';
import { NumberMultipleOfCondition } from './number.multipleof';
import { StringEqualsCondition } from './string.equals';
import { StringStartsWithCondition } from './string.startswith';
export const addDefaultConditions = validator => {
  validator.addCondition('array.in', ItemInArrayCondition);
  validator.addCondition('array.minlength', ArrayMinLengthCondition);
  validator.addCondition('boolean.equals', BooleanEqualsCondition);
  validator.addCondition('boolean.and', BooleanAndCondition);
  validator.addCondition('boolean.not', BooleanNotCondition);
  validator.addCondition('boolean.or', BooleanOrCondition);
  validator.addCondition('date.exists', DateExistsCondition);
  validator.addCondition('date.gt', DateGtCondition);
  validator.addCondition('date.gte', DateGteCondition);
  validator.addCondition('number.equals', NumberEqualsCondition);
  validator.addCondition('number.gte', NumberGteCondition);
  validator.addCondition('number.lt', NumberLtCondition);
  validator.addCondition('number.lte', NumberLteCondition);
  validator.addCondition('number.multipleof', NumberMultipleOfCondition);
  validator.addCondition('string.equals', StringEqualsCondition);
  validator.addCondition('string.startsWith', StringStartsWithCondition);
  return validator;
};
