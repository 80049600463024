import { selectors } from '../redux';

import {
  CREATE_SUBMISSION_REQUEST,
  CREATE_SUBMISSION_SUCCESS,
  CREATE_RENEWAL_SUBMISSION_REQUEST,
  CREATE_RENEWAL_SUBMISSION_SUCCESS,
  DELETE_DECLINED_SUBMISSION_FAILURE,
  DELETE_DECLINED_SUBMISSION_REQUEST,
  GET_SUBMISSION_BY_ID_REQUEST,
  GET_SUBMISSION_BY_ID_SUCCESS,
  GET_SUBMISSION_BY_RATING_ID_REQUEST,
  GET_SUBMISSION_BY_RATING_ID_SUCCESS,
  SEARCH_SUBMISSIONS_REQUEST,
  SEARCH_SUBMISSIONS_SUCCESS,
  MANUALLY_CLEAR_SUBMISSION_REQUEST,
  MANUALLY_CLEAR_SUBMISSION_SUCCESS,
  CONFIRM_SUBMISSION_CLEARANCE_REQUEST,
  GET_SUBMISSION_BY_QUOTE_ID_REQUEST,
  GET_SUBMISSION_BY_QUOTE_ID_SUCCESS,
  GET_SUBMISSION_BY_BINDER_ID_REQUEST,
  GET_SUBMISSION_BY_BINDER_ID_SUCCESS,
  GET_SUBMISSION_BY_POLICY_ID_REQUEST,
  GET_SUBMISSION_BY_POLICY_ID_SUCCESS,
  POST_DECLINE_SUBMISSION_FAILURE,
  POST_DECLINE_SUBMISSION_REQUEST,
  POST_DECLINE_SUBMISSION_SUCCESS,
  DELETE_DECLINED_SUBMISSION_SUCCESS,
} from './constants';

export function get(submission_id) {
  return (dispatch, getState, { api }) => {
    const state = getState();
    const submission = selectors.submissionById(state, submission_id);
    if (submission) {
      return Promise.resolve({
        submission,
      });
    }
    dispatch({
      type: GET_SUBMISSION_BY_ID_REQUEST,
    });
    return api.submissions.get(submission_id).then(response => {
      const { submission } = response;
      dispatch({
        type: GET_SUBMISSION_BY_ID_SUCCESS,
        payload: {
          submission,
        },
      });
      return response;
    });
  };
}

export function getByRating(rating_id) {
  return (dispatch, getState, { api }) => {
    const state = getState();
    const submission = selectors.submissionByRating(state, rating_id);
    if (submission) {
      return Promise.resolve({
        submission,
      });
    }
    dispatch({
      type: GET_SUBMISSION_BY_RATING_ID_REQUEST,
    });
    return api.submissions.getByRating(rating_id).then(response => {
      const { submission } = response;
      dispatch({
        type: GET_SUBMISSION_BY_RATING_ID_SUCCESS,
        payload: {
          rating_id,
          submission,
        },
      });
      return response;
    });
  };
}

export function search({ term, limit, offset }) {
  return (dispatch, getState, { api }) => {
    dispatch({
      type: SEARCH_SUBMISSIONS_REQUEST,
    });
    return api.submissions.search({ term, limit, offset }).then(response => {
      const { submissions } = response;
      dispatch({
        type: SEARCH_SUBMISSIONS_SUCCESS,
        payload: {
          submissions,
        },
      });
      return response;
    });
  };
}

export function createAndClear(submission) {
  return (dispatch, getState, { api }) => {
    dispatch({
      type: CREATE_SUBMISSION_REQUEST,
    });
    return api.submissions.createAndClear(submission).then(response => {
      const { submission } = response;
      dispatch({
        type: CREATE_SUBMISSION_SUCCESS,
        payload: {
          submission,
        },
      });
      return response;
    });
  };
}

export function createAndClearRenewal(submission) {
  return async (dispatch, getState, { api }) => {
    dispatch({
      type: CREATE_RENEWAL_SUBMISSION_REQUEST,
    });
    const response = await api.submissions.createAndClearRenewal(submission);
    dispatch({
      type: CREATE_RENEWAL_SUBMISSION_SUCCESS,
      payload: {
        submission: response.submission,
        policy: response.policy,
      },
    });
    return response;
  };
}

export function postDeclineSubmission({ comments, reasons, submission }) {
  return (dispatch, getState, { api, apolloClient }) => {
    dispatch({
      type: POST_DECLINE_SUBMISSION_REQUEST,
    });
    return api.submissions
      .postDeclineSubmission({
        comments,
        reasons,
        submission,
      })
      .then(async response => {
        await apolloClient.reFetchObservableQueries();
        return response;
      })
      .then(response => {
        dispatch({
          type: POST_DECLINE_SUBMISSION_SUCCESS,
          payload: {
            submission: response.submission,
          },
        });
        // note: declination submit.js does nothing with this succes response
        return response;
      })
      .catch(error => {
        dispatch({
          type: POST_DECLINE_SUBMISSION_FAILURE,
          payload: {
            submission,
            error,
          },
        });
        return Promise.reject(error);
      });
  };
}
export function deleteDeclinedSubmission({ submission }) {
  return (dispatch, getState, { api, apolloClient }) => {
    dispatch({
      type: DELETE_DECLINED_SUBMISSION_REQUEST,
    });
    return api.submissions
      .deleteDeclinedSubmission({
        submission,
      })
      .then(async response => {
        await apolloClient.reFetchObservableQueries();
        return response;
      })
      .then(response => {
        dispatch({
          type: DELETE_DECLINED_SUBMISSION_SUCCESS,
          payload: {
            submission: response.submission,
          },
        });
        // note: declination submit.js does nothing with this succes response
        return response;
      })
      .catch(error => {
        dispatch({
          type: DELETE_DECLINED_SUBMISSION_FAILURE,
          payload: {
            submission,
            error,
          },
        });
        return Promise.reject(error);
      });
  };
}
export function manuallyClear({ submission_id, reason }) {
  return (dispatch, getState, { api }) => {
    dispatch({
      type: MANUALLY_CLEAR_SUBMISSION_REQUEST,
    });
    return api.submissions
      .manuallyClear({ submission_id, reason })
      .then(response => {
        const { submission } = response;
        dispatch({
          type: MANUALLY_CLEAR_SUBMISSION_SUCCESS,
          payload: {
            submission,
          },
        });
        return response;
      });
  };
}

export function confirmClearance(submission_id) {
  return (dispatch, getState, { api }) => {
    dispatch({
      type: CONFIRM_SUBMISSION_CLEARANCE_REQUEST,
    });
    return api.submissions.confirmClearance(submission_id);
  };
}

export function getByQuote(quote_id) {
  return (dispatch, getState, { api }) => {
    const state = getState();
    const submission = selectors.submissionByQuote(state, quote_id);
    if (submission) {
      return Promise.resolve({
        submission,
      });
    }
    dispatch({
      type: GET_SUBMISSION_BY_QUOTE_ID_REQUEST,
    });
    return api.submissions.getByQuote(quote_id).then(response => {
      const { submission } = response;
      dispatch({
        type: GET_SUBMISSION_BY_QUOTE_ID_SUCCESS,
        payload: {
          submission,
        },
      });
      return response;
    });
  };
}

export function getByBinder(binder_id) {
  return (dispatch, getState, { api }) => {
    const state = getState();
    const submission = selectors.submissionByBinder(state, binder_id);
    if (submission) {
      return Promise.resolve({
        submission,
      });
    }
    dispatch({
      type: GET_SUBMISSION_BY_BINDER_ID_REQUEST,
    });
    return api.submissions.getByBinder(binder_id).then(response => {
      const { submission } = response;
      dispatch({
        type: GET_SUBMISSION_BY_BINDER_ID_SUCCESS,
        payload: {
          submission,
        },
      });
      return response;
    });
  };
}

export function getByPolicy(policy_id, options = {}) {
  const { cache = true } = options;
  return (dispatch, getState, { api }) => {
    const state = getState();
    const submission = selectors.submissionByPolicy(state, policy_id);
    if (cache && submission) {
      return Promise.resolve({
        submission,
      });
    }
    dispatch({
      type: GET_SUBMISSION_BY_POLICY_ID_REQUEST,
    });
    return api.submissions.getByPolicy(policy_id).then(response => {
      const { submission } = response;
      dispatch({
        type: GET_SUBMISSION_BY_POLICY_ID_SUCCESS,
        payload: {
          submission,
        },
      });
      return response;
    });
  };
}

export function getEligibleProducts(submission_id) {
  return async (dispatch, getState, { api }) => {
    const response = await api.submissions.getEligibleProducts(submission_id);
    return response;
  };
}
