import React from 'react';
import { Link } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';

import BasePriceOptionsSummaryCard from '../Base';
import DisabledStartBindButton from './DisabledStartBindButton';
import { permissions } from '../../../../quote';
import { ApiError, AsyncMutationWrapper } from '../../../../common';

import { SUBMISSION_STATUSES, compareStatuses } from '../../../../submissions';
const styles = {
  container: {
    border: '1px solid #979797',
    padding: '20px 20px 20px',
  },
  header: {
    float: 'left',
  },
  title: {
    fontSize: '0.875rem',
  },
  status: {
    fontSize: '0.75rem',
    marginLeft: '10px',
    textTransform: 'uppercase',
  },
  row: {
    marginLeft: '0',
    marginRight: '0',
  },
  inlineButton: {
    border: 'none',
    paddingTop: 0,
    paddingBottom: 0,
    textTransform: 'initial',
  },
};

const getRatingNumber = ({ quote, rating }) => {
  return (
    quote.ratings
      .filter(quoteRatingAssoc => !quoteRatingAssoc.deleted)
      .findIndex(quoteRatingAssoc => quoteRatingAssoc.rating_id === rating.id) +
    1
  );
};

export default function StartBinderPriceOptionsSummaryCard({
  rating,
  submission,
  quote,
  displayName,
  startBinder,
  actions,
}) {
  const can_start_bind = permissions.canStartBind({
    quote,
    rating,
    submission,
  });
  const defaultDisplayName = `Option ${getRatingNumber({ quote, rating })}`;
  return (
    <div style={styles.container}>
      <Row style={styles.row}>
        <Col>
          <BasePriceOptionsSummaryCard
            rating={rating}
            renderHeader={rating => {
              return (
                <div className="clearfix">
                  <div style={styles.header}>
                    <Link to={`/ratings/${rating.id}`} style={styles.title}>
                      {displayName || defaultDisplayName}
                    </Link>
                    <strong className="text-success" style={styles.status}>
                      Complete
                    </strong>
                  </div>
                  {!!actions.length &&
                    actions.map(action => (
                      <AsyncMutationWrapper
                        key={action.title}
                        mutate={action.onSelect}
                        render={({ mutate, loading }) => (
                          <Button
                            variant="link"
                            style={styles.inlineButton}
                            disabled={action.disabled || loading}
                            onClick={mutate}
                          >
                            {action.title}
                          </Button>
                        )}
                      />
                    ))}
                </div>
              );
            }}
            renderFooter={({ style }) => {
              if (!can_start_bind || submission.declined) {
                const started_binding =
                  compareStatuses(
                    submission.status,
                    SUBMISSION_STATUSES.BINDING_IN_PROGRESS
                  ) >= 0; /* submission status */
                return (
                  <DisabledStartBindButton
                    toolTipHoverText={
                      submission.declined
                        ? 'Cannot bind a declined submission'
                        : started_binding
                          ? 'A binder has already been started for this submission.'
                          : 'An effective date is needed before binding this Price Option. Requote to make changes.'
                    }
                    style={{ ...style }}
                  />
                );
              }
              return (
                <AsyncMutationWrapper
                  mutate={startBinder}
                  render={({ error, loading, mutate }) => {
                    return (
                      <React.Fragment>
                        <Button
                          type="submit"
                          variant="primary"
                          style={{ ...style }}
                          onClick={mutate}
                          disabled={loading}
                        >
                          {loading ? 'Loading...' : 'Start Bind'}
                        </Button>
                        {error && <ApiError error={error} />}
                      </React.Fragment>
                    );
                  }}
                />
              );
            }}
          />
        </Col>
      </Row>
    </div>
  );
}
