import React from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import InsuredSummarySection from './InsuredSummarySection';
import BrokerSummarySection from './BrokerSummarySection';

const styles = {
  flex_detail_container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  flex_detail_section: {
    flex: '1 1 50%',
    minWidth: '12em',
  },
};

const StandardDetailSection = ({ children, ...props }) => (
  <Col {...props} xs={12} md={6}>
    {children}
  </Col>
);

const FlexDetailContainer = ({ children, ...props }) => (
  <section {...props} style={styles.flex_detail_container}>
    {children}
  </section>
);

const FlexDetailSection = ({ children, ...props }) => (
  <section {...props} style={styles.flex_detail_section}>
    {children}
  </section>
);

export default function InsuredBrokerSummarySection({ submission, flex }) {
  const DetailContainer = flex ? FlexDetailContainer : Row;
  const DetailSection = flex ? FlexDetailSection : StandardDetailSection;
  return (
    <DetailContainer>
      <DetailSection>
        <InsuredSummarySection named_insured={submission.named_insured} />
      </DetailSection>
      <DetailSection>
        <BrokerSummarySection broker={submission.broker} />
      </DetailSection>
    </DetailContainer>
  );
}
