import React from 'react';

const styles = {
  section_header: {
    fontSize: '1.25rem',
  },
  list: {
    fontSize: '0.875rem',
    lineHeight: '1.5',
    listStyle: 'none',
    paddingLeft: '0',
  },
  list_item: {
    marginTop: '10px',
  },
};

export default function DecsPolForms({ policy_forms, ...props }) {
  return (
    <section {...props}>
      <h3 style={styles.section_header}>Declarations & Policy Forms</h3>
      <ul style={styles.list}>
        {policy_forms.map(form => {
          return (
            <li key={`${form.id}--${form.version}`} style={styles.list_item}>
              {form.title}
            </li>
          );
        })}
      </ul>
    </section>
  );
}
