import React from 'react';
import { Link } from 'react-router-dom';

import { DownloadDocuments } from '../../../common';
import PolicyDescription from '../PolicyDescription';

import 'spin.js/spin.css';

const styles = {
  link: {
    marginTop: '30px',
  },
};

export default function DownloadBinderDocuments({
  getBinderDocuments,
  binder,
  binder: { documents },
}) {
  return (
    <DownloadDocuments.Main
      fetch={getBinderDocuments}
      done={documents}
    >
      <DownloadDocuments.Header>Binder download</DownloadDocuments.Header>
      {!documents ? (
        <DownloadDocuments.InProgressDescription />
      ) : (
        <PolicyDescription policy_number={binder.policy_number}>
          The policy is bound!
        </PolicyDescription>
      )}
      <div style={{ marginTop: '30px' }}>
        <DownloadDocuments.Section
          loading={!documents}
          renderHeader={() => (
            <DownloadDocuments.SectionHeader>
              Bind Letter
            </DownloadDocuments.SectionHeader>
          )}
          renderBody={() => (
            <DownloadDocuments.Button url={documents.url}>
              Download Binder
            </DownloadDocuments.Button>
          )}
        />
      </div>
      <Link className="btn btn-link" style={styles.link} to="/">
        Return to Dashboard
      </Link>
    </DownloadDocuments.Main>
  );
}
