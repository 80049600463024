import { build as buildCondition } from './../condition/builder';
import { build as buildValidation } from '../validation-rule/builder';
import {
  ObjectSchema,
  ArraySchema,
  NumberSchema,
  StringSchema,
  BooleanSchema,
} from './implementations';
const buildSchema = (config, conditionsMap) => {
  let required = [];
  if (config.required) {
    [].concat(config.required).forEach(condition => {
      required.push(buildCondition(condition, conditionsMap));
    });
  }
  const error =
    config.error &&
    config.error.map(error => buildValidation(error, conditionsMap));
  const warn =
    config.warn &&
    config.warn.map(error => buildValidation(error, conditionsMap));
  const info =
    config.info &&
    config.info.map(error => buildValidation(error, conditionsMap));
  if (config.type === 'object') {
    const properties = buildProperties(config, conditionsMap);
    return new ObjectSchema(properties, config, required, error, warn, info);
  } else if (config.type === 'array') {
    const items = buildItems(config, conditionsMap);
    return new ArraySchema(items, config, required, error, warn, info);
  } else if (config.type === 'number') {
    return new NumberSchema(config, required, error, warn, info);
  } else if (config.type === 'string') {
    return new StringSchema(config, required, error, warn, info);
  } else if (config.type === 'boolean') {
    return new BooleanSchema(config, required, error, warn, info);
  }
  throw new Error(`Could not find matching schema for type ${config.type}`);
};
const buildItems = (config, conditionsMap) => {
  return buildSchema(config.items, conditionsMap);
};
const buildProperties = (config, conditionsMap) => {
  const properties = {};
  for (const property in config.properties) {
    properties[property] = buildSchema(
      config.properties[property],
      conditionsMap
    );
  }
  return properties;
};
export { buildSchema as build };
