import React from 'react';
import PropTypes from 'prop-types';
import Button from 'react-bootstrap/Button';

import { ModalDialog, Toggleable } from '../../../../common';

import CreateBlankQuote from './CreateBlankQuoteAction';
import CreatePrepopulatedQuote from './CreatePrepopulatedQuoteAction';

const Modal = ({ submission, navigate, ...props }) => {
  return (
    <CreateBlankQuote
      submission={submission}
      render={createBlankQuote => {
        return (
          <CreatePrepopulatedQuote
            submission={submission}
            render={createPrepopulatedQuote => {
              const disabled =
                createBlankQuote.loading || createPrepopulatedQuote.loading;
              return (
                <ModalDialog.Standard
                  {...props}
                  renderHeader={() => <h3>Quick Renewal?</h3>}
                  renderBody={() => (
                    <section>
                      <p>
                        A Quick Renewal will copy the rating and coverage
                        options from the expiring policy (at inception).
                      </p>
                      <p>
                        You may need to update the Quote and Rating Worksheet to
                        reflect any <strong>changes to the risk</strong> or{' '}
                        <strong>endorsement activity</strong>.
                      </p>
                    </section>
                  )}
                  renderFooter={() => {
                    return (
                      <React.Fragment>
                        <Button
                          variant="primary"
                          disabled={disabled}
                          onClick={() =>
                            createPrepopulatedQuote
                              .mutate()
                              .then(({ rating }) => {
                                navigate(
                                  `/ratings/${rating.id}/rating-worksheet`,
                                  {state: {
                                    fromExpiringPolicy: true,
                                  }}
                                );
                              })
                          }
                        >
                          Yes, Copy Details
                        </Button>
                        <Button
                          variant="link"
                          disabled={disabled}
                          onClick={() =>
                            createBlankQuote.mutate().then(({ quote }) => {
                              navigate(`/quotes/${quote.id}`);
                            })
                          }
                        >
                          No, Start Blank Quote
                        </Button>
                      </React.Fragment>
                    );
                  }}
                />
              );
            }}
          />
        );
      }}
    />
  );
};

export default function Renewal({ submission, navigate, disabled }) {
  return (
    <Toggleable
      render={({ visible, show, hide }) => {
        return (
          <React.Fragment>
            <Button
              variant="primary"
              disabled={disabled}
              onClick={() => show()}
            >
              Start Quote
            </Button>
            <Modal
              show={visible}
              hide={hide}
              submission={submission}
              navigate={navigate}
            />
          </React.Fragment>
        );
      }}
    />
  );
}

Renewal.propTypes = {
  disabled: PropTypes.bool,
};

Renewal.defaultProps = {
  disabled: false,
};
