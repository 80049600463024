import React from 'react';

import { StartBinderPriceOptionSummaryCard as PriceOptionSummaryCard } from '../../../../../rating';

const styles = {
  summary_card: {
    margin: '0 0 20px',
  },
  section_header: {
    fontSize: '1.25rem',
    marginBottom: '10px',
  },
};

const getRatingNumber = ({ quote, rating }) => {
  return (
    quote.ratings
      .filter(quoteRatingAssoc => !quoteRatingAssoc.deleted)
      .findIndex(quoteRatingAssoc => quoteRatingAssoc.id === rating.id) + 1
  );
};

export default function PriceOptionsSummarySection({
  style,
  quote,
  ratings,
  submission,
}) {
  return (
    <section style={style}>
      <h3 style={styles.section_header}>Premium</h3>
      {ratings.map(rating => (
        <PriceOptionSummaryCard
          key={rating.id}
          rating={rating}
          quote={quote}
          submission={submission}
          displayName={`Option ${getRatingNumber({ quote, rating })}`}
          style={styles.summary_card}
          actions={[
            {
              title: 'Edit',
              disabled: true,
            },
            {
              title: 'Copy',
              disabled: true,
            },
          ]}
        />
      ))}
    </section>
  );
}
