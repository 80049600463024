import React from 'react';
import Button from 'react-bootstrap/Button';

const styles = {
  labelCell: {
    display: 'table-cell',
    width: '100%',
    verticalAlign: 'middle',
  },
  cell: {
    display: 'table-cell',
    verticalAlign: 'middle',
  },
};

function UneditableRow(props) {
  return (
    <React.Fragment>
      <div style={styles.labelCell}>{props.label}</div>
      <div style={styles.cell}>
        <Button type="button" onClick={props.handleRemoveClick} variant="link">
          Remove
        </Button>
      </div>
    </React.Fragment>
  );
}

export default UneditableRow;
