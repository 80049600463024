import React from 'react';
import { connect } from 'react-redux';
import FormGroup from 'react-bootstrap/FormGroup';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';

import { AsyncQueryWrapper } from '../../../../common';

import { getEligibleProducts } from '../../../actions';
import { actions as QuoteActions } from '../../../../quote';

class StartNewBusinessQuoteButton extends React.Component {
  state = {
    selectedProduct: null,
    loading: false,
    submitted: false,
    error: null,
  };

  submit = async () => {
    try {
      let error = null;
      this.setState({ loading: true, submitted: true });
      if (!this.state.selectedProduct) {
        error = 'Choose a Paper/Carrier before proceeding.';
      } else if (this.props.submission.clearance.status === 'FAILED') {
        error =
          'This submission has not been cleared. You must review matches and override clearance before Quoting.';
      } else if (!!this.props.submission.declined) {
        error = 'This submission has been declined and cannot be Quoted.';
      }
      if (error) return this.setState({ error, loading: false });
      const { quote } = await this.props.createQuote(
        this.props.submission,
        this.state.selectedProduct
      );
      this.props.navigate(`/quotes/${quote.id}`);
    } catch (e) {
      console.error(e);
      this.setState({
        loading: false,
        error:
          'Something went wrong. Please try again. If this issue persists, please contact support.',
      });
    }
  };

  render() {
    const props = this.props;
    return (
      <AsyncQueryWrapper
        load={() => props.getEligibleProducts()}
        render={({ loading, data }) => {
          if (loading) return <p>Loading eligible products...</p>;
          return (
            <div>
              <FormGroup>
                <h4 style={{ fontSize: '0.875rem' }}>Paper and Carrier *</h4>
                {data.products.map(product => {
                  return (
                    <Form.Check
                      key={product.id}
                      type='radio'
                      checked={this.state.selectedProduct === product.id}
                      onChange={e => {
                        this.setState({ selectedProduct: product.id });
                      }}
                      label={product.short_name}
                      className="mt-2 mb-2"
                    />
                  );
                })}
              </FormGroup>
              <Button
                variant="primary"
                disabled={this.state.loading}
                onClick={this.submit}
              >
                {props.children}
              </Button>
              {this.state.error && (
                <p className="text-danger" style={{ marginTop: '5px' }}>
                  {this.state.error}
                </p>
              )}
            </div>
          );
        }}
      />
    );
  }
}

export default connect(
  null,
  (dispatch, props) => ({
    getEligibleProducts() {
      return dispatch(getEligibleProducts(props.submission.id));
    },
    createQuote(submission, productCode) {
      return dispatch(
        QuoteActions.createFromSubmission(submission.id, productCode)
      );
    },
  })
)(StartNewBusinessQuoteButton);
