export const resolve = (value, __typename) => {
  const typedef = typedefs[__typename];
  if (value == null) return null;
  if (Array.isArray(value)) {
    return value.map(v => resolve(v, __typename));
  }
  if (!typedef) return value;
  const result = { __typename, ...value };
  for (let key in typedef) {
    result[key] = resolve(value[key], typedef[key]);
  }
  return result;
};

const typedefs = {
  Submission: {
    id: null,
    status: null,
  },
  User: {
    id: null,
    group: null,
  },
  Clearance: {
    submission_id: null,
    status: null,
    timestamp: null,
  },
  Rating: {
    id: null,
    quote_id: null,
    submission_id: null,
    status: null,
    deleted: null,
    risk_analysis: 'RiskAnalysis',
    limits_retentions: 'LimitsRetentions',
    premium: 'Premium',
  },
  RiskAnalysis: {
    selected_coverages: null,
    loss_history: null,
    sic_code: null,
    effective_date: null,
    standard_policy_period: null,
    expiration_date: null,
    employee_count: 'EmployeeCount',
    financial: 'RiskAnalysisFinancial',
    company_history: 'CompanyHistory',
    coverage_specific: 'RiskAnalysisCoverageSpecific',
  },
  EmployeeCount: {
    full_time: null,
    other: null,
  },
  RiskAnalysisFinancial: {
    current: 'RiskAnalysisFinancialCurrent',
    previous: 'RiskAnalysisFinancialPrevious',
  },
  RiskAnalysisFinancialCurrent: {
    total_revenue: null,
    current_assets: null,
    total_assets: null,
    current_liabilities: null,
    total_liabilities: null,
    operating_income: null,
    net_income: null,
    cash_flow: null,
    retained_earnings: null,
  },
  RiskAnalysisFinancialPrevious: {
    total_revenue: null,
    net_income: null,
    cash_flow: null,
  },
  CompanyHistory: {
    years_in_business: null,
    mergers_acquisitions: null,
  },
  RiskAnalysisCoverageSpecific: {
    do: 'RiskAnalysisCoverageSpecificDO',
    epl: 'RiskAnalysisCoverageSpecificEPL',
    fid: 'RiskAnalysisCoverageSpecificFID',
  },
  RiskAnalysisCoverageSpecificDO: {
    bank_credit_compliance: null,
    ownership_structure: null,
    securities_offerings: null,
  },
  RiskAnalysisCoverageSpecificEPL: {
    employee_handbook: null,
    employee_turnover: null,
    hr_professional: null,
  },
  RiskAnalysisCoverageSpecificFID: {
    db_plan_funding_level: null,
    esop: null,
    plan_assets: null,
    plan_modification: null,
    plan_type: null,
  },
  LimitsRetentions: {
    type: null,
    coverages: 'LimitsRetentionsCoverages',
  },
  LimitsRetentionsCoverages: {
    do: 'LimitsRetentionsDO',
    epl: 'LimitsRetentionsEPL',
    fid: 'LimitsRetentionsFID',
  },
  LimitsRetentionsDO: {
    total_limit: null,
    limit_group: null,
    sublimit_shareholder_derivative_demands: null,
    sublimit_inquiry_costs: null,
    sublimit_employed_lawyers_claims: null,
    sublimit_non_indemnified_loss: null,
    custom_retentions: null,
    total_retention: null,
    subretention_company_reimbursement: null,
    subretention_company_liability: null,
  },
  LimitsRetentionsEPL: {
    total_limit: null,
    limit_group: null,
    sublimit_third_party: null,
    sublimit_sensitivity_training_costs: null,
    custom_retentions: null,
    total_retention: null,
    subretention_first_party: null,
    subretention_third_party: null,
  },
  LimitsRetentionsFID: {
    total_limit: null,
    limit_group: null,
    sublimit_hipaa: null,
    sublimit_voluntary_compliance: null,
    subretention_hipaa: null,
    subretention_voluntary_compliance: null,
  },
  Premium: {
    total: null,
    coverage_specific: 'CoverageSpecificPremium',
    minimum_premium: null,
    z_score: null,
  },
  CoverageSpecificPremium: {
    do: 'PremiumAmount',
    epl: 'PremiumAmount',
    fid: 'PremiumAmount',
  },
  PremiumAmount: {
    base: null,
    adjustment: null,
  },
  Quote: {
    status: null,
    expiration_date: null,
    quoted_at: null,
    broker: 'Broker',
    named_insured: 'NamedInsured',
    terms_conditions: 'QuoteTermsConditions',
    quote_letter: 'S3Document',
    specimen_documents: 'SpecimenDocuments',
  },
  Broker: {
    name: null,
    brokerage: null,
    email: null,
    phone: null,
    address: 'Address',
    commission: null,
  },
  Address: {
    street_line_1: null,
    street_line_2: null,
    city: null,
    state: null,
    zip: null,
  },
  NamedInsured: {
    name: null,
    address: 'Address',
  },
  QuoteTermsConditions: {
    subjectivities: 'Subjectivity',
    endorsements: 'InceptionEndorsement',
    policy_forms: 'PolicyForm',
    exclusions: 'Exclusions',
    erp: 'ERP',
  },
  Subjectivity: {
    value: null,
    label: null,
  },
  InceptionEndorsement: {
    value: null,
    version: null,
    data: null,
    supplementary_data: null,
    title: null,
    dynamic: null,
  },
  PolicyForm: {
    id: null,
    version: null,
    title: null,
  },
  Exclusions: {
    do: null,
    epl: null,
    fid: null,
  },
  ERP: {
    percentage: null,
    years: null,
  },
  S3Document: {
    location: 'S3Location',
  },
  S3Location: {
    key: null,
    bucket: null,
    url: null,
  },
  SpecimenDocuments: {
    zip: 'S3Document',
    documents: 'S3Document',
  },
  Binder: {
    id: null,
    submission_id: null,
    status: null,
    expiration_date: null,
    product_code: null,
    quote_id: null,
    rating: 'Rating',
    broker: 'Broker',
    sl_broker: 'SL_Broker',
    named_insured: 'NamedInsured',
    terms_conditions: 'BinderTermsConditions',
  },
  SL_Broker: {
    name: null,
    license_number: null,
    nj_transaction_number: null,
    address: 'Address',
  },
  BinderTermsConditions: {
    subjectivities: 'BinderSubjectivities',
    endorsements: 'InceptionEndorsement',
    policy_forms: 'PolicyForm',
    exclusions: 'Exclusions',
    erp: 'ERP',
  },
  BinderSubjectivities: {
    quote: 'Subjectivity',
    binder: 'Subjectivity',
  },
  Policy: {
    id: null,
    status: null,
    effective_date: null,
    expiration_date: null,
    product_code: null,
    policy_number: null,
    documents: 'S3Document',
    broker: 'Broker',
    sl_broker: 'SL_Broker',
    named_insured: 'NamedInsured',
    terms_conditions: 'PolicyTermsConditions',
    rating: 'Rating',
  },
  PolicyTermsConditions: {
    subjectivities: 'PolicySubjectivities',
    endorsements: 'InceptionEndorsement',
    policy_forms: 'PolicyForm',
    exclusions: 'Exclusions',
    erp: 'ERP',
  },
  PolicySubjectivities: {
    binder: 'Subjectivity',
    policy: 'Subjectivity',
  },
  DocumentTemplate: {
    id: null,
    dynamic: null,
    footer: null,
    location: 'S3Location',
    specimen_location: 'S3Location',
    template_location: 'S3Location',
    timestamp: null,
    title: null,
    type: null,
    valid_for: null,
    version: null,
    supplementary_data: null,
  },
};
