import React from 'react';
import { Link } from 'react-router-dom';

export default function ViewQuoteButton({ style, quote_id }) {
  return (
    <Link
      className="btn btn-primary"
      style={{ ...style }}
      to={`/quotes/${quote_id}`}
    >
      View Quote
    </Link>
  );
}
