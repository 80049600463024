import React from 'react';

const styles = {
  header:{
    color: '#8d6708'
  },
  subheader: {
    lineHeight: '1.5',
  },
  subtext: {
    fontSize: '0.875rem',
    opacity: '0.87',
  },
  email_icon: {
    fontSize: '2rem',
    marginRight: '10px',
    verticalAlign: 'middle',
  },
};

export default function InProgressDescription() {
  return (
    <div>
      <h3 style={styles.header}>Your documents are being prepared</h3>
      <p style={styles.subheader}>
        It can take up to sixty seconds to generate your documents.
      </p>
      <p style={styles.subtext}>
        <i style={styles.email_icon} className="bi bi-envelope"/>
        <span style={{ verticalAlign: 'middle' }}>
          Can't wait? We'll email when your download is ready.
        </span>
      </p>
    </div>
  );
}
