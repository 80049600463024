const toInt = val => parseInt(val, 10);

const isPositive = value => toInt(value) > 0;

export const isPositiveInt = value =>
  isPositive(value) && Number.isInteger(Number(value));

const is100orMore = value => value >= 100;

export const erp = {
  years: years => {
    if (!isPositiveInt(years)) return 'Must be a positive integer';
  },
  percentage: percentage => {
    if (!isPositiveInt(percentage)) return 'Must be a positive integer';
    if (!is100orMore(percentage)) return 'Must be at least 100';
  },
};
