import React from 'react';
import PropTypes from 'prop-types';
import { reduxForm } from 'redux-form';

import FieldContainer from './components/FieldContainer';
import * as defaultContainers from './defaults/containers';
import * as defaultControls from './defaults/controls';
import * as defaultValidators from './defaults/validators';
import * as defaultConditions from './defaults/conditions';
import mapSchemaToValidators from './mapSchemaToValidators';

export default class FormBuilder extends React.Component {
  static childContextTypes = {
    form: PropTypes.object.isRequired,
  };

  state = {
    containers: {
      ...defaultContainers,
      ...this.props.containers,
    },
    controls: {
      ...defaultControls,
      ...this.props.controls,
    },
    validators: {
      ...defaultValidators,
      ...this.props.validators,
    },
    conditions: {
      ...defaultConditions,
      ...this.props.conditions,
    },
  };

  getChildContext() {
    return {
      form: {
        name: this.props.schema.name,
        schema: this.props.schema,
        containers: this.state.containers,
        controls: this.state.controls,
        validators: this.state.validators,
        conditions: this.state.conditions,
        props: this.props,
      },
    };
  }

  validateErrors = mapSchemaToValidators(
    this.props.schema,
    'errors',
    this.state.validators
  );

  validateWarnings = mapSchemaToValidators(
    this.props.schema,
    'warnings',
    this.state.validators
  );

  render() {
    return (
      <FormContainer
        form={this.props.schema.name}
        validate={this.validateErrors}
        warn={this.validateWarnings}
        {...this.props}
      />
    );
  }
}

const FormComponent = props => {
  return (
    <form>
      {props.schema.fields.map(field => (
        <FieldContainer key={field.key || field.name} field={field} />
      ))}
    </form>
  );
};

const FormContainer = reduxForm()(FormComponent);
