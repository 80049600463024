import React from 'react';
import FormGroup from 'react-bootstrap/FormGroup';
import Form from 'react-bootstrap/Form';

import ControlContainer from '../../components/ControlContainer';
import Control from '../../components/Control';

const styles = {
  control: {
    width: 'auto',
  },
  helper: {
    fontSize: '0.875rem',
  },
  asterisk: { color: 'red', marginLeft: '0.125rem' },
};

const StandardControl = ({
  title,
  description,
  label,
  helper,
  input,
  meta,
  required,
  ...props
}) => {
  const validationClassName = !meta.touched
    ? null
    : meta.error ? 'has-error' : meta.warning ? 'has-warning' : null;
  return (
    <FormGroup>
      <h3>{title}</h3>
      {description && <p>{description}</p>}
      <div className={validationClassName}>
        {label && (
          <Form.Label htmlFor={input.name}>
            {label}
            {required && <span style={styles.asterisk}>*</span>}
          </Form.Label>
        )}
        <Control
          id={input.name}
          meta={meta}
          {...input}
          {...props}
          style={{ ...styles.control, ...props.style }}
        />
        {meta.touched &&
          meta.error && (
            <Form.Text style={styles.helper} muted>{meta.error}</Form.Text>
          )}
        {meta.touched &&
          meta.warning && (
            <Form.Text style={styles.helper} muted>{meta.warning}</Form.Text>
          )}
      </div>
      {helper && <Form.Text style={styles.helper} muted>{helper}</Form.Text>}
    </FormGroup>
  );
};

export default ControlContainer(StandardControl);
