import React from 'react';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';

import { Toggleable } from '../../../../common';

const styles = {
  alert: {
    fontSize: '0.8125rem',
  },
  info: {
    marginTop: '1em',
  },
  text: {
    color: 'black',
  },
  button: {
    fontSize: '0.75rem',
    paddingLeft: '0',
  },
  icon: {
    marginRight: '0.5em',
  },
};

function AdditionalInfo() {
  return (
    <Toggleable
      render={({ visible, show, hide }) => {
        if (visible) {
          return (
            <div style={styles.info}>
              <p style={styles.text}>
                To change the premium for this Price Option, return to the
                Submission Workspace and click "Create Copy" next to this Price
                Option.
              </p>
              <Button style={styles.button} variant="link" onClick={hide}>
                <i className="bi bi-dash-circle-fill"/>Show Less
              </Button>
            </div>
          );
        }
        return (
          <div style={styles.info}>
            <Button style={styles.button} variant="link" onClick={show}>
              <i className="bi bi-plus-circle-fill"/>How Do I Update
              Premium?
            </Button>
          </div>
        );
      }}
    />
  );
}

function LockedRatingAlert({ style }) {
  return (
    <Alert style={{ ...styles.alert, ...style }} variant="info">
      <i className="bi bi-lock-fill"/>
      <strong style={{ textTransform: 'uppercase' }}>Locked:</strong>{' '}
      <span style={styles.text}>
        A Quote Letter was already generated with this price. The rating
        information has been locked to provide an accurate reference.
      </span>
      <AdditionalInfo />
    </Alert>
  );
}

export default LockedRatingAlert;
