import React, { Component } from 'react';
import Button from 'react-bootstrap/Button';

import ClearanceOverrideReasons from './ClearanceOverrideReasons';
import { AsyncMutationWrapper } from '../../../../common';

const styles = {
  header: { fontSize: '1.5rem' },
  subheader: { fontSize: '0.875rem', fontWeight: 'bold' },
  text: { fontSize: '0.875rem' },
  error_text: { display: 'block' },
  reason_label: { 
    fontWeight: 'bold',
    margin: '0 0 15px 0'
  },
  button: {
    margin: '15px 0 0 0'
  }
};

const isManuallyCleared = submission => {
  return submission.clearance.status === 'MANUALLY_CLEARED';
};

const reasonsMap = {
  DIFFERENT_INSURED: {
    text: 'Match is not the same Insured as Submission',
    groups: new Set(['sgs', 'cdo']),
  },
  BOR_CHANGE: {
    text: 'Received Broker of Record change from Insured',
    groups: new Set(['sgs', 'cdo']),
  },
  ARGO_RENEWAL: {
    text: 'Insured is an Argo renewal Account',
    groups: new Set(['sgs']),
  },
  ARGO_APPROVED: {
    text: 'Approved by Argo to quote',
    groups: new Set(['cdo']),
  },
  OTHER: {
    text: 'Other',
    groups: new Set(['sgs', 'cdo']),
  },
};

const mapReasonToFullText = reason => {
  return reasonsMap[reason].text;
};

export default class ClearanceOverridePrompt extends Component {
  state = {
    reason: null,
  };

  handleChange = e => {
    this.setState({ reason: e.target.value });
  };

  render() {
    const { style, submission, manuallyClearSubmission } = this.props;

    return (
      <section style={style}>
        <h3 style={styles.header}>Manually Clear this Submission?</h3>
        <AsyncMutationWrapper
          mutate={() => manuallyClearSubmission(this.state.reason)}
          render={({ loading, error, mutate }) =>
            isManuallyCleared(submission) ? (
              <div>
                <p className="text-success" style={styles.subheader}>
                  Manually cleared.
                </p>
                <p style={styles.text}>
                  Reason: {mapReasonToFullText(submission.clearance.reason)}
                </p>
              </div>
            ) : (
              <div>
                <label style={styles.reason_label}>Reason</label>
                <ClearanceOverrideReasons
                  onChange={this.handleChange}
                  value={this.state.reason}
                  reasonsMap={reasonsMap}
                />
                {error && (
                  <p className="text-danger">
                    <span style={styles.error_text}>
                      Sorry, the robots missed your call.
                    </span>
                    <span style={styles.error_text}>
                      Please click the Override button again now.
                    </span>
                  </p>
                )}
                <Button
                  style={styles.button}
                  variant="primary"
                  disabled={loading || !this.state.reason}
                  onClick={mutate}
                >
                  Manually Clear Submission
                </Button>
              </div>
            )
          }
        />
      </section>
    );
  }
}
