import PropTypes from 'prop-types';
import React from 'react';

import declination_reasons from './declination_reasons';
import Select from './Select';
import { LabeledControl } from '../../../../common';

const declination_reasons_order = [
  'INCOMPLETE_APPLICATION',
  'OUTSIDE_GUIDELINES',
  'INDUSTRY',
  'EXCESSIVE_LOSS',
  'FINANCIALS',
  'PRICING_OR_RATING',
  'UNABLE_TO_MEET_TERMS',
  'NO_RESPONSE',
  'OTHER',
];

export default function Reasons({
  input,
  label,
  meta,
  multi,
  options,
  placeholder,
  required,
}) {
  return (
    <LabeledControl label={label} required={required} input={input} meta={meta}>
      <Select
        input={input}
        id={input.name}
        multi={multi}
        name={input.name}
        options={options}
        placeholder={placeholder}
      />
    </LabeledControl>
  );
}

Reasons.defaultProps = {
  options: declination_reasons_order.map(reason => ({
    label: declination_reasons[reason],
    value: reason,
  })),
  multi: false,
  required: false,
  placeholder: 'Please select the reasons',
};

Reasons.propTypes = {
  multi: PropTypes.bool,
  label: PropTypes.string.isRequired,
  // TODO: create custom validator to ensure key is UPPERCASE
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
};
