import { omit } from 'lodash';

import {
  GET_BINDER_BY_ID_SUCCESS,
  UPDATE_BINDER_TERMS_CONDITIONS_SUCCESS,
  CREATE_BINDER_SUCCESS,
  GET_BINDER_BY_SUBMISSION_SUCCESS,
  BIND_POLICY_SUCCESS,
  DELETE_BINDER_SUCCESS,
  UPDATE_BINDER_Z_SCORE_SUCCESS,
  UPDATE_BINDER_DETAILS_SUCCESS,
} from './constants';

const initial_state = {
  by_id: {},
};

export default function submissions(state = initial_state, action) {
  switch (action.type) {
    case GET_BINDER_BY_ID_SUCCESS:
    case CREATE_BINDER_SUCCESS:
    case GET_BINDER_BY_SUBMISSION_SUCCESS:
    case BIND_POLICY_SUCCESS:
    case UPDATE_BINDER_Z_SCORE_SUCCESS:
      return handlers.storeBinder(state, action);
    case UPDATE_BINDER_TERMS_CONDITIONS_SUCCESS:
      return handlers.updateTermsConditionsSuccess(state, action);
    case DELETE_BINDER_SUCCESS:
      return handlers.deleteBinderSuccess(state, action);
    case UPDATE_BINDER_DETAILS_SUCCESS:
      return handlers.updateDetailsSuccess(state, action);
    default:
      return state;
  }
}

const handlers = {
  storeBinder(state, { payload }) {
    return {
      ...state,
      by_id: {
        ...state.by_id,
        [payload.binder.id]: payload.binder,
      },
    };
  },
  updateTermsConditionsSuccess(state, { payload }) {
    const { binder_id, terms_conditions } = payload;
    return {
      ...state,
      by_id: {
        ...state.by_id,
        [binder_id]: {
          ...state.by_id[binder_id],
          terms_conditions,
        },
      },
    };
  },
  deleteBinderSuccess(state, { payload }) {
    return {
      ...state,
      by_id: omit(state.by_id, payload.binder_id),
    };
  },
  updateDetailsSuccess(
    state,
    {
      payload: { binder_id, broker, named_insured, sl_broker },
    }
  ) {
    const existingBinder = state.by_id[binder_id] || {};
    return {
      ...state,
      by_id: {
        ...state.by_id,
        [binder_id]: {
          ...existingBinder,
          broker: broker || existingBinder.broker,
          sl_broker: sl_broker || existingBinder.sl_broker,
          named_insured: named_insured || existingBinder.named_insured,
        },
      },
    };
  },
};
