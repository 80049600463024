import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import SubmissionSummaryCard from '../SubmissionSummaryCard';
import IncompleteBinderBanner from './IncompleteBinderBanner';
import { IssuePolicyBanner, DownloadPolicyBanner } from '../../../policies';
import { SearchQuotesBySubmission } from '../../../quote';
import { GetBinderBySubmission } from '../../../binder';
import * as permissions from '../../permissions';
import { SUBMISSION_STATUSES, compareStatuses } from '../../statuses';
import SubmissionWorkspaceSummaryBar from './SubmissionWorkspaceSummaryBar';

import WorkspaceHeader from './WorkspaceHeader';
import { StickySidebar } from '../../../layouts';

const styles = {
  banner: {
    marginTop: '30px',
  },
};

export default function SubmissionSummary({ submission }) {
  const binding_in_progress =
    submission.status === SUBMISSION_STATUSES.BINDING_IN_PROGRESS;
  const bound = submission.status === SUBMISSION_STATUSES.BOUND;
  const binder_exists =
    compareStatuses(submission.status, SUBMISSION_STATUSES.BOUND) >= 0;
  const issued = submission.status === SUBMISSION_STATUSES.ISSUED;
  const can_start_quote = permissions.canCreateQuote({ submission });
  return (
    <div>
      <div>
        <SubmissionWorkspaceSummaryBar submission={submission} />
      </div>
      <div style={{ backgroundColor: '#f0f0f0' }}>
        <Container fluid="lg">
          <Row>
            <Col md={{ span: 8, offset: 1 }}>
              <WorkspaceHeader submission={submission} />
            </Col>
          </Row>
        </Container>
      </div>
      <Container fluid="lg">
        <Row>
          {binding_in_progress && (
            <Col md={{ span: 10, offset: 1 }}>
              <IncompleteBinderBanner
                submission={submission}
                style={styles.banner}
              />
            </Col>
          )}
          {bound && (
            <Col md={{ span: 10, offset: 1 }}>
              <IssuePolicyBanner
                submission_id={submission.id}
                style={styles.banner}
              />
            </Col>
          )}
          {issued && (
            <Col md={{ span: 10, offset: 1 }}>
              <DownloadPolicyBanner
                submission_id={submission.id}
                style={styles.banner}
              />
            </Col>
          )}
          <Col md={12}>
            <StickySidebar
              renderMain={() => {
                return (
                  <div>
                    {binder_exists && (
                      <section style={{ marginTop: '40px' }}>
                        <header>
                          <h1>Policy documents</h1>
                        </header>
                        <main>
                          <GetBinderBySubmission
                            submission_id={submission.id}
                          />
                        </main>
                      </section>
                    )}
                    <section style={{ marginTop: '40px' }}>
                      <header>
                        <h1>Quotes</h1>
                      </header>
                      <main>
                        <SearchQuotesBySubmission
                          submission={submission}
                          can_start_quote={can_start_quote}
                        />
                      </main>
                    </section>
                  </div>
                );
              }}
              renderAside={() => {
                return (
                  <SubmissionSummaryCard
                    style={{ maxWidth: '240px', margin: 'auto' }}
                    flex
                    submission={submission}
                  />
                );
              }}
            />
          </Col>
        </Row>
      </Container>
    </div>
  );
}
