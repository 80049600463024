import { connect } from 'react-redux';
import { withRouter } from '../../../common/components/WithRouter';

import * as quotes from '../../actions';

import ViewQuoteContainer from './ViewQuoteContainer';

const mapDispatchToProps = (dispatch, props) => {
  return {
    updateBroker: (updatedBroker, oldBroker) => {
      return dispatch(
        quotes.updateQuote(
          props.match.params.quote_id,
          { broker: updatedBroker },
          { broker: oldBroker }
        )
      );
    },
  };
};

export default withRouter(
  connect(
    null,
    mapDispatchToProps
  )(ViewQuoteContainer)
);
