import { connect } from 'react-redux';
import { updateDetails } from '../../../actions';
import BrokerSection from './BrokerSection';

const mapDispatchToProps = (dispatch, props) => {
  const { binder_id } = props;
  return dispatch => ({
    updateBroker: (values, initialValues) => {
      return dispatch(
        updateDetails(binder_id, { broker: values }, { broker: initialValues })
      );
    },
  });
};

export default connect(
  null,
  mapDispatchToProps
)(BrokerSection);
