import { connect } from 'react-redux';
import { withRouter } from '../../../common/components/WithRouter';

import { generateQuoteDocuments } from '../../actions';

import QuotePreview from './QuotePreview';

const mapDispatchToProps = (dispatch, props) => dispatch => ({
  generateQuoteDocuments() {
    const { quote, ratings } = props;
    return dispatch(generateQuoteDocuments(quote.id, { ratings })).then(() => {
      return props.navigate(`/quotes/${quote.id}/documents`);
    });
  },
});

export default withRouter(connect(null, mapDispatchToProps)(QuotePreview));
