const QUOTE_STATUSES = {
  UNDEFINED: undefined,
  NULL: null,
  QUOTED: 'QUOTED',
};

const status_to_number = new Map();
status_to_number.set(QUOTE_STATUSES.UNDEFINED, 0);
status_to_number.set(QUOTE_STATUSES.NULL, 0);
status_to_number.set(QUOTE_STATUSES.QUOTED, 1);

const numericStatus = status => {
  if (status_to_number.has(status)) return status_to_number.get(status);
  throw new Error(`${status} is not a valid quote status.`);
};

const compareStatuses = (status1, status2) => {
  const num1 = numericStatus(status1);
  const num2 = numericStatus(status2);
  if (num1 > num2) return 1;
  if (num1 < num2) return -1;
  return 0;
};

export { QUOTE_STATUSES, compareStatuses };
