import { connect } from 'react-redux';

import { selectors } from '../../../redux';

import { get as getSubmission } from '../../actions';

import ViewSubmissionContainer from './ViewSubmissionContainer';
import { withRouter } from '../../../common/components/WithRouter'

const mapStateToProps = (state, props) => {
  const { submission_id } = props.match.params;
  return state => {
    const submission = selectors.submissionById(state, submission_id);
    return {
      submission,
    };
  };
};

const mapDispatchToProps = (dispatch, props) => {
  const { submission_id } = props.match.params;
  return dispatch => ({
    getSubmission: () => dispatch(getSubmission(submission_id)),
  });
};

export default withRouter(
  connect(
    mapStateToProps, 
    mapDispatchToProps
  )(ViewSubmissionContainer)
);
