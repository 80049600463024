import url from 'url';
import { OktaAuth } from '@okta/okta-auth-js';
import { EventEmitter } from 'events';


export default class Auth extends OktaAuth {
  constructor(params) {
    const login_redirect_uri = url.format({
      protocol: params.location.protocol,
      host: params.location.host,
      pathname: '/login/success',
    });
    super({
      issuer: params.issuer,
      clientId: params.client_id,
      redirectUri: login_redirect_uri,
      scope: [
        'openid',
        'profile',
        'offline_access',
        'email',
        'address',
        'phone',
      ],
      pkce: params.pkce
    });
    this.location = params.location;
    this._emitter = new EventEmitter();
  }

  async init() {
    const current_url = this.location.origin + this.location.pathname;
    const callback_url = this.options.redirectUri;
    if (current_url !== callback_url) return Promise.resolve();
    return new Promise(resolve => {
      this._emitter.on('authenticated', () => resolve());
    });
  }

  handleLoginRedirect() {
    return super.handleLoginRedirect().then(res => {
      this._emitter.emit('authenticated');
      return res;
    });
  }

  async getCurrentUser() {
    const [access_token, id_token] = await Promise.all([
      this.getAccessToken(),
      this.getIdToken(),
    ]);
    if (!access_token || !id_token) return null;
    const claims = this._decodeToken(access_token);
    const user = this._decodeToken(id_token);
    return {
      id: user.sub,
      email: user.email,
      username: user.preferred_username,
      first_name: user.given_name,
      last_name: user.family_name,
      /**
       *  When this code runs in IE11, the user object doesn't have
       *  `given_name` and `family_name` for an unknown reason.
       *  On the other hand, `name` property is always present
       *  in both IE11 and Chrome, so we're saving it as `full_name`
       *  to use it in analytics.
       */
      full_name: user.name,
      group: claims.protector_group,
      scopes: claims.protector_scopes || [],
    };
  }

  _decodeToken(token) {
    const decoded = this.token.decode(token);
    return decoded.payload;
  }

  getAccessToken(){
    return Promise.resolve(super.getAccessToken());
  }

  getIdToken(){
    return Promise.resolve(super.getIdToken());
  }
}
