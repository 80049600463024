import { useLocation, useMatch, useNavigate } from 'react-router-dom';

export const withRouter = (Component) => {
    const ComponentWithRouterProps = (props) => {
        const pathPattern = props.pathPattern != null ? props.pathPattern : "";
        const location = useLocation();
        const match = useMatch(pathPattern);
        const navigate = useNavigate();

        let routerProps = {
            location: location,
            navigate: navigate
        };

        if(props.pathPattern != null){ routerProps.match = match; }

        return (
            <Component 
                {...props} 
                {...routerProps}
            />
        );
    };

    return ComponentWithRouterProps;
}
