import React, { Component } from 'react';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

import {
  AsyncMutationWrapper,
  InlineButton,
  ModalDialog,
} from '../../../../../common';
import PropTypes from 'prop-types';

const styles = {
  alert: {
    fontSize: '0.8125rem',
  },
  text: {
    color: 'black',
    marginTop: '10px',
    paddingLeft: '30px',
  },
  button: {
    fontSize: '0.75rem',
  },
  footer: {
    textAlign: 'left',
  },
};

class EffectiveDateAlert extends Component {
  state = {
    modalVisible: false,
  };
  hideModal = () => {
    this.setState({ modalVisible: false });
  };
  showModal = () => {
    this.setState({ modalVisible: true });
  };
  render() {
    const { style, copyQuote } = this.props;
    return (
      <React.Fragment>
        <ModalDialog.Standard
          show={this.state.modalVisible}
          hide={this.hideModal}
          renderHeader={() => <Modal.Title>Requote submission?</Modal.Title>}
          renderBody={() =>
            'Continue to edit your Locked Quote and Price Options.'
          }
          renderFooter={() => (
            <AsyncMutationWrapper
              mutate={() => {
                return copyQuote({ withRatings: true });
              }}
              render={({ loading, mutate, error }) => (
                <section style={styles.footer}>
                  <Button
                    style={styles.button}
                    disabled={loading}
                    onClick={mutate}
                    type="button"
                    variant="primary"
                  >
                    Requote
                  </Button>
                  <Button
                    style={styles.button}
                    disabled={loading}
                    type="button"
                    variant="link"
                    onClick={this.hideModal}
                  >
                    Cancel
                  </Button>
                </section>
              )}
            />
          )}
        />
        <Alert style={{ ...styles.alert, ...style }} variant="info">
          <strong>Effective Date Needed</strong>
          <ul style={styles.text}>
            <li>
              <InlineButton onClick={this.showModal}>Requote</InlineButton> to
              generate an updated quote letter.
            </li>
          </ul>
        </Alert>
      </React.Fragment>
    );
  }
}

EffectiveDateAlert.propTypes = {
  style: PropTypes.object,
  copyQuote: PropTypes.func.isRequired,
};

export default EffectiveDateAlert;
