import React from 'react';
import Form from 'react-bootstrap/Form';

import ComponentWrapper from '../ComponentWrapper';

const Helper = ({ children }) => {
  return <Form.Text style={{ fontSize: '0.875rem' }} muted>{children}</Form.Text>;
};

const Section = props => {
  const { form, field, hidden } = props;
  const { title, children } = field;
  if (hidden) return null;
  return (
    <section style={
        {
          borderTop: '3px solid black',
          paddingTop: '1rem',
          marginBottom: '2rem'
        }
      } id={field.id}>
      {title && <h2>{title}</h2>}
      {children &&
        children.map((child, i) => (
          <ComponentWrapper key={i} form={form} field={child} />
        ))}
      {field.helper && <Helper>{field.helper}</Helper>}
    </section>
  );
};

export default Section;
