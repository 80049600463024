import { Api as RatingsApi } from '../rating';
import { Api as SubmissionsApi } from '../submissions';
import { Api as QuotesApi } from '../quote';
import { Api as BindersApi } from '../binder';
import { Api as PoliciesApi } from '../policies';
import { Api as CommonApi } from '../common';
import { Api as EndorsementsApi } from '../endorsements';

export default function Api(fetch, auth) {
  return {
    // 'ratings' is receiving the 'fetch' object rather than a specifc
    // implentation, because we're still migrating the methods over to v2
    ratings: RatingsApi(fetch, auth),
    submissions: SubmissionsApi(fetch.v1, auth),
    quotes: QuotesApi(fetch.v2, auth),
    binders: BindersApi(fetch.v1, auth),
    policies: PoliciesApi(fetch.v1, auth),
    common: CommonApi(fetch.v1, auth),
    endorsements: EndorsementsApi(fetch.v2, auth),
  };
}
