import get from 'lodash/get';

export const mapFormValuesToLimitsRetentions = (form_values, { rating }) => {
  const selected_coverages = get(
    rating,
    'risk_analysis.selected_coverages',
    []
  );
  const coverages = {};
  selected_coverages.forEach(cov => {
    const coverage = get(form_values, `coverages.${cov}`);
    const limit_type = getCoverageLimitType(cov, form_values);
    const limit_group = getCoverageLimitGroup(cov, form_values);
    coverages[cov] = { ...coverage, limit_type, limit_group };
    if (!limit_group) delete coverages[cov].limit_group;
  });
  const limitsRetentions = { ...form_values, coverages };
  return limitsRetentions;
};

const getCoverageLimitType = (coverage, formValues) => {
  const globalType = get(formValues, 'type');
  if (globalType === 'shared') return 'shared';
  const limitGroup = get(formValues, `coverages.${coverage}.limit_group`);
  if (limitGroup !== 'separate') {
    return 'shared';
  }
  return 'separate';
};

const getCoverageLimitGroup = (coverage, formValues) => {
  const globalType = get(formValues, 'type');
  if (globalType !== 'custom') return null;
  const group = get(formValues, `coverages.${coverage}.limit_group`);
  if (group === 'separate') return null;
  return group;
};
