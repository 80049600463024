import {
  GET_QUOTE_BY_ID_SUCCESS,
  CREATE_QUOTE_SUCCESS,
  SEARCH_QUOTES_BY_SUBMISSION_SUCCESS,
  GENERATE_QUOTE_DOCUMENTS_SUCCESS,
  GET_QUOTE_BY_BINDER_ID_SUCCESS,
  CREATE_QUOTE_FROM_SUBMISSION_SUCCESS,
  CREATE_PREPOPULATED_QUOTE_FROM_SUBMISSION_SUCCESS,
  GET_QUOTES_BY_RATING_SUCCESS,
  CREATE_QUOTE_COPY_SUCCESS,
  ADD_RATING_TO_QUOTE_SUCCESS,
  REMOVE_RATING_FROM_QUOTE_SUCCESS,
  UPDATE_QUOTE_SUCCESS,
} from './constants';

import { constants as ratings } from '../rating';

const initial_state = {
  by_id: {},
};

export default function submissions(state = initial_state, action) {
  switch (action.type) {
    case GET_QUOTE_BY_ID_SUCCESS:
      return handlers.getQuoteSuccess(state, action);
    case CREATE_QUOTE_SUCCESS:
      return handlers.createQuoteSuccess(state, action);
    case SEARCH_QUOTES_BY_SUBMISSION_SUCCESS:
      return handlers.searchQuotesSuccess(state, action);
    case GET_QUOTE_BY_BINDER_ID_SUCCESS:
      return handlers.getQuoteByBinderSuccess(state, action);
    case GENERATE_QUOTE_DOCUMENTS_SUCCESS:
    case CREATE_QUOTE_FROM_SUBMISSION_SUCCESS:
    case CREATE_PREPOPULATED_QUOTE_FROM_SUBMISSION_SUCCESS:
    case CREATE_QUOTE_COPY_SUCCESS:
    case ADD_RATING_TO_QUOTE_SUCCESS:
    case REMOVE_RATING_FROM_QUOTE_SUCCESS:
    case ratings.CREATE_RATING_FROM_QUOTE_SUCCESS:
    case ratings.CREATE_RATING_COPY_SUCCESS:
      return handlers.storeQuote(state, action);
    case GET_QUOTES_BY_RATING_SUCCESS:
      return handlers.storeMultipleQuotes(state, action);
    case UPDATE_QUOTE_SUCCESS:
      return handlers.updateQuoteSuccess(state, action);
    default:
      return state;
  }
}

const handlers = {
  storeQuote(state, { payload }) {
    return {
      ...state,
      by_id: {
        ...state.by_id,
        [payload.quote.id]: payload.quote,
      },
    };
  },
  storeMultipleQuotes(state, { payload }) {
    const { quotes } = payload;
    return quotes.reduce(
      (state, quote) => ({
        ...state,
        by_id: {
          ...state.by_id,
          [quote.id]: quote,
        },
      }),
      state
    );
  },
  getQuoteSuccess(state, { payload }) {
    return {
      ...state,
      by_id: {
        ...state.by_id,
        [payload.quote.id]: payload.quote,
      },
    };
  },
  createQuoteSuccess(state, { payload }) {
    return {
      ...state,
      by_id: {
        ...state.by_id,
        [payload.quote.id]: payload.quote,
      },
    };
  },
  searchQuotesSuccess(state, { payload }) {
    return {
      ...state,
      by_id: payload.quotes.reduce(
        (by_id, quote) => {
          by_id[quote.id] = quote;
          return by_id;
        },
        { ...state.by_id }
      ),
    };
  },
  getQuoteByBinderSuccess(
    state,
    {
      payload: { binder_id, quote },
    }
  ) {
    return {
      ...state,
      by_id: {
        ...state.by_id,
        [quote.id]: quote,
      },
    };
  },
  updateQuoteSuccess(
    state,
    {
      payload: { quote },
    }
  ) {
    return {
      ...state,
      by_id: {
        ...state.by_id,
        [quote.id]: quote,
      },
    };
  },
};
