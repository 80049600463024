import { Component } from 'react';

const getObjectPaths = (currentItem, prefix = []) => {
  return Object.keys(currentItem).reduce((acc, currentKey) => {
    const nextItem = currentItem[currentKey];
    if (typeof nextItem === 'object') {
      return [...acc, ...getObjectPaths(nextItem, [...prefix, currentKey])];
    }
    return [...acc, [...prefix, currentKey]];
  }, []);
};

export default class PersistErrors extends Component {
  displayErrorsWarnings() {
    const paths = [
      ...getObjectPaths(this.props.getFormSyncErrors),
      ...getObjectPaths(this.props.getFormSyncWarnings),
    ];
    paths.forEach(path => {
      const pathString = path.join('.');
      this.props.touch(pathString);
    });
  }
  componentDidMount() {
    if (this.props.persistErrors) {
      // Delay reading of errors and warnings to ensure that they're in the
      // redux store.
      setImmediate(() => this.displayErrorsWarnings());
    }
  }

  render() {
    return this.props.children;
  }
}
