import { gql } from '@apollo/client';
import get from 'lodash/get';
import set from 'lodash/set';
import merge from 'lodash/merge';
import pick from 'lodash/pick';

import { fragments as ratingFragments } from '../rating/components/ViewRatingContainer/query';
import {
  query as ViewQuoteContainerQuery,
  fragments as quoteFragments,
} from '../quote/components/ViewQuoteContainer/query';
import { fragments as binderFragments } from '../binder/components/ViewBinderContainer/query';
import { fragments as policyFragments } from '../policies/components/ViewPolicyContainer/query';
import { query as MidTermEndorsementQuery } from '../endorsements/components/MidTermEndorsements/query-midterm-endorsement';

import * as rating from '../rating/constants';
import * as quote from '../quote/constants';
import * as binder from '../binder/constants';
import * as policies from '../policies/constants';
import * as endorsements from '../endorsements/constants';

import { resolve } from './sync.models';

const RestSubmissionFragment = gql`
  fragment RestSubmissionFragment on Submission {
    id
    status
  }
`;

const QuoteRatingsFragment = gql`
  fragment QuoteRatingsFragment on Quote {
    id
    ratings {
      id
      deleted
    }
  }
`;

const EndorsementConfigFragment = gql`
  fragment endorsement on DocumentTemplate {
    id
    version
    title
    dynamic
  }
`;

const receiveSubmission = (client, submission) => {
  submission = resolve(submission, 'Submission');
  client.writeFragment({
    id: `Submission:${submission.id}`,
    fragment: RestSubmissionFragment,
    data: submission,
  });
};

const receiveRating = (client, rating) => {
  rating = resolve(rating, 'Rating');
  client.writeFragment({
    id: `Rating:${rating.id}`,
    fragment: ratingFragments.rating,
    data: rating,
  });
};

const getQuote = (client, id) => {
  return client.readFragment({
    id: `Quote:${id}`,
    fragment: quoteFragments.quote,
  });
};

const receiveQuoteRatings = (client, quote) => {
  const ratings = quote.ratings.map(qr => ({
    __typename: 'Rating',
    id: qr.rating_id,
    deleted: qr.deleted || null,
  }));
  client.writeFragment({
    id: `Quote:${quote.id}`,
    fragment: QuoteRatingsFragment,
    data: {
      __typename: 'Quote',
      id: quote.id,
      ratings,
    },
  });
};

const receiveQuote = (client, quote) => {
  receiveQuoteRatings(client, quote);
  quote = resolve(quote, 'Quote');
  const endorsements = get(quote, 'terms_conditions.endorsements', []).map(
    e => ({
      ...e,
      ...getEndorsementConfig(client, e.value, e.version),
    })
  );
  set(quote, 'terms_conditions.endorsements', endorsements);
  client.writeFragment({
    id: `Quote:${quote.id}`,
    fragment: quoteFragments.quote,
    data: quote,
  });
};

const updateQuoteConfig = async (client, quote) => {
  await client.query({
    query: gql`
      ${quoteFragments.config}
      query QuoteConfig($id: ID) {
        quote: quoteById(id: $id) {
          id
          ...QuoteConfig
        }
      }
    `,
    variables: { id: quote.id },
    fetchPolicy: 'network-only',
  });
};

const getEndorsementConfig = (client, id, version) => {
  return client.readFragment({
    id: `DocumentTemplate::${id}::${version}`,
    fragment: EndorsementConfigFragment,
  });
};

const getBinder = (client, id) => {
  return client.readFragment({
    id: `Binder:${id}`,
    fragment: binderFragments.binder,
  });
};

const receiveBinder = (client, binder) => {
  binder = resolve(binder, 'Binder');
  const endorsements = get(binder, 'terms_conditions.endorsements', []).map(
    e => ({
      ...e,
      ...getEndorsementConfig(client, e.value, e.version),
    })
  );
  set(binder, 'terms_conditions.endorsements', endorsements);
  client.writeFragment({
    id: `Binder:${binder.id}`,
    fragment: binderFragments.binder,
    data: binder,
  });
};

const getPolicy = (client, id) => {
  return client.readFragment({
    id: `Policy:${id}`,
    fragment: policyFragments.policy,
  });
};

const receivePolicy = (client, policy) => {
  policy = resolve(policy, 'Policy');
  const endorsements = get(policy, 'terms_conditions.endorsements', []).map(
    e => ({
      ...e,
      ...getEndorsementConfig(client, e.value, e.version),
    })
  );
  set(policy, 'terms_conditions.endorsements', endorsements);
  client.writeFragment({
    id: `Policy:${policy.id}`,
    fragment: policyFragments.policy,
    data: policy,
  });
};

const handlers = {
  [rating.UPDATE_RISK_ANALYSIS_SUCCESS]: async function(client, action) {
    const { rating } = action.payload;
    receiveRating(client, rating);
  },
  [rating.UPDATE_LIMITS_RETENTIONS_SUCCESS]: async function(client, action) {
    const { rating } = action.payload;
    receiveRating(client, rating);
  },
  [rating.GET_PREMIUM_SUCCESS]: async function(client, action) {
    const { rating } = action.payload;
    receiveRating(client, rating);
  },
  [rating.UPDATE_PREMIUM_ADJUSTMENTS_SUCCESS]: async function(client, action) {
    const { rating } = action.payload;
    receiveRating(client, rating);
  },
  [rating.CREATE_RATING_FROM_QUOTE_SUCCESS]: async function(client, action) {
    const { quote, rating } = action.payload;
    receiveRating(client, rating);
    receiveQuote(client, quote);
  },
  [rating.CREATE_RATING_COPY_SUCCESS]: async function(client, action) {
    const { quote, rating } = action.payload;
    receiveRating(client, rating);
    receiveQuote(client, quote);
  },
  [quote.ADD_RATING_TO_QUOTE_SUCCESS]: async function(client, action) {
    const { quote } = action.payload;
    receiveQuote(client, quote);
  },
  [quote.REMOVE_RATING_FROM_QUOTE_SUCCESS]: async function(client, action) {
    const { quote } = action.payload;
    receiveQuote(client, quote);
  },
  [quote.UPDATE_QUOTE_SUCCESS]: async function(client, action) {
    const { quote, ratings } = action.payload;
    const oldQuote = getQuote(client, quote.id);
    ratings.forEach(rating => {
      receiveRating(client, rating);
    });
    receiveQuote(
      client,
      merge({}, pick(oldQuote, 'terms_conditions.policy_forms'), quote)
    );
    const getInsuredState = quote =>
      get(quote, 'named_insured.address.state', null);
    const getProductCode = quote => get(quote, 'product_code', null);
    const insuredStateChanged =
      getInsuredState(quote) !== getInsuredState(oldQuote);
    const productCodeChanged =
      getProductCode(quote) !== getProductCode(oldQuote);
    if (insuredStateChanged || productCodeChanged) {
      await updateQuoteConfig(client, quote);
    }
  },
  [quote.CREATE_QUOTE_COPY_SUCCESS]: async function(client, action) {
    const { quote } = action.payload;
    await client.query({
      query: ViewQuoteContainerQuery,
      variables: {
        quoteId: quote.id,
      },
    });
  },
  [quote.GENERATE_QUOTE_DOCUMENTS_SUCCESS]: async function(client, action) {
    const { quote, ratings } = action.payload;
    receiveSubmission(client, {
      id: quote.submission_id,
      status: 'QUOTED',
    });
    ratings.forEach(rating => {
      receiveRating(client, { ...rating, status: 'QUOTED' });
    });
    receiveQuote(client, quote);
  },
  [binder.CREATE_BINDER_SUCCESS]: async function(client, action) {
    const { binder } = action.payload;
    receiveSubmission(client, {
      id: binder.submission_id,
      status: 'BINDING_IN_PROGRESS',
    });
    receiveBinder(client, binder);
  },
  [binder.DELETE_BINDER_SUCCESS]: async function(client, action) {
    const { submission_id } = action.payload;
    receiveSubmission(client, { id: submission_id, status: 'QUOTED' });
  },
  [binder.UPDATE_BINDER_TERMS_CONDITIONS_SUCCESS]: async function(
    client,
    action
  ) {
    const { binder_id, ...binder } = action.payload;
    const fragment = getBinder(client, binder_id);
    const merged = merge({}, fragment, binder);
    merged.terms_conditions.subjectivities =
      binder.terms_conditions.subjectivities;
    merged.terms_conditions.endorsements = binder.terms_conditions.endorsements;
    receiveBinder(client, merged);
  },
  [binder.UPDATE_BINDER_Z_SCORE_SUCCESS]: async function(client, action) {
    const { binder } = action.payload;
    receiveBinder(client, binder);
  },
  [binder.UPDATE_BINDER_DETAILS_SUCCESS]: async function(client, action) {
    const { binder_id, ...binder } = action.payload;
    const fragment = getBinder(client, binder_id);
    receiveBinder(client, merge({}, fragment, binder));
  },
  [binder.BIND_POLICY_SUCCESS]: async function(client, action) {
    const { binder } = action.payload;
    receiveBinder(client, binder);
  },
  [policies.UPDATE_POLICY_TERMS_CONDITIONS_SUCCESS]: async function(
    client,
    action
  ) {
    const { policy_id, ...policy } = action.payload;
    const fragment = getPolicy(client, policy_id);
    const merged = merge({}, fragment, policy);
    merged.terms_conditions.subjectivities =
      policy.terms_conditions.subjectivities;
    merged.terms_conditions.endorsements = policy.terms_conditions.endorsements;
    receivePolicy(client, merged);
  },
  [policies.UPDATE_POLICY_DETAILS_SUCCESS]: async function(client, action) {
    const { policy_id, ...policy } = action.payload;
    const fragment = getPolicy(client, policy_id);
    receivePolicy(client, merge({}, fragment, policy));
  },
  [policies.ISSUE_POLICY_SUCCESS]: async function(client, action) {
    const { policy } = action.payload;
    receiveSubmission(client, {
      id: policy.submission_id,
      status: 'ISSUED',
    });
    receivePolicy(client, policy);
  },
  [endorsements.ADD_ENDORSEMENT_TO_POLICY_SUCCESS]: async function(
    client,
    action
  ) {
    const { endorsement } = action.payload;
    await client.query({
      query: MidTermEndorsementQuery,
      variables: { id: endorsement.id },
      fetchPolicy: 'network-only',
    });
  },
  [endorsements.BOOK_ENDORSEMENT_SUCCESS]: async function(client, action) {
    const { endorsement } = action.payload;
    await client.query({
      query: MidTermEndorsementQuery,
      variables: { id: endorsement.id },
      fetchPolicy: 'network-only',
    });
  },
};

const syncApollo = client => store => next => async action => {
  const handler = handlers[action.type];
  if (handler) {
    await handler.call(handlers, client, action);
  }
  return next(action);
};

export default syncApollo;
