import React from 'react';
import { reduxForm, Field } from 'redux-form';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';
import InputGroup from 'react-bootstrap/InputGroup';
import FormControl from 'react-bootstrap/FormControl';
import { AddressFields, LabeledControl, InputControl } from '../../../common';
import submit from './submit';
import { errors, is_required } from './validation';

const Commission = props => {
  const { input, meta, ...otherProps } = props;
  return (
    <LabeledControl {...props}>
      <InputGroup>
        <FormControl
          id={otherProps.id || input.name}
          {...input}
          {...otherProps}
        />
        <InputGroup.Text>%</InputGroup.Text>
      </InputGroup>
    </LabeledControl>
  );
};

function BrokerActive(props) {
  const { makeInactive, handleSubmit } = props;
  return (
    <form
      onSubmit={event => {
        event.preventDefault();
        handleSubmit();
      }}
      noValidate
    >
      <Row>
        <Col sm={4}>
          <Field
            name="commission"
            type="number"
            component={Commission}
            label="Broker commission"
            required
            validate={errors.commission}
          />
        </Col>
      </Row>
      <Row>
        <Col sm={8}>
          <AddressFields
            validate={errors.address}
            is_required={is_required.address}
          />
        </Col>
      </Row>
      <Row>
        <Col sm={8}>
          <Field
            name="phone"
            type="tel"
            component={InputControl}
            label="Phone"
            required
            validate={errors.phone}
            text="Example: 555-555-1234 x123"
          />
        </Col>
      </Row>
      <Row>
        <Col sm={8}>
          <Button variant="primary" type="submit" disabled={props.submitting}>
            {props.submitting ? 'Submitting...' : 'Submit'}
          </Button>
          <Button onClick={makeInactive} variant="link">
            Cancel
          </Button>
        </Col>
      </Row>
    </form>
  );
}

export default reduxForm({
  form: 'broker',
  onSubmit: submit,
})(BrokerActive);
