import GetBySubmission from './getBySubmission';
import GetOptionUrlByIdVersion from './getOptionUrlByIdVersion';
import GetUrlById from './getUrlById';
import AddToPolicy from './addToPolicy';
import GetOptionByIdVersion from './getOptionByIdVersion';
import DeleteEndorsement from './deleteEndorsement';
import GetUploadUrl from './get-upload-url';
import UploadEndorsement from './upload-endorsement';
import BookEndorsement from './book-endorsement';
import GetById from './getById';

export default function EndorsementsApi(fetch, auth) {
  return {
    getBySubmission: GetBySubmission(fetch, auth),
    getOptionUrlByIdVersion: GetOptionUrlByIdVersion(fetch, auth),
    getUrlById: GetUrlById(fetch, auth),
    addToPolicy: AddToPolicy(fetch, auth),
    getOptionByIdVersion: GetOptionByIdVersion(fetch, auth),
    deleteEndorsement: DeleteEndorsement(fetch, auth),
    getUploadUrl: GetUploadUrl(fetch, auth),
    bookEndorsement: BookEndorsement(fetch, auth),
    // This call does not require our "pre-baked" fetch
    // or auth. Not sure if it actually belongs here, but
    // I couldn't think of a better place so for now,
    // here it stays
    uploadEndorsement: UploadEndorsement(),
    getById: GetById(fetch, auth),
  };
}
