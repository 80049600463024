import { connect } from 'react-redux';
import { reduxForm, getFormValues } from 'redux-form';

import submit from './submit';
import Modal from './Modal';
import { declinedStatus, disableButton } from './util';

import { deleteDeclinedSubmission, postDeclineSubmission } from '../../actions';

export const mapStateToProps = (state, { submission }) => {
  const { declined, declination } = submission;
  const { comments, reasons } = declination || {};

  const formValues = getFormValues('submission_declination')(state) || {};
  const initialValues = !declined
    ? {
        status: 'OPEN',
      }
    : {
        ...(comments ? { comments } : {}),
        reasons,
        status: 'DECLINED',
      };

  return {
    disableButton: disableButton(initialValues, formValues),
    isDeclinedStatus: declinedStatus(formValues),
    initialValues,
  };
};

export const mapDispatchToProps = (dispatch, props) => ({
  openDeclinedSubmission: ({ submission }) =>
    dispatch(deleteDeclinedSubmission({ submission })),
  declineSubmission: ({ comments, reasons, submission }) =>
    dispatch(postDeclineSubmission({ comments, reasons, submission })),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm({
    enableReinitialize: true,
    form: 'submission_declination',
    onSubmit: submit,
    onSubmitSuccess: (_result, _dispatch, props) => {
      props.hide();
    },
  })(Modal)
);
