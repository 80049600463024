import React, { Fragment } from 'react';
import Button from 'react-bootstrap/Button';

import { DeclinationDetails } from '../../../../submissions';
import AlertBanner from './AlertBanner';
import BaseSubmissionInfo from './BaseSubmissionInfo';
import { InlineButton, Toggleable } from '../../../../common';
import { DeclinationModal } from '../../../../submissions';

const styles = {
  outer_container: {
    border: 'solid 1px #979797',
  },
  inner_container: {
    padding: '26px 30px',
    backgroundColor: '#ffffff',
  },
  header: {
    container: {
      display: 'flex',
      marginBottom: '20px',
    },
    banner: {
      display: 'inline-block',
      flexGrow: '1',
      fontWeight: 'normal',
      margin: '0px',
    },
    submissionNumber: {
      alignSelf: 'center',
    },
  },
  expiring_policy: {
    fontSize: '12px',
    marginTop: '-15px',
    marginBottom: '12px',
  },
  declinedBanner: {
    container: {
      backgroundColor: 'rgba(240, 240, 240, 0.6)',
      borderTopColor: '#d3000d',
    },
    header: {
      color: '#d3000d',
      marginTop: '0px',
    },
  },
  subheader: {
    fontSize: '0.875rem',
    fontWeight: 'bold',
    textTransform: 'uppercase',
  },
  detail_text: {
    fontSize: '0.875rem',
    margin: '0.25em 0',
    wordWrap: 'break-word',
  },
  baseSubmissionInfo: {
    padding: '0px 30px  26px ',
  },
};

const formatSubmissionNumber = submission_number =>
  submission_number.match(/\d{4}/g).join('-');

function DeclinedBanner({ submission }) {
  return (
    <AlertBanner
      style={styles.declinedBanner}
      renderHeader={() => (
        <h3 style={styles.declinedBanner.header}>Submission declined</h3>
      )}
      renderBody={() => 'This submission has been recorded as a declination'}
      renderButton={() => (
        <Toggleable
          render={({ visible, show, hide }) => (
            <Fragment>
              <Button variant="primary" onClick={show}>
                Edit Status
              </Button>
              {!visible ? null : (
                <DeclinationModal
                  hide={hide}
                  show={show}
                  submission={submission}
                  visible={visible}
                />
              )}
            </Fragment>
          )}
        />
      )}
    />
  );
}

export default function DeclinedSubmissionCard({ submission, quotes }) {
  const isRenewal = !!submission.renewed_from_policy;
  const headerText = isRenewal ? 'Renewal Quote' : 'New Business Quote';

  return (
    <div style={styles.outer_container}>
      <DeclinedBanner submission={submission} />
      <div style={styles.inner_container}>
        <div style={styles.header.container}>
          <h2 style={styles.header.banner}>{headerText}</h2>
          <section style={styles.header.submissionNumber}>
            Submission #{formatSubmissionNumber(submission.submission_number)}
          </section>
        </div>
        {isRenewal && (
          <div style={styles.expiring_policy}>
            Renewal of {submission.renewed_from_policy.policy_number}
          </div>
        )}
        <DeclinationDetails declination={submission.declination} />
      </div>
      <div style={styles.baseSubmissionInfo}>
        <Toggleable
          render={({ visible, show, hide }) => {
            if (visible) {
              return (
                <React.Fragment>
                  <InlineButton onClick={hide}>Hide Quote History</InlineButton>
                  <BaseSubmissionInfo quotes={quotes} submission={submission} />
                </React.Fragment>
              );
            }
            return (
              <InlineButton onClick={show}>Show Quote History</InlineButton>
            );
          }}
        />
      </div>
    </div>
  );
}
