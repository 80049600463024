export const GET_POLICY_BY_ID_REQUEST = 'GET_POLICY_BY_ID_REQUEST';
export const GET_POLICY_BY_ID_SUCCESS = 'GET_POLICY_BY_ID_SUCCESS';
export const GET_POLICY_BY_SUBMISSION_ID_REQUEST =
  'GET_POLICY_BY_SUBMISSION_ID_REQUEST';
export const GET_POLICY_BY_SUBMISSION_ID_SUCCESS =
  'GET_POLICY_BY_SUBMISSION_ID_SUCCESS';
export const UPDATE_POLICY_TERMS_CONDITIONS_REQUEST =
  'UPDATE_POLICY_TERMS_CONDITIONS_REQUEST';
export const UPDATE_POLICY_TERMS_CONDITIONS_SUCCESS =
  'UPDATE_POLICY_TERMS_CONDITIONS_SUCCESS';
export const ISSUE_POLICY_REQUEST = 'ISSUE_POLICY_REQUEST';
export const ISSUE_POLICY_SUCCESS = 'ISSUE_POLICY_SUCCESS';
export const SEARCH_POLICIES_REQUEST = 'SEARCH_POLICIES_REQUEST';
export const SEARCH_POLICIES_SUCCESS = 'SEARCH_POLICIES_SUCCESS';
export const GET_POLICY_DOCUMENTS_REQUEST = 'GET_POLICY_DOCUMENTS_REQUEST';
export const GET_POLICY_DOCUMENTS_SUCCESS = 'GET_POLICY_DOCUMENTS_SUCCESS';
export const UPDATE_POLICY_DETAILS_REQUEST = 'UPDATE_POLICY_DETAILS_REQUEST';
export const UPDATE_POLICY_DETAILS_SUCCESS = 'UPDATE_POLICY_DETAILS_SUCCESS';
