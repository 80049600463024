import React from 'react';
import Button from 'react-bootstrap/Button';

import { AsyncMutationWrapper } from '../../../../common';

import * as permissions from '../../../permissions';

const styles = {
  heading: { fontSize: '1rem' },
  subheading: { marginBottom: '0', fontSize: '0.875rem' },
  text: { marginTop: '0.5em' },
  error_text: { display: 'block' },
};

export default function ClearanceConfirmationPrompt({
  style,
  submission,
  confirmSubmissionClearance,
}) {
  return (
    <section style={style}>
      <h3 style={styles.heading}>E-mail Clearance Status</h3>
      <p style={styles.subheading}>
        Confirmation will be immediately sent to {submission.broker.email}.
      </p>
      <AsyncMutationWrapper
        mutate={confirmSubmissionClearance}
        render={({ loading, data, error, mutate }) => {
          return data ? (
            <p className="text-success" style={styles.text}>
              Clearance Status has been sent to the Submitting Broker
            </p>
          ) : (
            <React.Fragment>
              <Button
                variant="link"
                disabled={
                  loading ||
                  !permissions.canSendClearanceConfirmation({ submission })
                }
                onClick={mutate}
              >
                {loading ? 'Sending Status...' : 'Send Status'}
              </Button>
              {error && (
                <p className="text-danger">
                  <span style={styles.error_text}>
                    Sorry, we had a slight glitch in sending the message.
                  </span>
                  <span style={styles.error_text}>
                    Please click the send link again now.
                  </span>
                </p>
              )}
            </React.Fragment>
          );
        }}
      />
    </section>
  );
}
