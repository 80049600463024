import React from 'react';
import { Flag } from '../../../common/components/Flag';
import get from 'lodash/get';
import set from 'lodash/set';

import { SubmissionSummaryCard } from '../../../submissions';

import { DetailsSummary } from '../../../quote';
import { StickySidebar } from '../../../layouts';
import { DetailsBrokers as Brokers } from '../../../binder';
import * as validation from './validation';
import { submitPage } from './submit';
import { isSurplusLines } from '../../utils';

const styles = {
  header: {
    marginBottom: '37px',
  },
  sidebar: {
    margin: '25px auto auto',
    maxWidth: '240px',
  },
  submissions_summary: {
    marginTop: '25px',
  },
};

const fields = [
  'sl_broker.license_number',
  'sl_broker.nj_transaction_number',
  'sl_broker.name',
  'sl_broker.address.street_line_1',
  'sl_broker.address.street_line_2',
  'sl_broker.address.city',
  'sl_broker.address.state',
  'sl_broker.address.zip',
  'broker.name',
  'broker.brokerage',
  'broker.email',
  'broker.commission',
  'broker.phone',
  'broker.address.street_line_1',
  'broker.address.street_line_2',
  'broker.address.city',
  'broker.address.state',
  'broker.address.zip',
];

const getInitialValues = policy => {
  return fields.reduce((values, field) => {
    let value = get(policy, field);
    if (value == null) value = '';
    set(values, field, value.toString());
    return values;
  }, {});
};

function PolicyDetails({ policy, submission, navigate, updateDetails }) {
  return (
    <StickySidebar
      renderMain={() => {
        return (
          <section>
            <h1 style={styles.header}>Policy Details</h1>
            <DetailsSummary
              detail={policy}
              updateDetails={updateDetails}
              validation={validation.details}
            />
            <Brokers
              isSurplusLines={isSurplusLines(policy)}
              updateDetails={updateDetails}
              navigate={navigate}
              policy_id={policy.id}
              initialValues={getInitialValues(policy)}
              validation={validation}
              insured_state={policy.named_insured.address.state}
              namedInsured={policy.named_insured}
              onSubmit={submitPage}
              submitButtonLabel="Preview Policy"
              prevPagePath={`/policies/${policy.id}/terms-conditions`}
            />
          </section>
        );
      }}
      renderAside={() => {
        return (
          <section style={styles.sidebar}>
            <Flag
              name="policy.details.submission_summary_card"
              render={() => {
                return (
                  <SubmissionSummaryCard
                    style={styles.submissions_summary}
                    flex
                    submission={submission}
                  />
                );
              }}
            />
          </section>
        );
      }}
    />
  );
}

export default PolicyDetails;
