const GetOptionUrlByIdVersion = (fetch, auth) => ({ id, version }, options) => {
  const optionsQueryString = `options=${window.btoa(JSON.stringify(options))}`;
  const url = options
    ? `/endorsements/options/${id}/${version}/documents?${optionsQueryString}`
    : `/endorsements/options/${id}/${version}/documents`;
  return auth
    .getAccessToken()
    .then(token => {
      if (!token) {
        auth.signOut();
        return Promise.reject(new Error('User is not authenticated.'));
      }
      return token;
    })
    .then(token =>
      fetch(url, {
        method: 'GET',
        headers: {
          Authorization: token,
          'Content-Type': 'application/json',
        },
      })
    )
    .then(response => response.json());
};

export default GetOptionUrlByIdVersion;
