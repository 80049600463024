import qs from 'querystring';

const SearchPolicies = (fetch, auth) => async ({ term }) => {
  const query_string = qs.stringify({
    ...(term && { q: term }),
  });
  const token = await auth.getAccessToken();
  if (!token) {
    auth.signOut();
    throw new Error('User is not authenticated.');
  }
  const response = await fetch(`/policies?${query_string}`, {
    headers: {
      Authorization: token,
    },
  });
  return response.json();
};

export default SearchPolicies;
