import { connect } from 'react-redux';
import { getFormSyncErrors, getFormSyncWarnings, touch } from 'redux-form';
import PersistErrors from './PersistErrors';

const mapStateToProps = (state, props) => ({
  getFormSyncErrors: getFormSyncErrors(props.formName)(state),
  getFormSyncWarnings: getFormSyncWarnings(props.formName)(state),
});

const mapDispatchToProps = (dispatch, props) => ({
  touch: field => dispatch(touch(props.formName, field)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PersistErrors);
