import numeral from 'numeral';

export const formatters = {
  currency: value => {
    if (value == null) return '';
    return numeral(value).format('0,0');
  },
};

export const parsers = {
  currency: value => {
    if (!value) return null;
    return numeral(value).value();
  },
};
