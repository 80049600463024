import React from 'react';
import { Link } from 'react-router-dom';
import get from 'lodash/get';

import { EndorsementsPreview } from '../../../../endorsements';

import * as permissions from '../../../permissions';

const renderHeader = quote => ({ styles }) => {
  return (
    <React.Fragment>
      <h3 style={styles.section_header}>Endorsements</h3>
      <div className="pull-right">
        <Link
          style={styles.edit}
          className="btn btn-link"
          to={`/quotes/${quote.id}/terms-conditions`}
        >
          Edit
        </Link>
      </div>
    </React.Fragment>
  );
};

export default function Endorsements({ quote, ...props }) {
  const endorsements = get(quote, 'terms_conditions.endorsements', []);
  const can_update_terms_conditions = permissions.canUpdateTermsConditions({
    quote,
  });
  return (
    <EndorsementsPreview
      {...props}
      endorsements={endorsements}
      renderHeader={
        can_update_terms_conditions ? renderHeader(quote) : undefined
      }
    />
  );
}
