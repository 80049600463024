import React from 'react';

const styles = {
  text: { display: 'block' },
};

export default function SearchError({ hasSucceeded }) {
  if (hasSucceeded) {
    return (
      <p className="text-danger">
        <span style={styles.text}>Sorry, the Search robots got lost!</span>
        <span style={styles.text}>
          Please refresh the page and try your search again.
        </span>
      </p>
    );
  }
  return (
    <p className="text-danger" style={{ textAlign: 'center' }}>
      <span style={styles.text}>
        Sorry, there was an error loading the Submissions.
      </span>
      <span style={styles.text}>Please refresh the page.</span>
    </p>
  );
}
