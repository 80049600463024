import React, { useRef, useState, useEffect } from 'react';
import Control from '../Control';
import { format, isValid, parse } from 'date-fns';
import FocusTrap from 'focus-trap-react';
import { DayPicker } from 'react-day-picker';
import { usePopper } from 'react-popper';
import { getValidationClassName } from '../utils';

import './DateSelect.css';
import 'react-day-picker/dist/style.css';

const styles = {
  popper: {
    zIndex: 100000,
    backgroundColor: '#ffffff',
    border: '1px solid #cccccc',
    borderRadius: '3px',
    boxShadow: '0 2px 6px rgba(0,0,0,.1)',
  },
  dayPickerModifier: {
    selected: { backgroundColor: '#00a699' },
  },
  dayPicker: {
    caption: {
      color: '#555555',
    },
    head: {
      color: '#888888',
    },
  },
};

export default function DateSelect(props) {
  const { input, hidden, field } = props;

  const [selected, setSelected] = useState();
  const [inputValue, setInputValue] = useState('');
  const [isPopperOpen, setIsPopperOpen] = useState(false);
  const [popperElement, setPopperElement] = useState(null);
  const [validationClassName, setValidationClassName] = useState('');

  const popperRef = useRef(null);
  const buttonRef = useRef(null);

  const popper = usePopper(popperRef.current, popperElement, {
    placement: 'bottom-start',
    strategy: 'absolute',
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [0, 25],
        },
      },
    ],
  });

  const closePopper = () => {
    setIsPopperOpen(false);
    buttonRef?.current?.focus();
  };

  const openPopper = (e) => {
    setIsPopperOpen(true);
  };

  const isDateWellFormed = (dateInput) => {
    if (typeof dateInput === 'string') {
      const datePartsArr = dateInput.split('/');
      if (
        datePartsArr.length === 3 &&
        datePartsArr[0].length >= 1 &&
        datePartsArr[0].length <= 2 &&
        datePartsArr[1].length >= 1 &&
        datePartsArr[1].length <= 2 &&
        datePartsArr[2].length === 2
      ) {
        return true;
      }
    }
    return false;
  };

  const handleDateOnChange = (e) => {
    const dateVal = e.currentTarget.value;
    setInputValue(dateVal);

    if (!isDateWellFormed(dateVal)) {
      input.onChange(null);
      return;
    }

    const date = parse(dateVal, 'M/d/yy', new Date());
    if (isValid(date)) {
      input.onChange(format(date, 'yyyy-MM-dd'));
    } else {
      input.onChange(null);
    }
  };

  const handleDateOnBlur = (e) => {
    const dateVal = e.currentTarget.value;
    setInputValue(dateVal);

    if (!isDateWellFormed(dateVal)) {
      input.onBlur(null);
      return;
    }

    const date = parse(dateVal, 'M/d/yy', new Date());
    if (isValid(date)) {
      input.onBlur(format(date, 'yyyy-MM-dd'));
    } else {
      input.onBlur(null);
    }
  };

  const handleDaySelect = (date) => {
    if (date) {
      setSelected(date);
      setInputValue(format(date, 'M/d/yy'));
      input.onChange(format(date, 'yyyy-MM-dd'));
    } else {
      setInputValue('');
      input.onChange(null);
    }
    closePopper();
  };

  useEffect(() => {
    if (input.value) {
      let date = parse(input.value, 'yyyy-MM-dd', new Date());
      if (date && isValid(date)) {
        setSelected(date);
        setInputValue(format(date, 'M/d/yy'));
      }
    }
  }, [input.value, props, props.input.value]);

  useEffect(() => {
    setValidationClassName(
      getValidationClassName(props.error, props.warn, props.touched)
    );
  }, [props.error, props.warn, props.touched]);

  if (hidden) return null;

  return (
    <div>
      <Control {...props}>
        <div ref={popperRef} style={{ display: 'flex' }}>
          <input
            className={`form-control date-input ${validationClassName}`}
            type="text"
            value={inputValue}
            onChange={handleDateOnChange}
            onBlur={handleDateOnBlur}
            placeholder={field.placeholder}
          />
          <button
            ref={buttonRef}
            type="button"
            aria-label="Pick a date"
            onClick={openPopper}
            className="calender-button"
          >
            <i className="bi bi-calendar" />
          </button>
        </div>
      </Control>
      {isPopperOpen && (
        <FocusTrap
          active
          focusTrapOptions={{
            initialFocus: false,
            allowOutsideClick: true,
            clickOutsideDeactivates: true,
            onDeactivate: closePopper,
            fallbackFocus: buttonRef.current,
          }}
        >
          <div
            tabIndex={-1}
            style={{
              ...popper.styles.popper,
              ...styles.popper,
            }}
            className="dialog-sheet"
            {...popper.attributes.popper}
            ref={setPopperElement}
            role="dialog"
          >
            <DayPicker
              initialFocus={isPopperOpen}
              mode="single"
              numberOfMonths={2}
              defaultMonth={selected}
              selected={selected}
              onSelect={handleDaySelect}
              modifiersStyles={{
                selected: styles.dayPickerModifier.selected,
              }}
              styles={{
                month: styles.dayPicker.caption,
                head: styles.dayPicker.head,
              }}
            />
          </div>
        </FocusTrap>
      )}
    </div>
  );
}
