import { connect } from 'react-redux';
import { useOktaAuth } from '@okta/okta-react';

function WithAuth(props) {
  const { oktaAuth, authState } = useOktaAuth();
  return props.render({
    auth: oktaAuth,
    authState: authState,
    loading: props.loading,
    user: props.user,
  });
}

const mapStateToProps = state => {
  const auth = state.auth;
  return {
    user: auth.user,
    loading: auth.loading,
  };
};

export default connect(mapStateToProps)(WithAuth);
