import React from 'react';
import Modal from 'react-bootstrap/Modal';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import Button from 'react-bootstrap/Button';

import { AsyncMutationWrapper } from '../../../../common';

import './ValidationModal.css';

const styles = {
  container: {
    fontSize: '0.875rem',
    padding: '20px',
  },
  header: {
    border: '0',
    padding: '0',
    paddingBottom: '20px',
  },
  header_text: {
    fontSize: '0.875rem',
    margin: '30px 0 0',
  },
  header_sub_text: {
    fontWeight: 'normal',
    display: 'inline-block',
    margin: '10px 5px 0 0',
  },
  body: {
    background: '#f7f7f7',
    maxHeight: '300px',
    overflowY: 'scroll',
    padding: '20px 25px',
  },
  list: {
    listStyle: 'none',
    padding: '0',
  },
  list_item: {
    marginBottom: '0.8em',
  },
  footer: {
    border: '0',
    padding: '0',
    paddingTop: '20px',
    textAlign: 'left',
  },
};

export default function ValidationModal(props) {
  return (
    <Modal
      dialogClassName="ValidationModal__dialog"
      show={props.show}
      onHide={props.onHide}
    >
      <div style={styles.container}>
        <Modal.Header style={styles.header} closeButton>
          <Modal.Title style={styles.header_text}>
            <div>
              <strong className="text-danger">Warning</strong>
            </div>
            <div>
              <p style={styles.header_sub_text}>
                The following issues may prevent premium calculation
              </p>

              <OverlayTrigger
                placement="bottom"
                overlay={
                  <Popover id="popover-basic">
                    <Popover.Body>
                      You can still choose limit and retentions, but you may not
                      be able view a price unless issues are resolved.
                    </Popover.Body>
                  </Popover>
                }
              >
                <i className="bi bi-question-circle-fill" />
              </OverlayTrigger>
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="fadeout">
          <div style={styles.body}>
            <ul style={styles.list}>
              {props.messages.map((message, i) => (
                <li key={i} style={styles.list_item}>
                  <strong>{message.label}:</strong> {message.text}
                </li>
              ))}
            </ul>
          </div>
        </Modal.Body>
        <Modal.Footer style={styles.footer}>
          <AsyncMutationWrapper
            mutate={props.onSubmit}
            render={({ loading, mutate }) => (
              <Button
                disabled={loading}
                onClick={mutate}
                type="button"
                variant="primary"
              >
                Fix Later
              </Button>
            )}
          />
          <Button type="button" variant="link" onClick={props.onHide}>
            Return to Worksheet
          </Button>
        </Modal.Footer>
      </div>
    </Modal>
  );
}
