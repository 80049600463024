import React from 'react';
import { connect } from 'react-redux';

import { AsyncMutationWrapper } from '../../../../common';

import { actions as QuoteActions } from '../../../../quote';

const CreatePrepopulatedQuote = ({ createPrepopulatedQuote, render }) => {
  return (
    <AsyncMutationWrapper
      mutate={() => createPrepopulatedQuote()}
      render={render}
    />
  );
};

const mapDispatchToProps = (dispatch, props) => ({
  createPrepopulatedQuote: () =>
    dispatch(
      QuoteActions.createPrepopulatedQuoteFromSubmission(props.submission.id)
    ),
});

export default connect(
  null,
  mapDispatchToProps
)(CreatePrepopulatedQuote);
