import React from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';

import {
  BINDER_STATUSES,
  compareStatuses as compareBinderStatuses,
} from '../../../../../../binder';

const tableConfig = {
  columns: [
    {
      key: '0',
      content: binder => {
        return <Link to={`/binders/${binder.id}/preview`}>Binder</Link>;
      },
    },
    {
      key: '1',
      content: binder => {
        const is_bound =
          compareBinderStatuses(binder.status, BINDER_STATUSES.BOUND) === 0;
        if (!is_bound) {
          return <strong style={{ color: '#f5a623' }}>In Progress</strong>;
        }
        const label = `Booked (${moment(binder.updated_at).format('M/D')})`;
        return <strong className="text-success">{label}</strong>;
      },
    },
    {
      key: '2',
      content: binder => {
        return <span>{moment(binder.created_at).format('M/D/YYYY')}</span>;
      },
    },
    {
      key: '3',
      content: binder => {
        return <Link to={`/binders/${binder.id}/preview`}>View</Link>;
      },
      style: {
        paddingRight: '0',
      },
    },
    {
      key: '4',
      content: binder => {
        return <Link to={`/binders/${binder.id}/documents`}>Download</Link>;
      },
      style: {
        paddingLeft: '0',
      },
    },
  ],
};
export default tableConfig;
