import Main from './Main';
import Header from './Header';
import SectionHeader from './SectionHeader';
import Button from './Button';
import DocumentsList from './DocumentsList';
import Section from './Section';
import InProgressDescription from './InProgressDescription';

const docSections = {
  Main,
  Header,
  SectionHeader,
  Button,
  DocumentsList,
  Section,
  InProgressDescription,
};

export default docSections;
