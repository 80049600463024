import React from 'react';
import { Flag } from '../../../common/components/Flag';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Sticky from 'react-stickynode';
import { reduxForm } from 'redux-form';

import { SubmissionSummaryCard } from '../../../submissions';
import {
  EndorsementsForm,
  constants as endorsementConstants,
} from '../../../endorsements';

import ZScoreAlert from './ZScoreAlert';
import Subjectivities from './Subjectivities';
import ExclusionsReporting from './ExclusionsReporting';
import UpdateQuoteButton from './UpdateQuoteButton';
import submit from './submit';
import { SaveOnLeave } from '../../../common';
import { utils } from '../../../rating';

const styles = {
  form_section_header: {
    marginTop: '20px'
  },
  form_section_container: {
    borderTop: '1px solid grey',
    padding: '8px 0px',
    margin: '0px',
  },
  section_container: {
    borderTop: '1px solid grey',
    marginTop: '35px',
  },
  sidebar_container: {
    margin: '25px auto auto',
    maxWidth: '240px',
  },
  narrow_nested_column: {
    paddingLeft: '0',
  },
  wide_nested_column: {
    paddingLeft: '0',
    paddingRight: '0',
  },
};

const RowDivider = ({ children, ...props }) => {
  return (
    <Row {...props} style={styles.form_section_container}>
      {children}
    </Row>
  );
};

const Column = ({ children, ...props }) => (
  <Col 
    md={{span: 6}} 
    lg={{span: 6, offset: 1}}
    {...props}
  >
    {children}
  </Col>
);

function QuoteWorksheet({
  form,
  config,
  submission,
  ratings,
  handleSubmit,
  initialValues,
  submitting,
  error,
  updateTermsConditions,
}) {
  return (
      <Container fluid="md">
        <Row>
          <Column style={{ paddingTop: '25px', paddingBottom: '25px' }}>
            <form
              style={styles.form_container}
              onSubmit={handleSubmit}
              noValidate
            >
              <SaveOnLeave
                form={form}
                save={values => updateTermsConditions(values, initialValues)}
              />
              <ZScoreAlert ratings={ratings} />
              <RowDivider>
                <Subjectivities
                  styles={styles}
                  options={config.subjectivities}
                />
              </RowDivider>
              <EndorsementsForm
                style={styles.section_container}
                options={config.endorsements}
                context={endorsementConstants.ENDORSEMENT_CONTEXT_INCEPTION}
              />
              <RowDivider>
                <ExclusionsReporting
                  required_coverages={utils.aggregateCoverages(ratings)}
                />
              </RowDivider>
              <UpdateQuoteButton submitting={submitting} />
              {error &&
                !submitting && (
                  <p className="text-danger" style={styles.error}>
                    <span style={styles.error_text}>
                      Sorry, there was an error updating Quote.
                    </span>
                    <span style={styles.error_text}>
                      Please submit again now.
                    </span>
                  </p>
                )}
            </form>
          </Column>
          <Col sm={5}>
            <Sticky>
              {props => (
                <div style={{ ...props.style, zIndex: 1000 }}>
                  <section style={styles.sidebar_container}>
                    <Flag
                      name="quote.worksheet.submission_summary_card"
                      render={() => {
                        return (
                          <SubmissionSummaryCard flex submission={submission} />
                        );
                      }}
                    />
                  </section>
                </div>
              )}
            </Sticky>
          </Col>
        </Row>
      </Container>
  );
}

export default reduxForm({
  form: 'terms_conditions',
  onSubmit: submit,
  onSubmitSuccess: (_result, _dispatch, props) =>
    props.navigate(`/quotes/${props.quote.id}/details`),
})(QuoteWorksheet);
