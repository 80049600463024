import React from 'react';
import Row from 'react-bootstrap/Row';
import Exclusions from '../../Exclusions';
import ExtendedReporting from '../../ExtendedReporting';

import * as validation from '../validation';

const all_coverages = ['do', 'epl', 'fid'];
export default function ExclusionsExtendedReporting({
  required_coverages = all_coverages,
}) {
  const coverages_to_show_for_quote = all_coverages;
  return (
    <section>
      <h3 style={{marginTop: '20px'}}>Exclusions and Reporting</h3>
      <Row style={{ margin: '15px 0 0' }}>
        <Exclusions
          selected_coverages={coverages_to_show_for_quote}
          validation={validation}
          required_coverages={required_coverages}
        />
      </Row>
      <ExtendedReporting
        validation={validation.erp}
        style={{ marginTop: '10px' }}
      />
    </section>
  );
}
