import React from 'react';
import Alert from 'react-bootstrap/Alert';

import { AsyncMutationWrapper, InlineButton } from '../../../../../common';
import { utils as r_utils } from '../../../../../rating';

const styles = {
  alert: {
    fontSize: '0.8125rem',
  },
  text: {
    color: 'black',
    marginTop: '10px',
    paddingLeft: '30px',
  },
  icon: {
    marginRight: '0.5em',
  },
};

function NextStepsAlert({ style, ratings, copyQuote }) {
  const any_ratings_tbd_eff_dt = ratings.some(r_utils.isEffectiveDateTBD);
  return (
    <AsyncMutationWrapper
      mutate={() => {
        return copyQuote();
      }}
      render={({ loading, mutate }) => (
        <Alert style={{ ...styles.alert, ...style }} variant="info">
          <i className="bi bi-lock-fill"/>
          <strong>Quote locked for your records</strong>
          <ul style={styles.text}>
            <li>
              <InlineButton
                onClick={mutate}
                variant="link"
                style={{ padding: '0' }}
                disabled={loading}
              >
                Requote
              </InlineButton>{' '}
              to generate an updated quote letter and change any limits, rating,
              or policy period information
            </li>
            <li>When ready to book a policy, you can start a Binder below</li>
            {any_ratings_tbd_eff_dt && (
              <li>You may not bind price options with TBD effective dates.</li>
            )}
          </ul>
        </Alert>
      )}
    />
  );
}

export default NextStepsAlert;
