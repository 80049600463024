import React from 'react';

import { AsyncMutationWrapper, ActionMenu } from '../../../../../common';

const styles = {
  container: {
    backgroundColor: '#f0f0f0',
    padding: '20px',
  },
  list: {
    listStyleType: 'none',
    padding: '0',
    margin: '0',
  },
};

const getComponentForAction = action => {
  if (action.type === 'link') {
    return ActionMenu.Link;
  }
  if (action.disabled && action.tooltiptext) {
    return ActionMenu.DisabledButton;
  }
  return ActionMenu.Button;
};

function ManagePolicy({ style, actions = [] }) {
  return (
    <AsyncMutationWrapper
      mutate={(fn, ...args) => {
        return fn(...args);
      }}
      render={({ mutate, loading }) => {
        return (
          <ActionMenu>
            <ActionMenu.Title>Manage Policy</ActionMenu.Title>
            <ul style={styles.list}>
              {actions.map(action => {
                const { disabled, onClick, title } = action;
                const _onClick = () =>
                  mutate(onClick || (() => Promise.resolve()));
                const Component = getComponentForAction(action);
                return (
                  <li key={title}>
                    <Component
                      {...action}
                      key={title}
                      onClick={_onClick}
                      disabled={disabled || loading}
                    >
                      {title}
                    </Component>
                  </li>
                );
              })}
            </ul>
          </ActionMenu>
        );
      }}
    />
  );
}

export default ManagePolicy;
