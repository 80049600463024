import React from 'react';

const styles = {
  coverages_list: {
    listStyleType: 'none',
    fontSize: '0.875rem',
    marginBottom: '0',
    paddingLeft: '0',
  },
};

const PrettyNameMap = {
  do: 'Directors & Officers',
  epl: 'Employment Practices',
  fid: 'Fiduciary',
};

export default function CoverageDescription({ limit_summary, style }) {
  return (
    <ul style={{ ...styles.coverages_list, ...style }}>
      {limit_summary.separate.map(({ coverage }, i) => (
        <li key={i}>{PrettyNameMap[coverage]}</li>
      ))}
      {limit_summary.shared.map((shared_group, i) => (
        <li key={i}>
          {shared_group.members
            .reduce(
              (arr, { coverage }) => arr.concat(PrettyNameMap[coverage]),
              []
            )
            .join(', ')
            .concat(' (shared limit)')}
        </li>
      ))}
    </ul>
  );
}
