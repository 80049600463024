import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { Button, Form, FormGroup, Modal } from 'react-bootstrap';
import { Field } from 'redux-form';

import Reasons from './Reasons';
import Status from './Status';
import InputControl from '../../../common/components/InputControl';

const validate = (reasons = [], allValues, props) =>
  !reasons.length ? 'Required' : undefined;

const styles = {
  container: {
    fontSize: '0.875rem',
    padding: '20px',
  },
  error: {
    marginTop: '10px',
  },
  header: {
    border: '0',
    padding: '0 0 20px 0',
  },
  body: {
    backgroundColor: '#f7f7f7',
    padding: '20px 25px',
  },
};

export default function DeclinationModal({
  disableButton,
  error,
  handleSubmit,
  hide,
  isDeclinedStatus,
  modalTitle,
  pristine,
  submitting,
  visible,
}) {
  return (
    <Modal
      dialogClassName="DeclinationModal__dialog"
      show={visible}
      onHide={hide}
    >
      <div style={styles.container}>
        <Modal.Header style={styles.header} closeButton>
          <h3>{modalTitle}</h3>
        </Modal.Header>
        <Modal.Body style={styles.body} className="DeclinationModal__fadeout">
          <Form>
            <FormGroup>
              <Field
                component={Status}
                inline
                label="Status"
                name="status"
                required
              />
              {isDeclinedStatus && (
                <Fragment>
                  <Field
                    component={Reasons}
                    label="Reason(s)"
                    multi
                    name="reasons"
                    placeholder="Please select a reason"
                    required
                    validate={validate}
                  />
                  <Field
                    component={InputControl}
                    as="textarea"
                    label="Comment / Details"
                    name="comments"
                    placeholder="Example: Earnings too low, does not meet appetite"
                    style={{ height: '6rem' }}
                  />
                </Fragment>
              )}
            </FormGroup>
            <Fragment>
              <Button
                variant="primary"
                disabled={pristine || submitting || disableButton}
                onClick={handleSubmit}
                type="submit"
                className="mt-3"
              >
                {submitting ? 'Recording Status...' : 'Record Status'}
              </Button>
              <Button 
                variant="link" 
                disabled={submitting} 
                onClick={hide}
                className="mt-3"  
              >
                Cancel
              </Button>
              {error && (
                <p style={styles.error} className="text-danger">
                  {error}
                </p>
              )}
            </Fragment>
          </Form>
        </Modal.Body>
      </div>
    </Modal>
  );
}

DeclinationModal.defaultProps = {
  modalTitle: 'Decline Submission',
};

DeclinationModal.propTypes = {
  disableButton: PropTypes.bool.isRequired,
  error: PropTypes.any,
  handleSubmit: PropTypes.func.isRequired,
  hide: PropTypes.func.isRequired,
  isDeclinedStatus: PropTypes.bool.isRequired,
  modalTitle: PropTypes.string,
  pristine: PropTypes.bool.isRequired,
  submitting: PropTypes.bool,
  visible: PropTypes.bool.isRequired,
};
