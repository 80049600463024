import { Condition } from './_base';
export class StringStartsWithCondition extends Condition {
  eval(value, values, context) {
    const $ = { value, values, context };
    const string = this.value(this.config.string).eval($);
    const characters = this.value(this.config.characters).eval($);
    return (
      typeof string !== 'string' ||
      typeof characters !== 'string' ||
      string.startsWith(characters)
    );
  }
}
