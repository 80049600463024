import React from 'react';
import Form from 'react-bootstrap/Form';

import Control from './Control';

const InlineRadio = props => {
  const { input, field } = props;
  return (
    <Control {...props}>
      <div>
        {field.options.map(option => {
          const id = `${field.name}-${option.value}`;
          return (
            <Form.Check
              key={option.value}
              type='radio'
              {...input}
              title={option.label}
              inline
              id={id}
              value={option.value}
              checked={input.value === option.value}
              aria-label={option.label}
              label={option.label}
              isInvalid={((props.touched && props.warn )|| (props.touched && props.error)) ? true : false}
            />
          );
        })}
      </div>
    </Control>
  );
};

export default InlineRadio;
