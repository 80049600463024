import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

import { AsyncMutationWrapper } from '../../../../common';

import './ConfirmCancel.css';

const styles = {
  container: {
    fontSize: '0.875rem',
    padding: '20px',
  },
  header: {
    border: '0',
    padding: '0 0 20px',
  },
  header_text: {
    fontSize: '2.0rem',
    margin: '30px 0 0',
  },
  body: {
    backgroundColor: '#f7f7f7',
    maxHeight: '300px',
    padding: '20px 25px',
  },
  list: {
    listStyle: 'none',
    padding: '0',
  },
  list_item: {
    marginTop: '0.25em',
  },
  footer: {
    border: '0',
    padding: '20px 0 0',
    textAlign: 'left',
  },
};

export default function ConfirmCancel(props) {
  return (
    <Modal
      dialogClassName="ConfirmCancel__dialog"
      show={props.show}
      onHide={props.hideModal}
      size="md"
    >
      <div style={styles.container}>
        <Modal.Header style={styles.header} closeButton>
          <Modal.Title style={styles.header_text}>Are you sure?</Modal.Title>
        </Modal.Header>
        <Modal.Body style={styles.body}>
          The Binder and any updates will be deleted.
        </Modal.Body>
        <Modal.Footer style={styles.footer}>
          <AsyncMutationWrapper
            mutate={() => {
              props.disableAutoSave();
              return props.deleteBinder();
            }}
            render={({ loading, mutate, error }) => (
              <React.Fragment>
                <Button
                  disabled={loading}
                  onClick={mutate}
                  type="button"
                  variant="danger"
                >
                  {loading ? 'Working' : 'Yes, Delete Binder'}
                </Button>
                <Button
                  style={styles.button}
                  type="button"
                  variant="link"
                  onClick={props.hideModal}
                  disabled={loading}
                >
                  No, Keep Binder
                </Button>
                {error &&
                  !loading && (
                    <p className="text-danger" style={styles.error}>
                      <span style={styles.error_text}>
                        Sorry, there was an error deleting the Binder. Please
                        try again. If this issue persists, please contact
                        support.
                      </span>
                    </p>
                  )}
              </React.Fragment>
            )}
          />
        </Modal.Footer>
      </div>
    </Modal>
  );
}
