import React from 'react';
import { connect } from 'react-redux';
import { getFormValues } from 'redux-form';
import Scrollspy from 'react-scrollspy';

import './RatingWorksheetProgressBox.css';

const styles = {
  container: {
    border: '1px solid black',
    padding: '15px 20px',
  },
  header: {
    marginTop: '0',
  },
  list: {
    fontSize: '0.75rem',
    listStyle: 'none',
    marginTop: '15px',
    paddingLeft: '0',
    textTransform: 'uppercase',
  },
  list_item: {
    marginTop: '0.625em',
  },
  link: {
    enabled: {
      color: '#333333',
    },
    disabled: {
      color: '#b3b3b3',
    },
  },
};

const WithValues = connect((state, props) => state => {
  return {
    values: getFormValues(props.name)(state),
  };
})(props => props.render({ values: props.values }));

export default function RatingWorksheetProgressBox({
  formName,
  style,
  uiSchema,
  validator,
  context,
}) {
  const ids = uiSchema.children.map(section => section.id);
  return (
    <section style={{ ...styles.container, ...style }}>
      <h4 style={styles.header}>On this worksheet</h4>
      <WithValues
        name={formName}
        render={({ values }) => (
          <Scrollspy
            style={styles.list}
            items={ids}
            currentClassName="RatingWorksheetProgressBox__section--selected"
          >
            {uiSchema.children.map(section => {
              let enabled = true;
              if (section.disabled) {
                const disabled = []
                  .concat(section.disabled)
                  .every(condition =>
                    validator.evaluateCondition(
                      condition,
                      values,
                      values,
                      context
                    )
                  );
                enabled = !disabled;
              }
              const link_style = enabled
                ? styles.link.enabled
                : styles.link.disabled;
              return (
                <li key={section.id} style={styles.list_item}>
                  <span style={link_style}>{section.title}</span>
                </li>
              );
            })}
          </Scrollspy>
        )}
      />
    </section>
  );
}
