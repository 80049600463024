import React from 'react';
import Label from './Label';
import { getValidationClassName } from './utils';

const Helper = ({ children }) => {
  return <span 
          className='help-text'
          style={{ fontSize: '0.875rem' }}
        >
          {children}
        </span>;
};

const styles = {
  cell: {
    display: 'table-cell',
    padding: '5px 0',
    verticalAlign: 'middle',
    width: '170px'
  },
  titleCell: {
    display: 'table-cell',
    padding: '5px 0',
    verticalAlign: 'middle',
    width: '170px',
    fontSize: '.9rem'
  }
};

const TableControl = ({
  field,
  required,
  error,
  warn,
  info,
  touched,
  children,
}) => {

  const validationClassName = getValidationClassName(error, warn, touched);

  return (
    <div className={validationClassName} style={{ marginBottom: '5px' }}>
      <div style={{ display: 'table-row' }}>
        <div style={styles.titleCell}>
          {field.title && <Label required={required}>{field.title}</Label>}
        </div>
        <div style={styles.cell}>{children}</div>
        <div style={{ ...styles.cell, paddingLeft: '15px' }}>
          {field.helper && <Helper>{field.helper}</Helper>}
        </div>
      </div>
      {((touched && (error || warn)) || info) && (
        <div>
          {touched && error && <Helper>{error}</Helper>}
          {touched && warn && <Helper>{warn}</Helper>}
          {info && <Helper>{info}</Helper>}
        </div>
      )}
    </div>
  );
};

export default TableControl;
