const UpdatePremiumAdjustments = (fetch, auth) => (rating_id, diff) => {
  if (!diff) return Promise.resolve({ success: true });
  return auth
    .getAccessToken()
    .then(token => {
      if (!token) {
        auth.signOut();
        return Promise.reject(new Error('User is not authenticated.'));
      }
      return token;
    })
    .then(token =>
      fetch(`/ratings/${rating_id}/premium`, {
        method: 'PATCH',
        headers: {
          Authorization: token,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          premium: diff,
        }),
      })
    )
    .then(response => response.json())
    .catch(response => {
      return response.json().then(body => {
        return Promise.reject(new Error(body.message));
      });
    });
};

export default UpdatePremiumAdjustments;
