import React from 'react';
import ReactSelect from 'react-select';

import Control from './Control';

const Select = props => {
  const { field, name, hidden, input } = props;
  if (hidden) return null;
  return (
    <Control {...props}>
      <ReactSelect
        value={field.options.filter(({ value }) => value === input.value)}
        onChange={(option, actionMeta) => {
          input.onChange(option && option.value)
        }}
        onBlur={option => input.onBlur(option && option.value)}
        placeholder={field.placeholder}
        getOptionLabel={(option) => option.label}
        getOptionValue={(option) => option.value}
        options={field.options}
        isClearable={true}
        id={name}
        styles={{
          control: (base) => ({ ...base, width: '100%' }),
          option: (base, {isFocused, isSelected}) => ({ 
            ...base, 
            color: '#555',
            backgroundColor: isFocused || isSelected ? '#eff7ff' : '#fff'
          })
        }}
      />
    </Control>
  );
};

export default Select;
