import React from 'react';
import { Link } from 'react-router-dom';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import moment from 'moment';
import sortBy from 'lodash/sortBy';

import { QUOTE_STATUSES, compareStatuses } from '../../../statuses';
import { InsuredSummarySection } from '../../../../submissions';

const styles = {
  container: {
    border: '1px solid black',
    padding: '20px',
  },
  header: { marginTop: '0', marginBottom: '15px', fontSize: '1rem' },
  list: {
    listStyleType: 'none',
    padding: '0',
    margin: '0',
  },
  item: { padding: '0', marginTop: '10px', fontSize: '0.875rem' },
};

function formatDate(date) {
  return moment(date).format('M/D');
}

function getQuoteName(quote, current_quote) {
  if (quote.id === current_quote.id) {
    return <strong>{quote.display_name}</strong>;
  }
  return <Link to={`/quotes/${quote.id}`}>{quote.display_name}</Link>;
}

function getStatusLabel(quote) {
  const is_quoted = compareStatuses(quote.status, QUOTE_STATUSES.QUOTED) >= 0;
  if (is_quoted) {
    return (
      <strong className="text-success" style={{ marginLeft: '10px' }}>
        Completed ({formatDate(quote.updated_at)})
      </strong>
    );
  }
  return (
    <span style={{ marginLeft: '10px' }}>
      Last edited ({formatDate(quote.updated_at)})
    </span>
  );
}

const CurrentQuoteTooltip = ({ quote, children }) => {
  const tooltip = (
    <Tooltip id={quote.id}>You are currently viewing this quote...</Tooltip>
  );
  return (
    <OverlayTrigger overlay={tooltip} placement="bottom">
      <span>{children}</span>
    </OverlayTrigger>
  );
};

const renderQuote = (quote, current_quote) => {
  let QuoteWrapper = React.Fragment;
  const props = {};
  if (quote.id === current_quote.id) {
    Object.assign(props, { quote });
    QuoteWrapper = CurrentQuoteTooltip;
  }
  return (
    <QuoteWrapper {...props}>
      {getQuoteName(quote, current_quote)}
      {getStatusLabel(quote)}
    </QuoteWrapper>
  );
};

function QuoteHistory({ current_quote, related_quotes, productConfig, style }) {
  const displayQuotes = sortBy(related_quotes, 'created_at');
  return (
    <section style={{ ...styles.container, ...style }}>
      <section>
        <h3 style={styles.header}>History</h3>
        <ul style={styles.list}>
          {displayQuotes.map(quote => {
            return (
              <li key={quote.id} style={styles.item}>
                {renderQuote(quote, current_quote)}
              </li>
            );
          })}
        </ul>
      </section>
      <section style={{ marginTop: '20px' }}>
        <h3 style={styles.header}>Quote Details</h3>
        <h4 style={{ fontSize: '0.875rem' }}>Paper</h4>
        <p>
          {productConfig.carrier.name} (
          {productConfig.is_surplus_lines ? 'Non-Admitted' : 'Admitted'})
        </p>
        <InsuredSummarySection named_insured={current_quote.named_insured} />
      </section>
    </section>
  );
}

export default QuoteHistory;
