import React from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';

import { compareStatuses, QUOTE_STATUSES } from '../../../../../quote/statuses';

const tableConfig = {
  columns: [
    {
      key: '1',
      title: 'Name',
      content: quote => (
        <Link to={`/quotes/${quote.id}/`}>{quote.display_name}</Link>
      ),
    },
    {
      key: '2',
      title: 'Status',
      content: quote => {
        const is_quoted =
          compareStatuses(quote.status, QUOTE_STATUSES.QUOTED) === 0;
        if (!is_quoted)
          return <strong style={{ color: '#f5a623' }}>In Progress</strong>;
        const label_complete = `Completed (${moment(quote.updated_at).format(
          'M/D'
        )})`;
        return <strong className="text-success">{label_complete}</strong>;
      },
    },
    {
      key: '3',
      title: 'Created',
      content: quote => moment(quote.created_at).format('M/D/YYYY'),
    },
    {
      key: '4',
      title: 'Action',
      content: quote => {
        return <Link to={`/quotes/${quote.id}/`}>View</Link>;
      },
    },
  ],
};

export default tableConfig;
