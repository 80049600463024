import React from 'react';
import { FormSection as ReduxFormSection } from 'redux-form';

import Form from 'react-bootstrap/Form';

import FieldContainer from '../../components/FieldContainer';

const styles = {
  helper: {
    fontSize: '0.875rem',
  },
};

/**
 * TODO: Currently, this code only checks whether a field's immediate children are touched.
 * Ideally, we should be checking grandchildren, etc. as well.
 */
const isTouched = (meta, fields) => {
  return fields.reduce((touched, field) => {
    return touched || (meta[field.name] && meta[field.name].touched);
  }, meta.touched);
};

const FieldGroup = ({
  name,
  title,
  description,
  fields,
  helper,
  input,
  meta,
}) => {
  const touched = isTouched(meta, fields);
  const validationClassName = !touched
    ? null
    : typeof meta.error === 'string'
      ? 'has-error'
      : typeof meta.warning === 'string'
        ? 'has-warning'
        : null;
  return (
    <ReduxFormSection
      name={name}
      component="fieldset"
      className={validationClassName}
    >
      <h3>{title}</h3>
      <p>{description}</p>
      {fields.map(field => (
        <FieldContainer key={field.key || field.name} field={field} />
      ))}
      {touched &&
        typeof meta.warning === 'string' && (
          <Form.Text style={styles.helper} muted>{meta.warning}</Form.Text>
        )}
      {touched &&
        typeof meta.error === 'string' && (
          <Form.Text style={styles.helper} muted>{meta.error}</Form.Text>
        )}
      {helper && <Form.Text style={styles.helper} muted>{helper}</Form.Text>}
    </ReduxFormSection>
  );
};

export default FieldGroup;
