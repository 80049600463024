import React from 'react';
import Alert from 'react-bootstrap/Alert';

export default function TooManyPriceOptionsAlert({ style }) {
  return (
    <Alert style={style} variant="danger">
      <strong>Maximum Price Options exceeded</strong>
      <p style={{ color: 'black', marginTop: '10px' }}>
        We can only fit <span style={{ fontWeight: 'bold' }}>five</span> price
        options on a quote letter. Please remove an option before continuing.
      </p>
    </Alert>
  );
}
