const BookEndorsement = (fetch, auth) => async (
  endorsement_id,
  { documents }
) => {
  const token = await auth.getAccessToken();
  const req = {
    url: `/endorsements/${endorsement_id}/documents`,
    options: {
      method: 'POST',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ documents }),
    },
  };
  const res = await fetch(req.url, req.options);
  const body = await res.json();
  return body;
};

export default BookEndorsement;
