import { combineReducers } from 'redux';
import { reducer as form } from 'redux-form';

import { reducer as auth } from '../auth';
import { reducer as common } from '../common';
import { reducer as submissions } from '../submissions';
import { reducer as ratings } from '../rating';
import { reducer as quotes } from '../quote';
import { reducer as binders } from '../binder';
import { reducer as policies } from '../policies';
import { reducer as endorsements } from '../endorsements';

export default function createRootReducer() {
  return combineReducers({
    form,
    auth,
    common,
    submissions,
    ratings,
    quotes,
    binders,
    policies,
    endorsements,
  });
}
