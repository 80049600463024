import React from 'react';
import FormControl from 'react-bootstrap/FormControl';

const styles = {
  input: {
    textAlign: 'right',
  },
};

const NumberControl = props => {
  const value = props.value === '' ? NaN : Number(props.value);
  return (
    <FormControl
      type="number"
      {...props}
      style={{ ...styles.input, ...props.style }}
      value={isNaN(value) ? '' : String(value)}
    />
  );
};

export default NumberControl;
