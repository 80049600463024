import { connect } from 'react-redux';
import { withRouter } from '../../../common/components/WithRouter';

import { bindPolicy } from '../../actions';

import BindPolicyButton from './BindPolicyButton';

const mapDispatchToProps = (dispatch, props) => {
  const binder_id = props.binder.id;
  return dispatch => ({
    bindPolicy: () => {
      return dispatch(bindPolicy(binder_id)).then(res => {
        props.navigate(`/binders/${binder_id}/documents`);
        return res;
      });
    },
  });
};

export default withRouter(connect(null, mapDispatchToProps)(BindPolicyButton));
