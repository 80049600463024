import React from 'react';
import Button from 'react-bootstrap/Button';

const styles = {
  label: {
    paddingRight: '10px',
    width: '100%',
  },
  middle: {
    paddingRight: '9px',
  },
  cell: {
    display: 'table-cell',
    verticalAlign: 'middle',
    padding: '10px 0',
  },
};

function EditableRowInactive(props) {
  return (
    <React.Fragment>
      <div style={{ ...styles.cell, ...styles.label }}>{props.label}</div>
      <div style={{ ...styles.cell, ...styles.middle }}>
        <Button onClick={props.startEditing} variant="link">
          Edit
        </Button>
      </div>
      <div style={styles.cell}>
        <Button onClick={props.handleRemoveClick} variant="link">
          Remove
        </Button>
      </div>
    </React.Fragment>
  );
}

export default EditableRowInactive;
