import React, { Component } from 'react';
import last from 'lodash/last';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';

import { permissions as submission_permissions } from '../../../../../submissions';
import {
  StandardPriceOptionSummaryCard,
  CopyRatingButton,
  permissions as rating_permissions,
} from '../../../../../rating';

import { AsyncMutationWrapper } from '../../../../../common';
import DeletedPriceOptions from './DeletedPriceOptions';

const styles = {
  section_header: {
    fontSize: '1.25rem',
    marginBottom: '10px',
  },
  summary_card: {
    margin: '0 0 20px',
  },
  error: {
    alert: {
      fontSize: '0.8125rem',
      marginTop: '1rem',
    },
    header: {
      fontSize: '0.8125rem',
      marginTop: 0,
    },
    body: {
      color: '#000000',
    },
  },
  placeholder_card: {
    container: {
      background: '#f0f0f0',
      height: '135px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginBottom: '20px',
    },
    column: {
      display: 'flex',
      flexDirection: 'column',
      textAlign: 'center',
    },
    text: {
      fontSize: '1.25rem',
      color: '#9b9b9b',
      marginBottom: '1rem',
    },
  },
  justDeletedCard: {
    container: {
      background: '#f0f0f0',
      height: '70px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginBottom: '20px',
    },
  },
};

function ErrorAlert(error) {
  return (
    <Alert style={{ ...styles.error.alert }} variant="danger">
      <h5 style={styles.error.header}>Error</h5>
      <section style={styles.error.body}>
        <p>Sorry. Something went wrong. Please try refreshing.</p>
        {error && (
          <ul>
            <li>{error.message}</li>
          </ul>
        )}
      </section>
    </Alert>
  );
}

const PlaceholderCard = ({ createRating }) => {
  return (
    <AsyncMutationWrapper
      mutate={createRating}
      render={({ error, loading, mutate }) => {
        return (
          <React.Fragment>
            <div style={styles.placeholder_card.container}>
              <div style={styles.placeholder_card.column}>
                <div style={styles.placeholder_card.text}>No Price Options</div>
                <Button
                  variant="primary"
                  onClick={mutate}
                  disabled={loading}
                >
                  {loading ? 'Loading...' : 'Start Price Option'}
                </Button>
              </div>
            </div>
            {error && <ErrorAlert error={error} />}
          </React.Fragment>
        );
      }}
    />
  );
};

const JustDeletedCard = ({ restoreRating }) => {
  return (
    <AsyncMutationWrapper
      mutate={restoreRating}
      render={({ error, loading, mutate }) => {
        return (
          <React.Fragment>
            <div style={styles.justDeletedCard.container}>
              <div>Option Removed</div>
              <Button
                variant="link"
                onClick={mutate}
                disabled={loading}
              >
                {loading ? 'Loading...' : 'Undo'}
              </Button>
            </div>
            {error && <ErrorAlert error={error} />}
          </React.Fragment>
        );
      }}
    />
  );
};

const getRatingNumber = ({ quote, rating }) => {
  return (
    quote.ratings
      .filter(quoteRatingAssoc => !quoteRatingAssoc.deleted)
      .findIndex(quoteRatingAssoc => quoteRatingAssoc.id === rating.id) + 1
  );
};

export default class PriceOptionsSummarySection extends Component {
  state = {
    justDeleted: new Set(),
  };

  addJustDeleted = rating_id =>
    this.setState(
      state =>
        state.justDeleted.has(rating_id)
          ? state
          : { ...state, justDeleted: new Set(state.justDeleted).add(rating_id) }
    );
  removeJustDeleted = rating_id =>
    this.setState(state => {
      if (state.justDeleted.has(rating_id)) {
        const justDeleted = new Set(state.justDeleted);
        justDeleted.delete(rating_id);
        return { ...state, justDeleted };
      }
      return state;
    });

  render() {
    let {
      style,
      quote,
      ratings,
      submission,
      productConfig,
      copyRating,
      removeRating,
      getRating,
      createRating,
      addRatingToQuote,
    } = this.props;
    const activeRatings = ratings.filter(
      rating => !rating.deleted || this.state.justDeleted.has(rating.id)
    );
    const deletedRatings = ratings.filter(rating => rating.deleted);
    const lastRating = last(activeRatings);
    return (
      <section style={style}>
        <h3 style={styles.section_header}>Premium</h3>
        {!ratings.filter(rating => !rating.deleted).length && (
          <PlaceholderCard createRating={createRating} />
        )}
        {activeRatings.map(
          (rating, i) =>
            this.state.justDeleted.has(rating.id) ? (
              <JustDeletedCard
                key={rating.id}
                restoreRating={() => {
                  const params = {
                    rating_id: rating.id,
                  };
                  if (i + 1 < activeRatings.length) {
                    Object.assign(params, { before: activeRatings[i + 1].id });
                  }
                  return addRatingToQuote(params).then(() =>
                    this.removeJustDeleted(rating.id)
                  );
                }}
                rating_id={rating.id}
              />
            ) : (
              <StandardPriceOptionSummaryCard
                style={styles.summary_card}
                key={rating.id}
                rating={rating}
                quote={quote}
                productConfig={productConfig}
                displayName={`Option ${getRatingNumber({ quote, rating })}`}
                handleDelete={() =>
                  removeRating(rating.id).then(() =>
                    this.addJustDeleted(rating.id)
                  )
                }
                actions={[
                  {
                    title: 'Edit',
                    onSelect: () => getRating(rating.id),
                    disabled: !rating_permissions.canUpdateRating({
                      rating,
                    }),
                  },
                  {
                    title: 'Copy',
                    onSelect: () => copyRating(rating.id),
                    disabled: !submission_permissions.canCreateRatings({
                      quote,
                      submission,
                    }),
                  },
                ]}
              />
            )
        )}
        {deletedRatings.length !== 0 && (
          <DeletedPriceOptions
            deletedRatings={deletedRatings}
            quote={quote}
            productConfig={productConfig}
            removeJustDeleted={this.removeJustDeleted}
          />
        )}
        {!!lastRating && (
          <CopyRatingButton rating={lastRating}>
            Add Price Option
          </CopyRatingButton>
        )}
      </section>
    );
  }
}
