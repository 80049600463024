import {
  GET_CONFIGURATION_REQUEST,
  GET_CONFIGURATION_SUCCESS,
} from './constants';

import * as selectors from '../redux/selectors';

export function getConfiguration() {
  return (dispatch, getState, { api }) => {
    const configuration = selectors.configuration(getState());
    if (configuration) {
      return Promise.resolve({ configuration });
    }
    dispatch({ type: GET_CONFIGURATION_REQUEST });
    return api.common.getConfiguration().then(response => {
      dispatch({
        type: GET_CONFIGURATION_SUCCESS,
        payload: {
          configuration: response,
        },
      });
      return response;
    });
  };
}
