import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';

import { WithAuth } from '../../../auth';
import { Toggleable } from '../../../common';
import { SubmissionSummaryCard } from '../../';

import ClearanceResultsHeader from './ClearanceResultsHeader';
import ClearanceConfirmationPrompt from './ClearanceConfirmationPrompt';
import StartQuoteButton from './StartQuoteButton';
import MatchesList from './MatchesList';
import ClearanceOverridePrompt from './ClearanceOverridePrompt';
import DeclinationModal from '../DeclinationModal';
import DeclinationDetails from '../DeclinationDetails';

import * as permissions from '../../permissions';

const styles = {
  container: { paddingTop: '25px', paddingBottom: '50px' },
  header: { marginBottom: '1em' },
  broker_confirmation: { marginTop: '1em' },
  start_quote: { marginTop: '1em' },
  clearance_override: { marginTop: '2em' },
  submission_declined: {
    display: 'block',
    fontSize: '0.875rem',
    margin: '20px 0px 10px',
  },
};

const submissionHasNoMatches = submission => {
  const matches = submission.clearance.matches;
  return !(matches && matches.length);
};

const Column = ({ children, ...props }) => (
  <Col md={{span: 6, offset: 1}} {...props}>
    {children}
  </Col>
);

export default function ClearanceResults(props) {
  const { submission } = props;
  return (
    <Container fluid="md" style={styles.container}>
      <Row>
        <Col md={{offset: 1}}>
          <ClearanceResultsHeader
            style={styles.header}
            submission={submission}
          />
        </Col>
      </Row>
      <Row>
        <Col
          style={{
            backgroundColor: '#d8d8d8',
            paddingTop: '20px',
            paddingBottom: '20px',
          }}
          md={{span: 10, offset: 1}}
        >
          <Row>
            <Col md={8}>
              <SubmissionSummaryCard
                style={{ backgroundColor: 'white' }}
                submission={submission}
              />
            </Col>
            <Col md={4} lg={{offset: 0}}>
              <WithAuth
                render={({ loading, user }) => {
                  if (loading) {
                    return null;
                  }
                  return (
                    <React.Fragment>
                      <ClearanceConfirmationPrompt
                        style={styles.broker_confirmation}
                        submission={submission}
                      />
                      {permissions.canChangeDeclinationStatus({
                        submission,
                      }) && (
                        <Toggleable
                          render={({ visible, show, hide }) => {
                            return (
                              <section>
                                <h3 style={{ fontSize: '1rem', marginTop: '20px' }}>
                                  Decline Submission
                                </h3>
                                <Button variant="link" onClick={show}>
                                  Record Declination Status
                                </Button>
                                <DeclinationModal
                                  hide={hide}
                                  show={show}
                                  submission={submission}
                                  visible={visible}
                                />
                              </section>
                            );
                          }}
                        />
                      )}
                      {submission.declined && (
                        <section>
                          <strong
                            className="text-danger"
                            style={styles.submission_declined}
                          >
                            Submission declined
                          </strong>
                          <DeclinationDetails
                            declination={submission.declination}
                          />
                        </section>
                      )}
                      <section style={styles.start_quote}>
                        <h3 style={{ fontSize: '1rem' }}>Price Submission</h3>
                        <StartQuoteButton submission={submission}>
                          Start Quote
                        </StartQuoteButton>
                      </section>
                    </React.Fragment>
                  );
                }}
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Column>
          {submissionHasNoMatches(submission) ? null : (
            <React.Fragment>
              <MatchesList matches={submission.clearance.matches} />
              <ClearanceOverridePrompt
                style={styles.clearance_override}
                submission={submission}
              />
            </React.Fragment>
          )}
        </Column>
      </Row>
    </Container>
  );
}
