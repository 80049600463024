import React from 'react';

const styles = {
  header: {
    margin: '0.5em 0 0.5em',
  },
  text: {
    marginBottom: '1em',
  },
};

export default function HomeUnauthenticated({ auth }) {
  return (
    <React.Fragment>
      <h1 style={styles.header}>Welcome</h1>
      <p style={styles.text}>Have an account?</p>
      <button
        className="btn btn-primary"
        onClick={() => {
          auth.signInWithRedirect('/');
        }}
      >
        Log in to Protector
      </button>
    </React.Fragment>
  );
}
