import get from 'lodash/get';
import set from 'lodash/set';
import { utils } from '../../../common/components/FormBuilderV2';
import uiSchema from './ui-schema.json';

export const mapPropsToFormValues = ({ rating }) => {
  const { limits_retentions } = rating;
  const leafFields = utils
    .getAllFields(uiSchema)
    .filter(field => !field.children);
  const formValues = {};
  leafFields.forEach(field => {
    let value = get(limits_retentions, field.name);
    value = value != null ? value : field.defaultValue;
    if (value != null) {
      set(formValues, field.name, value);
    }
  });
  return formValues;
};
