import React from 'react';
import Button from 'react-bootstrap/Button';

export default function UpdateQuoteButton({ submitting }) {
  return (
    <Button className="mt-3" type="submit" variant="primary" disabled={submitting}>
      {submitting ? 'Submitting...' : 'Add Quote Details'}
    </Button>
  );
}
