import { withRouter } from '../../../common/components/WithRouter';
import { connect } from 'react-redux';

import RatingWorksheet from './RatingWorksheet';

import * as actions from '../../actions';

import isValid from './isValid';
import getFormSectionSummaries from './getFormSectionSummaries';
import { mapFormValuesToRiskAnalysis } from './mapFormValuesToRiskAnalysis';

const mapDispatchToProps = (dispatch, props) => {
  const updateRiskAnalysis = form_values => {
    const initialValues = props.rating.risk_analysis;
    const { rating_id } = props.match.params;
    return dispatch(
      actions.updateRiskAnalysis(
        rating_id,
        mapFormValuesToRiskAnalysis(form_values),
        initialValues
      )
    );
  };
  const getPremium = values => {
    const initialValues = props.rating.risk_analysis;
    return updateRiskAnalysis(values, initialValues).then(() => {
      return dispatch(actions.getPremium(props.rating.id));
    });
  };
  return {
    updateRiskAnalysis,
    getPremium,
  };
};

export default withRouter(
  connect(
    null,
    mapDispatchToProps
  )(RatingWorksheet)
);

export { isValid, getFormSectionSummaries };
