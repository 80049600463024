import React from 'react';

import { PriceOptionSummary } from '../../../rating';

const styles = {
  section_header: {
    fontSize: '1.25rem',
    marginBottom: '10px',
  },
};

export default function PriceOptionSection({ style, rating, ...props }) {
  return (
    <section style={style} {...props}>
      <h3 style={styles.section_header}>Premium</h3>
      <PriceOptionSummary key={rating.id} rating={rating} />
    </section>
  );
}
