import validator from 'validator';

const isNotEmpty = (value) => value && typeof value === "string" && value.trim().length;
const validations = {
  broker: {
    name(value = '') {
      if (!isNotEmpty(value)) return 'Required';
    },
    brokerage(value = '') {
      if (!isNotEmpty(value)) {
        return 'Required';
      }
    },
    email(value = '') {
      if (!isNotEmpty(value)) {
        return 'Required';
      }
      if (!validator.isEmail(value)) {
        return 'Please enter a valid e-mail address';
      }
    },
  },
  named_insured: {
    name(value = '') {
      if (!isNotEmpty(value)) return 'Required';
    },
    address: {
      street_line_1(value = '') {
        if (!isNotEmpty(value)) return 'Required';
      },
      city(value = '') {
        if (!isNotEmpty(value)) return 'Required';
      },
      state(value = '') {
        if (!isNotEmpty(value)) return 'Required';
      },
      zip(value = '') {
        if (!isNotEmpty(value)) return 'Required';
        if (!validator.isPostalCode(value, 'US'))
          return 'Please enter a valid Zip Code';
      },
    },
  },
};

export default validations;
