import { useState, useEffect } from 'react';

const shallowEqual = (obj1, obj2) => {
  if (Object.keys(obj1).length !== Object.keys(obj2).length) {
    return false;
  }
  for (let key in obj1) {
    if (obj1[key] !== obj2[key]) return false;
  }
  return true;
};

const AsyncQueryWrapper = (props) => {
  const [variables, setVariables] = useState({});
  const [responseState, setResponseState] = useState({
    loading: true,
    data: null,
    error: null,
    hasSucceeded: false
  });

  const load = (props) => {
    setVariables({ ...props.variables });
    setResponseState({
      ...responseState,
      loading: true,
      error: null
    });

    return props
      .load(props.variables)
      .then((data) => {
        setResponseState({
          ...responseState,
          data: data,
          hasSucceeded: true,
          loading: false,
          error: null
        });
        return data;
      })
      .catch((error) => {
        setResponseState({
          ...responseState,
          data: null,
          loading: false,
          error: error
        });
        return Promise.reject(error);
      });
  };

  useEffect(() => {
    load(props);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!shallowEqual(variables, props.variables)) {
      load(props);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props, props.variables, variables]);

  return props.render({
    reload: () => load(props),
    loading: !!responseState.loading,
    data: responseState.data,
    error: responseState.error,
    hasSucceeded: responseState.hasSucceeded,
  });
};

AsyncQueryWrapper.defaultProps = { variables: {} };

export default AsyncQueryWrapper;
