import PropTypes from 'prop-types';
import React from 'react';
import Select from 'react-select';

// https://gist.github.com/leocristofani/98312e61807db8f32e720c9f97a186e5

/**
 * For single select, Redux Form keeps the value as a string, while React Select
 * wants the value in the form { value: "grape", label: "Grape" }
 *
 * * For multi select, Redux Form keeps the value as array of strings, while React Select
 * wants the array of values in the form [{ value: "grape", label: "Grape" }]
 */
const singleChangeHandler = onChange => value =>
  onChange(value ? value.value : '');

const multiChangeHandler = onChange => values =>
  onChange(values.map(({ value }) => value));

const transformValue = (selectedValue, options, multi) => {
  if (multi && typeof selectedValue === 'string') return [];

  const filteredOptions = options.filter(
    ({ value }) =>
      multi ? selectedValue.includes(value) : selectedValue === value
  );

  return multi ? filteredOptions : filteredOptions[0];
};

export default function ReasonSelect({
  className,
  input: { name, value, onBlur, onChange, onFocus },
  options,
  multi,
  placeholder,
}) {
  const transformedValue = transformValue(value, options, multi);
  const changeHandler = multi
    ? multiChangeHandler(onChange)
    : singleChangeHandler(onChange);
  return (
    <Select
      className={className}
      isMulti={multi}
      name={name}
      onBlur={() => onBlur(value)}
      onChange={changeHandler}
      onFocus={onFocus}
      options={options}
      placeholder={placeholder}
      value={transformedValue}
      getOptionValue={(option) => option.value}
      styles={{
        control: (base) => ({ ...base, width: '100%' }),
        option: (base, {isFocused, isSelected}) => ({ 
          ...base, 
          color: '#555',
          backgroundColor: isFocused || isSelected ? '#eff7ff' : '#fff'
        })
      }}
    />
  );
}

ReasonSelect.defaultProps = {
  className: '',
  placeholder: 'Select an Option',
  multi: false,
};

ReasonSelect.propTypes = {
  className: PropTypes.string,
  input: PropTypes.shape({
    onBlur: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    onFocus: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
  }).isRequired,
  multi: PropTypes.bool,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })
  ),
};
