import React from 'react';
import InputGroup from 'react-bootstrap/InputGroup';
import FormControl from 'react-bootstrap/FormControl';

import TableControl from './TableControl';

const styles = {
  input: {
    textAlign: 'right',
  },
};

const CurrencyTableRow = props => {
  const { input } = props;
  return (
    <TableControl {...props}>
      <InputGroup>
        <InputGroup.Text>$</InputGroup.Text>
        <FormControl
          type="text"
          {...input}
          style={{ ...props.style, ...styles.input }}
        />
      </InputGroup>
    </TableControl>
  );
};

export default CurrencyTableRow;
