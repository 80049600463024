import {
  compareStatuses as compareSubmissionStatuses,
  SUBMISSION_STATUSES,
} from '../submissions';

import {
  compareStatuses as comparePolicyStatuses,
  POLICY_STATUSES,
} from './statuses';

export const canUpdateBroker = ({ policy }) => {
  const is_policy_issued =
    comparePolicyStatuses(policy.status, POLICY_STATUSES.ISSUED) >= 0;
  return !is_policy_issued;
};

export const canUpdateSLBroker = ({ policy }) => {
  const is_policy_issued =
    comparePolicyStatuses(policy.status, POLICY_STATUSES.ISSUED) >= 0;
  return !is_policy_issued;
};

export const canUpdateTermsConditions = ({ policy }) => {
  const is_policy_issued =
    comparePolicyStatuses(policy.status, POLICY_STATUSES.ISSUED) >= 0;
  return !is_policy_issued;
};

export const canIssuePolicy = ({ policy, submission }) => {
  const is_submission_issued =
    compareSubmissionStatuses(submission.status, SUBMISSION_STATUSES.ISSUED) >=
    0;
  const is_policy_issued =
    comparePolicyStatuses(policy.status, POLICY_STATUSES.ISSUED) >= 0;
  return !is_submission_issued && !is_policy_issued;
};

export const canAddEndorsement = ({ policy }) => {
  return comparePolicyStatuses(policy.status, POLICY_STATUSES.ISSUED) >= 0;
};

export const canDownloadDocuments = ({ policy }) => {
  return comparePolicyStatuses(policy.status, POLICY_STATUSES.ISSUED) >= 0;
};
