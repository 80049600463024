// removeSpecialChars() and sanitizeFileName() are copied from backend
// shared/utils/index.js and it should be updated when these are updated

const removeSpecialChars = str => {
  const special_chars_regex = /[*.”/\[\]:;|=,<?>&$#!‘{}()]/g; // eslint-disable-line no-useless-escape
  return str.replace(special_chars_regex, '');
};

export const sanitizeFileName = raw => {
  return removeSpecialChars(raw)
    .trim()
    .replace(/\s/g, '_');
};

export const combinePathPatterns = (patternArr) => {
  if(patternArr == null || patternArr.length === 0) return "";
  if(patternArr.length === 1) return patternArr[0];
  let combinedPattern = "";
  patternArr.forEach((pattern, index) => {
    if(index < patternArr.length - 1) pattern = (pattern.endsWith("/*")) ? pattern.substring(0, pattern.length - 2) : pattern;
    combinedPattern = (index > 0) ? combinedPattern + "/" + pattern : pattern;
  });

  return combinedPattern;
}
