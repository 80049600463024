export const GET_QUOTE_BY_ID_REQUEST = 'GET_QUOTE_BY_ID_REQUEST';
export const GET_QUOTE_BY_ID_SUCCESS = 'GET_QUOTE_BY_ID_SUCCESS';
export const GET_QUOTE_BY_ID_FAILURE = 'GET_QUOTE_BY_ID_FAILURE';
export const SEARCH_QUOTES_BY_SUBMISSION_REQUEST =
  'SEARCH_QUOTES_BY_SUBMISSION_REQUEST';
export const SEARCH_QUOTES_BY_SUBMISSION_SUCCESS =
  'SEARCH_QUOTES_BY_SUBMISSION_SUCCESS';
export const SEARCH_QUOTES_BY_SUBMISSION_FAILURE =
  'SEARCH_QUOTES_BY_SUBMISSION_FAILURE';
export const CREATE_QUOTE_REQUEST = 'CREATE_QUOTE_REQUEST';
export const CREATE_QUOTE_SUCCESS = 'CREATE_QUOTE_SUCCESS';
export const CREATE_QUOTE_FAILURE = 'CREATE_QUOTE_FAILURE';
export const GET_RATINGS_BY_QUOTE_REQUEST = 'GET_RATINGS_BY_QUOTE_REQUEST';
export const GET_RATINGS_BY_QUOTE_SUCCESS = 'GET_RATINGS_BY_QUOTE_SUCCESS';
export const GENERATE_QUOTE_DOCUMENTS_REQUEST =
  'GENERATE_QUOTE_DOCUMENTS_REQUEST';
export const GENERATE_QUOTE_DOCUMENTS_SUCCESS =
  'GENERATE_QUOTE_DOCUMENTS_SUCCESS';
export const GENERATE_QUOTE_DOCUMENTS_FAILURE =
  'GENERATE_QUOTE_DOCUMENTS_FAILURE';
export const GET_QUOTE_BY_BINDER_ID_REQUEST = 'GET_QUOTE_BY_BINDER_ID_REQUEST';
export const GET_QUOTE_BY_BINDER_ID_SUCCESS = 'GET_QUOTE_BY_BINDER_ID_SUCCESS';
export const CREATE_QUOTE_FROM_SUBMISSION_REQUEST =
  'CREATE_QUOTE_FROM_SUBMISSION_REQUEST';
export const CREATE_QUOTE_FROM_SUBMISSION_SUCCESS =
  'CREATE_QUOTE_FROM_SUBMISSION_SUCCESS';
export const CREATE_PREPOPULATED_QUOTE_FROM_SUBMISSION_REQUEST =
  'CREATE_PREPOPULATED_QUOTE_FROM_SUBMISSION_REQUEST';
export const CREATE_PREPOPULATED_QUOTE_FROM_SUBMISSION_SUCCESS =
  'CREATE_PREPOPULATED_QUOTE_FROM_SUBMISSION_SUCCESS';
export const GET_QUOTES_BY_RATING_REQUEST = 'GET_QUOTE_BY_RATING_REQUEST';
export const GET_QUOTES_BY_RATING_SUCCESS = 'GET_QUOTES_BY_RATING_SUCCESS';
export const CREATE_QUOTE_COPY_REQUEST = 'CREATE_QUOTE_COPY_REQUEST';
export const CREATE_QUOTE_COPY_SUCCESS = 'CREATE_QUOTE_COPY_SUCCESS';
export const ADD_RATING_TO_QUOTE_REQUEST = 'ADD_RATING_TO_QUOTE_REQUEST';
export const ADD_RATING_TO_QUOTE_SUCCESS = 'ADD_RATING_TO_QUOTE_SUCCESS';
export const REMOVE_RATING_FROM_QUOTE_REQUEST =
  'REMOVE_RATING_FROM_QUOTE_REQUEST';
export const REMOVE_RATING_FROM_QUOTE_SUCCESS =
  'REMOVE_RATING_FROM_QUOTE_SUCCESS';
export const UPDATE_QUOTE_REQUEST = 'UPDATE_QUOTE_REQUEST';
export const UPDATE_QUOTE_SUCCESS = 'UPDATE_QUOTE_SUCCESS';
