import React from 'react';
import { Flag } from '../../../common/components/Flag';
import { reduxForm } from 'redux-form';

import Button from 'react-bootstrap/Button';

import { StickySidebar } from '../../../layouts';
import { SubmissionSummaryCard } from '../../../submissions';
import { StandardPriceOptionSummaryCard } from '../../../rating';
import SaveOnLeave from '../../../common/components/SaveOnLeave';
import ExtendedReportingSection from './ExtendedReportingSection';

import { Subjectivities } from '../../../binder';
import submit from './submit';

const styles = {
  section_container: {
    borderTop: '1px solid grey',
    marginTop: '35px',
    paddingTop: '20px'
  },
  sidebar: {
    margin: 'auto',
    maxWidth: '240px',
  },
  submitButton: {
    marginTop: '1.25rem',
  },
};

function PolicyWorksheet(props) {
  const {
    config,
    policy,
    submission,
    rating,
    quote,
    initialValues,
    submitting,
    error,
    handleSubmit,
    form,
    updateTermsConditions,
  } = props;
  return (
    <StickySidebar
      renderMain={() => {
        return (
          <section>
            <form
              noValidate
              onSubmit={event => {
                // Promise.resolve(o) returns o if o is a promise
                // per http://www.ecma-international.org/ecma-262/6.0/#sec-promise.resolve
                const isPromise = o => Promise.resolve(o) === o;
                const submitResult = handleSubmit(event);
                if (isPromise(submitResult)) {
                  submitResult.then(e => {
                    // redux-form returns a resolved promise with errors,
                    // instead of erroring/rejecting as you'd expect.
                    //
                    // We need to check for errors, otherwise our .then() is
                    // still executed, even if we throw errors in our submit handler
                    if (e._error) return;
                    const { policy_id } = props.match.params;
                    props.navigate(`/policies/${policy_id}/details`);
                  });
                }
              }}
            >
              <SaveOnLeave
                form={form}
                save={values => updateTermsConditions(values, initialValues)}
              />
              <h1>Issue policy</h1>
              <StandardPriceOptionSummaryCard
                style={{ marginTop: '35px' }}
                rating={rating}
                submission={submission}
                quote={quote}
                productConfig={{
                  risk_analysis_schema: {},
                  limits_retentions_schema: {},
                }} // A little hacky, but we don't need validation here
              />
              <Subjectivities
                style={styles.section_container}
                subjectivities={policy.terms_conditions.subjectivities}
                options={config.subjectivities}
                loading={submitting}
                error={error}
                updateTermsConditions={handleSubmit}
                config={{ oldType: 'binder', newType: 'policy' }}
              />
              <ExtendedReportingSection style={styles.section_container} />
              <Button
                style={styles.submitButton}
                variant="primary"
                type="submit"
                disabled={submitting}
              >
                {submitting ? 'Working...' : 'Update policy'}
              </Button>
              {error &&
                !submitting && (
                  <p className="text-danger" style={styles.error}>
                    <span style={styles.error_text}>
                      Sorry, there was an error updating the policy.
                    </span>
                    <span style={styles.error_text}>
                      Please submit again now.
                    </span>
                  </p>
                )}
            </form>
          </section>
        );
      }}
      renderAside={() => {
        return (
          <section style={styles.sidebar}>
            <Flag
              name="policy.worksheet.submission_summary_card"
              render={() => {
                return <SubmissionSummaryCard flex submission={submission} />;
              }}
            />
          </section>
        );
      }}
    />
  );
}

export default reduxForm({
  form: 'policy_terms_conditions',
  onSubmit: submit,
})(PolicyWorksheet);
