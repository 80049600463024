import React from 'react';

import moment from 'moment-timezone';
import declination_reasons_map from '../DeclinationModal/Reasons/declination_reasons.json';
const styles = {
  flex_detail_container: {
    display: 'flex',
    flexWrap: 'wrap',
    margin: '0px -5px',
  },
  flex_detail_header: {
    fontSize: '20px',
    margin: '0 5px',
  },
  flex_detail_section: {
    flex: '1 1 45%',
    minWidth: '12em',
    margin: '5px',
  },
  subheader: {
    fontSize: '0.875rem',
    fontWeight: 'bold',
    textTransform: 'uppercase',
  },
  detail_text: {
    fontSize: '0.875rem',
    margin: '0.25em 0',
    wordWrap: 'break-word',
  },
  declined_by: {
    margin: '5px',
  },
};

const getLocalTimezoneAbbreviation = dtm => {
  return moment.tz.zone(moment.tz.guess()).abbr(dtm.valueOf());
};

// placeholder is an 'em-dash':
// http://www.chicagomanualofstyle.org/qanda/data/faq/topics/HyphensEnDashesEmDashes/faq0002.html
const placeholder = '\u2014';

export default function DeclinationDetails({
  declination: {
    comments,
    declined_at,
    declined_by: { first_name, last_name } = {},
    reasons,
  } = {},
} = {}) {
  const declined_date = moment(declined_at).format('MMMM D YYYY');
  const declined_time = moment(declined_at).format('hh:mm A');
  const declined_time_zone = getLocalTimezoneAbbreviation(moment(declined_at));

  const reasons_label = reasons
    .map(reason => declination_reasons_map[reason])
    .join(', ');

  return (
    <section style={styles.flex_detail_container}>
      <h4
        style={{ ...styles.flex_detail_section, ...styles.flex_detail_header }}
      >
        Declination details
      </h4>
      <div style={{ ...styles.detail_text, ...styles.declined_by }}>
        Updated {declined_date} at {declined_time} {declined_time_zone} by{' '}
        {first_name} {last_name}
      </div>
      <section style={styles.flex_detail_section}>
        <h3 style={styles.subheader}>Reasons</h3>
        <div style={styles.detail_text}>{reasons_label}</div>
      </section>
      <section style={styles.flex_detail_section}>
        <h3 style={styles.subheader}>Comment</h3>
        <div style={styles.detail_text}>{comments || placeholder}</div>
      </section>
    </section>
  );
}
