import { SubmissionError } from 'redux-form';

export default async function submit(
  { comments, reasons, status },
  dispatch,
  { declineSubmission, openDeclinedSubmission, submission }
) {
  try {
    const handler =
      status === 'OPEN' ? openDeclinedSubmission : declineSubmission;
    const payload =
      status === 'OPEN'
        ? { submission }
        : {
            ...(comments ? { comments } : {}),
            reasons,
            submission,
          };
    return await handler(payload);
  } catch (error) {
    throw new SubmissionError({
      _error: 'Something went wrong when updating the declination status.',
    });
  }
}
