import React from 'react';
import PropTypes from 'prop-types';

import InsuredBrokerSummarySection from './InsuredBrokerSummarySection';
import SubmissionTypeBadge from './SubmissionTypeBadge';

const styles = {
  header: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  submission_number: {
    flexGrow: 3,
    fontSize: '1.5rem',
    margin: '0 0.5rem 0 0',
  },
  body: {
    flex: '1 1 100%',
    minWidth: '12rem',
  },
};

export default function BaseSummaryCard({
  className,
  data,
  displayBadge,
  flex,
  style,
  renderClearanceStatus,
}) {
  return (
    <section className={className} style={style}>
      <section style={styles.header}>
        <h1 style={styles.submission_number}>
          Submission # {data.submission_number}
        </h1>
        {displayBadge && <SubmissionTypeBadge data={data} />}
      </section>
      <section style={styles.body}>{renderClearanceStatus()}</section>
      <section style={styles.body}>
        <InsuredBrokerSummarySection submission={data} flex={flex} />
      </section>
    </section>
  );
}

BaseSummaryCard.defaultProps = {
  displayBadge: true,
  flex: false,
};

BaseSummaryCard.propTypes = {
  className: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired,
  flex: PropTypes.bool,
  renderClearanceStatus: PropTypes.func.isRequired,
};
