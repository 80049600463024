const UpdateRiskAnalysis = (fetch, auth) => (rating_id, diff) => {
  if (!diff) return Promise.resolve({ success: true });
  return auth
    .getAccessToken()
    .then(token => {
      if (!token) {
        auth.signOut();
        return Promise.reject(new Error('User is not authenticated.'));
      }
      return token;
    })
    .then(token =>
      fetch(`/ratings/${rating_id}/risk-analysis`, {
        method: 'PATCH',
        headers: {
          Authorization: token,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          risk_analysis: diff,
        }),
      })
    )
    .then(response => response.json());
};

export default UpdateRiskAnalysis;
