import React from 'react';
import Form from 'react-bootstrap/Form';

import { Toggleable } from '../../../common';

const SubmissionTypeToggle = ({ initiallyNewBusiness = true, render }) => {
  return (
    <Toggleable
      initiallyVisible={initiallyNewBusiness}
      render={({ visible, show, hide }) => {
        const handleChange = ({ target }) =>
          target.value === 'new_business' ? show() : hide();

        return (
          <React.Fragment>
            <fieldset>
              <legend
                style={{
                  marginBottom: '5px',
                  borderBottom: '0',
                  fontSize: '0.875rem',
                  fontWeight: 'bold',
                }}
              >
                Is this new business, or the renewal of an expiring policy?{' '}
                <span style={{ color: 'red' }}>*</span>
              </legend>
              <Form.Check
                type='radio'
                inline
                name="submission_type"
                value="new_business"
                onChange={handleChange}
                checked={visible}
                label='New Business'
              />
              <Form.Check
                type='radio'
                inline
                name="submission_type"
                value="renewal"
                onChange={handleChange}
                checked={!visible}
                label='Renewal'
              />
            </fieldset>
            {render({ type: visible ? 'new-business' : 'renewal' })}
          </React.Fragment>
        );
      }}
    />
  );
};

export default SubmissionTypeToggle;
