import { ApiError } from '../../api/errors';
import { isEmpty } from 'lodash';

const UpdateQuote = (fetch, auth) => async (quote_id, patch) => {
  if (!patch || isEmpty(patch)) return { success: true };

  const token = await auth.getAccessToken();
  if (!token) {
    auth.signOut();
    throw new Error('User is not authenticated.');
  }
  const response = await fetch(`/quotes/${quote_id}`, {
    method: 'PATCH',
    headers: {
      Authorization: token,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(patch),
  });
  switch (response.status) {
    case 200:
      return response.json();
    case 400:
    case 404:
      return response.json().then(body => {
        throw new ApiError(body.message);
      });
    default:
      throw new ApiError();
  }
};

export default UpdateQuote;
