import React from 'react';
import PropTypes from 'prop-types';

import { WorksheetSummarySection, CoverageDescription } from '../../../rating';

import * as PricingWorksheet from '../PricingWorksheet';
import PriceOptionHighlights from '../PriceOptionHighlights';

const styles = {
  section_header: {
    fontSize: '1.25rem',
    marginBottom: '10px',
  },
};

const renderDefaultHeader = ({ style, display_name }) => {
  return (
    !!display_name && <h3 style={styles.section_header}>{display_name}</h3>
  );
};

/**
 *
 * @param {object} style
 * @param {object} rating
 * @param {string} display_name
 * @param {function} renderHeader
 */
export default function PriceOptionSummary({
  style,
  rating,
  display_name,
  renderHeader = renderDefaultHeader,
}) {
  const limit_summary = PricingWorksheet.getLimitAggregations({ rating });
  return (
    <section style={style}>
      {renderHeader({ style: styles.section_header, rating, display_name })}
      <PriceOptionHighlights rating={rating} limit_summary={limit_summary} />
      <CoverageDescription
        limit_summary={limit_summary}
        style={{ marginTop: '10px', marginBottom: '15px' }}
      />
      <WorksheetSummarySection
        values={PricingWorksheet.getLimitSummaries({ rating })}
      />
    </section>
  );
}

PriceOptionSummary.propTypes = {
  /**
   * React style object passed to the outermost element rendered.
   */
  style: PropTypes.object,
  /**
   * Rating object. Used to populate data about the Price Option.
   */
  rating: PropTypes.object.isRequired,
  /**
   * The name of the Price Option to be displayed in the header. Not required
   * if a custom `renderHeader()` prop is provided.
   */
  display_name: PropTypes.string,
  /**
   * A custom function to render the header of the summary. Receives a named `style` argument
   * containing default styles to be applied, along with the `rating` and `display_name` props
   * passed to PriceOptionSummary.
   */
  renderHeader: PropTypes.func,
};
