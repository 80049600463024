const GetEligibleProducts = (fetch, auth) => async quote_id => {
  const token = await auth.getAccessToken();
  if (!token) {
    auth.signOut();
    throw new Error('User is not authenticated.');
  }
  const response = await fetch(`/quotes/${quote_id}/eligible-products`, {
    headers: {
      Authorization: token,
      'Content-Type': 'application/json',
    },
  });
  const body = await response.json();
  return body;
};

export default GetEligibleProducts;
