import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import SubmissionCard from './SubmissionCard/SubmissionCard';
import CompassLink from './CompassLink';

const styles = {
  header: {
    margin: '25px 0 25px',
    borderBottom: 'none',
  },
  list: {
    listStyle: 'none',
    marginTop: '20px',
    paddingLeft: '0',
  },
  list_item: {
    marginTop: '15px',
  },
};

export default function AccountWorkspace({
  insured_name,
  policy_number,
  submissions,
}) {
  return (
    <React.Fragment>
      <div style={{ backgroundColor: '#f0f0f0' }}>
        <Container fluid="md">
          <Row>
            <Col md={{span: 7, offset: 1}}>
              <div style={styles.header}><h1>{insured_name}</h1></div>
            </Col>
            {policy_number && <CompassLink policy_number={policy_number} />}
          </Row>
        </Container>
      </div>
      <Container fluid="md">
        <ul style={styles.list}>
          {submissions.map(rawSubmission => {
            const {
              quotes,
              binder,
              policy,
              endorsements,
              submission,
            } = rawSubmission;
            return (
              <li style={styles.list_item} key={submission.id}>
                <SubmissionCard
                  submission={submission}
                  quotes={quotes}
                  binder={binder}
                  policy={policy}
                  endorsements={endorsements}
                />
              </li>
            );
          })}
        </ul>
      </Container>
    </React.Fragment>
  );
}
