import get from 'lodash/get';

export default function ZScoreBase({ rating, valid, indeterminate, invalid }) {
  const z_score = get(rating, 'premium.z_score');
  if (!z_score) return indeterminate({ z_score, rating });
  if (z_score < 1.25) return invalid({ z_score, rating });
  return valid({ z_score, rating });
}

const noop = () => null;

ZScoreBase.defaultProps = {
  valid: noop,
  indeterminate: noop,
  invalid: noop,
};
