import React from 'react';
import Col from 'react-bootstrap/Col';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import PropTypes from 'prop-types';
import Tooltip from 'react-bootstrap/Tooltip';

const styles = {
  header: {
    borderBottom: 'none',
    margin: '25px 0 10px',

  },
  icon: {
    fontSize: '1.2rem',
    marginBottom: '0.4rem',
    marginLeft: '0.4rem',
    verticalAlign: 'middle',
  },
};

const CompassLink = ({ linkText, policy_number, tooltiptext }) => (
  <Col md={4}>
    <div style={styles.header}>
      <h1>
        <a
          className="btn btn-link"
          href={`${process.env.REACT_APP_COMPASS_URL}/${policy_number}/claims`}
          rel="noopener noreferrer"
          target="_blank"
        >
          <OverlayTrigger
            overlay={<Tooltip id="tooltip">{tooltiptext}</Tooltip>}
            placement="bottom"
          >
            <span>
              {linkText}
              <i className="bi bi-box-arrow-up-right" style={styles.icon}/>
            </span>
          </OverlayTrigger>
        </a>
      </h1>
    </div>
  </Col>
);

CompassLink.defaultProps = {
  linkText: 'Search in Argo Claims Portal',
  tooltiptext:
    'It may take 24 hours before policy updates are available in the claims portal',
};

CompassLink.propTypes = {
  linkText: PropTypes.string,
  policy_number: PropTypes.string.isRequired,
  tooltiptext: PropTypes.string,
};

export default CompassLink;
