import React from 'react';

const styles = {
  text: { display: 'block', textAlign: 'center' },
};

const errorsMap = {
  NotFound: (
    <p className="text-danger">
      <span style={styles.text}>
        Sorry, there was an error locating this Submission.
      </span>
      <span style={styles.text}>
        Please return to the homepage and try again.
      </span>
      <span style={styles.text}>
        If the problem persists, email support@protector.argo-digital.com.
      </span>
    </p>
  ),
  ServerError: (
    <p className="text-danger">
      <span style={styles.text}>Sorry, the Fetching robots got lost!</span>
      <span style={styles.text}>
        Please return to the homepage and try again.
      </span>
    </p>
  ),
};

export default function ViewSubmission({ error }) {
  return errorsMap[error.message];
}
