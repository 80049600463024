import React from 'react';
import PropTypes from 'prop-types';

class SaveOnLeave extends React.Component {
  componentWillUnmount() {
    if (!this.props.submitSucceeded && !this.props.disabled) {
      this.props.save(this.props.values);
    }
  }

  render() {
    return null;
  }
}

SaveOnLeave.propTypes = {
  disabled: PropTypes.bool,
};

SaveOnLeave.defaultProps = {
  disabled: false,
};

export default SaveOnLeave;
