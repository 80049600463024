const UpdateTermsConditions = (fetch, auth) => (policy_id, diff) => {
  if (!diff) return Promise.resolve({ success: true });
  return auth
    .getAccessToken()
    .then(token => {
      if (!token) {
        auth.signOut();
        return Promise.reject(new Error('User is not authenticated.'));
      }
      return token;
    })
    .then(token =>
      fetch(`/policies/${policy_id}/terms-conditions`, {
        method: 'PATCH',
        headers: {
          Authorization: token,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          terms_conditions: diff,
        }),
      })
    )
    .then(response => response.json());
};

export default UpdateTermsConditions;
