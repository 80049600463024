import React from 'react';
import { Link } from 'react-router-dom';

import Alert from 'react-bootstrap/Alert';

const styles = {
  alert: {
    fontSize: '0.8125rem',
  },
  header: {
    fontSize: '0.8125rem',
    marginTop: 0,
  },
  body: {
    color: '#000000',
  },
};

export default function ErrorAlert({ style, quote, error }) {
  return (
    <Alert style={{ ...styles.alert, ...style }} variant="danger">
      <h5 style={styles.header}>Quote Generation Failed</h5>
      <section style={styles.body}>
        <p>A Quote Letter could not be generated for the following reason:</p>
        <ul>
          <li>{error.message}</li>
        </ul>
        <p>
          Visit the <Link to={`/quotes/${quote.id}`}>Quote Workspace</Link> to
          double-check the above and try again.
        </p>
      </section>
      <Link className="btn btn-link" to={`/quotes/${quote.id}`}>
        Show Quote Workspace
      </Link>
    </Alert>
  );
}
