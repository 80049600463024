import React from 'react';

import SubmissionSummarySection from '../../SubmissionSummarySection';

const styles = {
  section_header: {
    fontSize: '1.25rem',
  },
};

export default function InsuredBrokerDetails({
  named_insured,
  broker,
  style,
  ...props
}) {
  return (
    <section style={style} {...props}>
      <h3 style={styles.section_header}>Insured and Broker Details</h3>
      <SubmissionSummarySection named_insured={named_insured} broker={broker} />
    </section>
  );
}
