import React from 'react';
import { cloneDeep } from 'lodash';
import Select from 'react-select';
import Creatable from 'react-select/creatable';
import Form from 'react-bootstrap/Form';

import UneditableRow from './UneditableRow';

const styles = {
  selection_list: {
    borderCollapse: 'collapse',
    display: 'table',
    paddingLeft: '0',
  },
  selection: {
    display: 'table-row',
    fontSize: '0.875rem',
  },
  emptystate: {
    fontSize: '14px',
    paddingBottom: '15px',
    paddingTop: '14px',
    color: 'gray',
  },
  label: {
    fontSize: '0.8125rem',
    opacity: '0.7',
  },
  search_icon: {
    border: 'solid',
    borderWidth: '1px',
    borderRadius: '4px 0 0 4px',
    borderColor: '#ccc',
    padding: '0 10px 0 10px',
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    backgroundColor:'#eee'
  }
};

const defaultSelectedItem = ({ style, selection, unselect }) => {
  return (
    <li style={style} key={selection.value}>
      <UneditableRow {...selection} handleRemoveClick={unselect} />
    </li>
  );
};

const defaultSelectProps = {
  backspaceRemoves: false,
  isClearable: false,
  closeOnSelect: true,
  isMulti: true,
  noResultsText: 'No matches found',
  onBlur: () => {},
  removeSelected: true,
  // override the default behavior, which creates a new item when
  // comma (code 188) is pressed
  shouldKeyDownEventCreateNewOption: ({ keyCode }) => {
    switch (keyCode) {
      case 9: // TAB
      case 13: // ENTER
        return true;
      default:
        return false;
    }
  },
  valueComponent: () => null,
  controlShouldRenderValue: false
};

class SelectControl extends React.Component {
  static defaultProps = {
    renderSelectedItem: defaultSelectedItem,
  };

  state = {
    options: cloneDeep(this.props.options),
  };

  handleRemoveClick = id => event => {
    const newSelected = this.props.input.value.filter(
      option => option.value !== id
    );
    this.props.input.onChange(newSelected);
  };

  updateOptions = (newValue, key) => {
    const updateValues = option =>
      option.value === key
        ? {
            value: `CUSTOM-${newValue}`,
            label: newValue,
          }
        : option;
    const options = this.state.options.map(updateValues);
    this.setState({ options });
  };

  render() {
    const {
      label,
      placeholder,
      creatable,
      renderSelectedItem: SelectedItem,
    } = this.props;
    const SelectElement = creatable ? Creatable : Select;
    return (
      <div>
        {!this.props.input.value.length ? (
          this.props.emptystate && (
            <div style={styles.emptystate}>{this.props.emptystate}</div>
          )
        ) : (
          <ul style={styles.selection_list}>
            {this.props.input.value.map((selection, i) => {
              // support compound keys for endorsements and simple keys for
              // subjectivities
              const key =
                selection.value.id + selection.value.version || selection.value;
              return (
                <SelectedItem
                  key={key}
                  style={styles.selection}
                  creatable={creatable}
                  selection={selection}
                  index={i}
                  input={this.props.input}
                  updateOptions={this.updateOptions}
                  unselect={this.handleRemoveClick(selection.value)}
                />
              );
            })}
          </ul>
        )}
        <div>
          <Form.Label style={styles.label}>{label}</Form.Label>
          <div style={{ display: 'flex' }}>
            <div style={styles.search_icon}>
              <i className="bi bi-search" />
            </div>
            <div style={{ flex: 'auto' }} data-testid='select-element'>
              <SelectElement
                {...this.props.input}
                {...defaultSelectProps}
                getOptionLabel={option => option[this.props.labelKey || 'label']}
                options={this.state.options}
                placeholder={placeholder}
                menuStyle={{ maxHeight: '298px' }}
                menuContainerStyle={{ maxHeight: '300px' }}
                onCreateOption={(value) => {
                  const option = {};
                  option[this.props.valueKey || 'value'] = `CUSTOM-${value}`;
                  option[this.props.labelKey || 'label'] = value;
                  this.props.input.onChange([...this.props.input.value, option]);
                }}
                styles={{
                  option: (base, {isFocused, isSelected}) => ({ 
                    ...base, 
                    color: '#555',
                    backgroundColor: isFocused || isSelected ? '#eff7ff' : '#fff'
                  })
                }}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default SelectControl;
