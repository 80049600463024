export const getById = (state, id) => {
  return state.by_id[id];
};

export const getBySubmission = (state, submission_id) => {
  const binder_ids = Object.keys(state.by_id);
  let target_binder = null;
  binder_ids.forEach(binder_id => {
    const binder = getById(state, binder_id);
    if (binder.submission_id === submission_id) {
      target_binder = binder;
    }
  });
  return target_binder;
};
