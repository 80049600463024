import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { Query } from '@apollo/client/react/components';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import orderBy from 'lodash/orderBy';

import { PageTitle } from '../../../common';
import { queries } from '../../../graphql';
import { POLICY_STATUSES, compareStatuses } from '../../../policies';

import AccountWorkspace from '../AccountWorkspace';

const styles = {
  summary_bar: {
    padding: '15px 0',
    backgroundColor: '#0d3e67'
  },
};

function HeaderBar() {
  return (
    <div style={styles.summary_bar}>
      <Container fluid="md">
        <Row>
          <Col md={{span: 10, offset:1}}>
            <span style={{ color: 'white' }}>Account</span>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

const decomposeGraphQlResponse = submissions => {
  return submissions.map(rawSubmission => {
    const {
      quotes: { results: quotes },
      binder,
      policy,
      endorsements: { results: endorsements },
      ...submission
    } = rawSubmission;
    return { submission, quotes, binder, policy, endorsements };
  });
};

const getLatestPolicyNumber = submissions =>
  submissions
    .map(submission => submission.policy)
    .filter(
      policy =>
        !!policy && compareStatuses(policy.status, POLICY_STATUSES.ISSUED) === 0
    )
    .reduce(
      (policyNumber, policy) => policyNumber || policy.policy_number,
      undefined
    );

export default function ViewAccountContainer(props) {
  const { match } = props;
  return (
    <React.Fragment>
      <Query
        query={queries.accounts.ViewAccountContainer}
        variables={{
          account_id: match.params.account_id,
          quoteLimit: 10,
          quoteOffset: 0,
        }}
        fetchPolicy="cache-and-network"
      >
        {({ data, loading, error }) => {
          if (loading && !data?.accountById)
            return <p className="text-center-2">Loading...</p>;
          if (error)
            return (
              <Row>Sorry. Something went wrong. Please try refreshing.</Row>
            );
          const sorted_submissions = orderBy(
            data.accountById.submissions,
            ['created_at'],
            'desc'
          );
          const current_submission = sorted_submissions[0];
          const insured_name = current_submission.named_insured.name;
          const policy_number = getLatestPolicyNumber(sorted_submissions);
          const submissions = decomposeGraphQlResponse(sorted_submissions);
          return (
            <React.Fragment>
              <HeaderBar />
              <Routes>
                <Route
                  path="/"
                  element={
                    <React.Fragment>
                      <PageTitle title={insured_name} />
                      <AccountWorkspace
                        insured_name={insured_name}
                        policy_number={policy_number}
                        submissions={submissions}
                      />
                    </React.Fragment>
                  }
                />
              </Routes>
            </React.Fragment>
          );
        }}
      </Query>
    </React.Fragment>
  );
}
