export const configuration = ({ common, endorsements }) => {
  if (!common.configuration) return null;
  return {
    ...common.configuration,
    endorsements: common.configuration.endorsements.map(({ id, version }) => {
      const option = endorsements.options.by_id[id][version];
      return { ...option, value: option.id };
    }),
  };
};
