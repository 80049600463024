import GetRating from './get';
import GetByBinder from './getByBinder';
import GetByPolicy from './getByPolicy';
import GetPremium from './getPremium';
import SearchRatingsBySubmission from './searchBySubmission';
import UpdateLimitsRetentions from './updateLimitsRetentions';
import UpdatePremiumAdjustments from './updatePremiumAdjustments';
import UpdateRiskAnalysis from './updateRiskAnalysis';
import CreateCopy from './createCopy';
import CreateFromQuote from './createFromQuote';

export default function RatingsApi(fetch, auth) {
  return {
    // Mixing of v1 and v2 is intentional, as we migrate over to v2
    get: GetRating(fetch.v1, auth),
    getByBinder: GetByBinder(fetch.v1, auth),
    getByPolicy: GetByPolicy(fetch.v1, auth),
    searchBySubmission: SearchRatingsBySubmission(fetch.v1, auth),
    updateLimitsRetentions: UpdateLimitsRetentions(fetch.v2, auth),
    updateRiskAnalysis: UpdateRiskAnalysis(fetch.v1, auth),
    getPremium: GetPremium(fetch.v1, auth),
    updatePremiumAdjustments: UpdatePremiumAdjustments(fetch.v1, auth),
    createCopy: CreateCopy(fetch.v1, auth),
    createFromQuote: CreateFromQuote(fetch.v1, auth),
  };
}
