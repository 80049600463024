import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import { SummaryItem } from '../../../common';

const styles = {
  section_header: {
    fontSize: '1.25rem',
  },
};

/**
 * @param {Array.<{label: String, value: String}>} items - Items to display (max 3)
 */
export default function BinderOverviewSection({ style, items, ...props }) {
  return (
    <section style={style} {...props}>
      <h3 style={styles.section_header}>Overview</h3>
      <Row>
        {items.map(({ label, value }, i) => (
          <Col sm={4} key={i}>
            <SummaryItem label={label} value={value} />
          </Col>
        ))}
      </Row>
    </section>
  );
}
