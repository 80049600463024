import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { LinkContainer } from 'react-router-bootstrap';
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import NavItem from 'react-bootstrap/NavItem';
import './styles.css';

function NavigationBar({ user, logout }) {
  const [expandNav, setExpandNav] = useState(false);

  return (
    <Navbar expanded={expandNav} expand="md">
      <Container fluid="xxl">
        <Navbar.Brand className="mt-2 mb-2 ms-3">
          <Link to="/">Protector</Link>
        </Navbar.Brand>
        <Navbar.Toggle
          aria-controls="basic-navbar-nav"
          onClick={() => {
            setExpandNav(!expandNav);
          }}
        />
        <Navbar.Collapse className="justify-content-end pt-3">
          {user ? (
            <Nav>
              <LinkContainer to="submissions/new">
                <NavItem as="a" onClick={() => setExpandNav(false)}>
                  <Nav.Link as="span" eventKey={1}>
                    New Submission
                  </Nav.Link>
                </NavItem>
              </LinkContainer>
              <LinkContainer to="/">
                <NavItem as="a" onClick={() => setExpandNav(false)}>
                  <Nav.Link as="span" eventKey={2}>
                    Dashboard
                  </Nav.Link>
                </NavItem>
              </LinkContainer>
              <NavItem
                as="a"
                onClick={() => {
                  setExpandNav(false);
                  logout('/logout');
                }}
              >
                <Nav.Link as="span" eventKey={3}>
                  Logout
                </Nav.Link>
              </NavItem>
            </Nav>
          ) : (
            <Nav>
              <LinkContainer to="login">
                <NavItem as="a">
                  <Nav.Link as="span" eventKey={2}>
                    Login
                  </Nav.Link>
                </NavItem>
              </LinkContainer>
            </Nav>
          )}
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default NavigationBar;
