import React from 'react';
import { SummaryItem } from '../../common';

const styles = {
  section_header: {
    fontSize: '1.25rem',
    margin: '20px 0 10px 0',
    display: 'inline-block',
  },
  list: {
    listStyle: 'none',
    marginTop: '20px',
    paddingLeft: '0',
  },
  list_item: {
    marginTop: '15px',
  },
  edit: {
    marginTop: '14px',
  },
};

const renderDefaultHeader = ({ styles }) => (
  <h3 style={styles.section_header}>Extended Reporting Period</h3>
);

export default function ExtendedReportingSummary({
  erp,
  style,
  renderHeader = renderDefaultHeader,
  ...props
}) {
  const { years = null, percentage = null } = erp;

  return (
    <section style={style} {...props}>
      {renderHeader({ styles })}
      <ul style={styles.list}>
        <li style={styles.list_item}>
          <SummaryItem label="Years" value={years} />
        </li>
        <li style={styles.list_item}>
          <SummaryItem label="% of Annual Premium" value={`${percentage}%`} />
        </li>
      </ul>
    </section>
  );
}
