import { compareStatuses, POLICY_STATUSES } from './statuses';
import { isSurplusLines as isSurplusLinesSharedUtil } from "../shared/utils";

export const hasBeenRenewed = policy => !!policy.renewal_submission_id;
export const hasBeenIssued = policy => {
  return compareStatuses(policy.status, POLICY_STATUSES.ISSUED) >= 0;
};
export const isSurplusLines = policy => {
  return isSurplusLinesSharedUtil(policy.product_code);
};
