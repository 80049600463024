import React from 'react';
import styled from 'styled-components';
import BaseSummaryCard from '../BaseSummaryCard';
import ClearanceStatus from './ClearanceStatus';

const StyledBaseSummaryCard = styled(BaseSummaryCard)`
  border: 1px solid #979797;
  display: flex;
  flex-wrap: wrap;
  padding: 20px;
  width: 100%;
`;

const formatSubmissionNumber = submission_number =>
  submission_number.match(/\d{4}/g).join('-');

export default function SubmissionSummaryCard({ flex, style, submission }) {
  return (
    <StyledBaseSummaryCard
      flex={flex}
      style={style}
      data={{
        ...submission,
        submission_number: formatSubmissionNumber(submission.submission_number),
      }}
      renderClearanceStatus={() => <ClearanceStatus submission={submission} />}
    />
  );
}
