import React from 'react';
import numeral from 'numeral';

export default function MinimumPremiumWarning({ minimum_premium, style }) {
  return (
    <p className="text-info" style={style}>
      <strong>Note:</strong> Minimum Premium is{' '}
      {numeral(minimum_premium).format('$0,0')}
    </p>
  );
}
