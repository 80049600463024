const getFields = (schema, test = () => true, results = []) => {
  const node = schema;
  if (test(node)) results.push(node);
  if (node.children) {
    node.children.forEach(child => getFields(child, test, results));
  }
  return results;
};

export const getAllFields = schema => {
  return getFields(schema);
};

export const getLeafFields = schema => {
  return getFields(schema, node => !node.children);
};
