export default function Config() {
  return {
    api_url: process.env.REACT_APP_API_URL,
    cognito: {
      auth_domain: process.env.REACT_APP_COGNITO_AUTH_DOMAIN,
      client_id: process.env.REACT_APP_COGNITO_CLIENT_ID,
    },
    graphql: {
      uri: `${process.env.REACT_APP_API_URL}/graphql`,
    },
    okta: {
      issuer_url: process.env.REACT_APP_OKTA_ISSUER_URL,
      client_id: process.env.REACT_APP_OKTA_CLIENT_ID,
    },
  };
}
