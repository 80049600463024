import React from 'react';

const styles = {
  header: {
    margin: '15px 0 15px 0',
    borderBottom: 'none',
  },
};
export default function WorkspaceHeader({ submission }) {
  return (
    <div style={styles.header}>
      <h1>{submission.named_insured.name}</h1>
    </div>
  );
}
