import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useNavigate } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import { SearchSubmissions } from '../../submissions';

const styles = {
  header_container: {
    margin: '25px 0 45px',
  },
  subheader: {
    marginBottom: '20px',
  },
};

export default function HomeAuthenticated() {
  const navigate = useNavigate();
  return (
    <Container fluid>
      <Row>
        <Col md={{ span: 10, offset: 1 }} xxl={{ span: 8, offset: 2 }}>
          <header style={styles.header_container}>
            <h1>Clear Submissions for Quote.</h1>
            <p style={styles.subheader}>
              Start a New Submission to check if an insured's application has
              already been received from a different broker or previously
              received by Argo or Corona.
            </p>
            <Button
              variant="primary"
              onClick={() => navigate('/submissions/new')}
            >
              Start New Submission....
            </Button>
          </header>
          <main>
            <h1>Submissions</h1>
            <SearchSubmissions />
          </main>
        </Col>
      </Row>
    </Container>
  );
}
