import React from 'react';
import { StickySidebar } from '../../../../layouts';

import QuoteHistory from '../QuoteHistory';

import PriceOptionsSummarySection from './PriceOptionsSummarySection';
import PreviewQuoteButton from '../PreviewQuoteButton';
import CopiedQuoteBanner from '../CopiedQuoteBanner';
import NextStepsAlert from './NextStepsAlert';
import EffectiveDateAlert from './EffectiveDateAlert';
import QuoteActionMenu from './QuoteActionMenu';

import { permissions as submission_permissions } from '../../../../submissions';
import { utils as r_utils } from '../../../../rating';
import * as quote_permissions from '../../../permissions';

const styles = {
  container: {
    paddingTop: '15px',
    paddingBottom: '15px',
  },
  header: {
    display: 'inline-block',
    margin: 0,
    verticalAlign: 'middle',
  },
  header_tag: {
    textSize: '0.5rem',
    fontWeight: 'bold',
    textTransform: 'uppercase',
    marginLeft: '20px',
    verticalAlign: 'middle',
  },
  sidebar: {
    marginLeft: '20px',
    maxWidth: '240px',
  },
};

export default function QuotedQuoteWorkspace({
  quote,
  ratings,
  submission,
  related_quotes,
  productConfig,
  copyQuote,
  getQuoteLetter,
  copy,
}) {
  const can_start_bind = submission_permissions.canStartBind({ submission });
  const can_update_quote = quote_permissions.canUpdateQuoteDetails({ quote });
  const can_copy_quote = submission_permissions.canCreateQuote({ submission });
  const all_ratings_tbd = ratings.every(r_utils.isEffectiveDateTBD);
  return (
    <React.Fragment>
      {copy && <CopiedQuoteBanner />}
      <StickySidebar
        renderMain={() => {
          return (
            <React.Fragment>
              {!can_start_bind ? null : all_ratings_tbd ? (
                <EffectiveDateAlert copyQuote={copyQuote} />
              ) : (
                <NextStepsAlert ratings={ratings} copyQuote={copyQuote} />
              )}
              <PriceOptionsSummarySection
                quote={quote}
                ratings={ratings}
                submission={submission}
                style={{ marginTop: '15px' }}
              />
              {can_update_quote && (
                <React.Fragment>
                  <h3 style={styles.section_header}>Review and Confirm</h3>
                  <PreviewQuoteButton
                    style={{ marginTop: '20px' }}
                    quote={quote}
                  />
                </React.Fragment>
              )}
            </React.Fragment>
          );
        }}
        renderAside={() => {
          return (
            <section style={styles.sidebar}>
              <QuoteActionMenu
                actions={[
                  {
                    type: 'link',
                    title: 'Preview Quote',
                    to: `/quotes/${quote.id}/preview`,
                  },
                  {
                    type: 'button',
                    title: 'Download Letter',
                    onClick: () => {
                      return getQuoteLetter();
                    },
                  },
                  {
                    type: 'button',
                    title: 'Requote',
                    disabled: !can_copy_quote,
                    onClick: () => {
                      return copyQuote();
                    },
                  },
                ]}
              />
              <QuoteHistory
                style={{ marginTop: '20px' }}
                current_quote={quote}
                related_quotes={related_quotes}
                productConfig={productConfig}
              />
            </section>
          );
        }}
      />
    </React.Fragment>
  );
}
