import React from 'react';
import Alert from 'react-bootstrap/Alert';
import { Field } from 'redux-form';
import differenceBy from 'lodash/differenceBy';
import findIndex from 'lodash/findIndex';

import SelectControl from '../../../common/components/SelectControl';

import DynamicEndorsementAlert from '../DynamicEndorsementAlert';

import SupplementaryDataModal from './SupplementaryDataModal';
import SelectedEndorsement from './SelectedEndorsement';

const _styles = {
  alert: {
    fontSize: '0.8125rem',
    marginTop: '20px',
  },
  text: {
    color: 'black',
  },
};

function EndorsementsField(props) {
  const validityArray = props.input.value.map(selection => {
    return props.options.some(
      option =>
        option.value === selection.value && option.version === selection.version
    );
  });
  const allEndorsementsSupported = validityArray.every(Boolean);
  return (
    <div>
      <SelectControl
        {...props}
        labelKey="title"
        renderSelectedItem={selectProps => (
          <SelectedEndorsement
            {...selectProps}
            isValid={validityArray[selectProps.index]}
            options={props.options}
            edit={props.edit}
          />
        )}
      />
      {!allEndorsementsSupported && (
        <Alert variant="danger" style={_styles.alert}>
          <strong>Unsupported Endorsements Selected</strong>
          <p style={_styles.text}>
            Due to changes in the program, some previously selected endorsements
            are no longer allowed. It is likely that a new version of the
            endorsement is available for selection. To fix this issue, please
            remove the disallowed endorsements (highlighted above) and re-select
            the new version from the dropdown.
          </p>
        </Alert>
      )}
      <DynamicEndorsementAlert
        endorsements={props.input.value}
        config={props.options}
        render={endorsements => {
          return (
            <Alert style={_styles.alert} variant="success">
              <strong>Endorsement note</strong>
              <div style={_styles.text}>
                <p style={{ marginTop: '10px' }}>
                  If a policy is issued, you will need to add terms directly to
                  the policy PDF for the following endorsements:
                </p>
                <ul>
                  {endorsements.map((endorsement, i) => {
                    return <li key={i}>{endorsement.title}</li>;
                  })}
                </ul>
              </div>
            </Alert>
          );
        }}
      />
    </div>
  );
}

export default class Endorsements extends React.Component {
  state = {
    selected: null,
  };

  onChange = (e, newValue, oldValue) => {
    const endorsement = differenceBy(newValue, oldValue, 'value')[0];
    const index = findIndex(newValue, endorsement, 'value');
    this.setState({
      selected: endorsement && { endorsement, index },
    });
  };

  edit = (endorsement, index) => {
    this.setState({
      selected: { endorsement, index },
    });
  };

  render() {
    const { style, options, context } = this.props;
    const validEndorsements = options.filter(option =>
      Array.isArray(option.valid_for) && option.valid_for.includes(context)
    );
    return (
      <section style={style}>
        <SupplementaryDataModal
          {...this.state.selected}
          hide={() => this.setState({ selected: null })}
        />
        <h3 style={{marginTop:'20px'}}>Endorsements</h3>
        <Field
          name="endorsements"
          component={EndorsementsField}
          options={validEndorsements}
          label="Add Endorsement"
          placeholder="Select an option"
          emptystate="No Endorsements. Create one or select them from the list below."
          onChange={this.onChange}
          edit={this.edit}
        />
      </section>
    );
  }
}
