import React from 'react';

const styles = {
  list: {
    listStyle: 'none',
    paddingLeft: '0',
  },
  item: {
    marginTop: '0.5em',
  },
};

export default function DownloadDocsList({ documents, style }) {
  return (
    <ul style={{ ...styles.list, ...style }}>
      {documents.map(doc => (
        <li key={doc.title} style={styles.item}>
          <a href={doc.url} target="_blank" rel="noreferrer" title={doc.title}>
            {doc.title}
          </a>
        </li>
      ))}
    </ul>
  );
}
