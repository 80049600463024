import React from 'react';

import {
  AsyncQueryWrapper,
  SearchContainer,
  PaginatedTable,
} from '../../../common';
import { StartQuoteFromSubmissionButton } from '../../../quote';

import table_config from './table-config';

const styles = {
  button: {
    marginLeft: '-10px', // aligns with table
  },
};

export default function SearchQuotes(props) {
  return (
    <SearchContainer
      render={({ term, page, limit }) => {
        return (
          <AsyncQueryWrapper
            load={variables => props.searchQuotes(variables)}
            variables={{
              term: term.value,
              limit: limit.value,
              offset: limit.value * (page.value - 1),
            }}
            render={({ data, loading, error }) => {
              const { submission, can_start_quote } = props;
              return (
                <div>
                  {data &&
                    data.quotes && (
                      <React.Fragment>
                        <PaginatedTable
                          row_id="id"
                          columns={table_config.columns}
                          placeholder="Search price options"
                          loading={loading}
                          list={data.quotes}
                          total={data.count}
                          term={term}
                          limit={limit}
                          page={page}
                        />
                        {can_start_quote && (
                          <StartQuoteFromSubmissionButton
                            style={styles.button}
                            submission={submission}
                          >
                            Create New Quote
                          </StartQuoteFromSubmissionButton>
                        )}
                      </React.Fragment>
                    )}
                </div>
              );
            }}
          />
        );
      }}
    />
  );
}
