import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

import { AsyncMutationWrapper, ModalDialog } from '../../../common';

const styles = {
  container: {
    fontSize: '0.875rem',
  },
  title: {
    fontSize: '1.5rem',
    margin: '30px 0 0',
  },
  footer: {
    textAlign: 'left',
  },
};

export default function IssuePolicyModal({ show, hide, issuePolicy }) {
  return (
    <ModalDialog.FixedHeight
      show={show}
      hide={hide}
      renderHeader={() => {
        return <Modal.Title style={styles.title}>Confirm Issue</Modal.Title>;
      }}
      renderBody={() => {
        return <p>Policy Forms will be immediately generated.</p>;
      }}
      renderFooter={() => {
        return (
          <AsyncMutationWrapper
            mutate={issuePolicy}
            render={({ loading, mutate }) => (
              <section style={styles.footer}>
                <Button
                  disabled={loading}
                  onClick={mutate}
                  type="button"
                  variant="primary"
                >
                  Confirm and Issue
                </Button>
                <Button
                  disabled={loading}
                  type="button"
                  variant="link"
                  onClick={hide}
                >
                  Return to Preview
                </Button>
              </section>
            )}
          />
        );
      }}
    />
  );
}
