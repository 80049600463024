import React from 'react';
import { connect } from 'react-redux';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';

import { AsyncMutationWrapper } from '../../../common';

import { getPremium } from '../../actions';

const mapDispatchToProps = dispatch => ({
  getPremium(ratingId) {
    return dispatch(getPremium(ratingId));
  },
});

const styles = {
  alert: {
    fontSize: '0.8125rem',
  },
  button: {
    marginTop: '1em',
  },
};

function NoPremiumAlert({ rating, getPremium }) {
  return (
    <Alert variant="warning" style={styles.alert}>
      <strong>Calculate Premium</strong>
      <p style={{ color: 'black', marginTop: '10px' }}>
        Click below to view the price. Any changes to coverage or risk
        information require an updated premium.
      </p>
      <AsyncMutationWrapper
        mutate={() => getPremium(rating.id)}
        render={({ error, loading, data, mutate }) => {
          return (
            <Button
              style={styles.button}
              variant="primary"
              onClick={mutate}
              disabled={loading}
            >
              View Price
            </Button>
          );
        }}
      />
    </Alert>
  );
}

export default connect(
  null,
  mapDispatchToProps
)(NoPremiumAlert);
