export const GET_BINDER_BY_ID_REQUEST = 'GET_BINDER_BY_ID_REQUEST';
export const GET_BINDER_BY_ID_SUCCESS = 'GET_BINDER_BY_ID_SUCCESS';
export const UPDATE_BINDER_TERMS_CONDITIONS_REQUEST =
  'UPDATE_BINDER_TERMS_CONDITIONS_REQUEST';
export const UPDATE_BINDER_TERMS_CONDITIONS_SUCCESS =
  'UPDATE_BINDER_TERMS_CONDITIONS_SUCCESS';
export const CREATE_BINDER_REQUEST = 'CREATE_BINDER_REQUEST';
export const CREATE_BINDER_SUCCESS = 'CREATE_BINDER_SUCCESS';
export const GET_BINDER_BY_SUBMISSION_REQUEST =
  'GET_BINDER_BY_SUBMISSION_REQUEST';
export const GET_BINDER_BY_SUBMISSION_SUCCESS =
  'GET_BINDER_BY_SUBMISSION_SUCCESS';
export const BIND_POLICY_REQUEST = 'BIND_POLICY_REQUEST';
export const BIND_POLICY_SUCCESS = 'BIND_POLICY_SUCCESS';
export const DELETE_BINDER_REQUEST = 'DELETE_BINDER_REQUEST';
export const DELETE_BINDER_SUCCESS = 'DELETE_BINDER_SUCCESS';
export const UPDATE_BINDER_Z_SCORE_REQUEST = 'UPDATE_BINDER_Z_SCORE_REQUEST';
export const UPDATE_BINDER_Z_SCORE_SUCCESS = 'UPDATE_BINDER_Z_SCORE_SUCCESS';
export const UPDATE_BINDER_DETAILS_REQUEST = 'UPDATE_BINDER_DETAILS_REQUEST';
export const UPDATE_BINDER_DETAILS_SUCCESS = 'UPDATE_BINDER_DETAILS_SUCCESS';
