import React from 'react';
import Form from 'react-bootstrap/Form';

const StackedRadioControl = ({ options, ...props }) => {
  return (
    <div>
      {options.map(({ value, label }) => {
        const id = `${props.id}-${value}`;
        return (
          <Form.Check
            key={value}
            type='radio'
            {...props}
            id={id}
            value={value}
            checked={props.value === value}
            aria-label={label}
            label={label}
          />
        );
      })}
    </div>
  );
};

export default StackedRadioControl;
