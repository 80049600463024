const UploadEndorsement = () => async (upload_data, file) => {
  const { url, data } = upload_data;
  const req = {
    url,
    options: {
      method: 'POST',
      body: new FormData(),
    },
  };
  for (let name in data) {
    req.options.body.append(name, data[name]);
  }
  req.options.body.append('file', file);
  // Using native fetch here instead of the injected
  // fetch since the injected fetch steers to our API
  // and we want this to go to S3
  await window.fetch(req.url, req.options);
};

export default UploadEndorsement;
