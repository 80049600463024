import React from 'react';
import Button from 'react-bootstrap/Button';
import { AsyncMutationWrapper } from '../../../common';

const styles = {
  container: {
    border: '1px solid grey',
    padding: '20px 30px',
  },
  header: {
    marginTop: '0',
  },
  button: {
    marginRight: '20px',
  },
};
export default function IssuePolicyBanner({
  reviewBinder,
  issuePolicy,
  style,
}) {
  return (
    <section className="clearfix" style={{ ...styles.container, ...style }}>
      <section style={{ float: 'left' }}>
        <h3 className="text-success" style={styles.header}>
          Submission has been Bound
        </h3>
      </section>
      <section style={{ float: 'right' }}>
        <AsyncMutationWrapper
          mutate={issuePolicy}
          render={({ loading, mutate, error }) => (
            <Button
              variant="primary"
              style={styles.button}
              disabled={loading}
              onClick={mutate}
            >
              Issue Policy
            </Button>
          )}
        />
      </section>
    </section>
  );
}
