import React from 'react';
import { connect } from 'react-redux';
import { reduxForm, SubmissionError, FormSection, Field } from 'redux-form';
import FormGroup from 'react-bootstrap/FormGroup';
import Button from 'react-bootstrap/Button';

import {
  ModalDialog,
  InputControl,
  AddressFields,
  AsyncQueryWrapper,
  StackedRadioControl,
} from '../../../../common';

import { getEligibleProducts, updateQuote } from '../../../actions';

const ReduxFormRadio = ({ input, ...props }) => {
  return <StackedRadioControl {...input} {...props} />;
};

function ProductAndNamedInsuredModal({
  getEligibleProducts,
  show,
  hide,
  handleSubmit,
  submitting,
  error,
}) {
  return (
    <ModalDialog.Standard
      show={show}
      hide={hide}
      renderBody={() => (
        <div>
          <FormSection name="named_insured">
            <h3>Insured</h3>
            <div>
              <Field
                name="name"
                type="text"
                component={InputControl}
                label="Insured Name"
              />
            </div>
            <AddressFields />
          </FormSection>
          <section>
            <h3 style={{marginTop: '20px'}}>Policy</h3>
            <FormGroup>
              <h4>Paper and Rating Plan</h4>
              <AsyncQueryWrapper
                load={getEligibleProducts}
                render={({ loading, data }) => {
                  if (loading) return <p>Loading eligible products...</p>;
                  return (
                    <Field
                      name="product_code"
                      component={ReduxFormRadio}
                      props={{
                        options: data.products.map(product => {
                          return {
                            value: product.id,
                            label: product.short_name,
                          };
                        }),
                      }}
                    />
                  );
                }}
              />
            </FormGroup>
          </section>
        </div>
      )}
      renderFooter={() => {
        return (
          <div style={{ textAlign: 'left' }}>
            <div>
              <Button
                onClick={handleSubmit}
                variant="primary"
                disabled={submitting}
              >
                Update Details
              </Button>
              <Button variant="link" disabled={submitting}>
                Cancel
              </Button>
            </div>
            {error && <p className="text-danger">{error}</p>}
          </div>
        );
      }}
    />
  );
}

const mapStateToProps = (state, props) => {
  const { quote } = props;
  return {
    initialValues: {
      named_insured: quote.named_insured,
      product_code: quote.product_code,
    },
  };
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    getEligibleProducts() {
      return dispatch(getEligibleProducts(props.quote.id));
    },
    updateQuote(values, initialValues) {
      return dispatch(updateQuote(props.quote.id, values, initialValues));
    },
  };
};

const submit = async (values, dispatch, props) => {
  try {
    await props.updateQuote(values, props.initialValues);
    props.hide();
  } catch (e) {
    console.error(e);
    throw new SubmissionError({ _error: e.message });
  }
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm({ form: 'update_product_and_named_insured', onSubmit: submit })(
    ProductAndNamedInsuredModal
  )
);
