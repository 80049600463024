import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Alert from 'react-bootstrap/Alert';

const styles = {
  alert: {
    marginBottom: 0,
    marginTop: '20px',
  },
  header: {
    marginTop: 0,
  },
  text: {
    color: 'black',
  },
};

function DefaultBody() {
  return (
    <React.Fragment>
      <h3 style={styles.header}>This is a new Quote</h3>
      <p style={styles.text}>
        Any changes won’t be reflected in your other Quotes
      </p>
    </React.Fragment>
  );
}

function WithRatingsBody() {
  return (
    <React.Fragment>
      <h3 style={styles.header}>Your quote is ready to be updated</h3>
      <p style={styles.text}>
        Generate a new quote letter after you have updated Price Options and
        terms.
      </p>
    </React.Fragment>
  );
}

export default class CopiedQuoteBanner extends React.Component {
  state = {
    show: true,
  };

  render() {
    if (!this.state.show) return null;
    return (
      <Container>
        <Row>
          <Col sm={{span: 10, offset: 1}}>
            <Alert
              style={styles.alert}
              variant="success"
              onClose={() => this.setState({ show: false })}
            >
              {this.props.copiedWithRatings ? (
                <WithRatingsBody />
              ) : (
                <DefaultBody />
              )}
            </Alert>
          </Col>
        </Row>
      </Container>
    );
  }
}
