import { addDefaultConditions } from './defaults/conditions';
import { build as buildSchema } from './schema/builder';
import { build as buildCondition } from './condition/builder';
export { Condition } from './defaults/conditions/_base';
class ConditionsMap {
  constructor() {
    this.conditions = new Map();
  }
  get(name) {
    return this.conditions.get(name.toLowerCase());
  }
  has(name) {
    return this.conditions.has(name.toLowerCase());
  }
  set(name, value) {
    this.conditions.set(name.toLowerCase(), value);
    return this;
  }
}
export class Validator {
  constructor() {
    this.conditions = new ConditionsMap();
    addDefaultConditions(this);
  }
  addCondition(name, conditionClass) {
    if (this.conditions.has(name)) {
      console.warn(
        `Condition with name ${name} already exists. Overwriting it might result in unexpected behavior.`
      );
    }
    this.conditions.set(name, conditionClass);
  }
  evaluateCondition(config, value, values, context) {
    return buildCondition(config, this.conditions).eval(value, values, context);
  }
  required(schemaConfig, path, value, values, context) {
    const schema = buildSchema(schemaConfig, this.conditions).getSubSchema(
      path
    );
    const required = schema.required(value, values, context);
    return required;
  }
  validate(schemaConfig, path, value, values, context) {
    const schema = buildSchema(schemaConfig, this.conditions).getSubSchema(
      path
    );
    const validation = schema.validate(value, values, context);
    return validation;
  }
  validateDeep(config, values, context) {
    const schema = buildSchema(config, this.conditions);
    const validation = schema.validateDeep(null, values, values, context);
    return validation;
  }
}
