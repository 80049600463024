import ConfirmClearance from './confirmClearance';
import CreateAndClearSubmission from './createAndClear';
import CreateAndClearRenewal from './createAndClearRenewal';
import DeleteDeclinedSubmission from './deleteDeclinedSubmission';
import GetSubmission from './get';
import GetSubmissionByRating from './getByRating';
import ManuallyClearSubmission from './manuallyClear';
import SearchSubmissions from './search';
import GetSubmissionByQuote from './getByQuote';
import GetSubmissionByBinder from './getByBinder';
import GetSubmissionByPolicy from './getByPolicy';
import GetEligibleProducts from './getEligibleProducts';
import PostDeclineSubmission from './postDeclineSubmission';

export default function SubmissionsApi(fetch, auth) {
  return {
    confirmClearance: ConfirmClearance(fetch, auth),
    createAndClear: CreateAndClearSubmission(fetch, auth),
    createAndClearRenewal: CreateAndClearRenewal(fetch, auth),
    deleteDeclinedSubmission: DeleteDeclinedSubmission(fetch, auth),
    get: GetSubmission(fetch, auth),
    getByRating: GetSubmissionByRating(fetch, auth),
    manuallyClear: ManuallyClearSubmission(fetch, auth),
    search: SearchSubmissions(fetch, auth),
    getByQuote: GetSubmissionByQuote(fetch, auth),
    getByBinder: GetSubmissionByBinder(fetch, auth),
    getByPolicy: GetSubmissionByPolicy(fetch, auth),
    getEligibleProducts: GetEligibleProducts(fetch, auth),
    postDeclineSubmission: PostDeclineSubmission(fetch, auth),
  };
}
