import React from 'react';
import { Query } from '@apollo/client/react/components';
import { Routes, Route } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import jmp from 'json-merge-patch';

import { PageTitle } from '../../../common';

import RatingSummary from '../RatingSummary';
import { SubmissionsSummaryBar, ProgressBar } from '../../../common';
import RatingWorksheet from '../RatingWorksheet';
import PricingWorksheet from '../PricingWorksheet';

import CopiedRatingBanner from './CopiedRatingBanner';
import FromExpiringPolicyBanner from './FromExpiringPolicyBanner';
import { query } from './query';

import { removeTypename } from '../../../graphql/utils';
import * as permissions from '../../permissions';
import { combinePathPatterns } from '../../../utils';

const pages = [
  {
    title: 'Rating Worksheet',
    path: 'rating-worksheet',
    component: RatingWorksheet,
    disabled: ({ rating }) => {
      return !permissions.canUpdateRating({ rating });
    },
  },
  {
    title: 'Limits and Retentions',
    path: 'pricing-worksheet',
    component: PricingWorksheet,
    disabled: ({ rating }) => {
      return !permissions.canUpdateRating({ rating });
    },
  },
  {
    title: 'Premium Results',
    path: '',
    component: RatingSummary,
    disabled: ({ rating }) => {
      return !permissions.canUpdateRating({ rating });
    },
  },
];

const styles = {
  container: {
    backgroundColor: '#f0f0f0',
  },
  progress_indicator: {
    listStyle: 'none',
    marginTop: '10px',
    paddingLeft: '0',
  },
};

const stripNull = response => jmp.apply({}, response);

const decomposeGraphQLResponse = response => {
  const { submission, quote, ...rating } = stripNull(
    removeTypename(response.rating)
  );
  return { submission, quote, rating };
};

function InProgressHeader({ rating, match }) {
  return (
    <Container fluid="md">
      <Row>
        <Col md={{span: 12}} lg={{offset:1}}>
          <h2
            style={{
              display: 'inline-block',
              marginRight: '5px',
              marginTop:'1rem'
            }}
          >
            Price Calculator
          </h2>
          <ul style={styles.progress_indicator}>
            <ProgressBar
              pages={pages.map(page => ({
                ...page,
                disabled: page.disabled({ rating }),
              }))}
              pathPattern={match.pattern.path}
            />
          </ul>
        </Col>
      </Row>
    </Container>
  );
}

export default function ViewRatingContainer(props) {
  const { location, match } = props;
  return (
    <Query
      query={query}
      variables={{
        ratingId: match.params.rating_id,
      }}
      fetchPolicy="cache-and-network"
    >
      {({ data, loading, error }) => {
        if (loading && !data?.rating) {
          return <div className="text-center-2">Loading...</div>;
        }
        if (error) {
          return (
            <div className="text-center">
              Sorry. Something went wrong. Please try refreshing.
            </div>
          );
        }
        const { rating, submission, quote } = decomposeGraphQLResponse(data);
        const insured_name = submission.named_insured.name;
        return (
          <div style={styles.container}>
            <div style={{ margin: 'none' }}>
              <SubmissionsSummaryBar
                submission={submission}
                display_name={quote.display_name}
              />
            </div>
            <div>
              {location.state && location.state.copy && <CopiedRatingBanner />}
              {location.state &&
                location.state.fromExpiringPolicy && (
                  <FromExpiringPolicyBanner />
                )}
              <InProgressHeader 
                rating={rating} 
                match={match}
                />
              <Routes>
                {pages.map(page => (
                  <Route
                    key={page.title}
                    path={page.path}
                    element={
                      <React.Fragment>
                        <PageTitle title={`${insured_name} - ${page.title}`} />
                        <page.component
                          rating={rating}
                          submission={submission}
                          quote={quote}
                          productConfig={quote.config}
                          pathPattern={combinePathPatterns([match.pattern.path, page.path])}
                        />
                      </React.Fragment>
                    }
                  />
                ))}
              </Routes>
            </div>
          </div>
        );
      }}
    </Query>
  );
}
