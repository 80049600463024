import { connect } from 'react-redux';
import { withRouter } from '../../../common/components/WithRouter';
import { getFormValues } from 'redux-form';

import { updateLimitsRetentions, getPremium } from '../../actions';
import ViewPriceButton from './ViewPriceButton';

import { mapFormValuesToLimitsRetentions } from '../PricingWorksheet/mapFormValuesToLimitsRetentions';

const mapStateToProps = (state, props) => {
  const form = props.formName;
  return state => {
    const values = getFormValues(form)(state);
    return {
      values,
    };
  };
};

const mapDispatchToProps = (dispatch, props) => dispatch => ({
  getPremium: () => {
    return dispatch(getPremium(props.rating.id)).then(response => {
      props.navigate(`/ratings/${props.rating.id}`);
      return response;
    });
  },
  updateLimitsRetentions: form_values => {
    const { rating_id } = props.match.params;
    const new_limits_and_retentions = mapFormValuesToLimitsRetentions(
      form_values,
      { rating: props.rating }
    );
    return dispatch(
      updateLimitsRetentions(
        rating_id,
        new_limits_and_retentions,
        props.rating.limits_retentions
      )
    );
  },
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(ViewPriceButton)
);
