import get from 'lodash/get';
import numeral from 'numeral';

const CoverageTitleMap = {
  do: 'Directors & Officers',
  epl: 'Employment Practices',
  fid: 'Fiduciary',
};

const format = value => {
  return value == null ? null : numeral(value).format('$0,0');
};

export default function getLimitSummaries({ rating }) {
  const summaries = [];
  const coverages = get(rating, 'risk_analysis.selected_coverages', []);
  if (coverages.includes('do')) {
    summaries.push({
      title: CoverageTitleMap['do'],
      fields: DOFields(rating),
    });
  }
  if (coverages.includes('epl')) {
    summaries.push({
      title: CoverageTitleMap['epl'],
      fields: EPLFields(rating),
    });
  }
  if (coverages.includes('fid')) {
    summaries.push({
      title: CoverageTitleMap['fid'],
      fields: FIDFields(rating),
    });
  }
  return summaries;
}

const DOFields = rating => {
  const fields = [];
  fields.push({
    nickname: 'Limit',
    value: format(get(rating, `limits_retentions.coverages.do.total_limit`)),
  });
  fields.push({
    nickname: 'Sublimit: Shareholder Derivative Demand',
    value: format(
      get(
        rating,
        `limits_retentions.coverages.do.sublimit_shareholder_derivative_demands`
      )
    ),
  });
  fields.push({
    nickname: 'Sublimit: Inquiry Costs',
    value: format(
      get(rating, `limits_retentions.coverages.do.sublimit_inquiry_costs`)
    ),
  });
  fields.push({
    nickname: 'Sublimit: Employed Lawyers Claims',
    value: format(
      get(
        rating,
        `limits_retentions.coverages.do.sublimit_employed_lawyers_claims`
      )
    ),
  });
  fields.push({
    nickname: 'Additional Limit: Non-Indemnified Loss',
    value: format(
      get(
        rating,
        `limits_retentions.coverages.do.sublimit_non_indemnified_loss`
      )
    ),
  });
  const custom_retentions = get(
    rating,
    `limits_retentions.coverages.do.custom_retentions`
  );
  const total_retention = get(
    rating,
    `limits_retentions.coverages.do.total_retention`
  );
  const subretention_company_reimbursement = !custom_retentions
    ? total_retention
    : get(
        rating,
        `limits_retentions.coverages.do.subretention_company_reimbursement`
      );
  const subretention_company_liability = !custom_retentions
    ? total_retention
    : get(
        rating,
        `limits_retentions.coverages.do.subretention_company_liability`
      );
  fields.push({
    nickname: 'Retention: Company Reimbursement (Insuring Agreement B)',
    value: format(subretention_company_reimbursement),
  });
  fields.push({
    nickname: 'Retention: Company Liability (Insuring Agreement C)',
    value: format(subretention_company_liability),
  });
  return fields;
};

const EPLFields = rating => {
  const fields = [];
  fields.push({
    nickname: 'Limit',
    value: format(get(rating, `limits_retentions.coverages.epl.total_limit`)),
  });
  fields.push({
    nickname: 'Sublimit: Third Party (Insuring Agreement B)',
    value: format(
      get(rating, `limits_retentions.coverages.epl.sublimit_third_party`)
    ),
  });
  fields.push({
    nickname: 'Sublimit: Sensitivity Training Costs',
    value: format(
      get(
        rating,
        `limits_retentions.coverages.epl.sublimit_sensitivity_training_costs`
      )
    ),
  });
  const custom_retentions = get(
    rating,
    `limits_retentions.coverages.epl.custom_retentions`
  );
  const total_retention = get(
    rating,
    `limits_retentions.coverages.epl.total_retention`
  );
  const subretention_first_party = !custom_retentions
    ? total_retention
    : get(rating, `limits_retentions.coverages.epl.subretention_first_party`);
  const subretention_third_party = !custom_retentions
    ? total_retention
    : get(rating, `limits_retentions.coverages.epl.subretention_third_party`);
  fields.push({
    nickname: 'Retention: First Party (Insuring Agreement A)',
    value: format(subretention_first_party),
  });
  fields.push({
    nickname: 'Retention: Third Party (Insuring Agreement B)',
    value: format(subretention_third_party),
  });
  return fields;
};

const FIDFields = rating => {
  const fields = [];
  fields.push({
    nickname: 'Limit',
    value: format(get(rating, `limits_retentions.coverages.fid.total_limit`)),
  });
  fields.push({
    nickname: 'Sublimit: HIPAA',
    value: format(
      get(rating, `limits_retentions.coverages.fid.sublimit_hipaa`)
    ),
  });
  fields.push({
    nickname: 'Sublimit: Vol. Compliance',
    value: format(
      get(
        rating,
        `limits_retentions.coverages.fid.sublimit_voluntary_compliance`
      )
    ),
  });
  const subretention_hipaa = get(
    rating,
    `limits_retentions.coverages.fid.subretention_hipaa`
  );
  const subretention_voluntary_compliance = get(
    rating,
    `limits_retentions.coverages.fid.subretention_voluntary_compliance`
  );
  fields.push({
    nickname: 'Retention: HIPAA (Insuring Agreement A)',
    value: format(subretention_hipaa),
  });
  fields.push({
    nickname: 'Retention: Vol. Compliance (Insuring Agreement B)',
    value: format(subretention_voluntary_compliance),
  });
  return fields;
};
