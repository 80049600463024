import React from 'react';
import Table from 'react-bootstrap/Table';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import PaginationControl from './PaginationControl';

import SearchInput from './SearchInput';

const styles = {
  search_container: {
    marginBottom: '20px',
  },
  search_input: {
    verticalAlign: 'middle'
  },
  loading_indicator: {
    marginLeft: '10px',
    verticalAlign: 'middle',
  },
  table: {
    header: {
      fontSize: '0.75rem',
      fontWeight: 'bold',
      lineHeight: '1.2em',
      color: '#9B9B9B',
    },
    cell: {
      fontSize: '0.875rem',
    },
  },
};

const MessageContainer = ({ children }) => {
  return (
    <tr>
      <td className="text-center" style={styles.table.cell} colSpan={1000}>
        {children}
      </td>
    </tr>
  );
};

const DefaultLoading = () => 'Loading...';

function TableBody({
  list,
  columns,
  row_id,
  loading,
  renderLoading,
  error,
  renderError,
}) {
  const is_list_populated = list && list.length;
  if (loading && !is_list_populated) {
    return <MessageContainer>{renderLoading()}</MessageContainer>;
  }
  if (error) {
    return <MessageContainer>{renderError()}</MessageContainer>;
  }
  if (!is_list_populated) {
    return <MessageContainer>No results.</MessageContainer>;
  }
  return list.map(item => {
    return (
      <tr key={item[row_id]}>
        {columns.map(({ key, content }, i) => {
          return (
            <td key={key} style={styles.table.cell}>
              {content(item)}
            </td>
          );
        })}
      </tr>
    );
  });
}

export default function PaginatedTable({
  columns,
  list,
  row_id,
  total,
  term,
  page,
  limit,
  placeholder,
  loading,
  renderLoading = DefaultLoading,
  error,
  renderError,
  is_searchable = false,
}) {
  return (
    <React.Fragment>
      {is_searchable && (
        <Row style={styles.search_container}>
          <Col xs={12} sm={6} lg={4}>
            <SearchInput
              style={styles.search_input}
              placeholder={placeholder}
              {...term}
            />
          </Col>
          <Col xs={12} sm={2}>
            {loading && (
              <span style={styles.loading_indicator}>Loading...</span>
            )}
          </Col>
        </Row>
      )}
      <Table responsive>
        <thead>
          <tr>
            {columns.map(({ key, title }) => {
              return (
                <th key={key} style={styles.table.header}>
                  {title}
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody>
          <TableBody
            list={list}
            columns={columns}
            row_id={row_id}
            loading={loading}
            renderLoading={renderLoading}
            error={error}
            renderError={renderError}
          />
        </tbody>
      </Table>
      {total > limit.value && (
        <PaginationControl
          prev="‹ Back"
          next="Next ›"
          ellipsis={true}
          boundaryLinks={false}
          maxPageButttons={5}
          totalRecords={total}
          maxRecordsToDisplay={limit.value}
          activePage={page.value}
          onSelect={(new_page) => () => {
            return page.onChange(new_page);
          }}
        />
      )}
    </React.Fragment>
  );
}
