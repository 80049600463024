/**
 * I'm not thrilled with the abstraction we have here, but it does
 * the job for now. If we're tempted to reuse this component elsewhere,
 * we should strongly consider fixing this. For now, though, I
 * think it's worth explaining some of the details of how this
 * component works.
 *
 * UPDATING
 *
 * The resolved/rejected state of a subjectivity in the UI
 * is determined by looking at the value of the subjectivity
 * in the binders store rather than in the form. When the user
 * clicks the resolve/reopen button, the value is changed in
 * the form and then API call is triggered immediately
 * thereafter. We make use of the submit function passed to the
 * form for this purpose. Wile waiting on the response, the buttons
 * are disabled.
 *
 * ERRORS
 *
 * If the API call to update fails, an error is set on the form
 * and passed down to this component. Inside this component,
 * an error is set on the subjectivity that caused the error
 * using the handleError() method.
 *
 * When a subjectivity is resolved/reopened, all errors are reset.
 *
 */

import React from 'react';
import Button from 'react-bootstrap/Button';

import SubjectivityWrapper from './Wrapper';

const styles = {
  selection_list: {
    borderCollapse: 'collapse',
    display: 'table',
    listStyle: 'none',
    paddingLeft: '0',
  },
  selection: {
    display: 'table',
  },
  selection_resolved: {
    color: '#9B9B9B',
  },
  label: {
    fontSize: '0.875rem',
    width: '100%',
  },
  cell: {
    display: 'table-cell',
    padding: '10px 0',
    verticalAlign: 'middle',
  },
};

class OldSubjectivities extends React.Component {
  state = {
    errors: [],
  };

  resetErrors() {
    this.setState({
      errors: this.props.subjectivities.map(() => null),
    });
  }

  handleError(index) {
    this.setState({
      errors: this.state.errors.map((err, i) => {
        if (i !== index) return null;
        return 'Something went wrong updating this Subjectivity. Please try again. If this issue persists, please contact support.';
      }),
    });
  }

  resolve(subjectivity) {
    return { ...subjectivity, resolved: true };
  }

  reopen(subjectivity) {
    return { ...subjectivity, resolved: false };
  }

  handleChange(subjectivity) {
    if (this.props.loading) return () => null;
    if (subjectivity.resolved) return this.reopen(subjectivity);
    return this.resolve(subjectivity);
  }

  handleClick = (subjectivity, index) => e => {
    this.resetErrors();
    const values = this.props.input.value.map((value, i) => {
      if (index !== i) return value;
      return this.handleChange(subjectivity);
    });
    this.props.input.onChange(values);
    /**
     * setTimeout is here to make sure the change has made it
     * to the redux store before we run the afterChange function
     */
    setTimeout(() => {
      this.props.afterChange().then(() => {
        if (this.props.error) this.handleError(index);
      });
    }, 0);
  };

  render() {
    const { loading } = this.props;
    return (
      <ul style={styles.selection_list}>
        {this.props.subjectivities.map((subjectivity, index) => {
          const actionName = subjectivity.resolved ? 'Reopen' : 'Resolve';
          const resolved_style = subjectivity.resolved
            ? styles.selection_resolved
            : null;
          const error = this.state.errors[index];
          return (
            <SubjectivityWrapper
              key={subjectivity.value}
              style={resolved_style}
            >
              <div style={{ ...styles.cell, ...styles.label }}>
                {subjectivity.label}
              </div>
              <div style={styles.cell}>
                <div>
                  <Button
                    variant="link"
                    type="button"
                    disabled={loading}
                    onClick={this.handleClick(subjectivity, index)}
                  >
                    {actionName}
                  </Button>
                </div>
              </div>
              {error && !loading && <p className="text-danger">{error}</p>}
            </SubjectivityWrapper>
          );
        })}
      </ul>
    );
  }
}

export default OldSubjectivities;
