import React from 'react';

import { StickySidebar } from '../../../../layouts';

import QuoteHistory from '../QuoteHistory';

import PriceOptionsSummarySection from './PriceOptionsSummarySection';
import TooManyPriceOptionsAlert from './TooManyPriceOptionsAlert';
import AddTermsButton from '../AddTermsButton';
import CopiedQuoteBanner from '../CopiedQuoteBanner';
import { ActionMenu, Toggleable } from '../../../../common';
import ProductAndNamedInsuredModal from '../ProductAndNamedInsuredModal';

const MAX_ASSOCIATED_PRICE_OPTIONS = 5;

const styles = {
  alert: {
    marginTop: '30px',
  },
  sidebar: {
    marginLeft: '20px',
    maxWidth: '240px',
  },
};

export default function UnquotedQuoteWorkspace({
  quote,
  unfilteredRatings,
  ratings,
  submission,
  productConfig,
  related_quotes,
  copy,
  copiedWithRatings,
}) {
  const too_many_price_options = ratings.length > MAX_ASSOCIATED_PRICE_OPTIONS;
  return (
    <React.Fragment>
      {copy && <CopiedQuoteBanner copiedWithRatings={copiedWithRatings} />}
      <StickySidebar
        renderMain={() => {
          return (
            <section>
              <PriceOptionsSummarySection
                quote={quote}
                ratings={unfilteredRatings}
                submission={submission}
                productConfig={productConfig}
                style={{ marginTop: '15px' }}
              />
              {too_many_price_options && (
                <TooManyPriceOptionsAlert style={styles.alert} />
              )}
              <AddTermsButton style={{ marginTop: '10px' }} quote={quote} />
            </section>
          );
        }}
        renderAside={() => {
          return (
            <section style={styles.sidebar}>
              <Toggleable
                render={({ visible, show, hide }) => {
                  return (
                    <section>
                      <ActionMenu style={{ marginBottom: '20px' }}>
                        <ActionMenu.Title>Manage Quote</ActionMenu.Title>
                        <ActionMenu.Button onClick={show}>
                          Change Paper or Insured Details
                        </ActionMenu.Button>
                      </ActionMenu>
                      <ProductAndNamedInsuredModal
                        quote={quote}
                        show={visible}
                        hide={hide}
                      />
                    </section>
                  );
                }}
              />
              <QuoteHistory
                current_quote={quote}
                related_quotes={related_quotes}
                productConfig={productConfig}
              />
            </section>
          );
        }}
      />
    </React.Fragment>
  );
}
