import { connect } from 'react-redux';
import { withRouter } from '../../../common/components/WithRouter';

import { issuePolicy } from '../../actions';

import IssuePolicyButton from './IssuePolicyButton';

const mapDispatchToProps = (dispatch, props) => {
  const policy_id = props.policy.id;
  return dispatch => ({
    issuePolicy: () => {
      return dispatch(issuePolicy(policy_id)).then(res => {
        props.navigate(`/policies/${policy_id}/documents`);
        return res;
      });
    },
  });
};

export default withRouter(connect(null, mapDispatchToProps)(IssuePolicyButton));
