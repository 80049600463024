import React from 'react';
import Button from 'react-bootstrap/Button';
import get from 'lodash/get';
import round from 'lodash/round';

import { ModalDialog } from '../../../common';

import * as DebitsCreditsModal from './DebitsCreditsModal';
import * as permissions from '../../permissions';

const getCoveragePremium = (rating, coverage) => {
  return get(rating, `premium.coverage_specific.${coverage}`);
};

class DebitsCredits extends React.Component {
  state = {
    show_modal: false,
    coverages: this.getInitialValues(),
  };

  getInitialValues() {
    return this.props.coverages.reduce((values, coverage) => {
      values[coverage] = getCoveragePremium(this.props.rating, coverage);
      return values;
    }, {});
  }

  showModal = () =>
    this.setState({
      show_modal: true,
      coverages: this.getInitialValues(),
      initialValues: this.getInitialValues(),
    });
  hideModal = () => this.setState({ show_modal: false });

  updatePremiumAdjustment = (coverage, adjustment) => {
    this.setState(previous_state => ({
      coverages: {
        ...previous_state.coverages,
        [coverage]: {
          ...previous_state.coverages[coverage],
          adjustment,
        },
      },
    }));
  };

  getTotalPremium() {
    const coverages = this.props.coverages;
    const coverage_premiums = this.state.coverages;
    const calculated_premium = coverages.reduce((total, coverage) => {
      const premium = coverage_premiums[coverage];
      return total + premium.base + (premium.adjustment || 0);
    }, 0);
    return round(calculated_premium, 2);
  }

  submit = () => {
    return this.props
      .updatePremiumAdjustment(this.state.coverages, this.state.initialValues)
      .then(this.hideModal);
  };

  render() {
    const { style, className, rating } = this.props;
    const can_update_rating = permissions.canUpdateRating({ rating });
    return (
      <div style={style} className={className}>
        <Button
          variant="link"
          onClick={this.showModal}
          disabled={!can_update_rating}
        >
          Debits/Credits
        </Button>
        <ModalDialog.Standard
          show={this.state.show_modal}
          hide={this.hideModal}
          renderHeader={() => <h3>Debits/Credits</h3>}
          renderBody={() => (
            <DebitsCreditsModal.Body
              coverages={this.props.coverages.map(code => ({
                code,
                premium: this.state.coverages[code],
              }))}
              updatePremiumAdjustment={this.updatePremiumAdjustment}
            />
          )}
          renderFooter={() => (
            <DebitsCreditsModal.Footer
              submit={this.submit}
              hide={this.hideModal}
              total_premium={this.getTotalPremium()}
            />
          )}
        />
      </div>
    );
  }
}

export default DebitsCredits;
