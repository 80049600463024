import React from 'react';
import FormGroup from 'react-bootstrap/FormGroup';

import Label from './Label';

import { getValidationClassName } from './utils';

const Helper = ({ children }) => {
  return (
    <div 
      className='help-text'
      style={{ 
      fontSize: '0.875rem',
      margin: '5px 0 5px 0',
    }}>
      {children}
    </div>
  );
};

const Control = ({ field, required, error, warn, info, touched, children }) => {

  const validationClassName = getValidationClassName(error, warn, touched);

  return (
    <FormGroup>
      <div className={validationClassName}>
        {field.title && <Label required={required}>{field.title}</Label>}
        {children}
        {touched && error && <Helper>{error}</Helper>}
        {touched && warn && <Helper>{warn}</Helper>}
        {info && <Helper>{info}</Helper>}
        {field.helper && <Helper>{field.helper}</Helper>}
      </div>
    </FormGroup>
  );
};

export default Control;
