import React from 'react';
import { Link } from 'react-router-dom';
import Alert from 'react-bootstrap/Alert';

import { StickySidebar } from '../../../layouts';
import { SubmissionSummaryCard } from '../../../submissions';
import { DynamicEndorsementAlert } from '../../../endorsements';

import * as permissions from '../../permissions';

import DownloadPolicyDocuments from './DownloadPolicyDocuments';

const styles = {
  documents: {
    marginTop: '40px',
  },
  sidebar: {
    margin: 'auto',
    maxWidth: '240px',
  },
  alert: {
    fontSize: '0.8125rem',
    marginTop: '20px',
  },
  text: {
    color: 'black',
  },
};

export default function PolicyDocuments({
  submission,
  policy,
  refetch,
  config,
}) {
  return (
    <StickySidebar
      renderMain={() => {
        return permissions.canDownloadDocuments({ policy }) ? (
          <section>
            <DownloadPolicyDocuments
              style={styles.documents}
              policy={policy}
              refetch={refetch}
            />
            <DynamicEndorsementAlert
              endorsements={policy.terms_conditions.endorsements}
              config={config.endorsements}
              render={endorsements => {
                return (
                  <Alert style={styles.alert} variant="danger">
                    <strong>Action Required: Endorsements</strong>
                    <div style={styles.text}>
                      <p style={{ marginTop: '10px' }}>
                        Please remember to complete the following endorsements
                        by editing the Policy PDF after download:
                      </p>
                      <ul>
                        {endorsements.map((endorsement, i) => {
                          return <li key={i}>{endorsement.title}</li>;
                        })}
                      </ul>
                    </div>
                  </Alert>
                );
              }}
            />
          </section>
        ) : (
          <section>
            <p className="text-danger">
              Whoops. We sent you here, but we shouldn't have. This policy
              hasn't been issued.
            </p>
            <Link to={`/policies/${policy.id}/preview`}>Policy Preview</Link>
          </section>
        );
      }}
      renderAside={() => {
        return (
          <section style={styles.sidebar}>
            <SubmissionSummaryCard flex submission={submission} />
          </section>
        );
      }}
    />
  );
}
