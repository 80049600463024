import { SubmissionError } from 'redux-form';

export function submitPage(values, dispatch, props) {
  return props.updateDetails(values, props.initialValues).catch(() => {
    throw new SubmissionError({
      _error: 'Something went wrong when updating terms and conditions.',
    });
  });
}

export function submitModal(values, dispatch, props) {
  return props
    .updateDetails(values, props.initialValues)
    .then(res => {
      props.hide();
      return res;
    })
    .catch(() => {
      throw new SubmissionError({
        _error: 'Something went wrong when updating terms and conditions.',
      });
    });
}
