const GetRatingByBinder = (fetch, auth) => binder_id => {
  return auth
    .getAccessToken()
    .then(token => {
      if (!token) {
        auth.signOut();
        return Promise.reject(new Error('User is not authenticated.'));
      }
      return token;
    })
    .then(token => {
      return fetch(`/binders/${binder_id}/rating`, {
        method: 'GET',
        headers: {
          Authorization: token,
          'Content-Type': 'application/json',
        },
      });
    })
    .then(response => response.json());
};

export default GetRatingByBinder;
