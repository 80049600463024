import React from 'react';
import { Link } from 'react-router-dom';
import ExtendedReportingSummary from '../ExtendedReportingSummary';
import * as permissions from '../../permissions';

const renderHeader = quote => ({ styles }) => {
  return (
    <React.Fragment>
      <h3 style={styles.section_header}>Extended Reporting Period</h3>
      <div className="pull-right">
        <Link
          style={styles.edit}
          className="btn btn-link"
          to={`/quotes/${quote.id}/terms-conditions`}
        >
          Edit
        </Link>
      </div>
    </React.Fragment>
  );
};

export default function ExtendedReporting({ quote, style, ...props }) {
  const {
    terms_conditions: { erp },
  } = quote;
  const can_update_terms_conditions = permissions.canUpdateTermsConditions({
    quote,
  });

  return (
    <ExtendedReportingSummary
      erp={erp}
      style={style}
      renderHeader={
        can_update_terms_conditions ? renderHeader(quote) : undefined
      }
    />
  );
}
