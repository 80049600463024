import React from 'react';
import Card from 'react-bootstrap/Card';
import moment from 'moment';

import { InsuredSummarySection, BrokerSummarySection } from '../../submissions';

const formatDate = date => {
  return moment(date)
    .utc()
    .format('MMMM D, YYYY');
};

const styles = {
  container: {
    backgroundColor: '#f5f5f5',
    padding: '20px',
    margin: '20px 0 20px'
  },
  main_header: {
    fontSize: '1.875rem',
    fontWeight: 'normal',
    marginTop: '0',
    marginBottom: '20px',
  },
  body: {
    fontSize: '0.875rem',
  },
  sub_header: {
    fontSize: '0.875rem',
    fontWeight: 'bold',
    marginBottom: '5px',
    textTransform: 'uppercase',
  },
  details_section: {
    display: 'inline-block',
    width: '50%',
  },
  footer: {
    marginTop: '20px',
  },
};

export default function PolicySummaryCard({ policy, renderFooter }) {
  return (
    <Card style={styles.container}>
      <h3 style={styles.main_header}>Policy {policy.policy_number}</h3>
      <section style={styles.body}>
        <section>
          <h4 style={styles.sub_header}>Policy Period</h4>
          <span>
            {formatDate(policy.effective_date)} -{' '}
            {formatDate(policy.expiration_date)}
          </span>
        </section>
        <section>
          <section style={styles.details_section}>
            <InsuredSummarySection named_insured={policy.named_insured} />
          </section>
          <section style={styles.details_section}>
            <BrokerSummarySection broker={policy.broker} />
          </section>
        </section>
        {renderFooter && (
          <section style={styles.footer}>{renderFooter()}</section>
        )}
      </section>
    </Card>
  );
}
