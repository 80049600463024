import React from 'react';
import { Link } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import classnames from 'classnames';

const styles = {
  container: { backgroundColor: '#f0f0f0', padding: '20px' },
  title: { marginTop: '0', fontSize: '1rem' },
  button: { paddingLeft: '0', paddingRight: '0', textTransform: 'initial' },
};

function ActionMenu({ style, ...props }) {
  return <section style={{ ...styles.container, ...style }} {...props} />;
}

ActionMenu.Title = function ActionMenuTitle({ style, children, ...props }) {
  return <h3 style={{ ...styles.title, ...style }}>{children}</h3>;
};

ActionMenu.Button = function ActionMenuButton({ style, ...props }) {
  return (
    <Button style={{ ...styles.button, ...style }} variant="link" {...props} />
  );
};

ActionMenu.Link = function ActionMenuLink({
  style,
  className,
  Component = Link,
  ...props
}) {
  const cName = classnames('btn', 'btn-link', className);
  return (
    <Component
      style={{ ...styles.button, ...style }}
      className={cName}
      {...props}
    />
  );
};

ActionMenu.DisabledButton = function ActionMenuDisabledButton({
  children,
  title,
  onClick,
  style,
  tooltiptext,
  ...props
}) {
  return (
    <OverlayTrigger
      overlay={<Tooltip id={`tooltip-for-${title}`}>{tooltiptext}</Tooltip>}
      placement="bottom"
    >
      <div
        style={{
          display: 'inline-block',
          cursor: 'not-allowed',
        }}
      >
        <Button
          variant="link"
          disabled
          onClick={onClick}
          style={{
            ...styles.button,
            pointerEvents: 'none',
            textTransform: 'none',
            ...style,
          }}
          {...props}
        >
          {children}
        </Button>
      </div>
    </OverlayTrigger>
  );
};
export default ActionMenu;
