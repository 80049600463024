import React from 'react';
import { Query } from '@apollo/client/react/components';

import { queries } from '../../../../../graphql';
import { SearchContainer, PaginatedTable } from '../../../../../common';
import { StartQuoteFromSubmissionButton } from '../../../../../quote';

import table_config from './table-config';

const styles = {
  button: {
    marginLeft: '-10px', // aligns with table
  },
};

export default function SearchQuotesBySubmission({
  can_start_quote,
  submission,
}) {
  return (
    <SearchContainer
      render={({ term, page, limit }) => {
        return (
          <Query
            query={queries.quotes.SearchQuotesBySubmission}
            variables={{
              submission_id: submission.id,
              limit: limit.value,
              offset: limit.value * (page.value - 1),
            }}
          >
            {({ data, loading }) => {
              return (
                <div>
                  {data &&
                    data.submissionById &&
                    data.submissionById.quotes && (
                      <React.Fragment>
                        <PaginatedTable
                          row_id="id"
                          columns={table_config.columns}
                          placeholder="Search price options"
                          loading={loading}
                          list={data.submissionById.quotes.results}
                          total={data.submissionById.quotes.count}
                          term={term}
                          limit={limit}
                          page={page}
                        />
                        {can_start_quote && (
                          <StartQuoteFromSubmissionButton
                            style={styles.button}
                            submission={submission}
                          >
                            Create New Quote
                          </StartQuoteFromSubmissionButton>
                        )}
                      </React.Fragment>
                    )}
                </div>
              );
            }}
          </Query>
        );
      }}
    />
  );
}
