import React from 'react';
import FormControl from 'react-bootstrap/FormControl';
import Button from 'react-bootstrap/Button';

const styles = {
  input: {
    paddingRight: '10px',
    width: '100%',
  },
  middle: {
    paddingRight: '10px',
  },
  cell: {
    display: 'table-cell',
    padding: '10px 0',
    verticalAlign: 'middle',
  },
};

class EditableRowActive extends React.Component {
  state = { currentValue: this.props.label };

  applyItemEdits = event => {
    const newValue = this.state.currentValue;
    const key = this.props.value;
    const updateValues = option =>
      option.value === key
        ? {
            value: `CUSTOM-${newValue}`,
            label: newValue,
          }
        : option;
    if (newValue !== this.props.label) {
      const newSelected = this.props.input.value.map(updateValues);
      this.props.input.onChange(newSelected);
      this.props.updateOptions(newValue, key);
    }
    this.props.stopEditing();
  };

  render() {
    return (
      <React.Fragment>
        <div style={{ ...styles.cell, ...styles.input }}>
          <FormControl
            style={styles.input}
            as="input"
            type="text"
            value={this.state.currentValue}
            onChange={event =>
              this.setState({ currentValue: event.target.value })
            }
            onKeyDown={event => {
              if (event.key === 'Enter') {
                event.preventDefault();
              }
            }}
            onKeyUp={event => {
              if (event.key === 'Enter') {
                this.applyItemEdits(event);
              }
            }}
          />
        </div>
        <div style={{ ...styles.cell, ...styles.middle }}>
          <Button variant="primary" onClick={this.applyItemEdits}>
            Apply
          </Button>
        </div>
        <div style={styles.cell}>
          <Button onClick={() => this.props.stopEditing()} variant="link">
            Cancel
          </Button>
        </div>
      </React.Fragment>
    );
  }
}
export default EditableRowActive;
