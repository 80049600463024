import React from 'react';
import Row from 'react-bootstrap/Row';

import { Exclusions, ExtendedReporting } from '../../../../quote';
import * as validation from './validation';

export default function ExclusionsExtendedReporting({
  style,
  selected_coverages,
}) {
  return (
    <section style={style}>
      <Row style={{ margin: '15px 0 0' }}>
        <h3>Exclusions and Reporting</h3>
        <Row style={{ margin: '0' }}>
          <Exclusions
            selected_coverages={selected_coverages}
            validation={validation}
          />
        </Row>
        <ExtendedReporting
          validation={validation.erp}
          style={{ marginTop: '10px' }}
        />
      </Row>
    </section>
  );
}
