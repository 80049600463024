import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap/Modal';

import './ModalDialog.css';

const styles = {
  container: {
    padding: '20px',
  },
  header: {
    border: '0',
    padding: '0 0 20px 0',
  },
  body__fixed_height: {
    backgroundColor: '#f7f7f7',
    maxHeight: '300px',
    overflowY: 'scroll',
    padding: '20px 25px',
  },
  body__standard: {
    backgroundColor: '#f7f7f7',
    padding: '20px 25px',
  },
  footer: {
    border: '0',
    padding: '20px 0 0 0',
  },
};

function StandardModalDialog({
  style,
  show,
  hide,
  renderHeader,
  renderBody,
  renderFooter,
}) {
  return (
    <Modal dialogClassName="ModalDialog__dialog" show={show} onHide={hide}>
      <div style={{ ...styles.container, ...style }}>
        {renderHeader && (
          <Modal.Header style={styles.header} closeButton>
            {renderHeader()}
          </Modal.Header>
        )}
        {renderBody && (
          <Modal.Body style={styles.body__standard}>{renderBody()}</Modal.Body>
        )}
        {renderFooter && (
          <Modal.Footer style={styles.footer}>{renderFooter()}</Modal.Footer>
        )}
      </div>
    </Modal>
  );
}

function FixedHeightModalDialog({
  style,
  show,
  hide,
  renderHeader,
  renderBody,
  renderFooter,
}) {
  return (
    <Modal dialogClassName="ModalDialog__dialog" show={show} onHide={hide}>
      <div style={{ ...styles.container, ...style }}>
        {renderHeader && (
          <Modal.Header style={styles.header} closeButton>
            {renderHeader()}
          </Modal.Header>
        )}
        {renderBody && (
          <Modal.Body style={styles.body__fixed_height}>
            {renderBody()}
          </Modal.Body>
        )}
        {renderFooter && (
          <Modal.Footer style={styles.footer}>{renderFooter()}</Modal.Footer>
        )}
      </div>
    </Modal>
  );
}

StandardModalDialog.defaultProps = FixedHeightModalDialog.defaultProps = {
  renderHeader: () => null,
  renderBody: () => null,
  renderFooter: () => null,
};

StandardModalDialog.propTypes = FixedHeightModalDialog.propTypes = {
  style: PropTypes.object,
  show: PropTypes.bool.isRequired,
  hide: PropTypes.func.isRequired,
  renderHeader: PropTypes.func,
  renderBody: PropTypes.func,
  renderFooter: PropTypes.func,
};

export {
  FixedHeightModalDialog as FixedHeight,
  StandardModalDialog as Standard,
};
