import captureStackTrace from 'capture-stack-trace';

export class ApiError extends Error {
  constructor(
    message = 'An unexpected error occurred. Please try again.',
    ...args
  ) {
    super(message, ...args);
    captureStackTrace(this, ApiError);
  }
}
