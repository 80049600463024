import React from 'react';
import { DisableableLink } from '../../../../common';

import WorksheetSummarySection from '../WorksheetSummarySection';
import WorksheetSummaryErrorBanner from '../WorksheetSummaryErrorBanner';
import * as ZScoreAlert from '../ZScoreAlert';

import * as RatingWorksheet from '../../RatingWorksheet';

const styles = {
  section_header: {
    fontSize: '1.25rem',
    margin: '1.5rem 0 1.5rem 0',
  },
};

export default function RatingWorksheetSummary({
  style,
  rating,
  productConfig,
  can_update_rating,
}) {
  return (
    <div style={style}>
      <div>
        <div className="pull-right">
          <DisableableLink
            className="btn btn-link"
            disabled={!can_update_rating}
            to={`/ratings/${rating.id}/rating-worksheet`}
          >
            Edit
          </DisableableLink>
        </div>
        <h3 style={styles.section_header}>Rating worksheet details</h3>
      </div>
      <ZScoreAlert.Basic rating={rating} />
      {!RatingWorksheet.isValid(productConfig, {
        rating,
      }) && <WorksheetSummaryErrorBanner style={{ marginBottom: '20px' }} />}
      <div>
        <WorksheetSummarySection
          values={RatingWorksheet.getFormSectionSummaries({ rating })}
        />
      </div>
    </div>
  );
}
