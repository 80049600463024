import React from 'react';
import FormControl from 'react-bootstrap/FormControl';

const SelectControl = ({ options, ...props }) => {
  return (
    <FormControl id={props.name} as="select" {...props}>
      {options.map(({ value, label }) => (
        <option key={value} value={value}>
          {label}
        </option>
      ))}
    </FormControl>
  );
};

export default SelectControl;
