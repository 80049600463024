export const mapFormValuesToTermsConditions = form_values => {
  const endorsements = form_values.endorsements
    ? form_values.endorsements.map(({ value, version, data }) => ({
        value,
        version,
        ...(data && { data }),
      }))
    : [];
  // When no exclusions are defined, exclusions should be an empty object,
  // not undefined or null.
  const exclusions = { ...form_values.exclusions };
  return {
    subjectivities: form_values.subjectivities,
    endorsements,
    exclusions,
  };
};
