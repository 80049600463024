import React from 'react';
import { connect } from 'react-redux';
import { getFormValues } from 'redux-form';

import WithForm from './WithForm';

const WithValues = connect((state, props) => state => {
  return {
    values: getFormValues(props.name)(state),
  };
})(props => props.render({ values: props.values }));

export default function ConditionalField({ field, render }) {
  const field_conditions = field.conditions || [];
  return (
    <WithForm
      render={({ name, conditions, props: formProps }) => {
        return (
          <WithValues
            name={name}
            render={({ values }) => {
              const show = field_conditions.reduce((show, condition) => {
                return show && conditions[condition](field)(values, formProps);
              }, true);
              if (!show) return null;
              return render();
            }}
          />
        );
      }}
    />
  );
}
