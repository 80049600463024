import React from 'react';
import Active from './Active';
import Inactive from './Inactive';

// Note:
// this method deletes any property
// that equal to null. The better way
// (that's more consistent with the rest
// of the app) is to implement
// "mapFormValuesToSLBroker" and
// prevent passing in an empty string
// to dynamo.
const sanitize = obj => {
  let newObj = { ...obj };
  Object.keys(obj).forEach(key => {
    const is_null = newObj[key] === null;
    if (is_null) {
      delete newObj[key];
    }
  });
  return newObj;
};

class SLBroker extends React.Component {
  state = { active: false };
  makeActive = () => {
    this.setState({ active: true });
  };
  makeInactive = () => {
    this.setState({ active: false });
  };
  render() {
    const {
      style,
      sl_broker,
      is_locked,
      updateSLBroker,
      validation,
      is_required,
      named_insured,
      ...props
    } = this.props;
    return (
      <section style={style} {...props}>
        {this.state.active ? (
          <Active
            makeInactive={this.makeInactive}
            sl_broker={sl_broker}
            updateSLBroker={updateSLBroker}
            initialValues={sanitize(sl_broker)}
            is_required={is_required}
            validation={validation}
            named_insured={named_insured}
          />
        ) : (
          <Inactive
            makeActive={this.makeActive}
            is_locked={is_locked}
            sl_broker={sl_broker}
          />
        )}
      </section>
    );
  }
}
export default SLBroker;
