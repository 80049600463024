import React, { Component } from 'react';
import { Spinner } from 'spin.js';

const styles = {
  text: {
    marginLeft: '35px',
    fontSize: '0.875rem',
  },
};

class Loader extends Component {
  componentDidMount() {
    const opts = {
      lines: 10,
      length: 8,
      width: 7,
      radius: 16,
      scale: 0.4,
      position: 'relative',
      left: '3%',
      top: '10px',
      zIndex: 0,
    };
    this.spinner = new Spinner(opts).spin(this.spinner);
  }

  render() {
    const { style } = this.props;
    return (
      <div style={style}>
        <span ref={spinner => (this.spinner = spinner)} />
        <span style={styles.text}>Preparing download</span>
      </div>
    );
  }
}

export default function Section({ loading, renderHeader, renderBody }) {
  return (
    <section>
      {renderHeader()}
      {loading ? <Loader style={{ marginTop: '15px' }} /> : renderBody()}
    </section>
  );
}

Section.defaultProps = {
  renderHeader: () => null,
  renderBody: () => null,
  loading: true,
};
