import './Currency.css';
import React from 'react';
import InputGroup from 'react-bootstrap/InputGroup';
import FormControl from 'react-bootstrap/FormControl';

import Control from '../Control';

const styles = {
  input: {
    textAlign: 'right',
  }
};

const CurrencyInput = props => {
  const { input, disabled } = props;
  return (
    <Control {...props}>
      <div className="currency_input">
        <InputGroup>
          <InputGroup.Text>$</InputGroup.Text>
          <FormControl
            type="text"
            {...input}
            disabled={disabled}
            style={styles.input}
          />
        </InputGroup>
      </div>
    </Control>
  );
};

export default CurrencyInput;
