import React from 'react';
import Col from 'react-bootstrap/Col';
import { Field, FormSection } from 'redux-form';

import { InputControl } from '../../../common';

const styles = {
  narrow_nested_column: {
    paddingLeft: '0',
    marginTop: '10px',
  },
};

const coverages = [
  { name: 'do', label: 'D&O' },
  { name: 'epl', label: 'EPL' },
  { name: 'fid', label: 'FID' },
];

export default function Exclusions({
  validation,
  selected_coverages,
  required_coverages = selected_coverages,
}) {
  const selected_coverages_set = new Set(selected_coverages);
  return (
    <FormSection name="exclusions">
      <strong>Pending and Prior Litigation Exclusions</strong>
      <Col md={6} style={styles.narrow_nested_column}>
        {coverages
          .filter(coverage => selected_coverages_set.has(coverage.name))
          .map(coverage => (
            <Field
              name={coverage.name}
              type="text"
              component={InputControl}
              label={coverage.label}
              key={coverage.name}
              required={required_coverages.includes(coverage.name)}
              validate={validation.exclusions[coverage.name]}
            />
          ))}
      </Col>
    </FormSection>
  );
}
