import React from 'react';
import Button from 'react-bootstrap/Button';

const styles = {
  button: {
    border: 'none',
    display: 'inline',
    fontSize: 'inherit',
    padding: 0,
    textTransform: 'initial',
    verticalAlign: 'initial',
  },
};

export default function InlineButton({ style, ...props }) {
  return (
    <Button style={{ ...styles.button, ...style }} variant="link" {...props} />
  );
}
