import { gql } from '@apollo/client';

import { fragments } from '../../../policies/components/ViewPolicyContainer/query';

export const query = gql`
  ${fragments.policy}
  query MidTermEndorsementsContainer($policyId: ID) {
    policy: policyById(id: $policyId) {
      ...ViewPolicyContainer
      config {
        endorsements {
          id
          value: id
          version
          title
          label: title
          valid_for
          supplementary_data
          dynamic
          template_location {
            url
          }
        }
      }
      submission {
        id
        account_id
        submission_number
        status
        broker {
          name
          brokerage
          email
        }
        named_insured {
          name
          address {
            street_line_1
            street_line_2
            city
            state
            zip
          }
        }
        created_by {
          user_id
          group
        }
        clearance {
          status
          timestamp
        }
        created_at
        updated_at
      }
    }
  }
`;
