import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

import { AsyncMutationWrapper } from '../../../common';

const styles = {
  container: {
    fontSize: '0.875rem',
    padding: '20px',
  },
  header: {
    border: '0',
    fontSize: '1.5rem',
    padding: '0 0 20px 0',
  },
  header_text: {
    margin: '30px 0 0',
  },
  body: {
    backgroundColor: '#f7f7f7',
    maxHeight: '300px',
    overflowY: 'scroll',
    padding: '20px 25px',
  },
  footer: {
    border: '0',
    padding: '20px 0 0 0',
    textAlign: 'left',
  },
};

export default function BindPolicyModal({ show, hide, bindPolicy }) {
  return (
    <Modal dialogClassName="ValidationModal__dialog" show={show} onHide={hide}>
      <div style={styles.container}>
        <Modal.Header  closeButton>
          <Modal.Title style={styles.header_text}>Ready to Bind?</Modal.Title>
        </Modal.Header>
        <Modal.Body style={styles.body}>
          <p>This action cannot be undone.</p>
          <p>
            Please confirm all the details of the binder are correct before
            binding.
          </p>
        </Modal.Body>
        <Modal.Footer style={styles.footer}>
          <AsyncMutationWrapper
            mutate={bindPolicy}
            render={({ loading, mutate }) => (
              <React.Fragment>
                <Button
                  disabled={loading}
                  onClick={mutate}
                  type="button"
                  variant="primary"
                >
                  Confirm and Bind
                </Button>
                <Button
                  disabled={loading}
                  type="button"
                  variant="link"
                  onClick={hide}
                >
                  Return to Preview
                </Button>
              </React.Fragment>
            )}
          />
        </Modal.Footer>
      </div>
    </Modal>
  );
}
