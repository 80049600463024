import React from 'react';

import DeleteEndorsement from './DeleteEndorsement';

import { ActionMenu } from '../../../../../common';

const styles = {
  list: {
    listStyleType: 'none',
    padding: '0',
    margin: '0',
  },
  download_icon: {
    marginRight: '5px',
  },
};

export default function EndorsementActionMenu({
  style,
  template_url,
  deleteEndorsement,
  submission_id,
  showEndorsementNumber,
}) {
  return (
    <ActionMenu style={style}>
      <ActionMenu.Title>Manage this Endorsement</ActionMenu.Title>
      <ul style={styles.list}>
        <li>
          <ActionMenu.Link href={template_url} Component="a">
            <i className="bi bi-download" style={{marginRight: '5px'}}/>
            Download Template
          </ActionMenu.Link>
        </li>
        <li>
          <ActionMenu.Button onClick={() => showEndorsementNumber()}>
            Find Endorsement Number
          </ActionMenu.Button>
        </li>
        <li>
          <DeleteEndorsement
            deleteEndorsement={deleteEndorsement}
            submission_id={submission_id}
          />
        </li>
      </ul>
    </ActionMenu>
  );
}
