import React from 'react';
import { Flag } from '../../../common/components/Flag';
import { reduxForm } from 'redux-form';

import Button from 'react-bootstrap/Button';

import { StickySidebar } from '../../../layouts';
import { SubmissionSummaryCard } from '../../../submissions';
import { EndorsementsPreview } from '../../../endorsements';
import { StandardPriceOptionSummaryCard } from '../../../rating';
import SaveOnLeave from '../../../common/components/SaveOnLeave';

import ZScoreAlert from './ZScoreAlert';
import Subjectivities from './Subjectivities';
import ExclusionsReporting from './ExclusionsReporting';

import * as permissions from '../../permissions';

import submit from './submit';
import CancelButton from './CancelButton';

const styles = {
  section_container: {
    borderTop: '1px solid grey',
    marginTop: '35px'
  },
  section_container2: {
    borderTop: '1px solid grey',
    marginTop: '35px',
    paddingTop: '20px'
  },
  sidebar: {
    margin: 'auto',
    maxWidth: '240px',
  },
  submitButton: {
    marginTop: '1.25rem',
  },
  cancelButton: { display: 'table', margin: 'auto' },
};

class BinderWorksheet extends React.Component {
  state = { autoSaveDisabled: false };
  disableAutoSave = () => this.setState({ autoSaveDisabled: true });
  render() {
    const {
      config,
      binder,
      rating,
      quote,
      submission,
      initialValues,
      submitting,
      error,
      handleSubmit,
      form,
      updateTermsConditions,
    } = this.props;
    return (
      <StickySidebar
        renderMain={() => {
          return (
            <section>
              <form
                noValidate
                onSubmit={event => {
                  // Promise.resolve(o) returns o if o is a promise
                  // per http://www.ecma-international.org/ecma-262/6.0/#sec-promise.resolve
                  const isPromise = o => Promise.resolve(o) === o;
                  const submitResult = handleSubmit(event);
                  if (isPromise(submitResult)) {
                    submitResult.then(e => {
                      // redux-form returns a resolved promise with errors,
                      // instead of erroring/rejecting as you'd expect.
                      //
                      // We need to check for errors, otherwise our .then() is
                      // still executed, even if we throw errors in our submit handler
                      if (e._error) return;
                      const { binder_id } = this.props.match.params;
                      this.props.navigate(`/binders/${binder_id}/details`);
                    });
                  }
                }}
              >
                <SaveOnLeave
                  form={form}
                  save={values => updateTermsConditions(values, initialValues)}
                  disabled={this.state.autoSaveDisabled}
                />
                <h1>Prepare Binder</h1>
                <StandardPriceOptionSummaryCard
                  style={{ marginTop: '35px' }}
                  rating={rating}
                  quote={quote}
                  productConfig={{
                    risk_analysis_schema: {},
                    limits_retentions_schema: {},
                  }} // A little hacky, but we don't need validation here
                />
                <ZScoreAlert style={{ marginTop: '35px' }} binder={binder} />
                <Subjectivities
                  style={styles.section_container2}
                  subjectivities={binder.terms_conditions.subjectivities}
                  options={config.subjectivities}
                  config={{
                    oldType: 'quote',
                    newType: 'binder',
                  }}
                  loading={submitting}
                  error={error}
                  updateTermsConditions={handleSubmit}
                  message="Resolve Subjectivities that have already been accepted"
                />
                <EndorsementsPreview
                  style={styles.section_container}
                  endorsements={binder.terms_conditions.endorsements}
                />
                <ExclusionsReporting
                  selected_coverages={rating.risk_analysis.selected_coverages}
                  style={styles.section_container}
                />
                <Button
                  style={styles.submitButton}
                  variant="primary"
                  type="submit"
                  disabled={submitting}
                >
                  {submitting ? 'Working...' : 'Add bind details'}
                </Button>
                {error &&
                  !submitting && (
                    <p className="text-danger" style={styles.error}>
                      <span style={styles.error_text}>
                        Sorry, there was an error updating Binder.
                      </span>
                      <span style={styles.error_text}>
                        Please submit again now.
                      </span>
                    </p>
                  )}
              </form>
            </section>
          );
        }}
        renderAside={() => {
          // To prevent errors when we're in the process of deleting the binder.
          // There should be a callback on the event loop to navigate away.
          if (quote === null) return null;
          return (
            <section style={styles.sidebar}>
              {permissions.canCancelBinder({
                binder,
              }) && (
                <CancelButton
                  deleteBinder={this.props.deleteBinder}
                  style={styles.cancelButton}
                  disableAutoSave={this.disableAutoSave}
                />
              )}
              <Flag
                name="binder.worksheet.submission_summary_card"
                render={() => {
                  return <SubmissionSummaryCard flex submission={submission} />;
                }}
              />
            </section>
          );
        }}
      />
    );
  }
}

export default reduxForm({
  form: 'binder_terms_conditions',
  onSubmit: submit,
})(BinderWorksheet);
