import get from 'lodash/get';
import validator from 'validator';

const required = (value = '') => {
  if (validator.isEmpty(value)) return 'Required';
};

const optional = () => {};

const validations = {
  errors: {
    address: {
      street_line_1: optional,
      city: optional,
      state: optional,
      zip: optional,
    },
    license_number: optional,
    nj_transaction_number(value, allValues, props) {
      const insuredState = get(props, 'named_insured.address.state');
      if (String(insuredState).toLowerCase() === 'nj') return required(value);
    },
    name: optional,
  },
  is_required: {
    address: {
      street_line_1: false,
      city: false,
      state: false,
      zip: false,
    },
    license_number: false,
    nj_transaction_number: insured_state => insured_state === 'NJ',
    name: false,
  },
};

export default validations;
