export const getById = (state, id) => {
  return state.by_id[id];
};

export const getBySubmission = (state, submission_id) => {
  const policy_ids = Object.keys(state.by_id);
  let target_policy = null;
  policy_ids.forEach(policy_id => {
    const policy = getById(state, policy_id);
    if (policy.submission_id === submission_id) {
      target_policy = policy;
    }
  });
  return target_policy;
};
