import validator from 'validator';

const errors = {
  commission(value = '') {
    if (validator.isEmpty(value)) return 'Required';
    if (!validator.isDecimal(value)) return 'Please enter a valid number';
  },
  address: {
    street_line_1(value = '') {
      if (validator.isEmpty(value)) return 'Required';
    },
    city(value = '') {
      if (validator.isEmpty(value)) return 'Required';
    },
    state(value = '') {
      if (validator.isEmpty(value)) return 'Required';
    },
    zip(value = '') {
      if (validator.isEmpty(value)) return 'Required';
      if (!validator.isPostalCode(value, 'US'))
        return 'Please enter a valid Zip Code';
    },
  },
  phone(value = '') {
    if (validator.isEmpty(value)) return 'Required';
    const phoneRegex = /^(\+?1[ -]?)?(\([0-9]{3}\)|[0-9]{3})[ -]?[0-9]{3}[- ]?[0-9]{4}( ?(x|ext|,) ?[0-9]+)?$/;
    if (!value.match(phoneRegex)) return 'Phone number appears invalid';
  },
};

const is_required = {
  commission: true,
  address: {
    street_line_1: true,
    city: true,
    state: true,
    zip: true,
  },
  phone: true,
};

export { errors, is_required };
