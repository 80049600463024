import { connect } from 'react-redux';
import { withRouter } from '../../../common/components/WithRouter';
import * as binders from '../../actions';

import BinderPreview from './BinderPreview';
import OverviewSection from './OverviewSection';
import PriceOptionSection from './PriceOptionSection';
import ExclusionsSection from './ExclusionsSection';
import EndorsementsSection from './EndorsementsSection';

const mapDispatchToProps = (dispatch, props) => {
  const binder = props.binder;
  return dispatch => ({
    deleteBinder: () => dispatch(binders.deleteBinder(binder, props.navigate)),
  });
};

export {
  OverviewSection,
  PriceOptionSection,
  ExclusionsSection,
  EndorsementsSection,
};

export default withRouter(
  connect(
    null,
    mapDispatchToProps
  )(BinderPreview)
);
