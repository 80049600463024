import React from 'react';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';

import { Toggleable, ZScoreBase } from '../../../common';

import FinancialsModal from './FinancialsModal';

const styles = {
  alert: {
    fontSize: '0.8125rem',
  },
  top: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  icon: {
    marginRight: '0.25em',
  },
  text: {
    color: 'black',
  },
  button: {
    marginLeft: '0.5em',
  },
  button_link: {
    padding: '0',
  },
};

export default function BinderZScoreAlert({ style, binder }) {
  return (
    <Toggleable
      render={({ visible, show, hide }) => {
        return (
          <div style={style}>
            <FinancialsModal binder={binder} visible={visible} hide={hide} />
            <ZScoreBase
              rating={binder.rating}
              valid={({ z_score }) => {
                return (
                  <Alert style={styles.alert} variant="success">
                    <div style={styles.top}>
                      <p style={{ marginBottom: '0' }}>
                        <strong>
                        <i className="bi bi-check-circle" style={{marginRight: '5px'}}/>Z-Score
                          passed:
                        </strong>{' '}
                        <span style={styles.text}>{z_score}</span>
                      </p>
                      <Button
                        style={{ ...styles.button, ...styles.button_link }}
                        variant="link"
                        onClick={show}
                      >
                        View Details
                      </Button>
                    </div>
                  </Alert>
                );
              }}
              indeterminate={() => {
                return (
                  <Alert style={styles.alert} variant="warning">
                    <div style={styles.top}>
                      <p style={{ marginBottom: '0' }}>
                        <strong>Note:</strong>{' '}
                        <span style={styles.text}>
                          If possible, please complete the financial information
                          to check the Z-Score
                        </span>
                      </p>

                      <Button
                        style={styles.button}
                        variant="primary"
                        onClick={show}
                      >
                        Check Z-Score
                      </Button>
                    </div>
                  </Alert>
                );
              }}
              invalid={({ z_score }) => {
                return (
                  <Alert style={styles.alert} variant="danger">
                    <div>
                      <div style={styles.top}>
                        <p style={{ marginBottom: '0' }}>
                          <strong>
                            <i className="bi bi-x-circle-fill"/>
                            {' '}
                            Z-Score Requires Review:
                          </strong>{' '}
                          <span style={styles.text}>{z_score}</span>
                        </p>
                        <Button
                          style={{ ...styles.button, ...styles.button_link }}
                          variant="link"
                          onClick={show}
                        >
                          View Details
                        </Button>
                      </div>
                      <p style={{ ...styles.text, marginTop: '0.5rem' }}>
                        This company does not the meet the financial
                        requirements to bind a policy without approval.
                      </p>
                    </div>
                  </Alert>
                );
              }}
            />
          </div>
        );
      }}
    />
  );
}
