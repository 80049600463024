import { withRouter } from '../../../common/components/WithRouter';
import { connect } from 'react-redux';

import * as ratings from '../../actions';

import CopyRatingButton from './CopyRatingButton';

const mapDispatchToProps = (dispatch, props) => {
  const rating = props.rating;
  return dispatch => ({
    copyRating: () => dispatch(ratings.createRatingCopy(rating.id)),
  });
};

export default withRouter(connect(null, mapDispatchToProps)(CopyRatingButton));
