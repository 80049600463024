import PropTypes from 'prop-types';
import React from 'react';
import Form from 'react-bootstrap/Form';

import declination_statuses from './declination_statuses';
import { LabeledControl } from '../../../../common';

const declination_statuses_order = ['OPEN', 'DECLINED'];

export default function Status({
  inline,
  input,
  label,
  meta,
  options,
  required,
}) {
  return (
    <LabeledControl input={input} label={label} meta={meta} required={required}>
      {options.map(({ label, value }) => (
        <Form.Check
          className='ms-2'
          {...input}
          type='radio'
          checked={value === input.value}
          disabled={meta.submitting}
          id={value}
          key={value}
          inline={inline}
          name={input.name}
          value={value}
          label={<label htmlFor={value}>{label}</label>}
        />
      ))}
    </LabeledControl>
  );
}

Status.defaultProps = {
  inline: false,
  options: declination_statuses_order.map(status => ({
    label: declination_statuses[status],
    value: status,
  })),
  required: false,
};

Status.propTypes = {
  inline: PropTypes.bool,
  input: PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    onBlur: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    onFocus: PropTypes.func.isRequired,
  }).isRequired,
  label: PropTypes.string.isRequired,
  // TODO: create custom validator to ensure key is UPPERCASE
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  required: PropTypes.bool,
};
