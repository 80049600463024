import { connect } from 'react-redux';

import SearchSubmissions from './SearchSubmissions';

import { search as searchSubmissions } from '../../actions';

const mapDispatchToProps = dispatch => ({
  searchSubmissions: (...args) => dispatch(searchSubmissions(...args)),
});

export default connect(null, mapDispatchToProps)(SearchSubmissions);
