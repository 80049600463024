import { connect } from 'react-redux';

import MidTermEndorsementDocuments from './MidTermEndorsementDocuments';
import { withRouter } from '../../../../common/components/WithRouter'

import { getById } from '../../../actions';

const mapDispatchToProps = (dispatch, props) => {
  const { endorsement_id } = props.match.params;
  return {
    getEndorsement() {
      return dispatch(getById(endorsement_id, { cache: false }));
    },
  };
};

export default withRouter(
  connect(
    null,
    mapDispatchToProps
  )(MidTermEndorsementDocuments)
);
