import { build as buildCondition } from './../../condition/builder';
import { build as buildValue } from '../../value/builder';
export class Condition {
  constructor(config, conditions) {
    this.config = config;
    this.conditions = conditions;
  }
  value(valueConfig) {
    return buildValue(valueConfig);
  }
  condition(conditionConfig) {
    return buildCondition(conditionConfig, this.conditions);
  }
}
