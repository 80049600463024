export const getValidationClassName = (error, warn, touched) => {
  if (!touched) return '';
  if (error) {
    return 'has-error';
  } else if (warn) {
    return'has-warning';
  }else{ 
    return '';
  }
}
