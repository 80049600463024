import React from 'react';

import * as PricingWorksheet from '../../PricingWorksheet';

import { DisableableLink } from '../../../../common';
import WorksheetSummarySection from '../WorksheetSummarySection';
import WorksheetSummaryErrorBanner from '../WorksheetSummaryErrorBanner';

const styles = {
  section_header: {
    fontSize: '1.25rem',
    marginBottom: '1.5rem',
    marginTop: '1.5rem'
  },
};

export default function PricingWorksheetSummary({
  style,
  productConfig,
  rating,
  quote,
  can_update_rating,
}) {
  return (
    <div style={style}>
      <div>
        <div className="pull-right">
          <DisableableLink
            className="btn btn-link"
            disabled={!can_update_rating}
            to={`/ratings/${rating.id}/pricing-worksheet`}
          >
            Edit
          </DisableableLink>
        </div>
        <h3 style={styles.section_header}>Limits and Retentions</h3>
      </div>
      {!PricingWorksheet.isValid(productConfig, {
        rating,
        quote,
      }) && <WorksheetSummaryErrorBanner style={{ marginBottom: '20px' }} />}
      <WorksheetSummarySection
        values={PricingWorksheet.getLimitSummaries({ rating })}
      />
    </div>
  );
}
