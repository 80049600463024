import React from 'react';
import Button from 'react-bootstrap/Button';
import get from 'lodash/get';

import { formatters } from '../../format';

const styles = {
  container: {
    borderTop: '1px solid #b3b3b3',
  },
  invalid: {
    background: 'rgba(255, 0, 0, 0.25)',
  },
};

// placeholder is an 'em-dash':
// http://www.chicagomanualofstyle.org/qanda/data/faq/topics/HyphensEnDashesEmDashes/faq0002.html
const placeholder = '\u2014';

export default function SelectedEndorsement({
  style,
  isValid,
  selection,
  index,
  edit,
  unselect,
}) {
  const fields = get(selection, 'supplementary_data.fields', []);
  const _style = { ...style, ...styles.container };
  if (!isValid) Object.assign(_style, styles.invalid);
  return (
    <React.Fragment>
      <li key={selection.value} style={_style}>
        <div style={{ display: 'table-cell', padding: '10px' }}>
          <span>{selection.title}</span>
          {!!fields.length && (
            <div
              style={{
                border: '1px solid #0d3e67',
                display: 'table',
                marginTop: '10px',
                padding: '10px',
                width: '100%',
              }}
            >
              <div style={{ display: 'table-row' }}>
                <ul
                  style={{
                    display: 'table-cell',
                    padding: '10px',
                    listStyle: 'none',
                  }}
                >
                  {fields.map(field => {
                    const format = formatters[field.format] || (x => x);
                    const value = get(selection.data, field.name);
                    return (
                      <li
                        key={field.name}
                        style={{ display: 'inline', marginRight: '10px' }}
                      >
                        <strong>{field.label}:</strong>{' '}
                        {value != null ? format(value) : placeholder}
                      </li>
                    );
                  })}
                </ul>
                <div
                  style={{
                    display: 'table-cell',
                    padding: '10px',
                    textAlign: 'right',
                  }}
                >
                  <Button variant="link" onClick={() => edit(selection, index)}>
                    Edit
                  </Button>
                </div>
              </div>
            </div>
          )}
        </div>
        <span style={{ display: 'table-cell', padding: '10px' }}>
          <Button variant="link" onClick={unselect}>
            Remove
          </Button>
        </span>
      </li>
    </React.Fragment>
  );
}
