import set from 'lodash/set';

import { sanitizeFileName } from '../utils';

export const appendEndorsementLabels = (endorsements, options) => {
  const by_value = options.reduce((by_value, option) => {
    set(by_value, `${option.value}.${option.version}`, option);
    return by_value;
  }, {});
  return endorsements.map(({ value, data, version }) => {
    return {
      value,
      version,
      data,
      ...by_value[value][version],
    };
  });
};

export const getOptionFilename = (option, policy) => {
  const filename = sanitizeFileName(
    `Endmt-${option.title}-${option.version}-${policy.named_insured.name}-${policy.policy_number}`
  );
  return filename;
};
