import React from 'react';
import Col from 'react-bootstrap/Col';
import { Field, FormSection } from 'redux-form';
import InputGroup from 'react-bootstrap/InputGroup';
import FormControl from 'react-bootstrap/FormControl';
import { InputControl, LabeledControl } from '../../../common';

const styles = {
  narrow_nested_column: {
    paddingLeft: '0',
    marginTop: '10px',
  },
};

const PercentageInputControl = props => {
  const { input, meta, ...otherProps } = props;
  return (
    <LabeledControl {...props}>
      <InputGroup>
        <FormControl
          id={otherProps.id || input.name}
          {...input}
          {...otherProps}
        />
        <InputGroup.Text>%</InputGroup.Text>
      </InputGroup>
    </LabeledControl>
  );
};

export default function ExtendedReporting({ style, validation = {} }) {
  return (
    <section style={style}>
      <FormSection name="erp">
        <strong style={{ display: 'block' }}>Extended Reporting Period</strong>
        <Col md={6} style={styles.narrow_nested_column}>
          <Field
            name="years"
            type="number"
            component={InputControl}
            label="Years"
            required
            disabled
            validate={validation.years}
          />
          <Field
            name="percentage"
            type="number"
            component={PercentageInputControl}
            label="% of Annual Premium"
            required
            disabled
            validate={validation.percentage}
            style={{ zIndex: 'auto' }}
          />
        </Col>
      </FormSection>
    </section>
  );
}
