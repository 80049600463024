import { gql } from '@apollo/client';

export const query = gql`
  query MidTermEndorsement($id: ID) {
    endorsement: midtermEndorsementById(id: $id) {
      id
      submission_id
      created_at
      updated_at
      booked_at
      booked_by {
        user_id
        group
        first_name
        last_name
      }
      status
      title
      policy {
        id
        policy_number
        footer_endorsements_count
        endorsements {
          results {
            id
            booked_at
            title
          }
        }
      }
      documents {
        location {
          url
        }
      }
      option {
        id
        version
        template_location {
          url
        }
      }
    }
  }
`;
