import React from 'react';
import { Link } from 'react-router-dom';

import { DownloadDocuments } from '../../../common';

import 'spin.js/spin.css';

const styles = {
  link: {
    marginTop: '30px',
  },
};

export default function DownloadQuoteDocuments({
  getQuoteDocuments,
  quote: { quote_letter },
}) {
  return (
    <DownloadDocuments.Main
      fetch={getQuoteDocuments}
      done={quote_letter}
    >
      <DownloadDocuments.Header>Quote download</DownloadDocuments.Header>
      {(!quote_letter) && (
        <DownloadDocuments.InProgressDescription />
      )}
      <div style={{ marginTop: '30px' }}>
        <DownloadDocuments.Section
          loading={!quote_letter}
          renderHeader={() => (
            <DownloadDocuments.SectionHeader>
              Quote Letter
            </DownloadDocuments.SectionHeader>
          )}
          renderBody={() => (
            <DownloadDocuments.Button url={quote_letter.url}>
              Download Quote
            </DownloadDocuments.Button>
          )}
        />
      </div>
      <Link className="btn btn-link" style={styles.link} to="/">
        Return to Dashboard
      </Link>
    </DownloadDocuments.Main>
  );
}
