import React from 'react';
import { FormSection as ReduxFormSection } from 'redux-form';

const SectionContainer = ({ name, title, className, children }) => {
  const FormSection = !!name ? ReduxFormSection : 'fieldset';
  return (
    <FormSection
      id={name}
      name={name}
      component="fieldset"
      className={className}
      style={{ borderTop: '3px solid black' }}
    >
      {title && <h2>{title}</h2>}
      {children}
    </FormSection>
  );
};

export default SectionContainer;
