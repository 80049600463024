import { connect } from 'react-redux';

import { selectors } from '../../../redux';

import GetBinderBySubmission from './GetBinderBySubmission';

import { getBinderBySubmission } from '../../actions';
import { actions as policies } from '../../../policies';
import { actions as endorsements } from '../../../endorsements';

const mapStateToProps = (state, props) => {
  const submission_id = props.submission_id;
  return state => {
    const binder = selectors.binderBySubmission(state, submission_id);
    const policy = selectors.policyBySubmission(state, submission_id);
    const endorsements = selectors.endorsementsBySubmission(
      state,
      submission_id
    );
    return {
      binder,
      policy,
      endorsements,
    };
  };
};

const mapDispatchToProps = (dispatch, props) => dispatch => ({
  getBinder: () => dispatch(getBinderBySubmission(props.submission_id)),
  getPolicy: () => dispatch(policies.getBySubmission(props.submission_id)),
  getEndorsements: () =>
    dispatch(endorsements.getEndorsementsBySubmission(props.submission_id)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GetBinderBySubmission);
