export const GET_RATING_BY_ID_REQUEST = 'GET_RATING_BY_ID_REQUEST';
export const GET_RATING_BY_ID_SUCCESS = 'GET_RATING_BY_ID_SUCCESS';
export const GET_RATING_BY_ID_FAILURE = 'GET_RATING_BY_ID_FAILURE';
export const GET_RATING_BY_BINDER_REQUEST = 'GET_RATING_BY_BINDER_REQUEST';
export const GET_RATING_BY_BINDER_SUCCESS = 'GET_RATING_BY_BINDER_SUCCESS';
export const GET_RATING_BY_BINDER_FAILURE = 'GET_RATING_BY_BINDER_FAILURE';
export const GET_RATING_BY_POLICY_REQUEST = 'GET_RATING_BY_POLICY_REQUEST';
export const GET_RATING_BY_POLICY_SUCCESS = 'GET_RATING_BY_POLICY_SUCCESS';
export const GET_RATING_BY_POLICY_FAILURE = 'GET_RATING_BY_POLICY_FAILURE';
export const UPDATE_RISK_ANALYSIS_REQUEST = 'UPDATE_RISK_ANALYSIS_REQUEST';
export const UPDATE_RISK_ANALYSIS_SUCCESS = 'UPDATE_RISK_ANALYSIS_SUCCESS';
export const UPDATE_RISK_ANALYSIS_FAILURE = 'UPDATE_RISK_ANALYSIS_FAILURE';
export const UPDATE_LIMITS_RETENTIONS_REQUEST =
  'UPDATE_LIMITS_RETENTIONS_REQUEST';
export const UPDATE_LIMITS_RETENTIONS_SUCCESS =
  'UPDATE_LIMITS_RETENTIONS_SUCCESS';
export const UPDATE_LIMITS_RETENTIONS_FAILURE =
  'UPDATE_LIMITS_RETENTIONS_FAILURE';
export const GET_PREMIUM_REQUEST = 'GET_PREMIUM_REQUEST';
export const GET_PREMIUM_SUCCESS = 'GET_PREMIUM_SUCCESS';
export const GET_PREMIUM_FAILURE = 'GET_PREMIUM_FAILURE';
export const UPDATE_PREMIUM_ADJUSTMENTS_REQUEST =
  'UPDATE_PREMIUM_ADJUSTMENTS_REQUEST';
export const UPDATE_PREMIUM_ADJUSTMENTS_SUCCESS =
  'UPDATE_PREMIUM_ADJUSTMENTS_SUCCESS';
export const UPDATE_PREMIUM_ADJUSTMENTS_FAILURE =
  'UPDATE_PREMIUM_ADJUSTMENTS_FAILURE';
export const GET_RATINGS_BY_QUOTE_SUCCESS = 'GET_RATINGS_BY_QUOTE_SUCCESS';
export const CREATE_RATING_COPY_REQUEST = 'CREATE_RATING_COPY_REQUEST';
export const CREATE_RATING_COPY_SUCCESS = 'CREATE_RATING_COPY_SUCCESS';
export const CREATE_RATING_FROM_QUOTE_REQUEST =
  'CREATE_RATING_FROM_QUOTE_REQUEST';
export const CREATE_RATING_FROM_QUOTE_SUCCESS =
  'CREATE_RATING_FROM_QUOTE_SUCCESS';
