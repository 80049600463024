import { connect } from 'react-redux';

import { confirmClearance as confirmSubmissionClearance } from '../../../actions';

import ClearanceConfirmationPrompt from './ClearanceConfirmationPrompt';

const mapDispatchToProps = (dispatch, props) => {
  const { id: submission_id } = props.submission;
  return dispatch => ({
    confirmSubmissionClearance: () =>
      dispatch(confirmSubmissionClearance(submission_id)),
  });
};

export default connect(null, mapDispatchToProps)(ClearanceConfirmationPrompt);
