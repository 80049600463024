import React from 'react';
import ButtonToolbar from 'react-bootstrap/ButtonToolbar';
import ButtonGroup from 'react-bootstrap/ButtonGroup';

import { StickySidebar } from '../../../layouts';
import { PersistErrors } from '../../../common';
import { SubmissionSummaryCard } from '../../../submissions';
import RatingWorksheetProgressBox from '../RatingWorksheetProgressBox';
import SubmitButton from './SubmitButton';

import { Form } from '../../../common/components/FormBuilderV2';
import { validator } from '../../../validation';
import uiSchema from './ui-schema.json';
import { mapPropsToFormValues } from './mapPropsToFormValues';

const styles = {
  main_container: {
    backgroundColor: '#fff',
    padding: '25px 40px',
  },
  sidebar_container: {
    maxWidth: '240px',
    margin: '25px auto auto',
  },
  sidebar_summary: {
    backgroundColor: '#ffffff',
    borderColor: '#979797',
  },
};

const FORM_NAME = 'rating_worksheet';

const SubmitButtons = ({
  style,
  rating,
  updateRiskAnalysis,
  getPremium,
  validationSchema,
  navigate,
}) => {
  const sharedProps = {
    rating: rating,
    formName: 'rating_worksheet',
    validator,
    uiSchema,
    validationSchema,
    context: {
      today: new Date().toISOString(),
    },
  };
  return (
    <ButtonToolbar style={style}>
      <ButtonGroup>
        <SubmitButton
          {...sharedProps}
          onSubmit={values => {
            return updateRiskAnalysis(values).then(() => {
              navigate(`/ratings/${rating.id}/pricing-worksheet`);
            });
          }}
        >
          Choose Limits
        </SubmitButton>
        <SubmitButton
          {...sharedProps}
          style={{ marginLeft: '10px' }}
          onSubmit={values => {
            return getPremium(values).then(() => {
              navigate(`/ratings/${rating.id}`);
            });
          }}
        >
          View Price
        </SubmitButton>
      </ButtonGroup>
    </ButtonToolbar>
  );
};

export default function RatingWorksheet({
  submission,
  rating,
  productConfig,
  navigate,
  updateRiskAnalysis,
  getPremium,
}) {
  return (
    <PersistErrors
      formName={FORM_NAME}
      persistErrors={rating.updated_at !== rating.created_at}
    >
      <div>
        <StickySidebar
          renderMain={() => {
            return (
              <section style={styles.main_container}>
                <Form
                  name={FORM_NAME}
                  uiSchema={uiSchema}
                  validationSchema={productConfig.risk_analysis_schema}
                  context={{
                    today: new Date().toISOString(),
                  }}
                  initialValues={mapPropsToFormValues({ rating })}
                />
                <SubmitButton
                  style={{ marginTop: '25px' }}
                  rating={rating}
                  updateRiskAnalysis={updateRiskAnalysis}
                  formName={FORM_NAME}
                  validator={validator}
                  uiSchema={uiSchema}
                  validationSchema={productConfig.risk_analysis_schema}
                  context={{
                    today: new Date().toISOString(),
                  }}
                  onSubmit={values => {
                    return updateRiskAnalysis(values).then(() => {
                      navigate(`/ratings/${rating.id}/pricing-worksheet`);
                    });
                  }}
                >
                  Choose Limits
                </SubmitButton>
              </section>
            );
          }}
          renderAside={() => {
            return (
              <section style={styles.sidebar_container}>
                <SubmitButtons
                  style={{ margin: 'auto auto 20px' }}
                  rating={rating}
                  updateRiskAnalysis={updateRiskAnalysis}
                  validationSchema={productConfig.risk_analysis_schema}
                  getPremium={getPremium}
                  navigate={navigate}
                />
                <RatingWorksheetProgressBox
                  formName={FORM_NAME}
                  validator={validator}
                  uiSchema={uiSchema}
                  context={{
                    today: new Date().toISOString(),
                  }}
                  sections={uiSchema.children.map(field => ({
                    id: field.id,
                    title: field.title,
                  }))}
                  style={{
                    marginBottom: '40px',
                    ...styles.sidebar_summary,
                  }}
                />
                <SubmissionSummaryCard
                  flex
                  submission={submission}
                  style={styles.sidebar_summary}
                />
              </section>
            );
          }}
        />
      </div>
    </PersistErrors>
  );
}
