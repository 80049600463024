import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import { SummaryItem } from '../../../common';
import { getQuoteExpirationDate } from './utils';

const styles = {
  section_header: {
    fontSize: '1.25rem',
  },
  list: {
    fontSize: '0.875rem',
    lineHeight: '1.5',
    listStyle: 'none',
    paddingLeft: '0',
  },
};

export default function QuoteOverviewSection({
  style,
  quote,
  ratings,
  config,
  ...props
}) {
  return (
    <section style={style} {...props}>
      <h3 style={styles.section_header}>Overview</h3>
      <Row>
        <Col md={4}>
          <SummaryItem
            label="Quote Expiration Date"
            value={getQuoteExpirationDate(ratings)}
          />
        </Col>
        <Col md={4}>
          <SummaryItem
            label="Carrier"
            value={`${config.carrier.name} (${
              config.is_surplus_lines ? 'Non-Admitted' : 'Admitted'
            })`}
          />
        </Col>
      </Row>
    </section>
  );
}
