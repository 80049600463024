import React from 'react';
import { Field as ReduxFormField } from 'redux-form';
import numeral from 'numeral';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import template from 'lodash/template';

import WithForm from './WithForm';

const required = value => {
  const truthy = !!value;
  const is_number = typeof value === 'number';
  const empty_object = isEmpty(value);
  const present = (truthy && !empty_object) || is_number;
  return !present ? 'Required.' : null;
};

const CreateBaseValidators = (ValidatorMap, validations = [], field, props) => {
  return validations.map(validation => {
    const validate = get(ValidatorMap, validation.validator)(validation, field);
    const validator = (value, ...args) => {
      const valid = validate(value, ...args);
      if (!valid) {
        // Per https://redux-form.com/7.3.0/docs/api/field.md/,
        // the validate function is called with signature:
        // (value, allValues, props, name)
        // If we're assigning the first parameter to value,
        // args is (allValues, props, name) and args[0] is allValues
        return template(validation.message)({
          value,
          values: args[0],
          field,
          props,
        });
      }
      return null;
    };
    return validator;
  });
};

const CreateValidators = (ValidatorMap, errors = [], field, ...args) => {
  const validators = [];
  if (field.required) {
    validators.push(required);
  }
  return validators.concat(
    CreateBaseValidators(ValidatorMap, errors, field, ...args)
  );
};

const CreateWarnings = CreateBaseValidators;

const NormalizerMap = {
  currency: (value = '', previous = '') => {
    if (value === '' || value === '-') return value;
    const as_float = parseFloat(value, 10);
    if (isNaN(as_float)) return previous ? previous : '';
    return numeral(value).format('0,0');
  },
};

class ControlContainer extends React.Component {
  state = {
    validate: this.createValidate(),
    warn: this.createWarn(),
  };

  createValidate() {
    const { validators, errors, field, formProps } = this.props;
    return CreateValidators(validators, errors, field, formProps);
  }

  createWarn() {
    const { validators, warnings, field, formProps } = this.props;
    return CreateWarnings(validators, warnings, field, formProps);
  }

  render() {
    const {
      name,
      component: Component,
      normalizer,
      required,
      otherProps,
    } = this.props;
    return (
      <ReduxFormField
        name={name}
        component={Component}
        validate={this.state.validate}
        required={required}
        warn={this.state.warn}
        normalize={normalizer}
        props={otherProps}
      />
    );
  }
}

const CreateControlContainer = Component => ({
  errors = [],
  warnings = [],
  format,
  name,
  ...field
}) => {
  const { required, ...otherProps } = field;
  const normalizer = NormalizerMap[format] || (value => value);
  return (
    <WithForm
      render={({ validators, props }) => {
        return (
          <ControlContainer
            name={name}
            component={Component}
            validators={validators}
            required={required}
            normalizer={normalizer}
            errors={errors}
            warnings={warnings}
            field={field}
            formProps={props}
            otherProps={otherProps}
          />
        );
      }}
    />
  );
};

export default CreateControlContainer;
