const sameStatus = (initial, current) => initial.status === current.status;
const openStatus = ({ status }) => status === 'OPEN';
const sameDeclinedStatus = (initial, current) => {
  const sameReasons = (initialReasons = [], reasons = []) =>
    initialReasons.length === reasons.length &&
    reasons.every(reason => initialReasons.includes(reason));
  const unchangedComments = initial.comments === current.comments;
  const unchangedReasons = sameReasons(initial.reasons, current.reasons);
  return unchangedComments && unchangedReasons ? true : false;
};

export const declinedStatus = ({ status }) => status === 'DECLINED';

export const disableButton = (initialValues = {}, formValues = {}) => {
  const unchangedOpenStatus =
    sameStatus(initialValues, formValues) && openStatus(formValues);

  const unchangedDeclinedStatus =
    sameStatus(initialValues, formValues) &&
    declinedStatus(formValues) &&
    sameDeclinedStatus(initialValues, formValues);

  const declineStatusWithoutReasons =
    declinedStatus(formValues) &&
    (!formValues.reasons || !formValues.reasons.length);

  return (
    declineStatusWithoutReasons ||
    unchangedDeclinedStatus ||
    unchangedOpenStatus
  );
};
