import { connect } from 'react-redux';
import { withRouter } from '../../../../common/components/WithRouter';
import { path } from 'path';

import { actions as endorsements } from '../../../';

import MidTermEndorsementsPreview from './MidTermEndorsementsPreview';

const mapDispatchToProps = (dispatch, props) => {
  const { option_id, option_version, policy_id } = props.match.params;
  const { match } = props;
  return dispatch => ({
    addToPolicy: () =>
      dispatch(
        endorsements.addToPolicy({ option_id, option_version, policy_id })
      ).then(response => {
        const { endorsement } = response;
        props.navigate(
          path.join(match.pathname, `../../../../${endorsement.id}/confirmation`)
        );
        return endorsement;
      }),
  });
};

export default withRouter(
  connect(
    null,
    mapDispatchToProps
  )(MidTermEndorsementsPreview)
);
