import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import { reduxForm } from 'redux-form';
import qs from 'querystring';

import { FormStateSaver } from '../../../common';

import { Column, FormSectionDivider } from './common';

import Renewal from './Renewal';
import NewBusiness from './NewBusiness';
import SubmissionTypeToggle from './SubmissionTypeToggle';

const styles = {
  container: { 
    paddingBottom: '50px',
    marginTop: '25px' 
  },
  subheader: { fontSize: '0.875rem', fontWeight: '500' },
  form_section_container: {
    borderTop: '1px solid grey',
    padding: '8px 0',
    margin: '1',
  },
};

function CreateSubmission(props) {
  const queryString = props.location.search;
  // Drop the leading '?' that react-router returns in the query string
  const queryValues = qs.parse(queryString.slice(1));
  const { renewed_from_policy_id } = queryValues;
  return (
    <Container fluid="md" style={styles.container}>
      <Row>
        <Column>
          <h1>Create and Clear Submission</h1>
          <p style={styles.subheader}>
            Enter the Submitting Broker and the Insured details to clear a
            Submission
          </p>
          <FormStateSaver
            style={styles.form_section_container}
            form_name={props.form}
            restore={props.initialize}
            unsubmitted={!props.submitting && !props.submitSucceeded}
          />
          <FormSectionDivider name="trans_type">
            <h2 style={{ fontSize: '1.25rem', marginTop: '0' }}>Account</h2>
            <SubmissionTypeToggle
              initiallyNewBusiness={!renewed_from_policy_id}
              render={({ type }) => {
                return type === 'renewal' ? (
                  <Renewal
                    navigate={props.navigate}
                    initialValues={{ renewed_from_policy_id }}
                  />
                ) : (
                  <NewBusiness navigate={props.navigate} />
                );
              }}
            />
          </FormSectionDivider>
        </Column>
      </Row>
    </Container>
  );
}

export default reduxForm({
  form: 'create_submission',
})(CreateSubmission);
