import React from 'react';
import get from 'lodash/get';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Button from 'react-bootstrap/Button';
import { FormSection, Field } from 'redux-form';

import { AddressAutocomplete, InputControl } from '../../../common';

import validation from './validation';
import states from './states';

const styles = {
  container: { paddingBottom: '50px' },
  form_container: { marginTop: '25px' },
  form_section_container: {
    borderTop: '1px solid grey',
    padding: '8px 0',
    margin: '0',
  },
  form_section_header: { fontSize: '1.25rem', marginTop: '0' },
  form_section_container_nested: { paddingLeft: '0' },
  error: { marginTop: '15px' },
  error_text: { display: 'block' },
};

export const FormSectionDivider = ({ name, children, ...props }) => {
  return (
    <FormSection name={name}>
      <Row {...props} style={styles.form_section_container}>
        {children}
      </Row>
    </FormSection>
  );
};

export const Column = ({ children, ...props }) => (
  <Col md={{span: 6, offset: 1}} {...props}>
    {children}
  </Col>
);

export const NestedColumn = ({ children, ...props }) => (
  <Col md={8} style={styles.form_section_container_nested}>
    {children}
  </Col>
);

const Select = ({ options, ...props }) => {
  return (
    <select {...props}>
      {options.map(option => (
        <option value={option.value} key={option.value}>
          {option.value} - {option.label}
        </option>
      ))}
    </select>
  );
};

export const FormContainer = ({ handleSubmit, children }) => (
  <form style={styles.form_container} onSubmit={handleSubmit} noValidate>
    {children}
  </form>
);

export const Broker = () => {
  return (
    <FormSectionDivider name="broker">
      <NestedColumn>
        <h2 style={styles.form_section_header}>Broker Details</h2>
        <Field
          name="brokerage"
          type="text"
          component={InputControl}
          label="Submitting Brokerage"
          validate={validation.broker.brokerage}
          required
        />
        <Field
          name="name"
          type="text"
          component={InputControl}
          label="Broker Name"
          validate={validation.broker.name}
          required
        />
        <Field
          name="email"
          type="email"
          component={InputControl}
          label="Broker E-mail"
          validate={validation.broker.email}
          required
        />
      </NestedColumn>
    </FormSectionDivider>
  );
};

export class Insured extends React.Component {
  state = {};

  // NOTE: this whole hack is for when user changes city value that was returned
  // by the autocomplete and we need to bring his attention to zipCode field
  // so that he doesn't forget to update it as well.
  handleCityChange = (event, onChange) => {
    const { cityZip } = this.state;
    const { value } = event.target;
    const zipCodeClassList = get(this.zipCodeInput, 'parentNode.classList');

    if (zipCodeClassList && cityZip) {
      zipCodeClassList.toggle('has-error', value !== cityZip.city);
    }

    this.pendingCity = value;
    onChange(value);
  };

  handleZipCodeChange = (event, onChange) => {
    const { cityZip } = this.state;
    const { value } = event.target;
    const zipCodeClassList = get(this.zipCodeInput, 'parentNode.classList');

    if (zipCodeClassList && cityZip) {
      zipCodeClassList.remove('has-error');
    }

    this.setState({ cityZip: { city: this.pendingCity, zip: value } });
    onChange(value);
  };

  renderAutocompleteField = ({ input }) => {
    const handleSelect = value => {
      input.onChange(value);
      this.setState({ cityZip: { city: value.city, zip: value.zip } });
    };

    return <AddressAutocomplete onSelect={handleSelect} />;
  };

  render() {
    return (
      <FormSectionDivider name="named_insured">
        <NestedColumn>
          <h2 style={styles.form_section_header}>Insured Details</h2>
          <Field
            name="name"
            component={InputControl}
            label="Named Insured"
            validate={validation.named_insured.name}
            required
          />
          <Field name="address" component={this.renderAutocompleteField} />
          <FormSection name="address">
            <Field
              name="street_line_1"
              component={InputControl}
              label="Street Address"
              validate={validation.named_insured.address.street_line_1}
              required
            />
            <Field
              name="street_line_2"
              component={InputControl}
              label="Line 2"
              placeholder="Optional"
            />
            <Field
              name="city"
              component={InputControl}
              props={{
                onValueChange: (event, onChange) =>
                  this.handleCityChange(event, onChange),
              }}
              label="City"
              validate={validation.named_insured.address.city}
              required
            />
            <Row>
              <Col md={6}>
                <Field
                  name="state"
                  as={Select}
                  options={states.map(state => ({
                    value: state.abbreviation,
                    label: state.name,
                  }))}
                  component={InputControl}
                  label="State"
                  validate={validation.named_insured.address.state}
                  required
                />
              </Col>
              <Col md={6}>
                <Field
                  name="zip"
                  component={InputControl}
                  label="Zip"
                  validate={validation.named_insured.address.zip}
                  props={{
                    onValueChange: (event, onChange) =>
                      this.handleZipCodeChange(event, onChange),
                  }}
                  required
                  ref={input => (this.zipCodeInput = input)}
                />
              </Col>
            </Row>
          </FormSection>
        </NestedColumn>
      </FormSectionDivider>
    );
  }
}

export const SubmitButton = ({ submitting, error }) => {
  return (
    <NestedColumn>
      <ButtonGroup className="mt-2">
        <Button type="submit" variant="primary" disabled={submitting}>
          {submitting ? 'Submitting...' : 'Create Submission'}
        </Button>
      </ButtonGroup>
      {error &&
        !submitting && (
          <p className="text-danger" style={styles.error}>
            <span style={styles.error_text}>
              Sorry, there was an error reaching Clearance.
            </span>
            <span style={styles.error_text}>Please submit again now.</span>
          </p>
        )}
    </NestedColumn>
  );
};
