import { GET_CONFIGURATION_SUCCESS } from './constants';

const initial_state = {
  configuration: null,
};

export default function common(state = initial_state, action) {
  switch (action.type) {
    case GET_CONFIGURATION_SUCCESS:
      const config = action.payload.configuration;
      return {
        ...state,
        configuration: {
          subjectivities: config.subjectivities,
          endorsements: config.endorsements.map(({ id, version }) => ({
            id,
            version,
          })),
        },
      };
    default:
      return state;
  }
}
