import React from 'react';
import Alert from 'react-bootstrap/Alert';

import { ZScoreBase } from '../../../common';

const styles = {
  icon: {
    marginRight: '0.25em',
  },
  text: {
    color: 'black',
    fontSize: '0.875rem',
  },
};

const ZScoreAlertBasic = ({ style, rating }) => {
  return (
    <ZScoreBase
      rating={rating}
      valid={({ z_score }) => {
        return (
          <Alert style={style} variant="success">
            <strong>
            <i className="bi bi-check-circle" style={{marginRight: '5px'}}/>Z-Score passed:
            </strong>{' '}
            <span style={styles.text}>{z_score}</span>
          </Alert>
        );
      }}
      indeterminate={() => {
        return (
          <Alert style={style} variant="warning">
            <strong>Note:</strong>{' '}
            <span style={styles.text}>
              A Z-Score could not be calculated with the financials provided
            </span>
          </Alert>
        );
      }}
      invalid={({ z_score }) => {
        return (
          <Alert style={styles.alert} variant="danger">
            <div>
              <strong style={{ fontSize: '0.875rem' }}>
              <i className="bi bi-x-circle-fill" style={{marginRight: '5px'}}/> Z-Score Requires Review:
              </strong>{' '}
              <span style={styles.text}>{z_score}</span>
            </div>
            <p style={{ ...styles.text, marginTop: '5px' }}>
              This company does not the meet the financial requirements to bind
              a policy without approval.
            </p>
            <p style={styles.text}>
              You won’t be prevented from quoting,{' '}
              <strong>but must get approval</strong> according to your agreement
              with Argo Pro.
            </p>
          </Alert>
        );
      }}
    />
  );
};

export { ZScoreAlertBasic as Basic };
