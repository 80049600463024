import get from 'lodash/get';
import set from 'lodash/set';

function mapFieldToValidations(
  field,
  validator_selector,
  validator_map,
  prefix
) {
  let validators = [];
  const field_name = prefix ? `${prefix}.${field.name}` : field.name;
  const field_validators = field[validator_selector];
  if (field_validators && !field.control) {
    validators.push({
      key: field_name,
      validations: field_validators.map(error => ({
        validator: validator_map[error.validator](error, field),
        message: error.message,
      })),
    });
  }
  if (field.fields) {
    validators = field.fields.reduce((validators, field) => {
      return validators.concat(
        mapFieldToValidations(
          field,
          validator_selector,
          validator_map,
          field_name
        )
      );
    }, validators);
  }
  return validators;
}

export default function mapSchemaToValidators(
  schema,
  validator_selector,
  validator_map
) {
  const validation_fields = schema.fields.reduce((validation_fields, field) => {
    return validation_fields.concat(
      mapFieldToValidations(field, validator_selector, validator_map)
    );
  }, []);
  return (values, props) => {
    return validation_fields.reduce((errors, field) => {
      const error = field.validations.reduce((error, validation) => {
        if (error) return error;
        const value = get(values, field.key);
        const valid = validation.validator(value, values, props);
        return valid ? null : validation.message;
      }, null);
      if (error) set(errors, field.key, error);
      return errors;
    }, {});
  };
}
