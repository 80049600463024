import React from 'react';
import { Flag } from '../../../common/components/Flag';
import { StickySidebar } from '../../../layouts';

import { SubmissionSummaryCard } from '../../../submissions';

import CopyRatingButton from '../CopyRatingButton';
import * as RatingWorksheet from '../RatingWorksheet';
import * as PricingWorksheet from '../PricingWorksheet';

import PremiumSummary from './PremiumSummary';
import RatingWorksheetSummary from './RatingWorksheetSummary';
import PricingWorksheetSummary from './PricingWorksheetSummary';
import LimitsSummary from './LimitsSummary';
import LockedRatingAlert from './LockedRatingAlert';
import NoPremiumAlert from './NoPremiumAlert';
import InvalidRatingAlert from './InvalidRatingAlert';

import * as permissions from '../../permissions';

const styles = {
  container: {
    backgroundColor: '#fff',
    padding: '25px 40px',
  },
  header: {
    marginTop: '20px',
  },
  title: {
    float: 'left',
    marginTop: 0,
  },
  copy_button: {
    float: 'right',
    marginTop: '0',
  },
  summary_section: {
    borderTop: '1px solid #979797',
    marginTop: '35px',
  },
  sidebar: {
    margin: '25px auto auto',
    maxWidth: '240px',
  },
  sidebar_summary: {
    marginBottom: '25px',
    backgroundColor: '#ffffff',
    borderColor: '#979797',
  },
};

function Alerts({ rating, quote, productConfig }) {
  const canUpdateRating = permissions.canUpdateRating({ rating });
  if (!canUpdateRating) {
    return <LockedRatingAlert style={{ marginBottom: '30px' }} />;
  }
  const isRatingValid =
    RatingWorksheet.isValid(productConfig, { rating }) &&
    PricingWorksheet.isValid(productConfig, { rating, quote });
  if (!isRatingValid) {
    return <InvalidRatingAlert />;
  }
  if (!rating.premium) {
    return <NoPremiumAlert rating={rating} />;
  }
  return null;
}

export default function RatingSummary({
  rating,
  submission,
  quote,
  productConfig,
}) {
  const can_update_rating = permissions.canUpdateRating({ rating });
  return (
    <StickySidebar
      renderMain={() => {
        return (
          <div style={styles.container}>
            <Alerts
              rating={rating}
              quote={quote}
              productConfig={productConfig}
            />
            <div style={styles.header} className="clearfix">
              <h1 style={styles.title}>{rating.display_name}</h1>
              <Flag
                name="rating.preview.copy"
                render={() => {
                  return (
                    <CopyRatingButton
                      style={styles.copy_button}
                      rating={rating}
                    >
                      Duplicate Price Option
                    </CopyRatingButton>
                  );
                }}
              />
            </div>
            <PremiumSummary
              style={styles.summary_section}
              rating={rating}
              submission={submission}
            />
            <PricingWorksheetSummary
              style={styles.summary_section}
              rating={rating}
              quote={quote}
              productConfig={productConfig}
              can_update_rating={can_update_rating}
            />
            <RatingWorksheetSummary
              style={styles.summary_section}
              rating={rating}
              productConfig={productConfig}
              can_update_rating={can_update_rating}
            />
          </div>
        );
      }}
      renderAside={() => {
        return (
          <div style={styles.sidebar}>
            <LimitsSummary rating={rating} style={styles.sidebar_summary} />
            <SubmissionSummaryCard
              style={styles.sidebar_summary}
              flex
              submission={submission}
            />
          </div>
        );
      }}
    />
  );
}
