import React from 'react';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';

import { ZScoreBase } from '../../../../common';

const styles = {
  alert: {
    fontSize: '0.8125rem',
  },
  header: {
    marginTop: '0',
  },
  text: {
    color: 'black',
  },
  button: {
    marginTop: '10px',
  },
};

export default function BinderZScoreAlert({ style, binder, hideModal }) {
  return (
    <div style={{ textAlign: 'left' }}>
      <ZScoreBase
        rating={binder.rating}
        valid={({ z_score }) => {
          return (
            <Alert style={styles.alert} variant="success">
              <h3 style={styles.header}>
                <i className="bi bi-check-circle" style={{marginRight: '5px'}}/> Z-Score passed:{' '}
                <span style={styles.text}>{z_score}</span>
              </h3>
              <Button style={styles.button} variant="link" onClick={hideModal}>
                Return to Binder
              </Button>
            </Alert>
          );
        }}
        indeterminate={() => {
          return (
            <Alert style={styles.alert} variant="warning">
              <h5 style={styles.header}>Z-Score unknown</h5>
              <p style={styles.text}>
                If possible, please complete the above financials to calculate a
                Z-Score
              </p>
              <Button style={styles.button} variant="link" onClick={hideModal}>
                Return to Binder
              </Button>
            </Alert>
          );
        }}
        invalid={({ z_score }) => {
          return (
            <Alert style={styles.alert} variant="danger">
              <h3 style={styles.header}>
                <span style={{ fontSize: '0.9em' }}>
                  <i className="bi bi-x-circle-fill" style={{marginRight: '5px'}}/> Z-Score Requires Review:{' '}
                  <span style={styles.text}>{z_score}</span>
                </span>
              </h3>
              <p style={styles.text}>
                This company does not the meet the financial requirements to
                bind a policy without approval.
              </p>
              <p style={styles.text}>
                You may continue, <strong>but must get approval</strong>{' '}
                according to your agreement with Argo Pro.
              </p>
              <Button style={styles.button} variant="link" onClick={hideModal}>
                Return to Binder
              </Button>
            </Alert>
          );
        }}
      />
    </div>
  );
}
