import React from 'react';
import FormGroup from 'react-bootstrap/FormGroup';
import Form from 'react-bootstrap/Form';

const styles = {
  label: {
    display: 'inline-block',
    fontSize: '0.8125rem',
    marginTop: '1rem'
  },
  error_text: { fontSize: '0.75rem', lineHeight: '1.2em' },
  asterisk: { color: 'red', marginLeft: '0.125rem' },
};

const getValidationClassName = props => {
  if (props.meta.touched) {
    if (props.meta.error) {
      return 'has-error';
    }
    if (props.meta.warning) {
      return 'has-warning';
    }
    if (props.input.value) {
      return 'has-success';
    }
  }
  return '';
};

const LabeledControl = props => {
  const {
    input,
    meta,
    required,
    text,
    ...otherProps
  } = props;

  const validationClassName = getValidationClassName(props);

  return (
    <FormGroup>
      <div className={validationClassName}>
        <div style={styles.label}>
          <Form.Label htmlFor={otherProps.id || input.name}>
            <span>
              {otherProps.label}
            </span>
            {required && <span style={styles.asterisk}>*</span>}
          </Form.Label>
        </div>
        {props.children}
        {(meta.touched &&
          meta.error && (
            <div style={styles.error_text}>
              {meta.error}
            </div>
          )) ||
          (meta.touched &&
            meta.warning && (
              <div style={styles.error_text} muted>
                {meta.warning}
              </div>
            ))}
        {text && <div style={styles.label}>{text}</div>}
      </div>
    </FormGroup>
  );
};

export default LabeledControl;
