import React from 'react';
import numeral from 'numeral';
import isEmpty from 'lodash/isEmpty';
import Button from 'react-bootstrap/Button';

import { SummaryItem } from '../../../common';

const percentFormat = '0.00%';

const styles = {
  editButton: {
    paddingLeft: 0,
    paddingRight: 0,
    marginTop: '1.25rem',
  },
};

// placeholder is an 'em-dash':
// http://www.chicagomanualofstyle.org/qanda/data/faq/topics/HyphensEnDashesEmDashes/faq0002.html
const placeholder = '\u2014';

const formatAddress = address => {
  if (!address || isEmpty(address)) return null;
  return (
    <address>
      {address.street_line_1 || placeholder}
      {!address.street_line_2 ? null : (
        <React.Fragment>
          <br />
          {address.street_line_2}
        </React.Fragment>
      )}
      <br />
      {address.city || placeholder}, {address.state || placeholder}{' '}
      {address.zip || placeholder}
    </address>
  );
};

const formatCommission = commission => {
  return commission ? numeral(commission / 100).format(percentFormat) : null;
};

const formatPhone = phone => {
  return phone || null;
};

function BrokerInactive({ broker, is_locked, ...props }) {
  return (
    <div>
      <SummaryItem
        label="Broker Commission"
        value={formatCommission(broker.commission)}
      />
      <SummaryItem label="Address" value={formatAddress(broker.address)} />
      <SummaryItem label="Phone Number" value={formatPhone(broker.phone)} />
      {!is_locked && (
        <Button
          onClick={props.makeActive}
          style={{ ...styles.editButton }}
          variant="link"
        >
          Edit
        </Button>
      )}
    </div>
  );
}

export default BrokerInactive;
