import React from 'react';

import table_config from './table-config';

function EndorsementRow({ endorsement, getEndorsementDocuments }) {
  return table_config.columns.map(column => {
    return (
      <td key={column.key} style={column.style}>
        {column.content({ endorsement, getEndorsementDocuments })}
      </td>
    );
  });
}

export default EndorsementRow;
