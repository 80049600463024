import React from 'react';
import Button from 'react-bootstrap/Button';
import ConfirmCancel from '../ConfirmCancel';

const styles = {
  cancelButton: {
    marginBottom: '1.25rem',
  },
};

class CancelButton extends React.Component {
  state = {
    show: false,
  };

  showModal = () => {
    this.setState({ show: true });
  };

  hideModal = () => {
    this.setState({ show: false });
  };

  handleClick = () => {
    this.showModal();
  };

  render() {
    return (
      <div style={this.props.style}>
        <Button
          style={styles.cancelButton}
          variant="danger"
          onClick={this.handleClick}
        >
          Cancel Binder Request
        </Button>
        <ConfirmCancel
          show={this.state.show}
          hideModal={this.hideModal}
          deleteBinder={this.props.deleteBinder}
          disableAutoSave={this.props.disableAutoSave}
        />
      </div>
    );
  }
}

CancelButton.defaultProps = {
  disableAutoSave: () => null,
};

export default CancelButton;
