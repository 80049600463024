import { connect } from 'react-redux';
import { withRouter } from '../../../common/components/WithRouter';

import * as binders from '../../actions';

import BinderWorksheet from './BinderWorksheet';

const mapStateToProps = (state, props) => ({
  initialValues: {
    ...props.binder.terms_conditions,
  },
});

const mapDispatchToProps = (dispatch, props) => {
  const binder = props.binder;
  const binder_id = binder.id;
  return dispatch => ({
    updateTermsConditions: async (
      updatedTermsConditions,
      oldTermsConditions
    ) => {
      return dispatch(
        binders.updateTermsConditions(
          binder_id,
          updatedTermsConditions,
          oldTermsConditions
        )
      );
    },
    deleteBinder: () => {
      return dispatch(binders.deleteBinder(binder, props.navigate));
    },
  });
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(BinderWorksheet)
);
