const ManuallyClearSubmission = (fetch, auth) => ({
  submission_id,
  reason,
}) => {
  return auth
    .getAccessToken()
    .then(token => {
      if (!token) {
        auth.signOut();
        return Promise.reject(new Error('User is not authenticated.'));
      }
      return token;
    })
    .then(token => {
      return fetch(`/submissions/${submission_id}/clearance-override`, {
        method: 'POST',
        headers: {
          Authorization: token,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          reason,
        }),
      });
    })
    .then(response => response.json());
};

export default ManuallyClearSubmission;
