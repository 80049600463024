import { connect } from 'react-redux';
import { updateDetails } from '../../../actions';
import SLBrokerSection from './SLBrokerSection';

const mapDispatchToProps = (dispatch, props) => {
  const { binder_id } = props;
  return dispatch => ({
    updateSLBroker: (values, initialValues) => {
      return dispatch(
        updateDetails(
          binder_id,
          { sl_broker: values },
          { sl_broker: initialValues }
        )
      );
    },
  });
};

export default connect(
  null,
  mapDispatchToProps
)(SLBrokerSection);
