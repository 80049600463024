import React from 'react';
import InputGroup from 'react-bootstrap/InputGroup';

import DebouncedInput from './DebouncedInput';

export default function SearchInput({ style, ...props }) {
  return (
    <InputGroup style={{ ...style }}>
        <InputGroup.Text>
          <i className="bi bi-search"/>
        </InputGroup.Text>
      <DebouncedInput type="text" wait={250} {...props} />
    </InputGroup>
  );
}
