import * as RatingWorksheet from './components/RatingWorksheet';
import * as PricingWorksheet from './components/PricingWorksheet';

export default function isRatingValid(productConfig, { rating, quote }) {
  return (
    RatingWorksheet.isValid(productConfig, { rating }) &&
    PricingWorksheet.isValid(productConfig, { rating, quote }) &&
    rating.premium
  );
}
