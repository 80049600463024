const GetSubmission = (fetch, auth) => submission_id => {
  return auth
    .getAccessToken()
    .then(token => {
      if (!token) {
        auth.signOut();
        return Promise.reject(new Error('User is not authenticated.'));
      }
      return token;
    })
    .then(token =>
      fetch(`/submissions/${submission_id}`, {
        headers: {
          Authorization: token,
          'Content-Type': 'application/json',
        },
      })
    )
    .then(response => response.json())
    .catch(response => {
      if (response.status === 404) return Promise.reject(new Error('NotFound'));
      return Promise.reject(new Error('ServerError'));
    });
};

export default GetSubmission;
