import { connect } from 'react-redux';
import { updateDetails } from '../../../actions';
import BrokerSection from './BrokerSection';

const mapDispatchToProps = (dispatch, props) => {
  const { policy_id } = props;
  return dispatch => ({
    updateBroker: async (values, initialValues) =>
      dispatch(
        updateDetails(policy_id, { broker: values }, { broker: initialValues })
      ),
  });
};

export default connect(
  null,
  mapDispatchToProps
)(BrokerSection);
