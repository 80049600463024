import React from 'react';
import { Flag } from '../../../common/components/Flag';
import get from 'lodash/get';
import set from 'lodash/set';

import { SubmissionSummaryCard } from '../../../submissions';

import { DetailsSummary } from '../../../quote';
import { StickySidebar } from '../../../layouts';
import CancelButton from '../BinderWorksheet/CancelButton';
import Brokers from './Brokers';
import * as validation from './validation';
import { submitPage } from './submit';

import * as permissions from '../../permissions';
import { isSurplusLines } from '../../utils';

const styles = {
  header: {
    marginBottom: '37px',
  },
  sidebar: {
    margin: '25px auto auto',
    maxWidth: '240px',
  },
  submissions_summary: {
    marginTop: '25px',
  },
  cancelButton: { display: 'table', margin: 'auto' },
};

const fields = [
  'sl_broker.license_number',
  'sl_broker.nj_transaction_number',
  'sl_broker.name',
  'sl_broker.address.street_line_1',
  'sl_broker.address.street_line_2',
  'sl_broker.address.city',
  'sl_broker.address.state',
  'sl_broker.address.zip',
  'broker.name',
  'broker.brokerage',
  'broker.email',
  'broker.commission',
  'broker.phone',
  'broker.address.street_line_1',
  'broker.address.street_line_2',
  'broker.address.city',
  'broker.address.state',
  'broker.address.zip',
];

const getInitialValues = binder => {
  return fields.reduce((values, field) => {
    let value = get(binder, field);
    if (value == null) value = '';
    set(values, field, value.toString());
    return values;
  }, {});
};

function BinderDetails({
  binder,
  submission,
  navigate,
  updateDetails,
  deleteBinder,
}) {
  return (
    <StickySidebar
      renderMain={() => {
        return (
          <section>
            <h1 style={styles.header}>Binder Details</h1>
            <DetailsSummary
              detail={binder}
              updateDetails={updateDetails}
              validation={validation.details}
            />
            <Brokers
              isSurplusLines={isSurplusLines(binder)}
              updateDetails={updateDetails}
              navigate={navigate}
              binder_id={binder.id}
              namedInsured={binder.named_insured}
              initialValues={getInitialValues(binder)}
              validation={validation}
              submitButtonLabel="Preview Binder"
              onSubmit={submitPage}
              prevPagePath={`/binders/${binder.id}/terms-conditions`}
            />
          </section>
        );
      }}
      renderAside={() => {
        return (
          <section style={styles.sidebar}>
            {permissions.canCancelBinder({
              binder,
            }) && (
              <CancelButton
                deleteBinder={deleteBinder}
                style={styles.cancelButton}
              />
            )}
            <Flag
              name="binder.details.submission_summary_card"
              render={() => {
                return (
                  <SubmissionSummaryCard
                    style={styles.submissions_summary}
                    flex
                    submission={submission}
                  />
                );
              }}
            />
          </section>
        );
      }}
    />
  );
}

export default BinderDetails;
