import { Component } from 'react';

export default class SearchContainer extends Component {
  state = {
    term: '',
    page: 1,
    limit: 10,
  };

  createProps(property) {
    return {
      value: this.state[property],
      onChange: value => {
        const page = property === 'term' ? 1 : this.state.page;
        const is_event = value && value.stopPropagation && value.preventDefault;
        let new_value = value;
        if (is_event) {
          new_value = value.target.value;
        }
        return this.setState({
          page,
          [property]: new_value,
        });
      },
    };
  }

  render() {
    return this.props.render({
      term: this.createProps('term'),
      page: this.createProps('page'),
      limit: this.createProps('limit'),
    });
  }
}
