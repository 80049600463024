import get from 'lodash/get';
import validator from 'validator';

const required = (value = '') => {
  if (validator.isEmpty(value)) return 'Required';
};

const optional = () => {};

const decimal = (value = '') => {
  if (validator.isEmpty(value)) return 'Required';
  if (!validator.isDecimal(value)) return 'Please enter a valid number';
};

const zipCode = (value = '') => {
  if (validator.isEmpty(value)) return 'Required';
  if (!validator.isPostalCode(value, 'US')) {
    return 'Please enter a valid Zip Code';
  }
};

const phoneRegex = /^(\+?1[ -]?)?(\([0-9]{3}\)|[0-9]{3})[ -]?[0-9]{3}[- ]?[0-9]{4}( ?(x|ext|,) ?[0-9]+)?$/;
const phoneNumber = (value = '') => {
  if (validator.isEmpty(value)) return 'Required';
  if (!value.match(phoneRegex)) return 'Phone number appears invalid';
};

const email = (value = '') => {
  if (validator.isEmpty(value)) return 'Required';
  if (!validator.isEmail(value)) {
    return 'Please enter a valid e-mail address';
  }
};

const broker = {
  errors: {
    commission: decimal,
    address: {
      street_line_1: required,
      city: required,
      state: required,
      zip: zipCode,
    },
    phone: phoneNumber,
  },
  is_required: {
    address: {
      street_line_1: true,
      city: true,
      state: true,
      zip: true,
    },
    phone: true,
    commission: true,
  },
};

// sl_broker is not required at the Bind stage.
// These non-validations are provided so that the Brokers.jsx component
// can be reused in Policy Details, where sl_broker is required.
const sl_broker = {
  errors: {
    address: {
      street_line_1: optional,
      city: optional,
      state: optional,
      zip: optional,
    },
    license_number: optional,
    nj_transaction_number(value, allValues, props) {
      const state = get(props, 'namedInsured.address.state');
      if (String(state).toLowerCase() === 'nj') return required(value);
    },
    name: optional,
  },
  is_required: {
    address: {
      street_line_1: false,
      city: false,
      state: false,
      zip: false,
    },
    license_number: false,
    nj_transaction_number: insured_state => insured_state === 'NJ',
    name: false,
  },
};

const details = {
  errors: {
    broker: {
      name: required,
      brokerage: required,
      email: email,
    },
    named_insured: {
      name: required,
      address: {
        street_line_1: required,
        city: required,
        state: required,
        zip: zipCode,
      },
    },
  },
  is_required: {
    broker: {
      name: true,
      brokerage: true,
      email: true,
    },
    named_insured: {
      name: true,
      address: {
        street_line_1: true,
        city: true,
        state: true,
        zip: true,
      },
    },
  },
};

export { broker, sl_broker, details };
