import PropTypes from 'prop-types';
import React from 'react';

import Button from 'react-bootstrap/Button';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import ButtonToolbar from 'react-bootstrap/ButtonToolbar';

const styles = {
  wrapper: {
    display: 'inline-block',
  },
  button: {
    pointerEvents: 'none',
  },
};

export default function DisabledStartBindButton({ style, toolTipHoverText }) {
  return (
    <ButtonToolbar>
      <OverlayTrigger
        placement="right"
        overlay={<Tooltip id="tooltip">{toolTipHoverText}</Tooltip>}
      >
        <div style={styles.wrapper}>
          <Button
            variant="primary"
            style={{
              ...styles.button,
              ...style,
            }}
            disabled={true}
          >
            Start Bind
          </Button>
        </div>
      </OverlayTrigger>
    </ButtonToolbar>
  );
}

DisabledStartBindButton.propTypes = {
  toolTipHoverText: PropTypes.string.isRequired,
};
