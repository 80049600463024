import React from 'react';
import get from 'lodash/get';

import { formatters } from '../format';

const styles = {
  section_header: {
    fontSize: '1.25rem',
    margin: '20px 0 10px 0',
    display: 'inline-block',
  },
  list: {
    fontSize: '0.875rem',
    lineHeight: '1.5',
    listStyle: 'none',
    paddingLeft: '0',
  },
  list_item: {
    marginTop: '10px',
  },
  item: {
    color: 'rgba(155, 155, 155, 0.87)',
  },
  edit: {
    marginTop: '14px',
  },
};

// placeholder is an 'em-dash':
// http://www.chicagomanualofstyle.org/qanda/data/faq/topics/HyphensEnDashesEmDashes/faq0002.html
const placeholder = '\u2014';

const renderDefaultHeader = ({ styles }) => {
  return <h3 style={styles.section_header}>Endorsements</h3>;
};

const getEndorsementNotes = endorsements => {
  return endorsements
    .filter(endorsement => !!endorsement.supplementary_data)
    .map(endorsement => {
      return {
        label: endorsement.supplementary_data.nickname,
        fields: endorsement.supplementary_data.fields.map(field => {
          const format = formatters[field.format] || (x => x);
          const value = get(endorsement.data, field.name);
          return {
            label: field.label,
            value: value != null ? format(value) : placeholder,
          };
        }),
      };
    });
};

export default function Endorsements({
  style,
  endorsements,
  renderHeader = renderDefaultHeader,
  ...props
}) {
  const hasEndorsements = endorsements.length > 0;
  const notes = getEndorsementNotes(endorsements);
  return (
    <section style={style} {...props}>
      {renderHeader({ styles })}
      <ul style={styles.list}>
        {hasEndorsements ? (
          endorsements.map(({ value, title }, i) => {
            return (
              <li key={i} style={styles.list_item}>
                {title}
              </li>
            );
          })
        ) : (
          <p style={styles.item}>No Endorsements selected</p>
        )}
      </ul>
      {!!notes.length && (
        <div style={{ marginTop: '25px' }}>
          <h5>Endorsement Notes</h5>
          <ul>
            {notes.map(note => (
              <li key={note.label}>
                {note.label}:
                <ul
                  style={{
                    display: 'inline',
                    marginLeft: '10px',
                    paddingLeft: '0',
                  }}
                >
                  {note.fields.map(({ label, value }) => (
                    <li
                      key={label}
                      style={{ display: 'inline', marginLeft: '10px' }}
                    >
                      <strong>{label}:</strong> {value}
                    </li>
                  ))}
                </ul>
              </li>
            ))}
          </ul>
        </div>
      )}
    </section>
  );
}
