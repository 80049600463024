import React from 'react';

import { SelectControl } from '../../../common';

import SubjectivityWrapper from '../Wrapper';

import EditableRow from './EditableRow';

export default function NewSubjectivities(props) {
  return (
    <SelectControl
      {...props}
      renderSelectedItem={({ selection, ...selectProps }) => {
        return (
          <SubjectivityWrapper>
            <EditableRow {...selection} {...selectProps} />
          </SubjectivityWrapper>
        );
      }}
    />
  );
}
