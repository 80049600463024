import { connect } from 'react-redux';

import SearchQuotesBySubmission from './SearchQuotesBySubmission';

import { searchBySubmission as searchQuotesBySubmission } from '../../actions';

const mapDispatchToProps = (dispatch, props) => dispatch => ({
  searchQuotes: (...args) =>
    dispatch(searchQuotesBySubmission(props.submission.id, ...args)),
});

export default connect(null, mapDispatchToProps)(SearchQuotesBySubmission);
