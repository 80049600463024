import React, { Component } from 'react';
import get from 'lodash/get';
import FormGroup from 'react-bootstrap/FormGroup';
import FormControl from 'react-bootstrap/FormControl';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';

const componentizeAddress = place => {
  const google_to_output = {
    street_number: 'street_number',
    route: 'street_name',
    locality: 'city',
    sublocality: 'city',
    sublocality_level_1: 'city',
    administrative_area_level_1: 'state',
    postal_code: 'zip',
    postal_code_suffix: 'zip_suffix',
  };
  const address = place.address_components
    .filter(component => {
      const google_component = component.types[0];
      return google_to_output[google_component];
    })
    .reduce((address, component) => {
      const google_component = component.types[0];
      const protector_component = google_to_output[google_component];
      // Using `short_name` for cities sometimes causes problems (e.g., San Francisco),
      // so we're using `long_name` for cities.
      if (protector_component === 'city') {
        address[protector_component] = component.long_name;
      } else {
        address[protector_component] = component.short_name;
      }
      return address;
    }, {});
  return {
    street_line_1:
      (address.street_number ? address.street_number + ' ' : '') +
      address.street_name,
    city: address.city,
    state: address.state,
    zip: address.zip,
  };
};

const styles = {
  container: {
    backgroundColor: '#f0f0f0',
    padding: '10px',
    marginTop: '20px'
  },
  text: {
    fontSize: '0.625rem',
    marginBottom: 0,
  },
};

class MapAddress extends Component {
  state = { value: '' };

  componentDidMount() {
    this.init();
  }

  componentDidUpdate() {
    this.init();
  }

  componentWillUnmount() {
    if (get(window, 'google.maps')) {
      window.google.maps.event.removeListener(this.autocompleteListener);
    }
  }

  init = () => {
    if (!this.input || this.isInitialized) return;

    this.isInitialized = true;
    this.autocomplete = new window.google.maps.places.Autocomplete(this.input);
    this.autocompleteListener = this.autocomplete.addListener(
      'place_changed',
      this.handlePlaceSelect
    );
  };

  handlePlaceSelect = () => {
    this.props.onSelect(componentizeAddress(this.autocomplete.getPlace()));
  };

  render() {
    return (
      <FormGroup style={styles.container}>
        <InputGroup>
          <InputGroup.Text>
            <i className="bi bi-search"/>
          </InputGroup.Text>
          <FormControl
            type="text"
            placeholder="Search addresses"
            ref={input => (this.input = input)}
          />
        </InputGroup>
        <Form.Text style={styles.text} muted>
          Example searches: "100 Main St Brooklyn", "542 Market"
        </Form.Text>
      </FormGroup>
    );
  }
}

export default MapAddress;
