import React, { Component } from 'react';
import Button from 'react-bootstrap/Button';
import './DeletedPriceOptions.css';
import {
  StandardPriceOptionSummaryCard,
  permissions as rating_permissions,
} from '../../../../../../rating';

const styles = {
  summary_card: {
    margin: '0 0 20px',
  },
  banner: {
    padding: '0',
  },
};

class DeletedPriceOptions extends Component {
  state = {
    show: false,
  };

  hide = () => {
    this.setState({ show: false });
  };

  show = () => {
    this.setState({ show: true });
  };

  handleClick = () => {
    this.state.show ? this.hide() : this.show();
  };

  render() {
    let {
      addRatingToQuote,
      deletedRatings,
      quote,
      productConfig
    } = this.props;
    return (
      <React.Fragment>
        <div className="DeletedPriceOptions__banner">
          <Button
            variant="link"
            onClick={this.handleClick}
            style={styles.banner}
          >
            {this.state.show ? 'Hide ' : 'Show '} removed options
          </Button>
        </div>

        {this.state.show &&
          deletedRatings.map(rating => (
            <StandardPriceOptionSummaryCard
              style={styles.summary_card}
              key={rating.id}
              rating={rating}
              quote={quote}
              productConfig={productConfig}
              actions={[
                {
                  title: 'Restore',
                  onSelect: () => addRatingToQuote(rating.id),
                  disabled: !rating_permissions.canUpdateRating({
                    rating,
                  }),
                },
              ]}
            />
          ))}
      </React.Fragment>
    );
  }
}

export default DeletedPriceOptions;
