import { omit, set } from 'lodash';

import {
  GET_ENDORSEMENTS_BY_SUBMISSION_SUCCESS,
  ADD_ENDORSEMENT_TO_POLICY_SUCCESS,
  GET_ENDORSEMENT_OPTION_BY_ID_SUCCESS,
  GET_ENDORSEMENT_BY_ID_SUCCESS,
  DELETE_ENDORSEMENT_SUCCESS,
  BOOK_ENDORSEMENT_SUCCESS,
} from './constants';
import { GET_CONFIGURATION_SUCCESS } from '../common/constants';

const initial_state = {
  attached: {
    by_id: {},
  },
  options: {
    by_id: {},
  },
};

export default function endorsements(state = initial_state, action) {
  switch (action.type) {
    case ADD_ENDORSEMENT_TO_POLICY_SUCCESS:
    case GET_ENDORSEMENT_BY_ID_SUCCESS:
    case BOOK_ENDORSEMENT_SUCCESS:
      return handlers.storeEndorsement(state, action);
    case GET_ENDORSEMENTS_BY_SUBMISSION_SUCCESS:
      return handlers.storeEndorsements(state, action);
    case GET_CONFIGURATION_SUCCESS:
      return handlers.storeEndorsementOptions(state, action);
    case GET_ENDORSEMENT_OPTION_BY_ID_SUCCESS:
      return handlers.storeEndorsementOption(state, action);
    case DELETE_ENDORSEMENT_SUCCESS:
      return handlers.deleteEndorsement(state, action);
    default:
      return state;
  }
}

const handlers = {
  storeEndorsement(state, { payload }) {
    const { endorsement } = payload;
    return {
      ...state,
      attached: {
        ...state.attached,
        by_id: {
          ...state.attached.by_id,
          [endorsement.id]: endorsement,
        },
      },
    };
  },
  storeEndorsements(state, { payload }) {
    const { endorsements } = payload;
    return {
      ...state,
      attached: {
        ...state.attached,
        by_id: endorsements.reduce(
          (by_id, endorsement) => {
            by_id[endorsement.id] = endorsement;
            return by_id;
          },
          { ...state.attached.by_id }
        ),
      },
    };
  },
  storeEndorsementOptions(state, { payload }) {
    const config = payload.configuration;
    const endorsementsById = config.endorsements.reduce(
      (endorsementsById, endorsement) => {
        set(
          endorsementsById,
          `${endorsement.id}.${endorsement.version}`,
          endorsement
        );
        return endorsementsById;
      },
      {}
    );
    return {
      ...state,
      options: {
        ...state.options,
        by_id: {
          ...state.options.by_id,
          ...endorsementsById,
        },
      },
    };
  },
  storeEndorsementOption(state, { payload }) {
    const { option } = payload;
    return {
      ...state,
      options: {
        ...state.options,
        by_id: {
          ...state.options.by_id,
          [option.id]: {
            ...state.options.by_id[option.id],
            [option.version]: option,
          },
        },
      },
    };
  },
  deleteEndorsement(state, { payload }) {
    const { endorsement_id } = payload;
    return {
      ...state,
      attached: {
        ...state.attached,
        by_id: omit(state.attached.by_id, endorsement_id),
      },
    };
  },
};
