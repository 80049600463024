import { connect } from 'react-redux';

import { getBinderDocuments } from '../../actions';

import BinderDocuments from './BinderDocuments';

const mapDispatchToProps = (dispatch, props) => dispatch => ({
  getBinderDocuments() {
    return dispatch(getBinderDocuments(props.binder.id));
  },
});

export default connect(null, mapDispatchToProps)(BinderDocuments);
