import {
  INITIATE_AUTH_REQUEST,
  INITIATE_AUTH_SUCCESS,
  INITIATE_AUTH_FAILURE,
  CLEAR_USER_INFO
} from './constants';

export function init() {
  return (dispatch, getState, { auth }) => {
    dispatch({ type: INITIATE_AUTH_REQUEST });
    return auth
      .init()
      .then(() => {
        return auth.getCurrentUser();
      })
      .then(user => {
        dispatch({ type: INITIATE_AUTH_SUCCESS, payload: { user } });
      })
      .catch(e => {
        console.error(e);
        return dispatch(logout());
      });
  };
}

export function logout(path) {
  return (dispatch, getState, { auth }) => {
    return auth.signOut({ postLogoutRedirectUri: window.location.origin + path }).then(() => {
      dispatch({ type: INITIATE_AUTH_FAILURE });
    });
  };
}

export function clearUser() {
  return (dispatch, getState, { auth }) => {
    const state = getState();
    if(state?.auth?.user != null){
      dispatch({ type: CLEAR_USER_INFO });
    }
  }
};

