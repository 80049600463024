import React from 'react';

import { EndorsementsPreview } from '../../../endorsements';

export default function EndorsementsSection({ terms_conditions, ...props }) {
  return (
    <EndorsementsPreview
      {...props}
      endorsements={terms_conditions.endorsements}
    />
  );
}
