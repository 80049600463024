export const ENDORSEMENT_CONTEXT_MIDTERM = 'midterm';
export const ENDORSEMENT_CONTEXT_INCEPTION = 'inception';
export const GET_ENDORSEMENTS_BY_SUBMISSION_REQUEST =
  'GET_ENDORSEMENTS_BY_SUBMISSION_REQUEST';
export const GET_ENDORSEMENTS_BY_SUBMISSION_SUCCESS =
  'GET_ENDORSEMENTS_BY_SUBMISSION_SUCCESS';
export const GET_ENDORSEMENT_OPTION_URL_BY_ID_REQUEST =
  'GET_ENDORSEMENT_OPTION_URL_BY_ID_REQUEST';
export const GET_ENDORSEMENT_OPTION_URL_BY_ID_SUCCESS =
  'GET_ENDORSEMENT_OPTION_URL_BY_ID_SUCCESS';
export const GET_ENDORSEMENT_URL_BY_ID_REQUEST =
  'GET_ENDORSEMENT_URL_BY_ID_REQUEST';
export const GET_ENDORSEMENT_URL_BY_ID_SUCCESS =
  'GET_ENDORSEMENT_URL_BY_ID_SUCCESS';
export const ADD_ENDORSEMENT_TO_POLICY_REQUEST =
  'ADD_ENDORSEMENT_TO_POLICY_REQUEST';
export const ADD_ENDORSEMENT_TO_POLICY_SUCCESS =
  'ADD_ENDORSEMENT_TO_POLICY_SUCCESS';
export const GET_ENDORSEMENT_OPTION_BY_ID_REQUEST =
  'GET_ENDORSEMENT_OPTION_BY_ID_REQUEST';
export const GET_ENDORSEMENT_OPTION_BY_ID_SUCCESS =
  'GET_ENDORSEMENT_OPTION_BY_ID_SUCCESS';
export const GET_ENDORSEMENT_BY_ID_REQUEST = 'GET_ENDORSEMENT_BY_ID_REQUEST';
export const GET_ENDORSEMENT_BY_ID_SUCCESS = 'GET_ENDORSEMENT_BY_ID_SUCCESS';
export const DELETE_ENDORSEMENT_REQUEST = 'DELETE_ENDORSEMENT_REQUEST';
export const DELETE_ENDORSEMENT_SUCCESS = 'DELETE_ENDORSEMENT_SUCCESS';
export const BOOK_ENDORSEMENT_REQUEST = 'BOOK_ENDORSEMENT_REQUEST';
export const BOOK_ENDORSEMENT_SUCCESS = 'BOOK_ENDORSEMENT_SUCCESS';
