import React from 'react';

import { SubjectivitiesSummary } from '../../../subjectivities';

const combineSubjectivities = subjectivities => {
  return subjectivities.quote
    .filter(subjectivity => !subjectivity.resolved)
    .concat(subjectivities.binder);
};

export default function SubjectivitiesSection({ binder, ...props }) {
  const subjectivities = combineSubjectivities(
    binder.terms_conditions.subjectivities
  );
  return <SubjectivitiesSummary {...props} subjectivities={subjectivities} />;
}
