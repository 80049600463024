import React from 'react';
import { Link } from 'react-router-dom';

import {
  SUBMISSION_STATUSES,
  compareStatuses as compareSubmissionStatuses,
} from '../../../../submissions';
import {
  compareStatuses as compareBinderStatuses,
  BINDER_STATUSES,
} from '../../../../binder';

import AlertBanner from './AlertBanner';
import BaseSubmissionInfo from './BaseSubmissionInfo';

const styles = {
  outer_container: {
    border: 'solid 1px #979797',
  },
  inner_container: {
    padding: '26px 30px',
    backgroundColor: '#ffffff',
  },
  header: {
    container: {
      display: 'flex',
      marginBottom: '20px',
    },
    banner: {
      display: 'inline-block',
      flexGrow: '1',
      fontWeight: 'normal',
      margin: '0px',
    },
    submissionNumber: {
      alignSelf: 'center',
    },
  },
  expiring_policy: {
    fontSize: '12px',
    marginTop: '-15px',
    marginBottom: '12px',
  },
  banner: {
    container: {
      backgroundColor: 'rgba(240, 240, 240, 0.6)',
      borderTopColor: '#f7a700',
    },
    header: {
      color: '#f7a700',
      marginTop: '0px',
    },
  },
};

const formatSubmissionNumber = submission_number =>
  submission_number.match(/\d{4}/g).join('-');

function StartBinderBanner({ binder_id }) {
  return (
    <AlertBanner
      style={styles.banner}
      renderHeader={() => <h3 style={styles.banner.header}>Incomplete bind</h3>}
      renderBody={() =>
        'A Binder has been started but has not been booked for this policy.'
      }
      renderButton={() => (
        <Link
          className="btn btn-primary"
          to={`/binders/${binder_id}/terms-conditions`}
        >
          <span style={{whiteSpace: 'nowrap'}}>Resume Bind</span>
        </Link>
      )}
    />
  );
}

const isSubmissionBindingInProgress = submission => {
  return (
    compareSubmissionStatuses(
      submission.status,
      SUBMISSION_STATUSES.BINDING_IN_PROGRESS
    ) === 0
  );
};

const isBinderInProgress = binder => {
  return (
    compareBinderStatuses(
      binder.status,
      BINDER_STATUSES.BINDING_IN_PROGRESS
    ) === 0
  );
};

export default function InProgressSubmission({ submission, quotes, binder }) {
  const isRenewal = !!submission.renewed_from_policy;
  const isBindingInProgress =
    !!binder &&
    isSubmissionBindingInProgress(submission) &&
    isBinderInProgress(binder);
  const headerText = isRenewal ? 'Renewal Quote' : 'New Business Quote';
  return (
    <div style={styles.outer_container}>
      {isBindingInProgress && <StartBinderBanner binder_id={binder.id} />}
      <div style={styles.inner_container}>
        <div style={styles.header.container}>
          <h2 style={styles.header.banner}>{headerText}</h2>
          <section style={styles.header.submissionNumber}>
            Submission #{formatSubmissionNumber(submission.submission_number)}
          </section>
        </div>
        {isRenewal && (
          <div style={styles.expiring_policy}>
            Renewal of {submission.renewed_from_policy.policy_number}
          </div>
        )}
        <BaseSubmissionInfo quotes={quotes} submission={submission} />
      </div>
    </div>
  );
}
