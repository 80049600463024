import React from 'react';
import { Routes, Route, useNavigate } from 'react-router-dom';
import { LoginCallback } from '@okta/okta-react';
import { AuthProvider, toRelativeUrl } from '../../../auth';

import { NavigationBar } from '../../../common';
import { Home } from '../../../home';
import { ViewRatingContainer } from '../../../rating';
import { ViewQuoteContainer } from '../../../quote';
import { ViewBinderContainer } from '../../../binder';
import { ViewPolicyContainer } from '../../../policies';
import {
  CreateSubmission,
  ViewSubmissionContainer,
} from '../../../submissions';
import { MidTermEndorsementsContainer } from '../../../endorsements';
import { ViewAccountContainer } from '../../../accounts';
import { Login, Logout, SecureRoute } from '../../../auth';

export default function MainRoutes({ auth }) {
  const navigate = useNavigate();

  const restoreOriginalUri = async (_oktaAuth, originalUri) => {
    navigate(toRelativeUrl(originalUri || '/', window.location.origin));
  };

  return (
    <AuthProvider oktaAuth={auth} restoreOriginalUri={restoreOriginalUri}>
      <NavigationBar />
      <Routes>
        <Route
          path="/"
          element={
            <SecureRoute>
              <Home />
            </SecureRoute>
          }
        />
        <Route
          path="submissions/new"
          element={
            <SecureRoute>
              <CreateSubmission />
            </SecureRoute>
          }
        />
        <Route
          path="ratings/:rating_id/*"
          element={
            <SecureRoute>
              <ViewRatingContainer pathPattern="ratings/:rating_id/*" />
            </SecureRoute>
          }
        />
        <Route
          path="quotes/:quote_id/*"
          element={
            <SecureRoute>
              <ViewQuoteContainer pathPattern="quotes/:quote_id/*" />
            </SecureRoute>
          }
        />
        <Route
          path="binders/:binder_id/*"
          element={
            <SecureRoute>
              <ViewBinderContainer pathPattern="binders/:binder_id/*" />
            </SecureRoute>
          }
        />
        <Route
          path="policies/:policy_id/endorsements/mid-term/*"
          element={
            <SecureRoute>
              <MidTermEndorsementsContainer pathPattern="policies/:policy_id/endorsements/mid-term/*" />
            </SecureRoute>
          }
        />
        <Route
          path="policies/:policy_id/*"
          element={
            <SecureRoute>
              <ViewPolicyContainer pathPattern="policies/:policy_id/*" />
            </SecureRoute>
          }
        />
        <Route
          path="submissions/:submission_id/*"
          element={
            <SecureRoute>
              <ViewSubmissionContainer pathPattern="submissions/:submission_id/*" />
            </SecureRoute>
          }
        />
        <Route
          path="accounts/:account_id/*"
          element={
            <SecureRoute>
              <ViewAccountContainer pathPattern="accounts/:account_id/*" />
            </SecureRoute>
          }
        />
        <Route 
          path="login"
          element={<Login />}
        />
        <Route 
          path="login/success"
          element={<LoginCallback />}
        />
        <Route 
          path="logout"
          element={<Logout />}
        />
      </Routes>
    </AuthProvider>
  );
}
