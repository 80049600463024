const tableConfig = {
  columns: [
    {
      key: '0',
      title: 'Name',
    },
    {
      key: '1',
      title: 'Status',
    },
    {
      key: '2',
      title: 'Created',
    },
    {
      key: '3',
      title: 'Action',
    },
    {
      key: '4',
    },
  ],
};

export default tableConfig;
