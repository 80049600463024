import { connect } from 'react-redux';
import { getFormValues, submit } from 'redux-form';

import SubmitButton from './SubmitButton';

const mapStateToProps = (state, props) => {
  const form = props.formName;
  return state => {
    const values = getFormValues(form)(state);
    return {
      values,
    };
  };
};

const mapDispatchToProps = (dispatch, props) => {
  return dispatch => ({
    submitForm: () => dispatch(submit(props.formName)),
  });
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SubmitButton);
