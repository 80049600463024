import { RATING_STATUSES, compareStatuses } from './statuses';

export const canUpdatePremium = ({ rating }) => {
  const is_quoted = compareStatuses(rating.status, RATING_STATUSES.QUOTED) >= 0;
  const has_premium = !!rating.premium;
  return !is_quoted && has_premium;
};

export const canUpdateRating = ({ rating }) => {
  const is_quoted = compareStatuses(rating.status, RATING_STATUSES.QUOTED) >= 0;
  return !is_quoted;
};
