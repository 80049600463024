import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';

import { middleware as analyticsMiddleware } from '../../analytics';
import ApolloSync from '../../graphql/sync';

import createRootReducer from '../reducers';

export default function configureStore(initialState, args) {
  const { api, auth, apolloClient } = args;

  const enhancer = compose(
    applyMiddleware(
      thunk.withExtraArgument({
        api,
        auth,
        apolloClient,
      }),
      analyticsMiddleware,
      ApolloSync(apolloClient)
    )
  );

  const store = createStore(createRootReducer(), initialState, enhancer);

  return store;
}
