import React from 'react';
import { Link } from 'react-router-dom';

import { DownloadDocuments } from '../../../common';
import { PolicyDescription } from '../../../binder';

import 'spin.js/spin.css';

const styles = {
  link: {
    marginTop: '30px',
  },
};

export default function DownloadPolicyDocuments({ policy, refetch }) {
  return (
    <DownloadDocuments.Main fetch={() => refetch()} done={!!policy.documents}>
      <DownloadDocuments.Header>Policy download</DownloadDocuments.Header>
      {!policy.documents ? (
        <DownloadDocuments.InProgressDescription />
      ) : (
        <PolicyDescription policy_number={policy.policy_number}>
          The policy is issued!
        </PolicyDescription>
      )}
      <div style={{ marginTop: '30px' }}>
        <DownloadDocuments.Section
          loading={!policy.documents}
          renderHeader={() => (
            <DownloadDocuments.SectionHeader>
              Policy Document
            </DownloadDocuments.SectionHeader>
          )}
          renderBody={() => (
            <DownloadDocuments.Button url={policy.documents.location.url}>
              Download Document
            </DownloadDocuments.Button>
          )}
        />
      </div>
      <Link className="btn btn-link" style={styles.link} to="/">
        Return to Dashboard
      </Link>
    </DownloadDocuments.Main>
  );
}
