import React from 'react';
import get from 'lodash/get';
import numeral from 'numeral';

import mapValuesToLimitSummary from './mapValuesToLimitSummary';
import { mapFormValuesToLimitsRetentions } from '../PricingWorksheet/mapFormValuesToLimitsRetentions';

const styles = {
  container: {
    border: '1px solid #979797',
    padding: '20px 20px 10px',
  },
  header: {
    marginTop: '0',
  },
  aggregate: {
    fontSize: '1.25rem',
  },
  shared_section: {
    marginTop: '30px',
  },
  list: {
    listStyle: 'none',
    paddingLeft: '0',
  },
  text: {
    fontSize: '0.8125rem',
  },
};

const PrettyNameMap = {
  do: 'D&O',
  epl: 'EPL',
  fid: 'FID',
};

export default function LimitsSummary({ rating, values, style, className }) {
  const limit_type = get(values, 'type');
  const limit_summary = mapValuesToLimitSummary({
    ...rating,
    limits_retentions: mapFormValuesToLimitsRetentions(values, { rating }),
  });
  const coverages = get(rating, 'risk_analysis.selected_coverages', []);
  const more_than_one_coverage = coverages.length > 1;
  return (
    <section style={{ ...styles.container, ...style }} className={className}>
      <div>
        <header>
          <h4 style={styles.header}>Total aggregate</h4>
          <p style={styles.aggregate}>
            ${numeral(limit_summary.aggregate).format('0,0')}
          </p>
        </header>
        {more_than_one_coverage && (
          <div style={styles.shared_section}>
            <h4 style={styles.header}>Limit Sharing</h4>
            {limit_type === 'custom' ? (
              limit_summary.shared.length === 0 ? (
                <p style={styles.text}>No shared limits selected.</p>
              ) : (
                <ul style={styles.list}>
                  {limit_summary.shared.map(({ group, members, limit }) => (
                    <li key={group} style={styles.text}>
                      <strong>
                        Group {group} (
                        {members
                          .map(member => PrettyNameMap[member.coverage])
                          .join(', ')}
                        ):
                      </strong>
                      <p>${numeral(limit).format('0,0')}</p>
                    </li>
                  ))}
                </ul>
              )
            ) : (
              <p style={styles.text}>Aggregate limit shared for all sections</p>
            )}
          </div>
        )}
      </div>
    </section>
  );
}
