import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { getFormValues } from 'redux-form';
import ButtonToolbar from 'react-bootstrap/ButtonToolbar';
import Button from 'react-bootstrap/Button';
import isEmpty from 'lodash/isEmpty';

const FormStateSaver = props => {
  const [unsavedForm, setUnsavedForm] = useState(
    JSON.parse(
      props.storage.getItem(`protector.unsaved_forms.${props.form_name}`) ||
        'null'
    )
  );

  useEffect(() => {
    return () => {
      const has_values_to_save = !isEmpty(props.form_values);
      if (has_values_to_save && props.unsubmitted) {
        props.storage.setItem(
          `protector.unsaved_forms.${props.form_name}`,
          JSON.stringify(props.form_values || null)
        );
      } else if (!props.unsubmitted) {
        clearUnsavedForm();
      }
    };
  });

  function restoreForm() {
    props.restore(unsavedForm);
    clearUnsavedForm();
  }

  function removeCachedForm() {
    setUnsavedForm(null);
  }

  function clearUnsavedForm() {
    props.storage.removeItem(`protector.unsaved_forms.${props.form_name}`);
    removeCachedForm();
  }

  return !unsavedForm ? null : (
    <section style={props.style}>
      <p style={{ fontSize: '0.875rem' }}>
        You started this form, but left without submitting. You can Restore 
        your progress or Continue with a new submission.
      </p>
      <ButtonToolbar>
        <Button variant="primary" onClick={restoreForm}>
          Restore Progress
        </Button>
        <Button variant="default" onClick={clearUnsavedForm}>
          Continue New Submission
        </Button>
      </ButtonToolbar>
    </section>
  );
};

export default connect((state, props) => {
  const selector = getFormValues(props.form_name);
  return state => {
    const form_values = selector(state);
    return {
      form_values,
      storage: localStorage,
    };
  };
})(FormStateSaver);
