import {
  compareStatuses as compareSubmissionStatuses,
  SUBMISSION_STATUSES,
} from '../submissions';

import {
  compareStatuses as compareBinderStatuses,
  BINDER_STATUSES,
} from './statuses';

export const canBindPolicy = ({ binder, submission }) => {
  const is_submission_bound =
    compareSubmissionStatuses(submission.status, SUBMISSION_STATUSES.BOUND) >=
    0;
  const is_binder_bound =
    compareBinderStatuses(binder.status, BINDER_STATUSES.BOUND) >= 0;
  return !is_binder_bound && !is_submission_bound;
};

export const canDownloadDocuments = ({ binder }) => {
  return compareBinderStatuses(binder.status, BINDER_STATUSES.BOUND) >= 0;
};

export const canUpdateBroker = ({ binder, submission }) => {
  const is_binder_bound =
    compareBinderStatuses(binder.status, BINDER_STATUSES.BOUND) >= 0;
  return !is_binder_bound;
};

export const canUpdateSLBroker = ({ binder, submission }) => {
  const is_binder_bound =
    compareBinderStatuses(binder.status, BINDER_STATUSES.BOUND) >= 0;
  return !is_binder_bound;
};

export const canUpdateTermsConditions = ({ binder }) => {
  const is_binder_bound =
    compareBinderStatuses(binder.status, BINDER_STATUSES.BOUND) >= 0;
  return !is_binder_bound;
};

export const canCancelBinder = ({ binder }) => {
  const is_binder_bound =
    compareBinderStatuses(binder.status, BINDER_STATUSES.BOUND) >= 0;
  return !is_binder_bound;
};
