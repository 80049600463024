import React from 'react';
import BrokerActive from './BrokerActive';
import BrokerInactive from './BrokerInactive';

const styles = {
  section_header: {
    fontSize: '1.25rem',
    marginBottom: '20px',
  },
};

class Broker extends React.Component {
  state = { active: false };
  makeActive = () => {
    this.setState({ active: true });
  };
  makeInactive = () => {
    this.setState({ active: false });
  };
  render() {
    const { style, broker, updateBroker, is_locked, ...props } = this.props;
    return (
      <section style={style} {...props}>
        <h3 style={styles.section_header}>Broker details</h3>
        {this.state.active ? (
          <BrokerActive
            makeInactive={this.makeInactive}
            broker={broker}
            updateBroker={updateBroker}
            initialValues={{
              ...broker,
              // coercion to a string is necessary to avoid issues with validation
              commission: broker.commission && broker.commission.toString(10),
            }}
          />
        ) : (
          <BrokerInactive
            makeActive={this.makeActive}
            broker={broker}
            is_locked={is_locked}
          />
        )}
      </section>
    );
  }
}
export default Broker;
