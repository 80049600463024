import React from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';

import AlertBanner from './AlertBanner';
import BaseSubmissionInfo from './BaseSubmissionInfo';
import PolicyDocuments from './PolicyDocuments';
import { InlineButton, Toggleable } from '../../../../common';
import { permissions as permissions_p } from '../../../../policies';

const EN_DASH = '\u2013';

const styles = {
  outer_container: {
    border: 'solid 1px #979797',
    boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.5)',
  },
  inner_container: {
    padding: '26px 30px',
    backgroundColor: '#f5f5f5',
  },
  header: {
    container: {
      display: 'flex',
      marginBottom: '20px',
    },
    banner: {
      display: 'inline-block',
      fontWeight: 'normal',
      flexGrow: '1',
      margin: '0px',
    },
    submissionNumber: {
      alignSelf: 'center',
    },
  },
  expiring_policy: {
    fontSize: '12px',
    marginTop: '-15px',
  },
  baseSubmissionInfo: {
    padding: '26px 30px',
  },
  policyPeriod: {
    label: {
      fontSize: '0.875rem',
      fontWeight: 'bold',
      marginBottom: '6px',
      textTransform: 'uppercase',
    },
    dates: {
      fontSize: '14px',
      marginBottom: '20px',
    },
  },
  sectionHeader: {
    fontSize: '20px',
  },
  banner: {
    container: {
      backgroundColor: 'rgba(65, 117, 5, 0.13)',
      borderTopColor: '#417505',
    },
    header: {
      color: '#417505',
      marginTop: '0px',
    },
  },
  message: {
    marginRight: '10px'
  }
};

const formatSubmissionNumber = submission_number =>
  submission_number.match(/\d{4}/g).join('-');

function BoundPolicyBanner({ policy_id }) {
  return (
    <AlertBanner
      style={styles.banner}
      renderHeader={() => (
        <h3 style={styles.banner.header}>Submission has been Bound</h3>
      )}
      renderBody={() => (
        <div style={styles.message}>
          Issue policy when you are ready to generate the policy documents.
        </div>
      )}
      renderButton={() => (
        <Link
          className="btn btn-primary"
          to={`/policies/${policy_id}/terms-conditions`}
        >
          <span style={{whiteSpace: 'nowrap'}}>Issue Policy</span>
        </Link>
      )}
    />
  );
}

export default function InProgressSubmission({
  submission,
  quotes,
  binder,
  policy,
  endorsements,
}) {
  const isRenewal = !!submission.renewed_from_policy;
  return (
    <div style={styles.outer_container}>
      {permissions_p.canIssuePolicy({
        policy,
        submission,
      }) && <BoundPolicyBanner policy_id={policy.id} />}
      <div style={styles.inner_container}>
        <div style={styles.header.container}>
          <h2 style={styles.header.banner}>Policy {binder.policy_number}</h2>
          <section style={styles.header.submissionNumber}>
            Submission #{formatSubmissionNumber(submission.submission_number)}
          </section>
        </div>
        {isRenewal && (
          <div style={styles.expiring_policy}>
            Renewal of {submission.renewed_from_policy.policy_number}
          </div>
        )}
        <h3 style={styles.policyPeriod.label}>Policy Period (Inception)</h3>
        <div style={styles.policyPeriod.dates}>
          {moment(binder.policy_effective_date)
            .utc()
            .format('MMMM D YYYY')}
          {EN_DASH}
          {moment(binder.policy_expiration_date)
            .utc()
            .format('MMMM D YYYY')}
        </div>
        <h4 style={styles.sectionHeader}>Policy Documents</h4>
        <PolicyDocuments
          binder={binder}
          policy={policy}
          endorsements={endorsements}
        />
        {permissions_p.canAddEndorsement({ policy }) && (
          <Link to={`/policies/${policy.id}/endorsements/mid-term`}>
            Add Endorsement
          </Link>
        )}
      </div>
      <div style={styles.baseSubmissionInfo}>
        <Toggleable
          render={({ visible, show, hide }) => {
            if (visible) {
              return (
                <React.Fragment>
                  <InlineButton onClick={hide}>Hide Quote History</InlineButton>
                  <BaseSubmissionInfo quotes={quotes} submission={submission} />
                </React.Fragment>
              );
            }
            return (
              <InlineButton onClick={show}>Show Quote History</InlineButton>
            );
          }}
        />
      </div>
    </div>
  );
}
