import * as validation from './validation';
import { isFunction } from 'lodash';

function sanitize(input) {
  return isNaN(input) ? input : input.toString();
}

// Recursively walks a validator object, given a target object which should
// have the same shape as the validator object.

// If a validator property is a function, invoke it to validate the
// corresponding target object property. Otherwise, recursively call this
// function with a slice of the validator object and the corresponding slice
// of the target object.
function validate(target, validator) {
  try {
    return Object.keys(validator).reduce((result, key) => {
      return (
        result &&
        (isFunction(validator[key])
          ? // We're inverting the result of the function call below because
            // the validation functions below return error message strings if
            // the input is invalid and falsy values if the input is valid. Thus,
            // using the not operator produces a boolean value where true = valid
            // and false = invalid
            !validator[key](sanitize(target[key]))
          : validate(target[key], validator[key]))
      );
    }, true);
  } catch (e) {
    return false;
  }
}

export default function isValid({ quote }) {
  const { broker } = quote;
  return (
    validate(broker, validation.broker.errors) &&
    validate(quote, validation.details.errors)
  );
}
