const CreateAndClearSubmission = (fetch, auth) => submission => {
  return auth
    .getAccessToken()
    .then(token => {
      if (!token) {
        auth.signOut();
        return Promise.reject(new Error('User is not authenticated.'));
      }
      return token;
    })
    .then(token =>
      fetch('/submissions', {
        method: 'POST',
        headers: {
          Authorization: token,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          submission,
        }),
      })
    )
    .then(response => response.json());
};

export default CreateAndClearSubmission;
