import { connect } from 'react-redux';
import { withRouter } from '../../../../common/components/WithRouter';

import { actions as binder } from '../../../../binder';
import StartBinderPriceOptionsSummaryCard from './StartBinder';

const mapDispatchToProps = (dispatch, props) => {
  return dispatch => ({
    startBinder: () => {
      const { rating, quote } = props;
      const rating_id = rating.id;
      const quote_id = quote.id;
      binder.createBinder({ rating_id, quote_id });
      return dispatch(
        binder.createBinder({
          quote_id,
          rating_id,
        })
      ).then(({ binder }) => {
        return props.navigate(`/binders/${binder.id}/terms-conditions`);
      });
    },
  });
};

export default withRouter(
  connect(null, mapDispatchToProps)(StartBinderPriceOptionsSummaryCard)
);
